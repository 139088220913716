export enum CustomClientColors {
    BNC,
}

/**
 * Gray
 */
export const gray25 = '#FCFCFD'
export const gray50 = '#F8FAFC'
export const gray100 = '#EEF2F6'
export const gray200 = '#E3E8EF'
export const gray300 = '#CDD5DF'
export const gray400 = '#9AA3B2'
export const gray500 = '#697586'
export const gray600 = '#4B5565'
export const gray700 = '#364152'
export const gray800 = '#202939'
export const gray900 = '#121926'

/**
 * Primary
 */
export const primary25 = '#FAFBFB'
export const primary50 = '#F3F5F4'
export const primary100 = '#E7EAEA'
export const primary200 = '#9FADAA'
export const primary300 = '#6F8480'
export const primary400 = '#576F6B'
export const primary500 = '#3F5B55'
export const primary600 = '#0F322B'
export const primary700 = '#0C2822'
export const primary800 = '#091E1A'
export const primary900 = '#061411'

/**
 * Error
 */
export const error500 = '#F04438'
export const error600 = '#D92D20'

/**
 * Warning
 */
export const warning600 = '#DC6803'

/**
 * Success
 */
export const success600 = '#039855'

export const setColors = (useNewTheme: boolean, custom?: CustomClientColors) => {
    const root = document.documentElement
    const body = document.body
    if (useNewTheme) {
        root.style.setProperty('--ds-color-primary-1', primary600)
        root.style.setProperty('--ds-color-primary-2', gray700)
        root.style.setProperty('--ds-color-primary-4', gray50)
        root.style.setProperty('--ds-color-secondary-2', primary700)
        root.style.setProperty('--ds-color-secondary-3', gray700)
        root.style.setProperty('--ds-color-secondary-5', gray500)
        root.style.setProperty('--ds-color-secondary-6', gray300)
        root.style.setProperty('--ds-color-secondary-8', error500)
        root.style.setProperty('--ds-color-secondary-9', warning600)
        root.style.setProperty('--ds-color-secondary-10', success600)
        root.style.setProperty('--ds-color-state-hover', gray50)
        root.style.setProperty('--ds-color-state-selected', gray400)
        body.style.setProperty('--color-main-1', primary600)
        body.style.setProperty('--color-main-2', gray700)
        body.style.setProperty('--color-main-4', gray50)
        body.style.setProperty('--color-status-1', success600)
        body.style.setProperty('--color-status-2', error600)
        body.style.setProperty('--color-status-3', warning600)
        body.style.setProperty('--color-status-4', gray600)

        /* UntitledUI */

        /* Brand */
        root.style.setProperty('--brand-malibu', primary500)
        root.style.setProperty('--brand-mysin', '#443993')
        root.style.setProperty('--brand-botticelli', gray50)

        /* Gray */
        root.style.setProperty('--gray-25', gray25)
        root.style.setProperty('--gray-50', gray50)
        root.style.setProperty('--gray-100', gray100)
        root.style.setProperty('--gray-200', gray200)
        root.style.setProperty('--gray-300', gray300)
        root.style.setProperty('--gray-400', gray400)
        root.style.setProperty('--gray-500', gray500)
        root.style.setProperty('--gray-600', gray600)
        root.style.setProperty('--gray-700', gray700)
        root.style.setProperty('--gray-800', gray800)
        root.style.setProperty('--gray-900', gray900)

        /* Primary */
        root.style.setProperty('--primary-25', primary25)
        root.style.setProperty('--primary-50', primary50)
        root.style.setProperty('--primary-100', primary100)
        root.style.setProperty('--primary-200', primary200)
        root.style.setProperty('--primary-300', primary300)
        root.style.setProperty('--primary-400', primary400)
        root.style.setProperty('--primary-500', primary500)
        root.style.setProperty('--primary-600', primary600)
        root.style.setProperty('--primary-700', primary700)
        root.style.setProperty('--primary-800', primary800)
        root.style.setProperty('--primary-900', primary900)
    }
    if (custom === CustomClientColors.BNC) {
        root.style.setProperty('--color-main-1', '#363e3c')
        root.style.setProperty('--color-main-2', '#4a4a4a')
        root.style.setProperty('--color-main-3', '#edeff0')
        root.style.setProperty('--color-main-4', '#f5f8fa')
        root.style.setProperty('--color-main-5', '#ffffff')
        root.style.setProperty('--color-secondary-1', '#2df3c7')
        root.style.setProperty('--color-secondary-2', '#c6d3da')
        root.style.setProperty('--color-secondary-3', '#6a6a6a')
        root.style.setProperty('--color-secondary-4', '#9b9b9b')
        root.style.setProperty('--color-secondary-5', '#ffb64e')
        root.style.setProperty('--color-secondary-6', '#ff5959')
        root.style.setProperty('--color-secondary-7', '#ea3d3d')
        root.style.setProperty('--color-secondary-8', '#092c4d')
        root.style.setProperty('--color-secondary-9', '#0057a8')
        root.style.setProperty('--color-status-1', '#75c81b')
        root.style.setProperty('--color-status-2', '#ff5959')
        root.style.setProperty('--color-status-3', '#ffb64e')
        root.style.setProperty('--color-status-4', '#9b9b9b')
        root.style.setProperty('--color-value-negative', '#ff5959')
        root.style.setProperty('--color-value-positive', '#7ed321')
        root.style.setProperty('--color-inactive', '#959595')
        root.style.setProperty('--color-link', '#0072db')
        root.style.setProperty('--color-currency-value', '#363e3c')
        root.style.setProperty('--color-value-positive-leftmenu', '#9df33f')
        root.style.setProperty('--ds-color-valuesecondary', 'var(--ds-color-menublue-valuesecondary)')
        root.style.setProperty('--ds-color-valueprimary', 'var(--ds-color-menublue-valueprimary)')
        root.style.setProperty('--ds-color-sectionunselected', '#9b9b9b')
        root.style.setProperty('--ds-color-sectionselected', 'var(--ds-color-menublue-sectionselected)')
        root.style.setProperty('--ds-color-scroll', 'var(--ds-color-menublue-scroll)')
        root.style.setProperty('--ds-color-progressbarbackground', 'var(--ds-color-menublue-progressbarbackground)')
        root.style.setProperty('--ds-color-progressbar', 'var(--ds-color-menublue-progressbar)')
        root.style.setProperty('--ds-color-menubackground-secondary', '#363e3c')
        root.style.setProperty('--ds-color-menubackground-primary', '#363e3c')
        root.style.setProperty('--ds-color-labelunselected', '#9b9b9b')
        root.style.setProperty('--ds-color-labelselected', 'var(--ds-color-menublue-labelselected)')
        root.style.setProperty('--ds-color-state-selected', 'rgba(0, 0, 0, 0.2)')
        root.style.setProperty('--ds-color-state-hover', 'rgba(0, 0, 0, 0.1)')
        root.style.setProperty('--ds-color-secondary-9', 'rgba(255, 182, 78, 1)')
        root.style.setProperty('--ds-color-secondary-8', 'rgba(255, 89, 89, 1)')
        root.style.setProperty('--ds-color-secondary-7', 'rgba(234, 61, 61, 1)')
        root.style.setProperty('--ds-color-secondary-6', 'rgba(198, 211, 218, 1)')
        root.style.setProperty('--ds-color-secondary-5', 'rgba(155, 155, 155, 1)')
        root.style.setProperty('--ds-color-secondary-4', 'rgba(106, 106, 106, 1)')
        root.style.setProperty('--ds-color-secondary-3', 'rgba(59, 59, 59, 1)')
        root.style.setProperty('--ds-color-secondary-2', '#9b9b9b')
        root.style.setProperty('--ds-color-secondary-13', 'rgba(255, 233, 198, 1)')
        root.style.setProperty('--ds-color-secondary-12', 'rgba(255, 208, 208, 1)')
        root.style.setProperty('--ds-color-secondary-11', 'rgba(190, 224, 255, 1)')
        root.style.setProperty('--ds-color-secondary-10', 'rgba(117, 200, 27, 1)')
        root.style.setProperty('--ds-color-secondary-1', 'rgba(14, 50, 84, 1)')
        root.style.setProperty('--ds-color-primary-5', 'rgba(255, 255, 255, 1)')
        root.style.setProperty('--ds-color-primary-4', 'rgba(245, 248, 250, 1)')
        root.style.setProperty('--ds-color-primary-3', 'rgba(237, 239, 240, 1)')
        root.style.setProperty('--ds-color-primary-2', 'rgba(74, 74, 74, 1)')
        root.style.setProperty('--ds-color-primary-1', '#363E3C')
        root.style.setProperty('--ds-color-menulight-valuesecondary', 'rgba(255, 182, 78, 1)')
        root.style.setProperty('--ds-color-menulight-valueprimary', 'rgba(117, 200, 27, 1)')
        root.style.setProperty('--ds-color-menulight-sectionunselected', 'rgba(155, 155, 155, 1)')
        root.style.setProperty('--ds-color-menulight-sectionselected', 'rgba(0, 114, 219, 1)')
        root.style.setProperty('--ds-color-menulight-scroll', 'rgba(0, 0, 0, 0.3)')
        root.style.setProperty('--ds-color-menulight-progressbarbackground', 'rgba(0, 0, 0, 0.2)')
        root.style.setProperty('--ds-color-menulight-progressbar', 'rgba(255, 182, 78, 1)')
        root.style.setProperty('--ds-color-menulight-menubackground-secondary', 'rgba(245, 248, 250, 1)')
        root.style.setProperty('--ds-color-menulight-menubackground-primary', 'rgba(255, 255, 255, 1)')
        root.style.setProperty('--ds-color-menulight-labelunselected', 'rgba(74, 74, 74, 1)')
        root.style.setProperty('--ds-color-menulight-labelselected', 'rgba(0, 114, 219, 1)')
        root.style.setProperty('--ds-color-menudark-valuesecondary', 'rgba(255, 182, 78, 1)')
        root.style.setProperty('--ds-color-menudark-valueprimary', 'rgba(157, 243, 63, 1)')
        root.style.setProperty('--ds-color-menudark-sectionunselected', 'rgba(155, 155, 155, 1)')
        root.style.setProperty('--ds-color-menudark-sectionselected', 'rgba(255, 255, 255, 1)')
        root.style.setProperty('--ds-color-menudark-scroll', 'rgba(0, 0, 0, 0.3)')
        root.style.setProperty('--ds-color-menudark-progressbarbackground', 'rgba(0, 0, 0, 0.2)')
        root.style.setProperty('--ds-color-menudark-progressbar', 'rgba(255, 182, 78, 1)')
        root.style.setProperty('--ds-color-menudark-menubackground-secondary', 'rgba(59, 59, 59, 1)')
        root.style.setProperty('--ds-color-menudark-menubackground-primary', 'rgba(74, 74, 74, 1)')
        root.style.setProperty('--ds-color-menudark-labelunselected', 'rgba(255, 255, 255, 1)')
        root.style.setProperty('--ds-color-menudark-labelselected', 'rgba(255, 255, 255, 1)')
        root.style.setProperty('--ds-color-menublue-valuesecondary', 'rgba(255, 182, 78, 1)')
        root.style.setProperty('--ds-color-menublue-valueprimary', 'rgba(157, 243, 63, 1)')
        root.style.setProperty('--ds-color-menublue-sectionunselected', 'rgba(58, 160, 255, 1)')
        root.style.setProperty('--ds-color-menublue-sectionselected', 'rgba(255, 255, 255, 1)')
        root.style.setProperty('--ds-color-menublue-scroll', 'rgba(0, 0, 0, 0.3)')
        root.style.setProperty('--ds-color-menublue-progressbarbackground', 'rgba(0, 0, 0, 0.2)')
        root.style.setProperty('--ds-color-menublue-progressbar', 'rgba(255, 182, 78, 1)')
        root.style.setProperty('--ds-color-menublue-menubackground-secondary', 'rgba(0, 102, 197, 1)')
        root.style.setProperty('--ds-color-menublue-menubackground-primary', 'rgba(0, 114, 219, 1)')
        root.style.setProperty('--ds-color-menublue-labelunselected', 'rgba(255, 255, 255, 1)')
        root.style.setProperty('--ds-color-menublue-labelselected', 'rgba(255, 255, 255, 1)')
        root.style.setProperty('--ds-color-background-modal', 'rgba(0, 0, 0, 0.35)')
        root.style.setProperty('--gray-25', '#FCFCFD')
        root.style.setProperty('--gray-50', '#F8FAFC')
        root.style.setProperty('--gray-100', '#EEF2F6')
        root.style.setProperty('--gray-200', '#E3E8EF')
        root.style.setProperty('--gray-300', '#CDD5DF')
        root.style.setProperty('--gray-400', '#9AA3B2')
        root.style.setProperty('--gray-500', '#697586')
        root.style.setProperty('--gray-600', '#4B5565')
        root.style.setProperty('--gray-700', '#364152')
        root.style.setProperty('--gray-800', '#202939')
        root.style.setProperty('--gray-900', '#121926')
        root.style.setProperty('--primary-25', '#FFFAFA')
        root.style.setProperty('--primary-50', '#FBEBEA')
        root.style.setProperty('--primary-100', '#F1C1BF')
        root.style.setProperty('--primary-200', '#EBA3A0')
        root.style.setProperty('--primary-300', '#E27874')
        root.style.setProperty('--primary-400', '#DC5E59')
        root.style.setProperty('--primary-500', '#D33630')
        root.style.setProperty('--primary-600', '#C0312C')
        root.style.setProperty('--primary-700', '#962622')
        root.style.setProperty('--primary-800', '#741E1A')
        root.style.setProperty('--primary-900', '#591714')
    }
}

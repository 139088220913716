import React from 'react'
import Button, { ButtonSizes } from '../Button'
import { GenericAttributes } from '../../types'

type ButtonPositiveProps = Omit<React.ComponentProps<'button'>, 'size'> & {
    size?: ButtonSizes
} & GenericAttributes

export const ButtonPositive = ({ size = 'large', children, ...props }: ButtonPositiveProps) => {
    return (
        <Button {...props} size={size} mode={'positive'}>
            {children}
        </Button>
    )
}
export default ButtonPositive

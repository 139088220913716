import {
    SyncRequest, // Type import SyncRequest
    SyncResponse, // Type import SyncResponse
    TransferCurrentHoldingRequest, // Type import TransferCurrentHoldingRequest
    SplitStockRequest, // Type import SplitStockRequest
    DebtForgivenessRequest, // Type import DebtForgivenessRequest
    DebtPaymentRequest, // Type import DebtPaymentRequest
    RepatriateRequest, // Type import RepatriateRequest
    AssetTransferRequest, // Type import AssetTransferRequest
    AssetDistributionRequest, // Type import AssetDistributionRequest
    Page, // Type Pagination of list
} from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api, ResponseWithHeader } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Inventory-management
 */
export class InventoryManagementApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * Sync Transactions and Holdings
     * ${estateId}/inventory/${inventoryId}/holdingsParser/sync
     **/

    async syncTransactionsAndHoldings(estateId: string, inventoryId: string, body: SyncRequest): Promise<SyncResponse> {
        try {
            const res: AxiosResponse<SyncResponse> = await this.post<SyncResponse, SyncRequest>(`/estates/${estateId}/inventory/${inventoryId}/holdingsParser/sync`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Export
     * ${estateId}/inventory/export
     **/

    async exportInventory(estateId: string): Promise<Buffer> {
        try {
            const res: AxiosResponse<Buffer> = await this.get<Buffer>(`/estates/${estateId}/inventory/export`, { responseType: 'arraybuffer', params: {} })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Transfer a holding from a managed account to another managed account
     * ${estateId}/inventory/${inventoryId}/transferCurrentHolding
     **/

    async transferCurrentHolding(estateId: string, inventoryId: string, body: TransferCurrentHoldingRequest): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.post<void, TransferCurrentHoldingRequest>(`/estates/${estateId}/inventory/${inventoryId}/transferCurrentHolding`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Split a holding stock in n quantity
     * ${estateId}/inventory/${inventoryId}/stockSplit
     **/

    async stockSplit(estateId: string, inventoryId: string, body: SplitStockRequest): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.post<void, SplitStockRequest>(`/estates/${estateId}/inventory/${inventoryId}/stockSplit`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Forgive a debt
     * ${estateId}/inventory/${inventoryId}/forgive
     **/

    async setDebtForgiveness(estateId: string, inventoryId: string, body: DebtForgivenessRequest): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.post<void, DebtForgivenessRequest>(`/estates/${estateId}/inventory/${inventoryId}/forgive`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Pay a debt
     * ${estateId}/inventory/${inventoryId}/pay
     **/

    async payDebt(estateId: string, inventoryId: string, body: DebtPaymentRequest): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.post<void, DebtPaymentRequest>(`/estates/${estateId}/inventory/${inventoryId}/pay`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Repatriate Holdings of a Deceased investment account
     * ${estateId}/inventory/${inventoryId}/repatriate
     **/

    async repatriate(estateId: string, inventoryId: string, body: RepatriateRequest): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.post<void, RepatriateRequest>(`/estates/${estateId}/inventory/${inventoryId}/repatriate`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Transfer an asset
     * ${estateId}/inventory/${inventoryId}/transfer
     **/

    async transferAsset(estateId: string, inventoryId: string, body: AssetTransferRequest): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.post<void, AssetTransferRequest>(`/estates/${estateId}/inventory/${inventoryId}/transfer`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Distribute an asset
     * ${estateId}/inventory/${inventoryId}/distribute
     **/

    async distributeAsset(estateId: string, inventoryId: string, body: AssetDistributionRequest): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.post<void, AssetDistributionRequest>(`/estates/${estateId}/inventory/${inventoryId}/distribute`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

import React, { PropsWithChildren } from 'react'
import style from './style.module.scss'
import { GenericAttributes } from '../../types'

const CategorySidebar = ({ children, ...props }: PropsWithChildren<GenericAttributes>) => {
    return (
        <h2 {...props} data-test={`${props['data-test']}`} className={style.categorySidebar}>
            {children}
        </h2>
    )
}
export default React.memo(CategorySidebar)

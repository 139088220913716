import { Page, TeamMemberAddRequest, TeamMemberResponseList } from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Team-members
 */
export class TeamMembersApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * Delete a team member
     * ${orgId}/teams/${teamId}/members/${userId}
     **/

    async deleteTeamMember(userId: string, orgId: string, teamId: string): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.delete<void>(`/orgs/${orgId}/teams/${teamId}/members/${userId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Add a member to a team
     * ${orgId}/teams/${teamId}/members
     **/

    async addTeamMember(orgId: string, teamId: string, body: TeamMemberAddRequest): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.post<void, TeamMemberAddRequest>(`/orgs/${orgId}/teams/${teamId}/members`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * List team members
     * ${orgId}/teams/${teamId}/members
     **/

    async listTeamMembers(orgId: string, teamId: string, page?: Page, filter?: object, search?: string, sort?: string): Promise<TeamMemberResponseList> {
        try {
            const res: AxiosResponse<TeamMemberResponseList> = await this.get<TeamMemberResponseList>(`/orgs/${orgId}/teams/${teamId}/members`, { params: { page, filter, search, sort } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

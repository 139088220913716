import React, { useMemo } from 'react'
import style from './style.module.scss'
import AvatarsGroup, { AvatarsGroupItem } from '../../molecules/AvatarsGroup'
import Link from '../../atoms/Link'
import { GenericAttributes } from '../../types'

interface SmallAvatarDetailLink {
    label: string
    onClick: (ev: React.MouseEvent<HTMLElement>) => void
}
type SmallAvatarDetailProps = {
    label?: string
    list?: AvatarsGroupItem[]
    max?: number
    links?: SmallAvatarDetailLink[]
} & GenericAttributes

export const SmallAvatarDetail = ({ links = new Array<SmallAvatarDetailLink>(), max = 2, list = new Array<AvatarsGroupItem>(), label, ...props }: SmallAvatarDetailProps) => {
    const maxThreeLinksArray = useMemo(() => links && links.slice(0, 3), [links])
    return (
        <div {...props} data-test={props['data-test']} className={[style.smallAvatarDetail].join(' ')}>
            <div className={style.avatars}>
                <AvatarsGroup data-test={`${props['data-test']}.avatar`} max={max} list={list} />
            </div>
            <div className={style.col2}>
                <div className={style.label} data-test={`${props['data-test']}.label`}>
                    {label}
                </div>
                <div className={style.links}>
                    {maxThreeLinksArray?.map((link, idx) => (
                        <Link key={idx} onClick={link.onClick} data-test={`${props['data-test']}.links.${idx}`}>
                            {link.label}
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default React.memo(SmallAvatarDetail)

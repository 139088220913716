import { ACTION_GET_AUTH_CONFIG } from '../Login/actions'
import { ACTION_FETCH_ORGANIZATION, ACTION_FETCH_PROFILE, ACTION_FETCH_PROFILE_PICTURE, ACTION_FETCH_TEAMS, ACTION_SAVE_PROFILE, CLOSE_PROFILE, EDIT_PROFILE } from './actions'

const DEFAULT_STATE = {
    userInfo: null,
    errMsg: '',
    saved: false,
    show: false,
    teams: [],
    profilePic: null,
    sso: { enabled: false },
}

export default (state = DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case `ACTION_LOGOFF`:
            return DEFAULT_STATE
        case `${ACTION_FETCH_PROFILE}_PENDING`:
            return { ...state, userInfo: null }
        case `${EDIT_PROFILE}`:
            return { ...state, show: true }
        case `${CLOSE_PROFILE}`:
            return { ...state, show: false }
        case `${ACTION_FETCH_PROFILE}_FULFILLED`:
            return { ...state, userInfo: payload }
        case `${ACTION_FETCH_PROFILE_PICTURE}_FULFILLED`:
            return { ...state, profilePic: payload }
        case `${ACTION_FETCH_PROFILE}_REJECTED`:
            return { ...state } // TODO Addding message to user and not use generic message
        case `${ACTION_SAVE_PROFILE}_PENDING`:
            return { ...state, saved: false }
        case `${ACTION_SAVE_PROFILE}_FULFILLED`:
            return { ...state, userInfo: payload, saved: true, show: false }
        case `${ACTION_FETCH_TEAMS}_FULFILLED`: {
            return {
                ...state,
                teams: payload.data,
            }
        }
        case `${ACTION_GET_AUTH_CONFIG}_FULFILLED`:
            return { ...state, sso: payload.data.attributes.sso }
        case `${ACTION_GET_AUTH_CONFIG}_REJECTED`:
            return { ...state }
        case `${ACTION_SAVE_PROFILE}_REJECTED`:
            return { ...state, saved: false, errMsg: payload }
        case `${ACTION_FETCH_ORGANIZATION}_PENDING`:
            return { ...state, organizationInfo: null }
        case `${ACTION_FETCH_ORGANIZATION}_FULFILLED`:
            return { ...state, organizationInfo: payload.data.attributes }
        default:
            return state
    }
}

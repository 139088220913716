import { BeneficiaryRequest, BeneficiaryResponse, BeneficiaryResponseList, Page } from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Estate-beneficiaries
 */
export class EstateBeneficiariesApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * Delete a beneficiary
     * ${estateId}/beneficiaries/${beneficiaryId}
     **/

    async deleteBeneficiary(estateId: string, beneficiaryId: string): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.delete<void>(`/estates/${estateId}/beneficiaries/${beneficiaryId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Update a beneficiary
     * ${estateId}/beneficiaries/${beneficiaryId}
     **/

    async updateBeneficiary(estateId: string, beneficiaryId: string, body: BeneficiaryRequest): Promise<BeneficiaryResponseList> {
        try {
            const res: AxiosResponse<BeneficiaryResponseList> = await this.patch<BeneficiaryResponseList, BeneficiaryRequest>(`/estates/${estateId}/beneficiaries/${beneficiaryId}`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * List beneficiaries
     * ${estateId}/beneficiaries
     **/

    async listBeneficiaries(estateId: string, page?: Page, filter?: object, sort?: string, search?: string): Promise<BeneficiaryResponseList> {
        try {
            const res: AxiosResponse<BeneficiaryResponseList> = await this.get<BeneficiaryResponseList>(`/estates/${estateId}/beneficiaries`, { params: { page, filter, sort, search } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Add a beneficiary to the estate
     * ${estateId}/beneficiaries
     **/

    async addBeneficiary(estateId: string, body: BeneficiaryRequest): Promise<BeneficiaryResponse> {
        try {
            const res: AxiosResponse<BeneficiaryResponse> = await this.post<BeneficiaryResponse, BeneficiaryRequest>(`/estates/${estateId}/beneficiaries`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

import network from 'utils/network'
import utils from 'utils/utils'
import history from '../modules/App/history'
import queryString from 'query-string'
import FeatureFlags from 'utils/FeatureFlags'
import { Buffer } from 'buffer'
import Intercom from '@intercom/messenger-js-sdk'

const { auth, users, orgs } = network.api

export const createAuthSession = (data, redirectState) => {
    let session = {}
    return auth
        .login(data)
        .then((result) => {
            const { id, attributes } = result.data
            const { token, features, prices, config } = attributes
            session = {
                token,
                features,
                prices,
                config,
            }
            FeatureFlags.setOrgFlags(features)
            return users.get({ id })
        })
        .then((result) => {
            const { attributes, id, meta } = result.data
            const { lastLoginAt } = meta
            const { name, email, orgId, phoneNumber, role, isCitizenExecutor } = attributes
            session = {
                ...session,
                userInfo: {
                    token: session?.token,
                    name,
                    id: id,
                    email: email,
                    phoneNumber: phoneNumber,
                    isCitizenExecutor,
                    org: {
                        userRole: role,
                    },
                },
                lastLogin: lastLoginAt,
            }
            return orgs.get({ id: orgId })
        })
        .then((result) => {
            const { attributes, id, meta } = result.data

            const { language, theme } = attributes
            const lang = utils.getCookie('lang') || language || navigator.language || navigator.userLanguage || 'en'
            network.setLang(lang)

            if (history.location.search || location.search) {
                let url = queryString.parse(history.location.search || location.search).url
                /*
                State it's used to propagate state of addons received as bas64 and keep it when redirect to the right page
                 */
                const addOnsRedirectState = queryString.parse(history.location.search || location.search).state
                if (addOnsRedirectState) {
                    const url1 = Buffer.from(addOnsRedirectState, 'base64').toString().replace('#', '')
                    history.push(decodeURIComponent(url1), false)
                } else if (url) {
                    history.push(decodeURIComponent(url), false)
                } else {
                    if (redirectState != './') history.push(redirectState ? redirectState : '/dashboard', true)
                }
            } else {
                if (redirectState != './') history.push(redirectState ? redirectState : '/dashboard', true)
            }
            session.userInfo.org = {
                ...session.userInfo.org,
                ...attributes,
                currentPlan: meta.currentPlan,
                id,
            }
            session.theme = theme
            session.language = lang
            if (session.userInfo.email && session.config.supportHash) {
                Intercom({
                    user_id: session.userInfo?.id,
                    app_id: 'moq31h6g',
                    user_hash: session.config.supportHash,
                    email: session.userInfo.email,
                    // Custom Attributes to help on audience
                    name: session.userInfo?.name,
                    role: session.userInfo?.org?.userRole,
                    'Last Visit_at': new Date(),

                    company: {
                        id: session.userInfo?.org?.id,
                        name: session.userInfo?.org?.name,
                        plan: session.userInfo?.org?.currentPlan?.legacy || session.userInfo?.org?.billingPlan?.plan || 'No Plan',
                        country: session.userInfo?.org?.address?.country,
                        state: session.userInfo?.org?.address?.state,
                        planType: session.userInfo?.org?.currentPlan?.id,
                        planCountry: session.userInfo?.org?.currentPlan?.country,
                        planLegacy: session.userInfo?.org?.currentPlan?.legacy,
                        location: session.userInfo?.org?.location,
                        language: session.userInfo?.org?.language,
                        jurisdictions: session.userInfo?.org?.jurisdictions,
                        ssoEnabled: session.userInfo?.org?.sso?.enabled,
                    },
                })
            }
            return session
        })
        .catch((error) => {
            const currentLocation = history.location.pathname

            if (session.userInfo?.token || (currentLocation && currentLocation !== '/')) {
                // have token
            } else {
                history.push('/login', true, true)
                location.reload(true)
            }
            throw error
        })
}

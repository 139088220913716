const DEFAULT_STATE = {
    error: '',
    submitted: false,
    expired: false,
    email: '',
}
import { ACTION_RENDER_RESET_PASSWORD_FORM, ACTION_RESET_PASSWORD } from './actions'

export default (state = DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case `${ACTION_RESET_PASSWORD}_FULFILLED`:
            return { ...state, submitted: true, error: '' }
        case `${ACTION_RESET_PASSWORD}_REJECTED`:
            return {
                ...state,
                submitted: false,
                expired: payload.status === 400,
                hasError: payload.status >= 500,
                error: payload,
            }
        case `${ACTION_RENDER_RESET_PASSWORD_FORM}`:
            1
            return { ...state, submitted: false }
        default:
            return state
    }
}

import React from 'react'
import style from './style.module.scss'
import { GenericAttributes } from '../../types'

type SegmentProps = {
    label: string | React.ReactNode
    selected?: boolean
    onClick?: (ev: React.MouseEvent<HTMLElement>) => void
} & GenericAttributes

const Segment = ({ selected = false, label, onClick, ...props }: SegmentProps) => {
    return (
        <button
            type={'button'}
            {...props}
            className={[style.segment, selected ? style.selected : ''].join(' ')}
            onClick={onClick}
            data-test={props['data-test']}
            aria-selected={selected}
            aria-controls={`id-${label}-tabpanel`}
            role='tab'
            id={`id-${props['data-test']}-tab`}>
            <div className={style.unselectedBorder} />
            <div className={style.label}>{label}</div>
            {selected ? <div className={style.selectedBorder} /> : <div className={style.unselectedBorder} />}
        </button>
    )
}
export default React.memo<typeof Segment>(Segment)

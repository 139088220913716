import React, { PureComponent } from 'react'
import style from './style.module.scss'
import CheckboxTitledItem from '../../molecules/CheckboxTitledItem'
import { GenericAttributes } from '../../types'

export interface HeaderCheckboxTitledValue {
    selected: boolean
    onClick: (ev: React.MouseEvent<HTMLElement>) => void
    label: string
}
type HeaderCheckboxTitledProps = {
    label: string
    values: HeaderCheckboxTitledValue[]
} & GenericAttributes
export class HeaderCheckboxTitled extends PureComponent<HeaderCheckboxTitledProps> {
    static SELECTED = CheckboxTitledItem.selectionValues.SELECTED
    static NOT_SELECTED = CheckboxTitledItem.selectionValues.NOT_SELECTED
    render(): React.ReactNode {
        const { label, values, ...props } = this.props
        return (
            <div {...props} data-test={`${this.props['data-test']}`} className={style.headerCheckboxTitled}>
                <div className={style.label} data-test={`${this.props['data-test']}.label`}>
                    {label}
                </div>
                {values.map((value, index) => {
                    const selected = value.selected || false
                    return (
                        <div key={index} className={style.checkboxItem}>
                            <CheckboxTitledItem
                                data-test={`${this.props['data-test']}.options.${index}`}
                                onClick={value.onClick}
                                label={value.label}
                                value={selected ? HeaderCheckboxTitled.SELECTED : HeaderCheckboxTitled.NOT_SELECTED}
                            />
                        </div>
                    )
                })}
            </div>
        )
    }
}
export default HeaderCheckboxTitled

import React, { PureComponent } from 'react'
import style from './style.module.scss'
import Icon, { IconTypes } from '../../particles/Icon'
import Link, { LinkFontWeights, LinkSizes } from '../../atoms/Link'
import Tag from '../../atoms/Tag'
import LabelTimestamp from '../../atoms/LabelTimestamp'
import Radio from '../../atoms/Radio'
import AvatarText from '../../atoms/AvatarText'
import { GenericAttributes } from '../../types'
import { TranslationFunction } from '../../../../modules/types'

type NotificationChipProps = {
    iconType?: IconTypes
    avatarName?: string
    avatarURL?: string
    isRead?: boolean
    title?: string
    linkLabel?: string
    tag?: string
    timestamp: Date | string | number
    onClick?: (ev: React.MouseEvent<HTMLElement>) => void
    onClickLink?: (ev: React.MouseEvent<HTMLElement>) => void
    onClose?: (ev: React.MouseEvent<HTMLElement>) => void
    index?: number
    t: TranslationFunction
} & GenericAttributes
type NotificationChipState = {
    isHovered?: boolean
    showLess?: boolean
    isTextOverflowing?: boolean
}
export class NotificationChip extends PureComponent<NotificationChipProps, NotificationChipState> {
    static IconTypes = IconTypes
    static defaultProps = {
        isRead: false,
    }
    private readonly messageRef: React.RefObject<HTMLDivElement>
    constructor(props: NotificationChipProps) {
        super(props)
        this.messageRef = React.createRef()
        this.state = {
            isHovered: false,
            showLess: false,
            isTextOverflowing: undefined,
        }
    }
    toggleHover = (hover: boolean): void => {
        const { onClose } = this.props
        if (onClose) {
            this.setState(() => ({ isHovered: hover }))
        }
    }
    showOverflow = (): void => {
        this.setState((prevState) => ({ showLess: !prevState.showLess }))
    }

    isEllipsisActive = (element: HTMLDivElement | null) => {
        if (element) {
            return element.offsetHeight < element.scrollHeight
        }
    }
    componentDidMount(): void {
        if (this.props.index === undefined) return
        this.forceUpdate()
    }
    componentDidUpdate(oldProps: NotificationChipProps, oldState: NotificationChipState): void {
        if (this.props.index === undefined) return
        if (oldState.isHovered !== this.state.isHovered) {
            return
        }
        const messageElement = this.messageRef.current
        this.setState({ isTextOverflowing: this.isEllipsisActive(messageElement) })
    }

    render(): React.ReactNode {
        const { iconType, avatarName, avatarURL, title, children, linkLabel, onClick, onClickLink, onClose, timestamp, tag, index, isRead, t, ...props } = this.props

        return (
            <div
                {...props}
                onMouseOver={() => !this.state.isHovered && this.toggleHover(true)}
                onMouseEnter={() => this.toggleHover(true)}
                onMouseLeave={() => this.toggleHover(false)}
                className={[style.notificationChip].join(' ')}>
                <div className={style.content}>
                    <div className={style.columnLeft}>
                        <div className={style.avatar} data-test={`${this.props['data-test']}.avatar`}>
                            {avatarName ? (
                                <AvatarText data-test={`${this.props['data-test']}.avatar.content`} text={avatarName} img={avatarURL} size={AvatarText.sizeTypes.small} />
                            ) : iconType ? (
                                <Icon size={30} type={iconType} />
                            ) : null}
                        </div>
                    </div>
                    <div className={style.columnRight}>
                        <div className={[style.header, isRead ? style.disabled : ''].join(' ')}>
                            <span>{title}</span>
                            <Radio onClick={onClick} value={isRead ? Radio.selectionValues.NOT_SELECTED : Radio.selectionValues.SELECTED} data-test={`${this.props['data-test']}.select`} />
                            {onClose && this.state && this.state.isHovered ? (
                                <div onClick={onClose} className={style.closeIcon} data-test={`${this.props['data-test']}.close`}>
                                    <Icon type={Icon.types.Close} size={30} />{' '}
                                </div>
                            ) : null}
                        </div>
                        {children || linkLabel ? (
                            <div ref={this.messageRef} key={`bodyMessage.${index}`} className={!this.state.showLess ? style.shortBody : style.fullBody}>
                                {linkLabel ? (
                                    <Link
                                        data-test={`${this.props['data-test']}.link`}
                                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                                            e.stopPropagation()
                                            if (onClickLink) onClickLink(e)
                                        }}
                                        fontStyle={Link.fontStyles.underline}>
                                        <span>{linkLabel} </span>
                                    </Link>
                                ) : null}
                                {children ? children : null}
                            </div>
                        ) : null}
                        {(this.state.isTextOverflowing || this.state.showLess) && (
                            <Link fontWeight={LinkFontWeights.semibold} size={LinkSizes.normal} onClick={() => this.showOverflow()}>
                                {!this.state.showLess ? t('Show more') : t('Show less')}
                            </Link>
                        )}
                        <div className={style.footer}>
                            <LabelTimestamp date={new Date(timestamp)} data-test={`${this.props['data-test']}.time`} />
                            {tag ? (
                                <Tag type={Tag.Types.ReadOnly} data-test={`${this.props['data-test']}.tag`}>
                                    {tag}
                                </Tag>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className={style.separator} />
            </div>
        )
    }
}
export default NotificationChip

import React, { PropsWithChildren, useMemo } from 'react'
import style from './style.module.scss'
import SegmentBar from '../../molecules/SegmentBar'
import { GenericAttributes } from '../../types'

type HeaderTitleProps<T> = GenericAttributes & {
    title?: T
    subTitle?: string
    hasBorder?: boolean
    segments?: T[]
    selectedIndex?: number
    onChange?: (index: number, title: T) => void
}

export const HeaderTitle = <T,>({ selectedIndex = 0, title, subTitle, onChange, hasBorder = true, children, segments, ...props }: PropsWithChildren<HeaderTitleProps<T>>) => {
    const leftGroup = useMemo(() => {
        let newLeftGroup: JSX.Element
        if (segments) {
            newLeftGroup = <SegmentBar data-test={`${props['data-test']}.segmentBar`} onChange={onChange} segments={segments} selectedIndex={selectedIndex} />
        } else if (Array.isArray(title)) {
            newLeftGroup = <SegmentBar data-test={`${props['data-test']}.segmentBar`} onChange={onChange} segments={title} selectedIndex={selectedIndex} />
        } else {
            newLeftGroup = (
                <h2 data-test={`${props['data-test']}.label`} className={style.simpleLabel}>
                    {title}
                </h2>
            )
            if (subTitle) {
                newLeftGroup = (
                    <div>
                        <h2 data-test={`${props['data-test']}.label`} className={style.simpleLabel}>
                            {title}
                        </h2>
                        <h3 data-test={`${props['data-test']}.subtitle`} className={style.subTitle}>
                            {subTitle}
                        </h3>
                    </div>
                )
            }
        }
        return newLeftGroup
    }, [title, selectedIndex, onChange, props['data-test'], segments, subTitle])

    return (
        <div {...props} data-test={`${props['data-test']}`} className={[style.headerTitle, hasBorder ? style.hasBorder : ''].join(' ')}>
            <div className={style.left}>{leftGroup}</div>
            <div className={style.right}>{children}</div>
        </div>
    )
}

export default React.memo(HeaderTitle)

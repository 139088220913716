import React, { PropsWithChildren } from 'react'
import style from './style.module.scss'
import { GenericAttributes } from '../../types'

type SectionTextProps = {
    selected?: boolean
    disabled?: boolean
    onClick?: (ev: React.MouseEvent<HTMLElement>) => void
    onMouseDown?: (ev: React.MouseEvent<HTMLElement>) => void
} & GenericAttributes

export const SectionText = ({ selected = false, disabled, onClick, onMouseDown, children, ...props }: PropsWithChildren<SectionTextProps>) => {
    return (
        <div
            {...props}
            data-test={props['data-test']}
            data-disabled={disabled}
            onClick={disabled ? (e) => e.stopPropagation() : onClick}
            onMouseDown={disabled ? (e) => e.stopPropagation() : onMouseDown}
            className={[style.sectionText, selected ? style.selected : style.notSelected, disabled ? style.disabled : ''].join(' ')}
            id={`section-text-${props['data-test']}`}>
            <div className={style.mark}>
                <div className={style.symbol} />
            </div>
            <p>{children}</p>
        </div>
    )
}
export default React.memo(SectionText)

import {
    BulkTransactionsResponse, // Type import BulkTransactionsResponse
    SplitTransactionRequest, // Type import SplitTransactionRequest
    TransactionRequest, // Type import TransactionRequest
    TransactionResponse, // Type import TransactionResponse
    TransactionsRequest, // Type import TransactionsRequest
    TransactionsResponse, // Type import TransactionsResponse
    BulkTransactionsRequest, // Type import BulkTransactionsRequest
    Page,
} from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Account-transactions
 */
export class AccountTransactionsApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * Bulk action of transactions
     * ${estateId}/accounts/transactions/bulk
     **/

    async bulkActionTransactions(estateId: string, body: BulkTransactionsRequest): Promise<BulkTransactionsResponse> {
        try {
            const res: AxiosResponse<BulkTransactionsResponse> = await this.patch<BulkTransactionsResponse, BulkTransactionsRequest>(`/estates/${estateId}/accounts/transactions/bulk`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Split a transaction
     * ${estateId}/accounts/${accountId}/transactions/${txId}/split
     **/

    async splitTransaction(estateId: string, accountId: string, txId: string, body: SplitTransactionRequest): Promise<TransactionsResponse> {
        try {
            const res: AxiosResponse<TransactionsResponse> = await this.post<TransactionsResponse, SplitTransactionRequest>(
                `/estates/${estateId}/accounts/${accountId}/transactions/${txId}/split`,
                body
            )
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Get a transaction
     * ${estateId}/accounts/${accountId}/transactions/${txId}
     **/

    async getTransaction(estateId: string, accountId: string, txId: string): Promise<TransactionResponse> {
        try {
            const res: AxiosResponse<TransactionResponse> = await this.get<TransactionResponse>(`/estates/${estateId}/accounts/${accountId}/transactions/${txId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Delete a transaction
     * ${estateId}/accounts/${accountId}/transactions/${txId}
     **/

    async deleteTransaction(estateId: string, accountId: string, txId: string): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.delete<void>(`/estates/${estateId}/accounts/${accountId}/transactions/${txId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Update a transaction
     * ${estateId}/accounts/${accountId}/transactions/${txId}
     **/

    async updateTransaction(estateId: string, accountId: string, txId: string, body: TransactionRequest): Promise<TransactionResponse> {
        try {
            const res: AxiosResponse<TransactionResponse> = await this.patch<TransactionResponse, TransactionRequest>(`/estates/${estateId}/accounts/${accountId}/transactions/${txId}`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * List transactions
     * ${estateId}/accounts/${accountId}/transactions
     **/

    async listTransactions(estateId: string, accountId: string, page?: Page, filter?: object, sort?: string): Promise<TransactionsResponse> {
        try {
            const res: AxiosResponse<TransactionsResponse> = await this.get<TransactionsResponse>(`/estates/${estateId}/accounts/${accountId}/transactions`, { params: { page, filter, sort } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Create a transaction
     * ${estateId}/accounts/${accountId}/transactions
     **/

    async createTransaction(estateId: string, accountId: string, body: TransactionRequest): Promise<TransactionResponse> {
        try {
            const res: AxiosResponse<TransactionResponse> = await this.post<TransactionResponse, TransactionRequest>(`/estates/${estateId}/accounts/${accountId}/transactions`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Create transactions
     * ${estateId}/accounts/${accountId}/transactions/bulk
     **/

    async createTransactions(estateId: string, accountId: string, body: TransactionsRequest): Promise<TransactionsResponse> {
        try {
            const res: AxiosResponse<TransactionsResponse> = await this.post<TransactionsResponse, TransactionsRequest>(`/estates/${estateId}/accounts/${accountId}/transactions/bulk`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * List transactions in all accounts
     * ${estateId}/accounts/transactions
     **/

    async listAllTransactions(estateId: string, page?: Page, filter?: object, sort?: string, search?: string): Promise<TransactionsResponse> {
        try {
            const res: AxiosResponse<TransactionsResponse> = await this.get<TransactionsResponse>(`/estates/${estateId}/accounts/transactions`, { params: { page, filter, sort, search } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

import { InventoryType, TransactionCategory } from '../system/api/types'

export const EMAIL_REGEX =
    /* eslint-disable-next-line */
    /(?:[a-z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i
/* eslint-disable-next-line */
export const POSITIVE_NUMBER_REGEX_2_DECIMAL_DIGITS = /^[0-9]+(\.[0-9]{1,2})?$/
/* eslint-disable-next-line */
export const POSITIVE_NUMBER_REGEX_10_DECIMAL_DIGITS = /^[0-9]+(\.[0-9]{1,10})?$/
/* eslint-disable-next-line */
export const NEGATIVE_NUMBER_REGEX_2_DECIMAL_DIGITS = /^-[0-9]+(\.[0-9]{1,2})?$/
/* eslint-disable-next-line */
export const NEGATIVE_NUMBER_REGEX_10_DECIMAL_DIGITS = /^-[0-9]+(\.[0-9]{1,10})?$/

export const LOCKED_ACCOUNTING_PERIOD_MESSAGE = `Date entered is within a locked period ({{start}} to {{end}}). Please choose another date or change your locked period.`

export const ASSET_TYPES = {
    AccruedInterest: InventoryType.AccruedInterest,
    CashOnHand: InventoryType.CashOnHand,
    BankAccounts: InventoryType.BankAccounts,
    GrantorBankAccounts: InventoryType.GrantorBankAccounts,
    GrantorInvestmentAccounts: InventoryType.GrantorInvestmentAccounts,
    IncomeReceivable: InventoryType.IncomeReceivable,
    RealEstate: InventoryType.RealEstate,
    InvestmentAccount: InventoryType.InvestmentAccount,
    LifeInsuranceProceeds: InventoryType.LifeInsuranceProceeds,
    LoanDueToDeceased: InventoryType.LoanDueToDeceased,
    StakeInPrivateCompanies: InventoryType.StakeInPrivateCompanies,
    Collectables: InventoryType.Collectables,
    Vehicles: InventoryType.Vehicles,

    EstateBankAccounts: InventoryType.EstateBankAccounts,
    TrustBankAccounts: InventoryType.TrustBankAccounts,
    EstateInvestmentAccounts: InventoryType.EstateInvestmentAccounts,
    TrustInvestmentAccounts: InventoryType.TrustInvestmentAccounts,
    CaseBankAccounts: InventoryType.CaseBankAccounts,
    CaseInvestmentAccounts: InventoryType.CaseInvestmentAccounts,

    PersonalEffectsAndHousehold: InventoryType.PersonalEffectsAndHousehold,
    OtherAsset: InventoryType.OtherAsset,
    IncomeTaxReceivable: InventoryType.IncomeTaxReceivable,
    Holding: InventoryType.Holding,
    AccountsReceivable: InventoryType.AccountsReceivable,

    Annuities: InventoryType.Annuities,
    DigitalCurrency: InventoryType.DigitalCurrency,
    GIC: InventoryType.GIC,
    IntellectualPropertyRights: InventoryType.IntellectualPropertyRights,
    OilGasMineralRight: InventoryType.OilGasMineralRight,
    PublicCompanyCertificate: InventoryType.PublicCompanyCertificate,
    PooledMortgageFund: InventoryType.PooledMortgageFund,
} as const

export const LIABILITY_TYPES = {
    AccruedInterestLiability: InventoryType.AccruedInterestLiability,
    LoansPayable: InventoryType.LoansPayable,
    SpouseOrDependencies: InventoryType.SpouseOrDependencies,

    CivilLiabilities: InventoryType.CivilLiabilities,
    OutstandingBills: InventoryType.OutstandingBills,
    Guarantees: InventoryType.Guarantees,
    BusinessDebts: InventoryType.BusinessDebts,
    ContractsForServices: InventoryType.ContractsForServices,
    TaxHoldbacks: InventoryType.TaxHoldbacks,
    ExecutorCompensation: InventoryType.ExecutorCompensation,
    TrusteeCompensation: InventoryType.TrusteeCompensation,
    OtherLiability: InventoryType.OtherLiability,
    IncomeTaxDue: InventoryType.IncomeTaxDue,
} as const

export const INVENTORIES_WITH_PARTIAL_DISTRIBUTION_AND_LIQUIDATION = [InventoryType.LoanDueToDeceased, InventoryType.Collectables, InventoryType.IncomeReceivable, InventoryType.AccountsReceivable]
export const INVENTORIES_WITH_PARTIAL_DISTRIBUTION_AND_LIQUIDATION_BY_QUANTITY = [InventoryType.StakeInPrivateCompanies, InventoryType.OtherAsset, InventoryType.OtherLiability]
export const ALL_INVENTORIES_WITH_PARTIAL_LIQUIDATION_AND_DISTRIBUTION = [
    ...INVENTORIES_WITH_PARTIAL_DISTRIBUTION_AND_LIQUIDATION,
    ...INVENTORIES_WITH_PARTIAL_DISTRIBUTION_AND_LIQUIDATION_BY_QUANTITY,
]

export const ALLOCATION_TYPE = {
    Percentage: 'percentage',
    Fraction: 'fraction',
    Evenly: 'evenly',
} as const

export const ESTATE_STATUS = {
    COMPLETE: 'COMPLETE',
    IN_PROGRESS: 'IN_PROGRESS',
} as const

export const CASE_ACTIVITY_TYPES = {
    comment: 'COMMENT',
    task: 'TASK',
    activity: 'SYSTEM_ACTIVITY',
    documentSaved: 'SAVED',
    documentUpload: 'UPLOAD',
    signature: 'SIGNATURE',
    workflow: 'WORKFLOW',
    system: 'SYSTEM',
    save: 'SAVE',
    upload: 'UPLOAD',
    email: 'EMAIL',
} as const

export const TRANSACTION_CATEGORIES = {
    CashDistribution: TransactionCategory.CashDistribution,
    AssetDistribution: TransactionCategory.AssetDistribution,
    LiabilityDistribution: TransactionCategory.LiabilityDistribution,
    AssetSale: TransactionCategory.AssetSale,
    LoanRepayment: TransactionCategory.LoanRepayment,
    TrusteeLoanInterest: TransactionCategory.TrusteeLoanInterest,
    TrusteeLoanRepayment: TransactionCategory.TrusteeLoanRepayment,
    TrusteeReimbursement: TransactionCategory.TrusteeReimbursement,
    SubstituteDecisionMakerLoanInterest: TransactionCategory.SubstituteDecisionMakerLoanInterest,
    SubstituteDecisionMakerLoanRepayment: TransactionCategory.SubstituteDecisionMakerLoanRepayment,
    SubstituteDecisionMakerReimbursement: TransactionCategory.SubstituteDecisionMakerReimbursement,
    SubstituteDecisionMakerFees: TransactionCategory.SubstituteDecisionMakerFees,
    TrustExpense: TransactionCategory.TrustExpense,
    POAExpense: TransactionCategory.POAExpense,
    Income: TransactionCategory.Income,
    Interest: TransactionCategory.Interest,
    DebtPayment: TransactionCategory.DebtPayment,
    InterestCharge: TransactionCategory.InterestCharge,
    Dividend: TransactionCategory.Dividend,
    EstateExpense: TransactionCategory.EstateExpense,
    LiquidatorFees: TransactionCategory.LiquidatorFees,
    TrusteeFees: TransactionCategory.TrusteeFees,
    ExecutorLoanInterest: TransactionCategory.ExecutorLoanInterest,
    ExecutorLoanRepayment: TransactionCategory.ExecutorLoanRepayment,
    ExecutorReimbursement: TransactionCategory.ExecutorReimbursement,
    ForeignTaxPaid: TransactionCategory.ForeignTaxPaid,
    ForeignExchangeDeposit: TransactionCategory.ForeignExchangeDeposit,
    ForeignExchangeWithdraw: TransactionCategory.ForeignExchangeWithdraw,
    Fees: TransactionCategory.Fees,
    POAFees: TransactionCategory.POAFees,
    MiscellaneousDeposit: TransactionCategory.MiscellaneousDeposit,
    MiscellaneousWithdraw: TransactionCategory.MiscellaneousWithdraw,
    GainOnForeignExchange: TransactionCategory.GainOnForeignExchange,
    PreviousYearIncomeTaxesDeposit: TransactionCategory.PreviousYearIncomeTaxesDeposit,
    PreviousYearIncomeTaxesWithdraw: TransactionCategory.PreviousYearIncomeTaxesWithdraw,
    IncomeTaxes: TransactionCategory.IncomeTaxes,
    LossOnForeignExchange: TransactionCategory.LossOnForeignExchange,
    InterestAndBankCharges: TransactionCategory.InterestAndBankCharges,
    FuneralExpenses: TransactionCategory.FuneralExpenses,
    OtherDeposit: TransactionCategory.OtherDeposit,
    OtherWithdraw: TransactionCategory.OtherWithdraw,
    ProfessionalFees: TransactionCategory.ProfessionalFees,
    Uncategorized: TransactionCategory.Uncategorized,
    InvestmentFees: TransactionCategory.InvestmentFees,
    LegalFees: TransactionCategory.LegalFees,
    Transfer: TransactionCategory.Transfer,
    InvestmentPurchase: TransactionCategory.InvestmentPurchase,
    RepatriatedFunds: TransactionCategory.RepatriatedFunds,
    Note: TransactionCategory.Note,
    IncomeRemittances: TransactionCategory.IncomeRemittances,
    CapitalRemittances: TransactionCategory.CapitalRemittances,
    RealEstate: TransactionCategory.RealEstate,
    TransferToAccount: TransactionCategory.TransferToAccount,
    OtherInvestmentIncome: TransactionCategory.OtherInvestmentIncome,
    SaleAndMaturityOfInvestments: TransactionCategory.SaleAndMaturityOfInvestments,
    LongTermDebtToPay: TransactionCategory.LongTermDebtToPay,
    LongTermDebtToReceive: TransactionCategory.LongTermDebtToReceive,
    CapitalizationOfIncomeAccount: TransactionCategory.CapitalizationOfIncomeAccount,
    ManualUpdateAdjustedCostBase: TransactionCategory.ManualUpdateAdjustedCostBase,
    ReturnOfCapital: TransactionCategory.ReturnOfCapital,
    StockSplit: TransactionCategory.StockSplit,
    CapitalGainDistribution: TransactionCategory.CapitalGainDistribution,
} as const

export const COMMON_BULK_EDIT_DEPOSIT_TRANSACTIONS = [
    TRANSACTION_CATEGORIES.Dividend,
    TRANSACTION_CATEGORIES.ForeignExchangeDeposit,
    TRANSACTION_CATEGORIES.GainOnForeignExchange,
    TRANSACTION_CATEGORIES.IncomeTaxes,
    TRANSACTION_CATEGORIES.Interest,
    TRANSACTION_CATEGORIES.LoanRepayment,
    TRANSACTION_CATEGORIES.MiscellaneousDeposit,
    TRANSACTION_CATEGORIES.OtherDeposit,
    TRANSACTION_CATEGORIES.PreviousYearIncomeTaxesDeposit,
    TRANSACTION_CATEGORIES.Income, //a.k.a. Revenue
    TRANSACTION_CATEGORIES.Fees,
    TRANSACTION_CATEGORIES.Uncategorized,
] as const

export const COMMON_BULK_EDIT_WITHDRAWAL_TRANSACTIONS = [
    TRANSACTION_CATEGORIES.EstateExpense,
    TRANSACTION_CATEGORIES.ForeignExchangeWithdraw,
    TRANSACTION_CATEGORIES.ForeignTaxPaid,
    TRANSACTION_CATEGORIES.FuneralExpenses,
    TRANSACTION_CATEGORIES.IncomeTaxes,
    TRANSACTION_CATEGORIES.InterestAndBankCharges,
    TRANSACTION_CATEGORIES.InvestmentFees,
    TRANSACTION_CATEGORIES.LegalFees,
    TRANSACTION_CATEGORIES.LossOnForeignExchange,
    TRANSACTION_CATEGORIES.MiscellaneousWithdraw,
    TRANSACTION_CATEGORIES.OtherWithdraw,
    TRANSACTION_CATEGORIES.PreviousYearIncomeTaxesWithdraw,
    TRANSACTION_CATEGORIES.ProfessionalFees,
] as const

export const ESTATE_BULK_EDIT_DEPOSIT_TRANSACTIONS = [...COMMON_BULK_EDIT_DEPOSIT_TRANSACTIONS]

export const ESTATE_BULK_EDIT_WITHDRAWAL_TRANSACTIONS = [
    ...COMMON_BULK_EDIT_WITHDRAWAL_TRANSACTIONS,
    TRANSACTION_CATEGORIES.LiquidatorFees, //a.k.a. Executor Fees
    TRANSACTION_CATEGORIES.ExecutorLoanInterest,
    TRANSACTION_CATEGORIES.ExecutorLoanRepayment,
    TRANSACTION_CATEGORIES.ExecutorReimbursement,
]

export const TRUST_BULK_EDIT_DEPOSIT_TRANSACTIONS = [...COMMON_BULK_EDIT_DEPOSIT_TRANSACTIONS]

export const TRUST_BULK_EDIT_WITHDRAWAL_TRANSACTIONS = [
    ...COMMON_BULK_EDIT_WITHDRAWAL_TRANSACTIONS,
    TRANSACTION_CATEGORIES.TrustExpense,
    TRANSACTION_CATEGORIES.TrusteeLoanInterest,
    TRANSACTION_CATEGORIES.TrusteeLoanRepayment,
    TRANSACTION_CATEGORIES.TrusteeReimbursement,
    TRANSACTION_CATEGORIES.TrusteeFees,
]

export const POA_BULK_EDIT_DEPOSIT_TRANSACTIONS = [...COMMON_BULK_EDIT_DEPOSIT_TRANSACTIONS]

export const POA_BULK_EDIT_WITHDRAWAL_TRANSACTIONS = [
    ...COMMON_BULK_EDIT_WITHDRAWAL_TRANSACTIONS,
    TRANSACTION_CATEGORIES.POAExpense,
    TRANSACTION_CATEGORIES.POAFees,
    TRANSACTION_CATEGORIES.SubstituteDecisionMakerFees,
    TRANSACTION_CATEGORIES.SubstituteDecisionMakerLoanInterest,
    TRANSACTION_CATEGORIES.SubstituteDecisionMakerLoanRepayment,
    TRANSACTION_CATEGORIES.SubstituteDecisionMakerReimbursement,
]

export const TASKS_COMPLETED_PERCENTAGE = '100.00'

export const INVENTORIES_PER_QUANTITY = [
    InventoryType.StakeInPrivateCompanies,
    InventoryType.PublicCompanyCertificate,
    InventoryType.OtherAsset,
    InventoryType.Holding,
    InventoryType.OtherLiability,
    InventoryType.GIC,
    InventoryType.DigitalCurrency,
    InventoryType.BankAccounts,
]
export const InventoriesWithPartialDistributionAndLiquidation = [InventoryType.LoanDueToDeceased, InventoryType.Collectables, InventoryType.IncomeReceivable, InventoryType.AccountsReceivable]
export const InventoriesWithPartialDistributionAndLiquidationByQuantity = [
    InventoryType.StakeInPrivateCompanies,
    InventoryType.PublicCompanyCertificate,
    InventoryType.GIC,
    InventoryType.DigitalCurrency,
    InventoryType.OtherAsset,
    InventoryType.OtherLiability,
]

export const AllInventoriesWithPartialLiquidationAndDistribution = [...InventoriesWithPartialDistributionAndLiquidation, ...InventoriesWithPartialDistributionAndLiquidationByQuantity]

//This exception is used to check if the asset is an account that contain holdings (distributed by quantity) inside
export const INVENTORIES_CONTAINER = [
    ASSET_TYPES.InvestmentAccount,
    ASSET_TYPES.EstateInvestmentAccounts,
    ASSET_TYPES.EstateBankAccounts,
    ASSET_TYPES.TrustInvestmentAccounts,
    ASSET_TYPES.TrustBankAccounts,
    ASSET_TYPES.CaseInvestmentAccounts,
    ASSET_TYPES.GrantorInvestmentAccounts,
] as const

export const NOTIFICATION_STATUS_TYPES = {
    read: 'read',
    unread: 'unread',
} as const

export const NOTIFICATIONS_PREFERENCES = {
    All: '',
    None: 'none',
    Email: 'email',
    InApp: 'inApp',
} as const

export const NOTIFICATIONS_CATEGORIES = {
    caseAssignments: 'caseAssignments',
    taskReviews: 'taskReviews',
    taskStatusUpdate: 'taskStatusUpdate',
    taskAssignments: 'taskAssignments',
    caseNotes: 'caseNotes',
    digitalSignature: 'digitalSignature',
    deadlineReminder: 'deadlineReminder',
    importComplete: 'importComplete',
    exportComplete: 'exportComplete',
    workflowError: 'workflowError',
    productCommunications: 'productCommunications',
} as const

export const REMINDER_PREFERENCES = {
    TwoDays: 'twoDays',
    FiveDays: 'fiveDays',
    SevenDays: 'sevenDays',
    FourteenDays: 'fourteenDays',
    OneMonth: 'oneMonth',
    ThreeMonths: 'threeMonths',
    SixMonths: 'sixMonths',
    OneYear: 'oneYear',
} as const

export const TASK_STATUS = {
    COMPLETE: 'COMPLETE',
    INCOMPLETE: 'INCOMPLETE',
    DRAFT: 'DRAFT',
} as const

export const TODO_FILTER_LABELS = {
    all: 'All',
    completed: 'Completed',
    incomplete: 'Incomplete',
} as const

export default {
    CASE_ACTIVITY_TYPES,
    ESTATE_STATUS,
    TASKS_COMPLETED_PERCENTAGE,
    TRANSACTION_CATEGORIES,
    INVENTORIES_PER_QUANTITY,
    NOTIFICATION_STATUS_TYPES,
    NOTIFICATIONS_PREFERENCES,
    REMINDER_PREFERENCES,
    TASK_STATUS,
    EMAIL_REGEX,
}

export const PHONE_MASK_NORTH_AMERICA = ['canada', 'usa', 'united states', 'états-unis']

export const USA_BANK_INSTITUTIONS = [
    'JPMorgan Chase Bank',
    'Bank of America',
    'Wells Fargo Bank',
    'Citibank',
    'U.S. Bank National Association',
    'Truist Bank',
    'PNC Bank',
    'TD Bank',
    'The Bank of New York Mellon',
    'Capital One',
    'Charles Schwab Bank',
    'State Street Bank and Trust Company',
    'Goldman Sachs Bank USA',
    'Fifth Third Bank',
    'HSBC Bank USA',
    'Citizens Bank',
    'Morgan Stanley Bank',
    'Ally Bank',
    'The Northern Trust Company',
    'KeyBank National Association',
    'BMO Harris Bank National Association',
    'Regions Bank',
    'First Republic Bank',
    'Manufacturers and Traders Trust Company',
    'American Express National Bank',
    'MUFG Union Bank',
    'The Huntington National Bank',
    'Morgan Stanley Private Bank',
    'Silicon Valley Bank',
    'Discover Bank',
    'USAA Federal Savings Bank',
    'Capital One Bank (USA)',
    'BBVA USA',
    'Bank of the West',
    'Santander Bank, N.A.',
    'Comerica Bank',
    'UBS Bank USA',
    'Synchrony Bank',
    'First Horizon Bank',
    'Zions Bancorporation, N.A.',
    'City National Bank',
    'Signature Bank',
    "People's United Bank",
    'E*TRADE Bank',
    'New York Community Bank',
    'Bank of China',
    'Banco Popular de Puerto Rico',
    'Synovus Bank',
    'CIT Bank',
    'East West Bank',
    'First-Citizens Bank & Trust Company',
    'TCF National Bank',
    'BOKF',
    'Deutsche Bank Trust Company Americas',
    'CIBC Bank USA',
    'Frost Bank',
    'TIAA, FSB',
    'Valley National Bank',
    'Texas Capital Bank',
    'South State Bank',
    'First National Bank of Pennsylvania',
    'Western Alliance Bank',
    'BankUnited',
    'Pinnacle Bank',
    'Prosperity Bank',
    'Hancock Whitney Bank',
    'BNY Mellon',
    'Associated Bank',
    'UMB Bank',
    'Commerce Bank',
    'Webster Bank',
    'Raymond James Bank',
    'Charles Schwab Premier Bank, SSB',
    'Flagstar Bank, FSB',
    'Sallie Mae Bank',
    'Barclays Bank Delaware',
    'Sterling National Bank',
    'MidFirst Bank',
    'Pacific Western Bank',
    'Umpqua Bank',
    'TD Bank USA',
    'Bank OZK',
    'United Bank',
    'Investors Bank',
    'Fulton Bank',
    'First National Bank of Omaha',
    'Bank of America California',
    'FirstBank',
    'Arvest Bank',
    'BancorpSouth Bank',
    'Old National Bank',
    'First Hawaiian Bank',
    'Simmons Bank',
    'Wells Fargo National Bank West',
    'First Midwest Bank',
    'Bank of Hawaii',
    'Ameris Bank',
    'Pacific Premier Bank',
    'Atlantic Union Bank',
    'Mechanics Bank',
    'Washington Federal Bank',
    'Cathay Bank',
    'FirstBank Puerto Rico',
    'Cadence Bank, N.A.',
    'City National Bank of Florida',
    'Glacier Bank',
    'Customers Bank',
    'United Community Bank',
    'Independent Bank',
    'First Interstate Bank',
    'Bank of Hope',
    'Columbia State Bank',
    'Trustmark National Bank',
    'WesBanco Bank, Inc.',
    'Centennial Bank',
    'Apple Bank for Savings',
    'Eastern Bank',
    'First Financial Bank',
    'Bremer Bank',
    'Renasant Bank',
    'Banner Bank',
    'Stifel Bank and Trust',
    'Towne Bank',
    'Third Federal Association of Cleveland',
    'Citizens Business Bank',
    'Wilmington Savings Fund Society, FSB',
    'First Merchants Bank',
    'Northwest Bank',
    'Community Bank',
    'PlainsCapital Bank',
    'Optum Bank, Inc.',
    'Axos Bank',
    'Rockland Trust Company',
    'Provident Bank',
    'Berkshire Bank',
    'Great Western Bank',
    'Sandy Spring Bank',
    'Charles Schwab Trust Bank',
    'Bank of Baroda',
    'ServisFirst Bank',
    'State Bank of India',
    'BMW Bank of North America',
    'OceanFirst Bank',
    'EagleBank',
    'Israel Discount Bank of New York',
    'NBT Bank',
    'Busey Bank',
    'International Bank of Commerce',
    'First United Bank and Trust Company',
    'Popular Bank',
    'Comenity Bank',
    'Boston Private Bank & Trust Company',
    'Dollar Bank, Federal Savings Bank',
    'Farmers and Merchants Bank',
    'Tristate Capital Bank',
    'Washington Trust Bank',
    'Oriental Bank',
    'Enterprise Bank & Trust',
    'Cross River Bank',
    'Capitol Federal Savings Bank',
    'Safra National Bank of New York',
    'Merchants Bank of Indiana',
    'The Park National Bank',
    'NexBank',
    'First Commonwealth Bank',
    'S&T Bank',
    'Veritex Community Bank',
    'Columbia Bank',
    'Bell Bank',
    'Comenity Capital Bank',
    'American Savings Bank, FSB',
    'Seacoast National Bank',
    'Wintrust Bank',
    'Woodforest National Bank',
    'BancFirst',
    'AMERIPRISE BANK, FSB',
    'Flushing Bank',
    'Banc of California',
    'Live Oak Banking Company',
    'Amerant Bank',
    'Lakeland Bank',
    'Bank Hapoalim B.M.',
    'Tri Counties Bank',
    'Origin Bank',
    'Intrust Bank',
    'ConnectOne Bank',
    'Mizuho Bank (USA)',
    '1st Source Bank',
    'Kearny Bank',
    'Beal Bank USA',
    'First Bank',
    'Metabank',
    'First Security Bank',
    'Bank Leumi USA',
    'HomeStreet Bank',
    'E*TRADE Savings Bank',
    'Premier Bank',
    'Amarillo National Bank',
    'Southside Bank',
    'Liberty Bank',
    'First Foundation Bank',
    'Luther Burbank Savings',
    'Midland States Bank',
    'Westamerica Bank',
    'NBH Bank',
    'East Boston Savings Bank',
    'Heritage Bank',
    'Central Pacific Bank',
    'Bank of India',
    'Dime Community Bank',
    'Byline Bank',
    'Ridgewood Savings Bank',
    'Century Bank and Trust Company',
    'Univest Bank and Trust Co.',
    'The Bancorp Bank',
    'Emigrant Bank',
    'Wilmington Trust',
    'Hanmi Bank',
    'Republic Bank & Trust Company',
    'Bangor Savings Bank',
    'Allegiance Bank',
    'Amalgamated Bank',
    'Johnson Bank',
    'Lake Forest Bank & Trust Company',
    'First American Bank',
    'TBK BANK, SSB',
    'TrustCo Bank',
    'Peapack-Gladstone Bank',
    'Horizon Bank',
    'Lake City Bank',
    'Bankers Trust Company',
    'Union Bank and Trust Company',
    'Middlesex Savings Bank',
    'Brookline Bank',
    'The Washington Trust Company',
    'City National Bank of West Virginia',
    'Centier Bank',
    'CrossFirst Bank',
    'Salem Five Cents Savings Bank',
    'Happy State Bank',
    'Bank of Colorado',
    'Silvergate Bank',
    'MidWestOne Bank',
    'Great Southern Bank',
    'Northfield Bank',
    'The Bryn Mawr Trust Company',
] as const

export const ORG_LOCATION = {
    CA: 'CA',
    US: 'US',
} as const

export const ORG_ADDRESS_COUNTRY = {
    Canada: 'Canada',
    UnitedStates: 'United States',
} as const
export const RESULTS_PER_PAGE_PAGINATION = [
    {
        label: '50 results',
        value: 50,
    },
    {
        label: '100 results',
        value: 100,
    },
] as const

export const RESULTS_PER_PAGE_PAGINATION_DEFAULT = RESULTS_PER_PAGE_PAGINATION[0].value

export const PRICE_META_CASE_TYPE = {
    Trust: 'trust',
    TrustAccounting: 'trustAccounting',
    Estate: 'estate',
    ProbateToEstateUpdate: 'estateUpdate',
    EstateAccounting: 'estateAccounting',
    Probate: 'probate',
    PowerOfAttorney: 'poa',
    PowerOfAttorneyAccounting: 'poaAccounting',
} as const

export const NUMBER_FEATURE_FLAG_OPTIONS = {
    NoAccess: 0,
    MissingPrice: 1,
    FullAccess: 10,
} as const

export const PRICING_LEARN_MORE_URL = 'https://www.estateably.com/pricing'

export const ORG_TIERS_PLANS_LIST = {
    Essentials: 'Essentials',
    Core: 'Core',
    Advanced: 'Advanced',
} as const

export const ADD_ON_IDS = {
    Clio: 'clio',
    DecisionVault: 'decisionVault',
}

export const MAXIMUM_ATTACHMENT_SIZE_MB_ALLOWED_EMAIL = 20

export const BYTES_TO_MB = 1048576

export const MAX_NUMBER_OF_ALLOWED_DECIMAL_DIGITS = 10


import React, { PureComponent } from 'react'
import style from './style.module.scss'
import CheckboxItem from '../../molecules/CheckboxItem'
import { GenericAttributes } from '../../types'

interface LinewGridCheckboxItems {
    selected: boolean
    readonly?: boolean
    onClick: (ev: React.MouseEvent<HTMLElement>) => void
}
type LineGridCheckboxProps = {
    label: string
    readonly?: boolean
    values: LinewGridCheckboxItems[]
    alt?: string
} & GenericAttributes
export class LineGridCheckbox extends PureComponent<LineGridCheckboxProps> {
    static SELECTED = CheckboxItem.selectionValues.SELECTED
    static NOT_SELECTED = CheckboxItem.selectionValues.NOT_SELECTED
    static defaultProps = {
        readonly: false,
    }
    render(): React.ReactNode {
        const { label, readonly, values, alt, ...props } = this.props
        return (
            <tr {...props} data-test={this.props['data-test']} className={style.lineGridCheckbox} role={'row'}>
                <td className={style.label} id={`${this.props['data-test']}.${label}`} data-test={`${this.props['data-test']}.label`} role={'cell'}>
                    {label}
                </td>
                {values.map((value, index) => {
                    return (
                        <td key={index} className={style.checkboxItem} role={'cell'} aria-labelledby={`${this.props['data-test']}.${label}`} tabIndex={0}>
                            <CheckboxItem
                                data-test={`${this.props['data-test']}.checkboxes.${index}`}
                                background={!(index % 2)}
                                readonly={readonly || value.readonly}
                                onClick={value.onClick}
                                aria-labelledby={`${this.props['data-test']}.label`}
                                value={value.selected ? LineGridCheckbox.SELECTED : LineGridCheckbox.NOT_SELECTED}
                            />
                        </td>
                    )
                })}
            </tr>
        )
    }
}
export default LineGridCheckbox

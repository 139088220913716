import React, { PureComponent } from 'react'
import style from './style.module.scss'
import Icon from '../../particles/Icon'
import SectionText from '../../atoms/SectionText'
import AvatarText from '../../atoms/AvatarText'
import { GenericAttributes } from '../../types'

type MenuToolBarProps = {
    onSelect: (index: number, item: string) => void
    onOpen?: () => void
    onClose?: () => void
    onClickIcon?: (index: number, item: string | React.ReactNode) => void
    options: string[]
    iconsList?: React.ReactNode[] | string[]
    opened?: boolean
    title: string
    userName: string
    avatar?: string
} & GenericAttributes
type MenuToolBarState = {
    opened: boolean | undefined
}
export class MenuToolBar extends PureComponent<MenuToolBarProps, MenuToolBarState> {
    private myRef: React.RefObject<HTMLDivElement> = React.createRef()
    static iconTypes = Icon.types
    static defaultProps = {
        icon: MenuToolBar.iconTypes.Dynamic,
        opened: false,
    }
    constructor(props: MenuToolBarProps) {
        super(props)
        const { opened } = this.props
        this.state = { opened }
    }
    componentDidMount(): void {
        document.addEventListener('mousedown', this.handleClickOutside, false)
    }
    componentWillUnmount(): void {
        document.removeEventListener('mousedown', this.handleClickOutside, false)
    }
    toggleDropdown = (): void => {
        const { onClose, onOpen } = this.props
        const { opened } = this.state
        this.setState({ opened: !opened })
        if (!opened) {
            if (onOpen) onOpen()
        } else {
            if (onClose) onClose()
        }
    }
    handleClickOutside = (event: MouseEvent): void => {
        const { onClose } = this.props
        const { opened } = this.state
        if (this.myRef && this.myRef.current && !this.myRef.current.contains(event.target as Node)) {
            this.setState({ opened: false }, () => (opened ? onClose && onClose() : null))
        }
    }
    onSelect = (item: string): void => {
        const { onSelect, options, onClose } = this.props
        this.setState({ opened: false })
        if (onClose) onClose()
        if (onSelect) onSelect(options.indexOf(item), item)
    }
    onClickIcon = (item: string | React.ReactNode, index: number): void => {
        const { onClickIcon, onClose } = this.props
        this.setState({ opened: false })
        if (onClose) onClose()
        if (onClickIcon) onClickIcon(index, item)
    }
    render(): React.ReactNode {
        const { title, options = [], iconsList = [], avatar, userName, ...props } = this.props
        const { opened } = this.state
        const optionsMaxSize = 5
        const iconsListMaxSize = 4
        return (
            <div {...props} onSelect={undefined} className={[style.menuToolBar].join(' ')} ref={this.myRef}>
                <div className={style.dropdownHeader}>
                    <div onClick={this.toggleDropdown} className={[style.dropdown, opened ? style.openedDropdown : ''].join(' ')} data-test={`${this.props['data-test']}.dropdown`}>
                        <div className={style.dropdownLeft}>
                            <AvatarText data-test={`${this.props['data-test']}.dropdown.avatar`} text={userName} size={AvatarText.sizeTypes.small} img={avatar} />
                            <p className={style.label} data-test={`${this.props['data-test']}.dropdown.label`}>
                                {title}
                            </p>
                        </div>
                        <div className={[style.dropdownRight, opened ? style.openedDropdownRight : ''].join(' ')}>
                            <Icon size={30} type={opened ? MenuToolBar.iconTypes.ArrowClose : MenuToolBar.iconTypes.ArrowOpen} />

                            {!opened ? <div className={style.divider} /> : null}
                        </div>
                    </div>
                    <div className={style.iconsList}>
                        {iconsList &&
                            iconsList.slice(0, iconsListMaxSize).map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={[style.iconsListItem, item].join(' ')}
                                        onClick={() => this.onClickIcon(item, index)}
                                        data-test={`${this.props['data-test']}.icons.${index}`}>
                                        {typeof item === 'string' ? <Icon size={30} type={item} /> : item}
                                    </div>
                                )
                            })}
                    </div>
                </div>
                {opened ? (
                    <div className={style.dropdownoptions}>
                        <div className={style.header} />
                        {options &&
                            options.slice(0, optionsMaxSize).map((item, index) => {
                                return (
                                    <SectionText key={index} onClick={() => this.onSelect(item)} data-test={`${this.props['data-test']}.dropdown.options.${index}`}>
                                        {item}
                                    </SectionText>
                                )
                            })}
                    </div>
                ) : null}
            </div>
        )
    }
}
export default MenuToolBar

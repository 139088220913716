import React, { PureComponent } from 'react'
// eslint-disable-next-line css-modules/no-unused-class
import style from './style.module.scss'
import Icon from '../../particles/Icon'
import Link from '../../atoms/Link'
import { GenericAttributes } from '../../types'

export enum AlertModes {
    info = 'info',
    error = 'error',
    warning = 'warning',
}

type GeneralAlertProps = {
    title: string
    message: string
    mode: AlertModes
    linkMessage?: string
    onClickLink?: () => void
    onClose?: () => void
} & GenericAttributes
export class GeneralAlert extends PureComponent<GeneralAlertProps> {
    static Modes = AlertModes
    constructor(props: GeneralAlertProps) {
        super(props)
    }
    getIconType = (): string => {
        const { mode } = this.props
        switch (mode) {
            case AlertModes.error:
            case AlertModes.warning:
                return Icon.types.Alert
            case AlertModes.info:
                return Icon.types.Info
        }
    }
    render(): React.ReactNode {
        const { title, message, linkMessage, onClickLink, onClose, mode, ...props } = this.props
        return (
            <div {...props} data-test={`${this.props['data-test']}`} className={[style.generalAlert, style[mode]].join(' ')}>
                <div data-test={`${this.props['data-test']}.info.icon`} className={[style.icon, style[mode]].join(' ')}>
                    <Icon size={30} type={this.getIconType()} />
                </div>
                <div className={style.content}>
                    <div data-test={`${this.props['data-test']}.title`} className={style.title}>
                        {title}
                    </div>
                    <div data-test={`${this.props['data-test']}.message`} className={style.message}>
                        {message}
                    </div>
                    {onClickLink ? (
                        <div className={style.link}>
                            <Link data-test={`${this.props['data-test']}.link`} onClick={onClickLink}>
                                {linkMessage}
                            </Link>
                        </div>
                    ) : null}
                </div>
                {onClose ? (
                    <div data-test={`${this.props['data-test']}.close.icon`} className={style.closeIcon} onClick={onClose}>
                        <Icon size={26} type={Icon.types.Close} />
                    </div>
                ) : null}
            </div>
        )
    }
}
export default GeneralAlert

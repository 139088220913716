import {
    DocumentsTagsResponse, // Type import DocumentsTagsResponse
    DocumentsTagCreateRequest, // Type import DocumentsTagCreateRequest
    Page, // Type Pagination of list
} from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api, ResponseWithHeader } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Documents-tags
 */
export class DocumentsTagsApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * List the documents tags
     * ${orgId}/documentsTags
     **/

    async listDocumentsTags(orgId: string): Promise<DocumentsTagsResponse> {
        try {
            const res: AxiosResponse<DocumentsTagsResponse> = await this.get<DocumentsTagsResponse>(`/orgs/${orgId}/documentsTags`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Create a document tag to the org
     * ${orgId}/documentsTags
     **/

    async createDocumentsTag(orgId: string, body: DocumentsTagCreateRequest): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.post<void, DocumentsTagCreateRequest>(`/orgs/${orgId}/documentsTags`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

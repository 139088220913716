import {
    DistributionRequest,
    DistributionResponse,
    DistributionResponseList,
    Page,
    TrustDistributionRequestCreateRequest,
    TrustDistributionRequestResponse,
    TrustDistributionRequestsResponse,
    TrustDistributionRequestUpdateRequest,
} from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Estate-distributions
 */
export class EstateDistributionsApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * Get a distribution
     * ${estateId}/distributions/${txId}
     **/

    async getDistribution(estateId: string, txId: string): Promise<DistributionResponse> {
        try {
            const res: AxiosResponse<DistributionResponse> = await this.get<DistributionResponse>(`/estates/${estateId}/distributions/${txId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Delete a distribution
     * ${estateId}/distributions/${txId}
     **/

    async deleteDistribution(estateId: string, txId: string): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.delete<void>(`/estates/${estateId}/distributions/${txId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Update a Distribution
     * ${estateId}/distributions/${txId}
     **/

    async updateDistribution(estateId: string, txId: string, body: DistributionRequest): Promise<DistributionResponse> {
        try {
            const res: AxiosResponse<DistributionResponse> = await this.patch<DistributionResponse, DistributionRequest>(`/estates/${estateId}/distributions/${txId}`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * List distributions
     * ${estateId}/distributions
     **/

    async listDistributions(estateId: string, page?: Page, filter?: object, sort?: string): Promise<DistributionResponseList> {
        try {
            const res: AxiosResponse<DistributionResponseList> = await this.get<DistributionResponseList>(`/estates/${estateId}/distributions`, { params: { page, filter, sort } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Delete a distributionRequest
     * ${estateId}/distributionRequests/${distributionRequestId}
     **/

    async deleteDistributionRequest(distributionRequestId: string, estateId: string): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.delete<void>(`/estates/${estateId}/distributionRequests/${distributionRequestId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Update a specific distributionRequest
     * ${estateId}/distributionRequests/${distributionRequestId}
     **/

    async updateDistributionRequest(distributionRequestId: string, estateId: string, body: TrustDistributionRequestUpdateRequest): Promise<TrustDistributionRequestResponse> {
        try {
            const res: AxiosResponse<TrustDistributionRequestResponse> = await this.patch<TrustDistributionRequestResponse, TrustDistributionRequestUpdateRequest>(
                `/estates/${estateId}/distributionRequests/${distributionRequestId}`,
                body
            )
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Get a specific distributionRequest
     * ${estateId}/distributionRequests/${distributionRequestId}
     **/

    async getDistributionRequest(distributionRequestId: string, estateId: string): Promise<TrustDistributionRequestResponse> {
        try {
            const res: AxiosResponse<TrustDistributionRequestResponse> = await this.get<TrustDistributionRequestResponse>(`/estates/${estateId}/distributionRequests/${distributionRequestId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Create a distributionRequest
     * ${estateId}/distributionRequests
     **/

    async createDistributionRequest(estateId: string, body: TrustDistributionRequestCreateRequest): Promise<TrustDistributionRequestResponse> {
        try {
            const res: AxiosResponse<TrustDistributionRequestResponse> = await this.post<TrustDistributionRequestResponse, TrustDistributionRequestCreateRequest>(
                `/estates/${estateId}/distributionRequests`,
                body
            )
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * List distributionRequests
     * ${estateId}/distributionRequests
     **/

    async listDistributionRequests(estateId: string, page?: Page, filter?: object, search?: string, sort?: string): Promise<TrustDistributionRequestsResponse> {
        try {
            const res: AxiosResponse<TrustDistributionRequestsResponse> = await this.get<TrustDistributionRequestsResponse>(`/estates/${estateId}/distributionRequests`, {
                params: { page, filter, search, sort },
            })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

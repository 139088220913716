import {
    PublicNoticeRequestCreate, // Type import PublicNoticeRequestCreate
    PublicNoticePayRequest, // Type import PublicNoticePayRequest
    PublicNoticeResponse, // Type import PublicNoticeResponse
    Page, // Type Pagination of list
} from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api, ResponseWithHeader } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/PublicNotice
 */
export class PublicNoticeApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }

    //Methods

    /**
     * Track a public notice status on column service, the public notice has already been created
     * ${estateId}/publicNotice/trackPublicNotice
     **/

    async trackPublicNotice(estateId: string, body: unknown): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.put<void, unknown>(`/estates/${estateId}/publicNotice/trackPublicNotice`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Cancel current public notice
     * ${estateId}/publicNotice/cancel
     **/

    async cancelPublicNotice(estateId: string, body: PublicNoticePayRequest): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.patch<void, PublicNoticePayRequest>(`/estates/${estateId}/publicNotice/cancel`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Pay for a public notice to an estate case, the public notice has already been created
     * ${estateId}/publicNotice/pay
     **/

    async payForPublicNotice(estateId: string, body: PublicNoticePayRequest): Promise<PublicNoticeResponse> {
        try {
            const res: AxiosResponse<PublicNoticeResponse> = await this.post<PublicNoticeResponse, PublicNoticePayRequest>(`/estates/${estateId}/publicNotice/pay`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Create a public notice to an estate case
     * ${estateId}/publicNotice
     **/

    async createPublicNotice(estateId: string, body: FormData): Promise<PublicNoticeResponse> {
        try {
            const res: AxiosResponse<PublicNoticeResponse> = await this.post<PublicNoticeResponse, FormData>(`/estates/${estateId}/publicNotice`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

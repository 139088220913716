import React, { PropsWithChildren, useEffect, useMemo, useState } from 'react'
import style from './style.module.scss'
import Dropdown from '../../molecules/Dropdown'
import { GenericAttributes } from '../../types'
import SectionText from '../../atoms/SectionText/index'
import { useHistory } from 'react-router-dom'

type FilterDropdownProps = {
    title: string
    onChange: (listIndex: number, itemIndex: number, value: string) => void
    options?: string[][]
    children?: React.ReactElement | React.ReactElement[]
    defaultIndex?: number
} & GenericAttributes

export const FilterDropdown = ({ title, onChange, options, children, defaultIndex = 0, ...props }: PropsWithChildren<FilterDropdownProps>) => {
    /** States **/
    const [selectedItems, setSelectedItems] = useState<string[]>(new Array(options?.length || 0))

    /** useEffects **/
    /**
     * this use effect is to setup the options array with the initial selected
     * value, as so, the dependency array is empty to be just `on-mount`
     */
    useEffect(() => {
        if (!!options && !!selectedItems) {
            const newSelectedItems = new Array(selectedItems.length)
            options.forEach((option, listIndex) => (newSelectedItems[listIndex] = option[defaultIndex || 0]))
            setSelectedItems(newSelectedItems)
        }
    }, [])

    /**
     * If dependency list is removed/hidden/disabled from frontend,
     * the value of removed array will be reset to default for next time it is re-enabled to use.
     */
    const history = useHistory()
    const searchParams = new URLSearchParams(history?.location?.search)
    const currentUrlPageSize = searchParams.get('page[size]')

    useEffect(() => {
        //for global search, or any other with url query params - to match params to given options
        if (options?.length && selectedItems?.length && currentUrlPageSize != null) {
            const currentComponentOptions = options[0] || []
            const currentComponentPageSize = selectedItems[0] || 0
            if (currentUrlPageSize != currentComponentPageSize && currentComponentOptions?.indexOf(currentUrlPageSize) != -1) {
                setSelectedItems([currentUrlPageSize])
            }
        }

        if (options && options?.length < selectedItems?.length) {
            setSelectedItems([])
        }
    }, [options, selectedItems])

    /** memoized Values **/
    const isChildren = useMemo(() => React.Children && React.Children.count(children) > 0, [children])
    const selectItemInList = (listIndex: number, itemIndex: number, item: string, onChange: (listIndex: number, itemIndex: number, value: string) => void): void => {
        const newSelectedItems: string[] = [...selectedItems]
        onChange(listIndex, itemIndex, item)
        newSelectedItems[listIndex] = item
        setSelectedItems(newSelectedItems)
    }

    return (
        <div {...props} className={style.filterDropdown} data-test={props['data-test']}>
            <p className={style.title} data-test={`${props['data-test']}.label`}>
                {title}
            </p>
            <div className={style.separator} />
            {options?.length ? (
                <ul className={style.reset}>
                    {options?.map((option, listIndex) => {
                        return (
                            <li key={listIndex} className={style.reset}>
                                <Dropdown
                                    data-test={`${props['data-test']}.filters.${listIndex}`}
                                    title={selectedItems[listIndex] || option[0]}
                                    onClick={(itemIndex) => selectItemInList(listIndex, itemIndex, options[listIndex][itemIndex], onChange)}>
                                    {option?.map((item, idxItem) => {
                                        return (
                                            <SectionText
                                                key={`${listIndex}-${idxItem}`}
                                                selected={selectedItems[listIndex] === item}
                                                onClick={() => null}
                                                data-test={`${props['data-test']}.filters.${listIndex}.option.${idxItem}`}>
                                                {item}
                                            </SectionText>
                                        )
                                    })}
                                </Dropdown>
                            </li>
                        )
                    })}
                </ul>
            ) : null}
            {isChildren ? (
                <ul className={style.reset}>
                    {React.Children.map(children, (child, index) => {
                        return (
                            <li data-test={`${props['data-test']}.children.${index}`} key={index} className={style.reset}>
                                {child}
                                <div className={style.separator} />
                            </li>
                        )
                    })}
                </ul>
            ) : null}
        </div>
    )
}

export default React.memo(FilterDropdown)

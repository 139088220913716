import { buildApi, destroy, get, patch, post } from 'redux-bees'

const initRESTAPI = (config) => {
    return buildApi(
        {
            'permissions.load': { method: get, path: '/grants' },

            'resources.get': { method: get, path: '/resources/:id' },
            'resources.list': { method: get, path: '/resources' },
            'forms.get': { method: get, path: '/forms/:name' },
            'forms.generate': { method: post, path: '/forms/:id' },

            'docTemplates.list': { method: get, path: '/document-templates' },
            'docTemplates.get': {
                method: get,
                path: '/document-templates/:id',
            },
            'docTemplates.generate': {
                method: post,
                path: '/document-templates/:id',
            },

            'documents.generate': {
                method: patch,
                path: '/documents/:id/file',
            },
            'documents.create': { method: post, path: '/documents' },
            'documents.get': { method: get, path: '/documents/:id' },
            'documents.update': { method: patch, path: '/documents/:id' },
            'documents.updateBulk': { method: patch, path: '/documents/bulk' },
            'documents.delete': { method: destroy, path: '/documents/:id' },
            'documents.deleteFile': {
                method: destroy,
                path: '/documents/:id/file',
            },
            'documents.deleteBulk': {
                method: destroy,
                path: '/documents/bulk',
            },
            'documents.comments': {
                method: get,
                path: '/documents/:id/comments',
            },
            'documents.updateComment': {
                method: patch,
                path: '/documents/:id/comments/:idComment',
            },
            'documents.deleteComment': {
                method: destroy,
                path: '/documents/:id/comments/:idComment',
            },

            'estates.documents': {
                method: get,
                path: '/estates/:id/documents',
            },
            'estates.executors': {
                method: get,
                path: '/estates/:id/executors',
            },
            'estates.collaborators': {
                method: get,
                path: '/estates/:id/collaborators',
            },
            'estates.addEstateCollaborator': {
                method: post,
                path: '/estates/:id/collaborators',
            },
            'estates.getEstateMembers': {
                method: get,
                path: '/estates/:id/collaborators/members',
            },
            'estates.deleteEstateCollaborator': {
                method: destroy,
                path: '/estates/:id/collaborators/:collaboratorId',
            },
            'estates.activities': {
                method: get,
                path: '/estates/:id/activities',
            },
            'estates.comments': { method: get, path: '/estates/:id/comments' },
            'estates.updateComment': {
                method: patch,
                path: '/estates/:id/comments/:idComment',
            },
            'estates.deleteComment': {
                method: destroy,
                path: '/estates/:id/comments/:idComment',
            },
            'estates.list': { method: get, path: '/estates' },
            'estates.create': { method: post, path: '/estates' },
            'estates.get': { method: get, path: '/estates/:id' },
            'estates.getSummary': { method: get, path: '/estates/:id/summary' },
            'estates.update': { method: patch, path: '/estates/:id' },
            'estates.delete': { method: destroy, path: '/estates/:id' },
            'estates.workflow': {
                method: post,
                path: '/estates/:id/workflow/:action',
            },
            'estates.getWorkflow': {
                method: get,
                path: '/estates/:id/workflow',
            },
            'estates.createWorkflow': {
                method: post,
                path: '/estates/workflow',
            },

            'integrations.listIntegrations': {
                method: get,
                path: '/estates/:estateId/integrations',
            },
            'integrations.createIntegration': {
                method: post,
                path: '/estates/:estateId/integrations/:integration',
            },
            'integrations.removeIntegration': {
                method: destroy,
                path: '/estates/:estateId/integrations/:integration',
            },
            'integrations.updateIntegrationData': {
                method: post,
                path: '/estates/:estateId/integrations/:integration/import',
            },

            'users.create': { method: post, path: '/users' },
            'users.get': { method: get, path: '/users/:id' },
            'users.validateEmail': { method: post, path: '/users/validation' },
            'users.requestToResetPassword': {
                method: post,
                path: '/users/:email/passwordReset',
            },
            'users.resetPassword': {
                method: patch,
                path: '/users/:email/password',
            },
            'billingPlans.addPayment': { method: post, path: '/orgs/:orgId/billingPlans/payment' },
            'billingPlans.removePaymentMethod': { method: destroy, path: '/orgs/:orgId/billingPlans/payment' },
            'billingPlans.getDefaultPayment': { method: get, path: '/orgs/:orgId/billingPlans/payment' },
            'billingPlans.invoices': { method: get, path: '/orgs/:orgId/billingPlans/invoices' },
            'orgs.create': { method: post, path: '/orgs' },
            'orgs.list': { method: get, path: '/orgs' },
            'orgs.get': { method: get, path: '/orgs/:id' },
            'orgs.update': { method: patch, path: '/orgs/:id' },
            'orgs.delete': { method: destroy, path: '/orgs/:id' },
            'orgs.listMember': { method: get, path: '/orgs/:id/members' },
            'orgs.getMember': {
                method: get,
                path: '/orgs/:id/members/:userId',
            },
            'orgs.updateMember': {
                method: patch,
                path: '/orgs/:id/members/:userId',
            },
            'orgs.updateMemberActiveStatus': {
                method: patch,
                path: '/orgs/:id/members/:userId/activate',
            },
            'orgs.removeMember': {
                method: destroy,
                path: '/orgs/:id/members/:userId',
            },
            'orgs.listInvitations': {
                method: get,
                path: '/orgs/:id/invitations',
            },
            'orgs.addInvitation': {
                method: post,
                path: '/orgs/:id/invitations',
            },
            'orgs.updateInvitation': {
                method: patch,
                path: '/orgs/:id/invitations/:invitationId',
            },
            'orgs.removeInvitation': {
                method: destroy,
                path: '/orgs/:id/invitations/:invitationId',
            },
            'orgs.remindInvitation': {
                method: post,
                path: '/orgs/:id/invitations/:invitationId/reminders',
            },
            'orgs.listTeam': { method: get, path: '/orgs/:id/teams' },
            'orgs.getTeam': { method: get, path: '/orgs/:id/teams/:teamId' },
            'orgs.createTeam': { method: post, path: '/orgs/:id/teams' },
            'orgs.updateTeam': {
                method: patch,
                path: '/orgs/:id/teams/:teamId',
            },
            'orgs.deleteTeam': {
                method: destroy,
                path: '/orgs/:id/teams/:teamId',
            },

            'orgs.listTeamMembers': {
                method: get,
                path: '/orgs/:id/teams/:teamId/members',
            },
            'orgs.deleteTeamMember': {
                method: destroy,
                path: '/orgs/:id/teams/:teamId/members/:userId',
            },
            'orgs.addTeamMember': {
                method: post,
                path: '/orgs/:id/teams/:teamId/members',
            },
            'orgs.listFee': {
                method: get,
                path: '/orgs/:id/fees',
            },
            'orgs.addFee': {
                method: post,
                path: '/orgs/:id/fees',
            },
            'orgs.deleteFee': {
                method: destroy,
                path: '/orgs/:id/fees',
            },

            'roles.list': { method: get, path: '/orgs/:orgId/roles' },
            'roles.create': { method: post, path: '/orgs/:orgId/roles' },
            'roles.update': {
                method: patch,
                path: '/orgs/:orgId/roles/:roleId',
            },
            'roles.delete': {
                method: destroy,
                path: '/orgs/:orgId/roles/:roleId',
            },
            'permissions.list': { method: get, path: '/permissions' },

            'executors.get': {
                method: get,
                path: '/estates/:estateId/executors/:id',
            },
            'executors.update': {
                method: patch,
                path: '/estates/:estateId/executors/:id',
            },
            'executors.delete': {
                method: destroy,
                path: '/estates/:estateId/executors/:id',
            },

            'contacts.get': {
                method: get,
                path: '/estates/:estateId/contacts',
            },
            'contacts.update': {
                method: patch,
                path: '/estates/:estateId/contacts',
            },

            'beneficiaries.list': {
                method: get,
                path: '/estates/:estateId/beneficiaries',
            },
            'beneficiaries.create': {
                method: post,
                path: '/estates/:estateId/beneficiaries',
            },
            'beneficiaries.get': {
                method: get,
                path: '/estates/:estateId/beneficiaries/:id',
            },
            'beneficiaries.update': {
                method: patch,
                path: '/estates/:estateId/beneficiaries/:id',
            },
            'beneficiaries.delete': {
                method: destroy,
                path: '/estates/:estateId/beneficiaries/:id',
            },

            'reports.get': { method: get, path: '/reports/:id' },
            'reports.getAccounts': { method: get, path: '/reports/accounts' },

            'invitations.create': {
                method: post,
                path: '/estates/:estateId/invitations',
            },
            'invitations.list': {
                method: get,
                path: '/estates/:estateId/invitations',
            },
            'invitations.get': {
                method: get,
                path: '/estates/:estateId/invitations/:id',
            },
            'invitations.update': {
                method: patch,
                path: '/estates/:estateId/invitations/:id',
            },
            'invitations.delete': {
                method: destroy,
                path: '/estates/:estateId/invitations/:id',
            },
            'invitations.accept': { method: post, path: '/invitations/:token' },
            'invitations.reject': {
                method: destroy,
                path: '/invitations/:token',
            },
            'auth.get': { method: get, path: '/auth' },
            'auth.login': { method: post, path: '/auth/login' },
            'auth.logoff': { method: destroy, path: '/auth/logoff' },

            'labels.list': { method: get, path: '/labels' },
            'labels.update': { method: patch, path: '/labels/:key/:lang' },
            'labels.add': { method: post, path: '/labels/:key/:lang' },

            'exchanges.search': { method: get, path: '/exchanges' },
            'exchanges.getPriceHistory': {
                method: get,
                path: '/exchanges/:exchangeName/:symbolCode/:date',
            },
            'exchanges.getCurrencyPriceHistory': {
                method: get,
                path: '/exchanges/currency/:sourceCurrency/:targetCurrency/:date',
            },

            'places.search': { method: get, path: '/places' },
            'places.getPlaceDetails': { method: get, path: '/places/:placeId' },

            'inventory.get': {
                method: get,
                path: '/estates/:estateId/inventory/:itemId',
            },
            'inventory.list': {
                method: get,
                path: '/estates/:estateId/inventory',
            },
            'inventory.create': {
                method: post,
                path: '/estates/:estateId/inventory',
            },
            'inventory.update': {
                method: patch,
                path: '/estates/:estateId/inventory/:itemId',
            },
            'inventory.delete': {
                method: destroy,
                path: '/estates/:estateId/inventory/:itemId',
            },
            'inventory.transfer': {
                method: post,
                path: '/estates/:estateId/inventory/:itemId/transfer',
            },
            'inventory.repatriate': {
                method: post,
                path: '/estates/:estateId/inventory/:itemId/repatriate',
            },
            'inventory.payDebt': {
                method: post,
                path: '/estates/:estateId/inventory/:itemId/pay',
            },
            'inventory.forgive': {
                method: post,
                path: '/estates/:estateId/inventory/:itemId/forgive',
            },
            'inventory.updateDebtForgiveness': {
                method: patch,
                path: '/estates/:estateId/inventory/:itemId/forgive',
            },
            'inventory.deleteDebtForgiveness': {
                method: destroy,
                path: '/estates/:estateId/inventory/:itemId/forgive',
            },
            'inventory.distribute': {
                method: post,
                path: '/estates/:estateId/inventory/:itemId/distribute',
            },

            'distributions.list': {
                method: get,
                path: '/estates/:estateId/distributions',
            },
            'distributions.get': {
                method: get,
                path: '/estates/:estateId/distributions/:id',
            },
            'distributions.update': {
                method: patch,
                path: '/estates/:estateId/distributions/:id',
            },
            'distributions.delete': {
                method: destroy,
                path: '/estates/:estateId/distributions/:id',
            },

            'transactions.list': {
                method: get,
                path: '/estates/:estateId/accounts/transactions',
            },
            'transactions.create': {
                method: post,
                path: '/estates/:estateId/accounts/:accountId/transactions',
            },
            'transactions.get': {
                method: get,
                path: '/estates/:estateId/accounts/:accountId/transactions/:transactionId',
            },
            'transactions.update': {
                method: patch,
                path: '/estates/:estateId/accounts/:accountId/transactions/:transactionId',
            },
            'transactions.delete': {
                method: destroy,
                path: '/estates/:estateId/accounts/:accountId/transactions/:transactionId',
            },

            'accounts.list': {
                method: get,
                path: '/estates/:estateId/accounts',
            },
            'accounts.create': {
                method: post,
                path: '/estates/:estateId/accounts',
            },
            'accounts.get': {
                method: get,
                path: '/estates/:estateId/accounts/:accountId',
            },
            'accounts.update': {
                method: patch,
                path: '/estates/:estateId/accounts/:accountId',
            },
            'accounts.delete': {
                method: destroy,
                path: '/estates/:estateId/accounts/:accountId',
            },
            'accounts.transfer': {
                method: post,
                path: '/estates/:estateId/accounts/:accountId/transfer',
            },

            'accounts.distribute': {
                method: post,
                path: '/estates/:estateId/accounts/distribute',
            },
            'notifications.list': {
                method: get,
                path: '/notifications',
            },
            'notifications.update': {
                method: patch,
                path: '/notifications/:id',
            },
            'notifications.updateAll': {
                method: patch,
                path: '/notifications',
            },
            'notifications.delete': {
                method: destroy,
                path: '/notifications/:id',
            },
            'tasks.list': {
                method: get,
                path: '/estates/:estateId/tasks',
            },
            'tasks.get': {
                method: get,
                path: '/estates/:estateId/tasks/:uuid',
            },
            'tasks.update': {
                method: patch,
                path: '/estates/:estateId/tasks/:uuid',
            },
        },
        config
    )
}

const build = (config) => {
    const api = initRESTAPI(config)
    const builtApi = {}
    Object.keys(api).map((curr) => {
        const keys = curr.split('.')
        if (!builtApi[keys[0]]) builtApi[keys[0]] = {}
        builtApi[keys[0]][keys[1]] = api[curr]
        return builtApi
    })
    const apiEndpoints = {
        ...builtApi,
        format: (obj, type) => {
            const ret = {
                data: {},
            }
            if (type) ret.data.type = type
            if (obj.id != undefined || obj._id != undefined) {
                ret.data.id = obj.id != undefined ? obj.id : obj._id
                delete obj.id
                delete obj._id
            }
            ret.data.attributes = { ...obj }
            return ret
        },
        getQuery: (page, filter, search) => {
            const query = {}
            if (page && page.number) {
                query['page[number]'] = page.number
            }
            if (page && page.size) {
                query['page[size]'] = page.size
            }
            if (filter) {
                Object.keys(filter).map((key) => {
                    query[`filter[${key}]`] = filter[key]
                })
            }
            if (search) query.search = search
            return query
        },
        exportFormats: {
            xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            pdf: 'application/pdf',
        },
    }
    return apiEndpoints
}

export default { build }

import React from 'react'
import { ActionCard, ButtonSuccess, Illustration } from '../../ds/components'
import history from './history'
import { useTranslation } from 'react-i18next'

type LogoutModalProps = {
    logoff: () => void
}
const LogoutModal = ({ logoff }: LogoutModalProps) => {
    const { t } = useTranslation()
    const returnToPathname = history?.location?.pathname ? `?url=${history?.location?.pathname}` : ''
    return (
        <ActionCard
            data-test={'LogoutModal'}
            illustrationType={Illustration.types.Information}
            buttons={[
                <ButtonSuccess
                    data-test={'LogoutModal.logIn'}
                    onClick={() => {
                        logoff()
                        history.push(`/login${returnToPathname}`)
                        location.reload()
                    }}
                    key='button1'
                    size={'medium'}>
                    {t('Log in')}
                </ButtonSuccess>,
            ]}
            title={t('Just to be safe, we logged you out.')}
            subtitle={t('Log back in to pick up where you left off.')}
            onClose={() => {
                logoff()
                history.push(`/login${returnToPathname}`)
                location.reload()
            }}
        />
    )
}
export default LogoutModal

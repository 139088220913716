/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Reports
 */
export class ReportsApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * Get report data
     * ${reportId}
     **/

    async getReport(
        reportId: string,
        estateId?: string,
        inventoryId?: string,
        current?: boolean,
        lang?: string,
        startDate?: string,
        endDate?: string,
        FIValue?: number,
        currentInvValue?: number,
        timeSpentInYears?: number
    ): Promise<Buffer> {
        try {
            const res: AxiosResponse<Buffer> = await this.get<Buffer>(`/reports/${reportId}`, {
                responseType: 'arraybuffer',
                params: { estateId, inventoryId, current, lang, startDate, endDate, FIValue, currentInvValue, timeSpentInYears },
            })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

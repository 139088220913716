import network from '../../utils/network'
import history from '../App/history'
import { createAuthSession } from 'utils/auth'
import * as Sentry from '@sentry/react'
import { Buffer } from 'buffer'

export const ACTION_LOGIN = 'ACTION_LOGIN'
export const ACTION_GET_AUTH_CONFIG = 'ACTION_GET_AUTH_CONFIG'
export const ACTION_LOGOFF = 'ACTION_LOGOFF'
export const ACTION_REGISTER = 'ACTION_REGISTER'
export const ACTION_RENDER_REGISTER_FORM = 'ACTION_RENDER_REGISTER_FORM'
export const ACTION_RENDER_FORGOT_PASSWORD_FORM = 'ACTION_RENDER_FORGOT_PASSWORD_FORM'
export const ACTION_VALIDATE_EMAIL = 'ACTION_VALIDATE_EMAIL'
export const ACTION_ACCEPT_INVITE = 'ACTION_ACCEPT_INVITE'

const { auth, users, invitations, orgs } = network.api

export const actionLogin = (values) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_LOGIN,
            payload: createAuthSession({
                data: {
                    type: 'auth',
                    attributes: {
                        username: values.email.toLowerCase(),
                        password: values.password,
                    },
                },
            }).then((data) => {
                if (window.__CONFIG__.Sentry) {
                    /**
                     * Set userID and name for better tracking
                     */

                    Sentry.setUser({ id: data?.userInfo?.id, email: data?.userInfo?.email })
                    Sentry.setTags({
                        userRole: data?.userInfo?.org?.userRole,
                        sso_enabled: data?.userInfo?.org?.sso?.enabled || false,
                        org_name: data?.userInfo?.org?.name,
                        org_domain: data?.userInfo?.org?.domain || '',
                        billingPlanName: data?.userInfo?.org?.currentPlan?.name,
                        billingPlanCountry: data?.userInfo?.org?.currentPlan?.country,
                        location: data?.userInfo?.org?.location,
                        billingPlanLegacy: data?.userInfo?.org?.currentPlan?.legacy,
                    })
                }
                return orgs.getMemberPic({ orgId: data.userInfo.org.id, userId: data.userInfo.id }).then((response) => {
                    if (response.data && response.data.byteLength > 0) {
                        let tmp = new Buffer.from(response.data, 'binary')
                        const blob = new Blob([tmp], { type: 'image/*' })
                        let blobURL = window.URL.createObjectURL(blob)
                        data.userInfo.profilePic = blobURL
                        return data
                    }
                    data.userInfo.profilePic = null
                    return data
                })
            }),
        })
    }
}
export const logoff = () => {
    auth.logoff()
        .catch(() => {
            // catch is empty because doesn't matter if it succeed or not we want to redirect the user to the login page
            // the catch is also empty to fix the UncaughtError
        })
        .finally(() => {
            if (window.__CONFIG__.Sentry) {
                /**
                 * Clean user traking
                 */
                Sentry.setUser(null)
                Sentry.setTags({
                    userRole: '',
                    sso_enabled: 'false',
                    org_name: '',
                    org_domain: '',
                    billingPlanName: '',
                    billingPlanCountry: '',
                    location: '',
                    billingPlanLegacy: '',
                })
            }
            if (network.token) {
                history.push('/login')
            }
        })

    return {
        type: ACTION_LOGOFF,
        payload: {},
    }
}

export const checkSession = (redirectState) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_LOGIN,
            payload: createAuthSession(null, redirectState).then((data) => {
                if (window.__CONFIG__.Sentry) {
                    /**
                     * Set userID and name for better tracking
                     */
                    Sentry.setUser({ id: data?.userInfo?.id, email: data?.userInfo?.email })
                    Sentry.setTags({
                        userRole: data?.userInfo?.org?.userRole,
                        sso_enabled: `${data?.userInfo?.org?.sso?.enabled || false}`,
                        org_name: data?.userInfo?.org?.name,
                        org_domain: data?.userInfo?.org?.domain || '',
                        billingPlanName: data?.userInfo?.org?.currentPlan?.name,
                        billingPlanCountry: data?.userInfo?.org?.currentPlan?.country,
                        location: data?.userInfo?.org?.location,
                        billingPlanLegacy: `${data?.userInfo?.org?.currentPlan?.legacy}`,
                    })
                }
                return orgs.getMemberPic({ orgId: data.userInfo.org.id, userId: data.userInfo.id }).then((response) => {
                    if (response.data && response.data.byteLength > 0) {
                        let tmp = new Buffer.from(response.data, 'binary')
                        const blob = new Blob([tmp], { type: 'image/*' })
                        let blobURL = window.URL.createObjectURL(blob)
                        data.userInfo.profilePic = blobURL
                        return data
                    }
                    data.userInfo.profilePic = null
                    return data
                })
            }),
        })
    }
}
export const acceptInvite = (hash) => {
    return (dispatch) => {
        dispatch({
            type: ACTION_ACCEPT_INVITE,
            payload: {
                hash: hash,
            },
        })
        return dispatch({
            type: ACTION_ACCEPT_INVITE,
            payload: invitations.accept({ token: hash }, network.api.format({})),
        })
    }
}
export const actionRequestCode = (values) => {
    return {
        type: ACTION_LOGIN,
        payload: network.post(`${__CONFIG__.API}/register/resendhash`, {
            username: values.email,
        }),
    }
}

export const actionGetAuthConfig = () => {
    return {
        type: ACTION_GET_AUTH_CONFIG,
        payload: auth.get(),
    }
}

export const actionValidate = (values) => {
    return {
        type: ACTION_VALIDATE_EMAIL,
        payload: users.validateEmail({
            data: {
                attributes: {
                    token: values.code,
                },
            },
        }),
    }
}

export const gotoRegister = () => {
    return {
        type: ACTION_RENDER_REGISTER_FORM,
        payload: history.push('/register'),
    }
}
export const gotoForgotPassword = () => {
    return {
        type: ACTION_RENDER_FORGOT_PASSWORD_FORM,
        payload: history.push('/forgot-password'),
    }
}

import React, { createElement, useContext, useEffect, useMemo, useState } from 'react'
import { AccessControl } from 'role-acl'
import { useCancelAPIs } from '../system/api'
import { Permission } from 'role-acl/lib/src/core'

/**
 The component “Can“ is one React.Context.Consumer,
 when the Provider is declared on the AppRouter and is responsible for request the entrypoint
 “/api/v1/grants” witch return the grants for the current logged user on the organization,
 and this return is used to test the permissions on the Consumer.

 Use:
 <Can
 query='organizations.*'
 render={({ granted }) => <AppHeader
        openProfile={this.props.openProfile}
        logoff={this.props.logoff}
        userInfo={this.props.userInfo}
        handleLogoClick={this.props.handleLogoClick}
        showTutorial={this.showTutorial}
        isAdmin={granted}
    />}
 />
 <Can
 query='organizations.*,estates.delete'
 owner={estate.owner}
 render={({ grantedPermissions }) => ()}
 />


 PROPS:
 orgId: is passed on the context when the user is logged, so, don`t need to pass again, except if you need to check on the admin area.
 owner: is passed when you need to test the context of the estate (owner) or any other (in the future) to check if you have permission to “mine” resource.

 Query language supported:

 “resource.*:  will return:
 granted: If have any action allowed in this resource
 grantedPermissions: Object (map) with all grant found for this resource ex:

 {
  'resource.list': true,
  'resource.update: true
}

 “resource.action:  will return:
 granted: If have this action is allowed on this context
 grantedPermissions: Object (map) with all grant found for this resource ex:

 {
  'resource.action': true
}

 “resource.action.*: will return:
 granted: If have any scope is this action is allowed on this context
 grantedPermissions: Object (map) with all grant found for this resource ex:

 {
  'resource.action.org': true,
  'resource.action.mine': true,
  'resource.action.team': true
}

 */
type PermissionsContextProps = {
    permissions?: string[]
    orgId?: string
    teamId?: string
    owner?: string
    ac?: AccessControl
    collaborators?: string[]
    isPermissionsRequestLoading: boolean
}
/**
 * Context used to share Permission context in all app
 */
export const PermissionsContext = React.createContext<PermissionsContextProps>({
    permissions: [],
    orgId: '',
    teamId: '',
    owner: '',
    ac: undefined,
    isPermissionsRequestLoading: false,
    collaborators: undefined,
})

type PermissionsProviderProps = {
    userId?: string
    orgId?: string
    teamId?: string
    owner?: string
    collaborators?: string[]
}
/**
 * Permission provider used to expose the context PermissionsContext Logic
 * @param userId
 * @param orgId
 * @param teamId
 * @param owner
 * @param collaborators
 * @param children
 * @constructor
 */
export const PermissionsProvider = ({ userId, orgId, teamId, owner, collaborators, children }: React.PropsWithChildren<PermissionsProviderProps>) => {
    const api = useCancelAPIs()
    const [ac, setAc] = useState<AccessControl>()
    const [permissions, setPermissions] = useState<string[]>()
    const [isPermissionsRequestLoading, setisPermissionsRequestLoading] = useState(false)
    useEffect(() => {
        setisPermissionsRequestLoading(true)
        api.permissions.listGrants().then((permissions) => {
            setAc(new AccessControl(permissions.data?.map((p) => p.attributes)))
            setPermissions(permissions.data?.map((permission) => permission.id))
            setisPermissionsRequestLoading(false)
        })
    }, [userId, orgId, teamId, owner, collaborators])
    return (
        <PermissionsContext.Provider
            value={{
                permissions,
                orgId,
                teamId,
                owner,
                collaborators,
                ac,
                isPermissionsRequestLoading,
            }}>
            {ac ? children : null}
        </PermissionsContext.Provider>
    )
}
type CanCallBackProps = {
    granted: boolean
    grantedPermissions: { [key: string]: boolean }
}
type CanProps = {
    query: string
    render?: (props: CanCallBackProps) => JSX.Element
    owner?: string
    orgId?: string
    teamId?: string
    allowed?: any
}
/**
 * @deprecated
 * Method used to check permissions in a specific context
 * @param query
 * @param props
 * @constructor
 */
export const Can = ({ query, ...props }: CanProps) => {
    const context = useContext(PermissionsContext)
    const _permissions: string[] = query.split(',')
    const permissionContext: {
        teams?: string[]
        owner?: string
        orgId?: string
        teamId?: string
    } = {
        owner: context.owner || props.owner,
        orgId: context.orgId || props.orgId,
        // allowed: context.allowed || props.allowed, not used???
        teamId: context.teamId || props.teamId,
        teams: undefined,
    }
    if (context.teamId) permissionContext.teams = [context.teamId]
    else permissionContext.teams = props.teamId ? [props.teamId] : []
    const returns = _permissions.reduce(
        (all, _permission) => {
            const permission = _permission.trim()
            if (typeof permission == 'boolean') {
                all.granted = permission
                return all
            }
            const [resource, action, any] = permission.split('.')
            if (action && action != '*' && !any) {
                // When have resource.action
                const can =
                    context.ac &&
                    (context.ac
                        .can('authenticatedUser')
                        .context(permissionContext)
                        .execute(action || '*')
                        .sync()
                        .on(resource) as Permission) // Sync before guarantee that is not a promice
                all.granted = all.granted && (can && can.granted ? true : false)
                all.grantedPermissions[permission] = can && can.granted ? true : false
                return all
            } else {
                const grantsActions =
                    context.ac &&
                    context.ac.allowedActionsSync({
                        role: 'authenticatedUser',
                        resource,
                    })
                if (!any) {
                    // When have resource.* should return all actions for this resource
                    all.granted = grantsActions && grantsActions.length > 0 ? true : false
                    grantsActions &&
                        grantsActions.map((_action) => {
                            all.grantedPermissions[`${resource}.${_action}`] = true
                        })
                } else {
                    // When have resource.action.* need to know this action in any conditional
                    all.granted = grantsActions && grantsActions.indexOf(action) >= 0 ? true : false
                    if (grantsActions && grantsActions.indexOf(action) >= 0) {
                        all.grantedPermissions[`${resource}.${action}`] = true
                    }
                }
                return all
            }
        },
        {
            granted: true,
            grantedPermissions: {},
        } as CanCallBackProps
    )
    return (props.render && props.render({ ...returns })) || null
}
type mapGrantsDefaultType = { [key: string]: boolean }
/**
 * @deprecated
 * HOk used to map permission granst
 * @param map
 */
export const mapGrants: <T>(map: { [key: string]: string | string[] }) => (component: any) => React.FunctionComponent<T> = (map) => {
    return (component) => {
        return (props: any) => {
            const context = useContext(PermissionsContext)
            const [permissionsStatus, setPermissionsStatus] = useState<mapGrantsDefaultType>({})
            useEffect(() => {
                if (!context.ac) {
                    return
                }
                const permissionContext = {
                    owner: context.owner,
                    orgId: context.orgId,
                    // teamId: this.context.teamId,
                    teams: [context.teamId],
                    collaborators: context.collaborators,
                }
                const permissionsStatus = Object.keys(map).reduce((all, key) => {
                    let permissions: string[]
                    if (Array.isArray(map[key])) {
                        permissions = map[key] as string[]
                    } else {
                        permissions = [map[key] as string]
                    }
                    permissions.map((permission) => {
                        if (all[key]) return all
                        if (typeof permission == 'boolean') {
                            all[key] = permission
                            return all
                        }
                        const [resource, action, any] = permission.split('.')
                        if (action && action != '*' && !any) {
                            // When have resource.action
                            const can =
                                context.ac &&
                                (context.ac
                                    .can('authenticatedUser')
                                    .context(permissionContext)
                                    .execute(action || '*')
                                    .sync()
                                    .on(resource) as Permission) // Sync before guarantee that is not a promice
                            all[key] = can && can.granted ? true : false
                        } else {
                            const grantsActions =
                                context.ac &&
                                context.ac.allowedActionsSync({
                                    role: 'authenticatedUser',
                                    resource,
                                })

                            if (!action || action === '*') {
                                // When have resource.* should return all actions for this resource
                                all[key] = grantsActions && grantsActions.length > 0 ? true : false
                            } else {
                                // When have resource.action.* need to know this action in any conditional
                                if (any === '*') {
                                    all[key] = grantsActions && grantsActions.includes(action) ? true : false
                                } else {
                                    all[key] = grantsActions && grantsActions.indexOf(action) >= 0 ? true : false
                                }
                            }
                        }
                    })
                    return all
                }, {} as mapGrantsDefaultType)
                setPermissionsStatus(permissionsStatus)
            }, [context.orgId, context.owner, context.teamId, context.collaborators, context.ac])
            return useMemo(
                () =>
                    createElement(
                        component,
                        Object.assign({}, props, {
                            permissions: permissionsStatus,
                        })
                    ),
                [permissionsStatus, props]
            )
        }
    }
}
/**
 * React hook used to check permission for specific context
 * @param permissionsLists string of permission or arrya of permissions that want to test
 * @return boolean[] array of boolean with the same size of the permissionsList parameter
 */
export const usePermissions: (permissionsLists: Array<string | string[]>) => boolean[] = (permissionsLists) => {
    const context = useContext(PermissionsContext)
    const permissionContext = {
        owner: context.owner,
        orgId: context.orgId,
        // teamId: this.context.teamId,
        teams: [context.teamId],
        collaborators: context.collaborators,
    }
    const permissionsStatus = useMemo(() => {
        return permissionsLists.map((query) => {
            let permissions: string[]
            if (Array.isArray(query)) {
                permissions = query as string[]
            } else {
                permissions = [query as string]
            }
            let allow = false
            for (const permission of permissions) {
                if (allow) break
                if (typeof permission == 'boolean') {
                    allow = permission
                    continue
                }
                const [resource, action, any] = permission.split('.')
                if (action && action != '*' && !any) {
                    // When have resource.action
                    const can =
                        context.ac &&
                        (context.ac
                            .can('authenticatedUser')
                            .context(permissionContext)
                            .execute(action || '*')
                            .sync()
                            .on(resource) as Permission) // Sync before guarantee that is not a promice
                    allow = can && can.granted ? true : false
                } else {
                    const grantsActions =
                        context.ac &&
                        context.ac.allowedActionsSync({
                            role: 'authenticatedUser',
                            resource,
                        })

                    if (!action || action === '*') {
                        // When have resource.* should return all actions for this resource
                        allow = grantsActions && grantsActions.length > 0 ? true : false
                    } else {
                        // When have resource.action.* need to know this action in any conditional
                        if (any === '*') {
                            allow = grantsActions && grantsActions.includes(action) ? true : false
                        } else {
                            allow = grantsActions && grantsActions.indexOf(action) >= 0 ? true : false
                        }
                    }
                }
            }
            return allow
        })
    }, [context.orgId, context.owner, context.teamId, context.collaborators, context.ac])
    return permissionsStatus
}

export const useIsPermissionsRequestLoading = () => {
    const context = useContext(PermissionsContext)
    return context.isPermissionsRequestLoading
}

export default {
    PermissionsProvider,
}

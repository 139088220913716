import React, { useEffect } from 'react'
import AppRouter from './AppRouter'
import { applyMiddleware, createStore } from 'redux'
import { Provider } from 'react-redux'
import promiseMiddleware from 'redux-promise-middleware'
import ReduxThunk from 'redux-thunk'
import appReducers from './app.reducers'
import './App.module.scss'
import i18n, { changeLanguage, getBrowserLanguage, I18nProvider, init } from '../../utils/translation/index'
import ErrorBoundary from './ErrorBoundary'
import network from '../../utils/network'
import utils from '../../utils/utils'
import { CustomClientColors, primary600, setColors } from '../../utils/DesignSystem/colors'
import featureFlags from '../../utils/FeatureFlags'

network.setLang(utils.getCookie('lang') || getBrowserLanguage())
declare let __DEV__: boolean
declare let zE: any
const store = applyMiddleware(ReduxThunk, promiseMiddleware)(createStore)(
    appReducers,
    __DEV__ ? (window as any).__REDUX_DEVTOOLS_EXTENSION__ && (window as any).__REDUX_DEVTOOLS_EXTENSION__({ name: 'App' }) : false //eslint-disable-line
)
network.store = store

/**
 * Component to represent the app and control Zapier configuration
 *
 */
const App = () => {
    useEffect(() => {
        init()
        if (typeof zE != 'undefined') {
            zE('webWidget', 'hide')
            zE('webWidget', 'updateSettings', {
                webWidget: {
                    position: { vertical: 'top' },
                    color: { theme: primary600 },
                },
            })
            zE('webWidget', 'setLocale', `${utils.getCookie('lang') || 'en'}-ca`)
        }
        changeLanguage(utils.getCookie('lang') || getBrowserLanguage())
    }, [])

    setColors(true, featureFlags.isActive('BNCCustomColors') ? CustomClientColors.BNC : undefined)

    return (
        <Provider store={store}>
            <ErrorBoundary>
                <I18nProvider i18n={i18n}>
                    <AppRouter />
                </I18nProvider>
            </ErrorBoundary>
        </Provider>
    )
}
export default App

import network from '../../utils/network'
import history from '../App/history'

export const ACTION_FETCH_TRUSTS = 'ACTION_FETCH_TRUSTS'
export const ACTION_SELECT_TRUSTS = 'ACTION_SELECT_TRUSTS'
export const ACTION_NEW_TRUST = 'ACTION_NEW_TRUST'
export const ACTION_EDIT_TRUST = 'ACTION_EDIT_TRUST'
export const ACTION_DELETE_TRUST = 'ACTION_DELETE_TRUST'
export const ACTION_SET_SEARCH = 'ACTION_SET_SEARCH'
export const ACTION_FETCH_WORKFLOW = 'ACTION_FETCH_WORKFLOW'
export const ACTION_FETCH_MEMBERS = 'ACTION_FETCH_MEMBERS'
export const ACTION_FETCH_TEAMS = 'ACTION_FETCH_TEAMS'
export const ACTION_FETCH_PROFILE = 'ACTION_FETCH_PROFILE'
export const ACTION_SAVE_PROFILE = 'ACTION_SAVE_PROFILE'
export const ACTION_SAVE_PREFERENCE = 'ACTION_SAVE_PREFERENCE'
export const ACTION_ADD_TRUST_COLLABORATOR = 'ACTION_ADD_TRUST_COLLABORATOR'
export const ACTION_DELETE_TRUST_COLLABORATOR = 'ACTION_DELETE_TRUST_COLLABORATOR'
export const ACTION_FETCH_TRUST_COLLABORATORS = 'ACTION_FETCH_TRUST_COLLABORATORS'
export const ACTION_FETCH_BILLING_PLAN = 'ACTION_FETCH_BILLING_PLAN'

const ITEMS_PER_PAGE = 9

const api = network.api
const { estates, orgs, billingPlans, format, getQuery } = api

export const selectEstate = (estateId) => {
    // if (history.location.pathname != '/menu/documents')
    //     history.push('/menu/documents')
    history.push(`/trusts/${estateId}/overview`)
    return {
        type: ACTION_SELECT_TRUSTS,
        payload: estateId,
    }
}
export const fetchEstateWorkflow = (estateId) => {
    return {
        type: ACTION_FETCH_WORKFLOW,
        payload: estates.getWorkflow({ id: estateId }),
    }
}
export const fetchEstate = () => {
    const query = {
        'page[size]': ITEMS_PER_PAGE,
    }
    return {
        type: ACTION_FETCH_TRUSTS,
        payload: estates.list(query),
    }
}
export const fetchMembersList = (orgId, teamId, search, page = 0) => {
    const filter = { teamId: teamId }
    return {
        type: ACTION_FETCH_MEMBERS,
        payload: orgs.listMember({ id: orgId, ...getQuery(page, filter, search) }),
    }
}

export const fetchTeams = (orgId) => {
    return {
        type: ACTION_FETCH_TEAMS,
        payload: orgs.listTeam({
            id: orgId,
            ...getQuery(0, { onlyUserTeams: true }, null),
        }),
    }
}

export const addEstateCollaborator = (estateId, values) => {
    return {
        type: ACTION_ADD_TRUST_COLLABORATOR,
        payload: estates.addEstateCollaborator({ id: estateId }, format(values)),
    }
}

export const deleteEstateCollaborator = (estateId, collaboratorId) => {
    return {
        type: ACTION_DELETE_TRUST_COLLABORATOR,
        payload: estates.deleteEstateCollaborator({ id: estateId, collaboratorId }),
    }
}

export const fetchEstateCollaborators = (estateId) => {
    return {
        type: ACTION_FETCH_TRUST_COLLABORATORS,
        payload: estates.collaborators({ id: estateId }),
    }
}

export const searchEstate = (name, sort, currentPage = 0, selectedStatusFilter = null, activeFiles) => {
    const query = getQuery(
        {
            number: currentPage + 1,
            size: ITEMS_PER_PAGE,
        },
        null,
        name ? `%${name}%` : ''
    )
    if (sort) {
        query['sort'] = sort
    }
    if (selectedStatusFilter) {
        query['filter[status]'] = selectedStatusFilter
    }
    if (activeFiles) {
        query['filter[active]'] = true
    } else {
        query['filter[archive]'] = true
    }
    query['filter[estateType]'] = 'Trust'
    return (dispach) => {
        dispach({
            type: ACTION_SET_SEARCH,
            payload: { name, sort, currentPage, selectedStatusFilter },
        })
        return dispach({
            type: ACTION_FETCH_TRUSTS,
            payload: estates.list(query),
        })
    }
}

export const updateProfile = (orgId, userId, info) => {
    return {
        type: ACTION_SAVE_PROFILE,
        payload: orgs.updateMember({ id: orgId, userId }, format(info)),
    }
}

export const updateUserPreference = (orgId, userId) => {
    return {
        type: ACTION_SAVE_PREFERENCE,
        payload: orgs.updateMember({ id: orgId, userId }),
    }
}

export const newEstate = (teamId, estateId, subscriptionAddons) => {
    return {
        type: ACTION_NEW_TRUST,
        payload: estates.createWorkflow({}, format({ teamId, estateId, estateType: 'Trust', subscriptionAddons }, 'workflows')),
    }
}

export const saveEstate = (values) => {
    return {
        type: ACTION_EDIT_TRUST,
        payload: estates.update({ id: values.id }, api.format(values, 'estate')),
    }
}
export const deleteEstate = (estateId) => {
    if (!estateId) {
        return {
            type: ACTION_DELETE_TRUST,
            payload: Promise.resolve(),
        }
    }
    return {
        type: ACTION_DELETE_TRUST,
        payload: estates.delete({ id: estateId }).then(() => estateId),
    }
}

export const fetchBillingPlan = (orgId) => {
    return {
        type: ACTION_FETCH_BILLING_PLAN,
        payload: billingPlans.getDefaultPayment({ orgId }),
    }
}

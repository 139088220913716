import {
    GlobalContactsResponse, // Type import GlobalContactsResponse
    GlobalContactsRequest, // Type import GlobalContactsRequest
    GlobalContactResponse, // Type import GlobalContactResponse
    Page, // Type Pagination of list
} from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api, ResponseWithHeader } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Global-contacts
 */
export class GlobalContactsApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * Create a global contact to the case
     * ${orgId}/contacts
     **/

    async createGlobalContact(orgId: string, body: GlobalContactsRequest): Promise<GlobalContactsResponse> {
        try {
            const res: AxiosResponse<GlobalContactsResponse> = await this.post<GlobalContactsResponse, GlobalContactsRequest>(`/orgs/${orgId}/contacts`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Delete a global contact
     * ${orgId}/contacts/${contactId}
     **/

    async deleteGlobalContact(orgId: string, contactId: string): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.delete<void>(`/orgs/${orgId}/contacts/${contactId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Update a global contact
     * ${orgId}/contacts/${contactId}
     **/
    async updateGlobalContact(orgId: string, contactId: string, body: GlobalContactsRequest): Promise<GlobalContactResponse> {
        try {
            const res: AxiosResponse<GlobalContactResponse> = await this.patch<GlobalContactResponse, GlobalContactsRequest>(`/orgs/${orgId}/contacts/${contactId}`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * get a global contact
     * ${orgId}/contacts/${contactId}
     **/

    async getGlobalContact(orgId: string, contactId: string): Promise<GlobalContactResponse> {
        try {
            const res: AxiosResponse<GlobalContactResponse> = await this.get<GlobalContactResponse>(`/orgs/${orgId}/contacts/${contactId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * List the global contacts
     * ${orgId}/contacts
     **/

    async listGlobalContacts(orgId: string, page?: Page, filter?: object, sort?: string): Promise<GlobalContactsResponse> {
        try {
            const res: AxiosResponse<GlobalContactsResponse> = await this.get<GlobalContactsResponse>(`/orgs/${orgId}/contacts`, { params: { page, filter, sort } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

import {
    TaskTemplateUpdateRequest, // Type import TaskTemplateUpdateRequest
    TaskTemplateResponse, // Type import TaskTemplateResponse
    TaskTemplatePostRequest, // Type import TaskTemplatePostRequest
    TaskTemplateResponseList, // Type import TaskTemplateResponseList
    Page, // Type Pagination of list
} from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Org-TaskTemplates
 */
export class OrgTaskTemplatesApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }

    //Methods

    /**
     * That resource allows to delete a TaskTemplates in the Org.
     * ${orgId}/taskTemplates/${taskTemplateId}
     **/

    async deleteTaskTemplate(orgId: string, taskTemplateId: string): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.delete<void>(`/orgs/${orgId}/taskTemplates/${taskTemplateId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Update a TaskTemplates
     * ${orgId}/taskTemplates/${taskTemplateId}
     **/

    async updateTaskTemplates(orgId: string, taskTemplateId: string, body: TaskTemplateUpdateRequest): Promise<TaskTemplateResponse> {
        try {
            const res: AxiosResponse<TaskTemplateResponse> = await this.patch<TaskTemplateResponse, TaskTemplateUpdateRequest>(`/orgs/${orgId}/taskTemplates/${taskTemplateId}`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Get Task Template
     * ${orgId}/taskTemplates/${taskTemplateId}
     **/

    async getTaskTemplate(orgId: string, taskTemplateId: string): Promise<TaskTemplateResponse> {
        try {
            const res: AxiosResponse<TaskTemplateResponse> = await this.get<TaskTemplateResponse>(`/orgs/${orgId}/taskTemplates/${taskTemplateId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * List Task Templates
     * ${orgId}/taskTemplates
     **/

    async listTaskTemplates(orgId: string, page?: Page, filter?: object, search?: string, sort?: string): Promise<TaskTemplateResponseList> {
        try {
            const res: AxiosResponse<TaskTemplateResponseList> = await this.get<TaskTemplateResponseList>(`/orgs/${orgId}/taskTemplates`, {
                params: {
                    page,
                    filter,
                    search,
                    sort,
                },
            })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Create a TaskTemplates
     * ${orgId}/taskTemplates
     **/

    async createTaskTemplates(orgId: string, body: TaskTemplatePostRequest): Promise<TaskTemplateResponse> {
        try {
            const res: AxiosResponse<TaskTemplateResponse> = await this.post<TaskTemplateResponse, TaskTemplatePostRequest>(`/orgs/${orgId}/taskTemplates`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

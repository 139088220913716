import React, { PureComponent } from 'react'
import style from './style.module.scss'
import Checkbox, { CheckboxValues } from '../../atoms/Checkbox'
import { GenericAttributes } from '../../types'

type CheckboxTitledItemProps = {
    label: React.ReactNode
    value: CheckboxValues
    onClick?: (ev: React.MouseEvent<HTMLElement>) => void
} & GenericAttributes
export class CheckboxTitledItem extends PureComponent<CheckboxTitledItemProps> {
    static selectionValues = Checkbox.selectionValues
    static defaultProps = {}
    renderCheckBox(): React.ReactNode {
        const { value } = this.props
        return (
            <div>
                <Checkbox id={`${this.props['data-test']}.checkbox`} data-test={`${this.props['data-test']}.checkbox`} value={value} disabled={false} size={Checkbox.sizes.small} />
            </div>
        )
    }
    render(): React.ReactNode {
        const { label, onClick, ...props } = this.props
        return (
            <div {...props} data-test={`${this.props['data-test']}`} className={style.checkboxTitledItem} onClick={onClick}>
                <label htmlFor={`${this.props['data-test']}.checkbox`} data-test={`${this.props['data-test']}.label`} className={style.label}>
                    {label}
                </label>
                {this.renderCheckBox()}
            </div>
        )
    }
}
export default CheckboxTitledItem

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import i18n, { init, loadNameSpace, withTranslation } from '../utils/translation'
import style from './LabelsLoader.module.scss'
import network from 'utils/network'

/* global zE */
class LabelsLoader extends Component {
    static childContextTypes = {
        T: PropTypes.func.isRequired,
        t: PropTypes.func.isRequired,
        lang: PropTypes.string,
    }

    constructor(props) {
        super(props)
        this.fixedT = (t) => t
        this.state = {
            finishLoad: false,
            error: null,
            updatedAt: null,
        }

        this.i18nInstance = i18n.on('languageChanged', (newLang) => {
            network.setLang(newLang)
            if (typeof zE !== 'undefined') zE('webWidget', 'setLocale', `${newLang}-ca`)
            const d = new Date()
            d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000)
            const expires = 'expires=' + d.toUTCString()
            document.cookie = `lang=${newLang};${expires};path=/`
            this.loadNewLabels()
        })

        // i18n.on('missingKey', (lngs, namespace, key, res) => {
        //     if (__DEV__) {
        //         key &&
        //             lngs &&
        //             lngs.map((language) => {
        //                 return network.api.labels.update(
        //                     {
        //                         key: key,
        //                         lang: language,
        //                     },
        //                     network.api.format({
        //                         value: res,
        //                         // ns: (this.props.ns)
        //                     })
        //                 )
        //             })
        //     }
        // })
    }

    static propTypes = {
        children: PropTypes.any,
        params: PropTypes.object,
        lang: PropTypes.string,
        userId: PropTypes.string,
        ns: PropTypes.string.isRequired,
        showToast: PropTypes.func,
        showError: PropTypes.bool,
        context: PropTypes.string,
    }

    clearCache = () => {
        init()
    }
    loadNewLabels = () => {
        return loadNameSpace(i18n.language || 'en', this.props.ns || 'default')
            .then(() => {
                this.fixedT = i18n.getFixedT(null, [this.props.ns, 'default'])
                this.setState({
                    finishLoad: true,
                    error: null,
                    updatedAt: new Date().getMilliseconds(),
                })
            })
            .catch((err) => {
                this.fixedT = i18n.getFixedT(null, ['default'])
                this.setState({
                    finishLoad: true,
                    error: err,
                    updatedAt: null,
                })
            })
    }

    wrapT = (text, props) => {
        // return 'this needs translation'
        return this.fixedT(text, {
            ...props,
            context: (props && props.context) || this.props.context || '',
        })
    }

    T = (props) => {
        return this.wrapT(props.children, props.params)
    }

    t = this.wrapT

    componentDidUpdate(prevProps) {
        if (prevProps.userId != this.props.userId) {
            this.clearCache()
        }
        if (prevProps.ns != this.props.ns) {
            this.loadNewLabels()
        }
    }

    componentDidMount() {
        this.loadNewLabels()
    }

    getChildContext() {
        return {
            T: this.T,
            t: this.t,
            lang: i18n.language,
        }
    }

    render() {
        if (!this.state.finishLoad) return null
        if (this.props.showError && this.state.error)
            return (
                <div className={style.superPanel}>
                    <div className={style.panel}>
                        <div className={style.middle}>
                            <div className={style.middlepanel}>
                                <div className={style.text}>
                                    <div className={style.title}>Something went wrong!</div>
                                    <div className={style.subtitle}>Sorry we&#39;re unable to open this page now. Try it again later.</div>
                                </div>
                                <div className={style.image}>
                                    <div className={style.error}>{this.state.error.status || 500}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        else return this.props.children
    }
}

export default withTranslation()(LabelsLoader)

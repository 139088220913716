import {
    CashDistributionRequest,
    CashTransferRequest,
    BulkCashDistributionRequest,
    AdvanceImportResponse, // Type import AdvanceImportResponse
} from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'
import { Buffer } from 'buffer'

/*
 * https://app.estateably.com/api-docs/#tag/Account-management
 */
export class AccountManagementApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * Import transactions
     * ${estateId}/accounts/advanced-import
     **/

    async advancedImport(estateId: string, body: FormData, lang?: string, preview?: boolean): Promise<AdvanceImportResponse> {
        try {
            const res: AxiosResponse<AdvanceImportResponse> = await this.patch<AdvanceImportResponse, FormData>(`/estates/${estateId}/accounts/advanced-import`, body, { params: { lang, preview } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Bulk Distribute Cash
     * ${estateId}/accounts/${accountId}/distribute/bulk
     **/

    async bulkDistributeCash(estateId: string, accountId: string, body: BulkCashDistributionRequest): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.post<void, BulkCashDistributionRequest>(`/estates/${estateId}/accounts/${accountId}/distribute/bulk`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Transfer Cash
     * ${estateId}/accounts/${accountId}/transfer
     **/

    async transferCash(estateId: string, accountId: string, body: CashTransferRequest): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.post<void, CashTransferRequest>(`/estates/${estateId}/accounts/${accountId}/transfer`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Distribute Cash
     * ${estateId}/accounts/${accountId}/distribute
     **/

    async distributeCash(estateId: string, accountId: string, body: CashDistributionRequest): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.post<void, CashDistributionRequest>(`/estates/${estateId}/accounts/${accountId}/distribute`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Export transactions
     * ${estateId}/accounts/${accountId}/export
     **/

    async exportTransactions(estateId: string, accountId: string): Promise<Buffer> {
        try {
            const res: AxiosResponse<Buffer> = await this.get<Buffer>(`/estates/${estateId}/accounts/${accountId}/export`, { responseType: 'arraybuffer', params: {} })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Import transactions
     * ${estateId}/accounts/${accountId}/import
     **/

    async importTransactions(estateId: string, accountId: string, body: FormData, lang?: string): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.patch<void, FormData>(`/estates/${estateId}/accounts/${accountId}/import`, body, { params: { lang } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

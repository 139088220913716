import React from 'react'
import style from './style.module.scss'
import Tag from '../../atoms/Tag'
import Icon from '../../particles/Icon'
import { GenericAttributes } from '../../types'

type SelectableChipItemProps = {
    title: string
    subtitle?: string
    tags?: string[]
    selected?: boolean
    onClick?: (ev: React.MouseEvent<HTMLElement>) => void
} & GenericAttributes

export const SelectableChipItem = ({ selected = false, title, subtitle, tags, onClick, ...props }: SelectableChipItemProps) => {
    return (
        <div {...props} data-test={props['data-test']} className={[style.outline, selected ? style.selected : ''].join(' ')}>
            <div className={[style.selectableChipItem, onClick ? style.clickable : null].join(' ')} onClick={onClick} data-test={`${props['data-test']}.chip`}>
                <div className={style.labels}>
                    <div className={style.title}>{title}</div>
                    <div className={style.subtitle}>{subtitle}</div>
                    <div className={style.tags}>
                        {tags &&
                            tags.slice(0, 6).map((eachTag) => {
                                return (
                                    <div key={eachTag} className={style.tagItem}>
                                        <Tag type={Tag.Types.ReadOnly} data-test={`${props['data-test']}.tag`}>
                                            {eachTag}
                                        </Tag>
                                    </div>
                                )
                            })}
                    </div>
                </div>
                <div className={style.iconContainer}>
                    <div className={style.icon}>{selected ? <Icon size={30} type={Icon.types.Check} /> : null}</div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(SelectableChipItem)

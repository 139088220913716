import React, { PureComponent } from 'react'
import style from './style.module.scss'
import Icon, { IconTypes } from '../../particles/Icon'
import { GenericAttributes } from '../../types'

type AvatarSquareProps = {
    src?: string
    icon?: IconTypes
} & GenericAttributes
type AvatarSquareState = {
    loaded: boolean
}
export class AvatarSquare extends PureComponent<AvatarSquareProps, AvatarSquareState> {
    constructor(props: AvatarSquareProps) {
        super(props)
        this.state = { loaded: false }
    }
    static iconTypes = IconTypes
    static defaultProps = {
        icon: AvatarSquare.iconTypes.UserDefault,
    }
    render(): React.ReactNode {
        const { src, icon, ...props } = this.props
        return (
            <div {...props} data-test={`${this.props['data-test']}`} className={[style.avatarSquare].join(' ')}>
                {this.state.loaded ? null : <Icon size={45} type={icon} />}
                <img src={src} style={this.state.loaded ? {} : { display: 'none' }} onLoad={() => this.setState({ loaded: true })} onError={() => this.setState({ loaded: false })} />
            </div>
        )
    }
}
export default AvatarSquare

import { NotificationRequest, NotificationRequestList, NotificationResponse, NotificationResponseList, Page } from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Notifications
 */
export class NotificationsApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * Delete a Notification
     * ${notificationId}
     **/

    async deleteNotification(notificationId: string): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.delete<void>(`/notifications/${notificationId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Update an individual notification
     * ${notificationId}
     **/

    async updateNotification(notificationId: string, body: NotificationRequest): Promise<NotificationResponse> {
        try {
            const res: AxiosResponse<NotificationResponse> = await this.patch<NotificationResponse, NotificationRequest>(`/notifications/${notificationId}`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Update notifications status
     *
     **/

    async updateNotifications(body: NotificationRequestList): Promise<NotificationResponse> {
        try {
            const res: AxiosResponse<NotificationResponse> = await this.patch<NotificationResponse, NotificationRequestList>(`/notifications/`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * List notifications
     *
     **/

    async listNotifications(page?: Page, filter?: object, search?: string, sort?: string): Promise<NotificationResponseList> {
        try {
            const res: AxiosResponse<NotificationResponseList> = await this.get<NotificationResponseList>(`/notifications/`, { params: { page, filter, search, sort } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

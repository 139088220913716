import { ContactResponse, ContactsRequest, ContactsResponse, Page } from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Estate-contacts
 */
export class EstateContactsApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * Update contacts
     * ${estateId}/contacts
     **/

    async updateContacts(estateId: string, body: ContactsRequest): Promise<ContactResponse> {
        try {
            const res: AxiosResponse<ContactResponse> = await this.patch<ContactResponse, ContactsRequest>(`/estates/${estateId}/contacts`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * List the estate contacts
     * ${estateId}/contacts
     **/

    async listEstateContacts(estateId: string, page?: Page, filter?: object, sort?: string): Promise<ContactsResponse> {
        try {
            const res: AxiosResponse<ContactsResponse> = await this.get<ContactsResponse>(`/estates/${estateId}/contacts`, { params: { page, filter, sort } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

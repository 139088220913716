import React, { ComponentPropsWithoutRef, PureComponent } from 'react'
import style from './style.module.scss'
import Icon, { IconTypes } from '../../particles/Icon'
import { GenericAttributes } from '../../types'

type IconSelectionProps = {
    icon?: IconTypes
    selected?: boolean
    onClick?: (ev: React.MouseEvent<HTMLElement>) => void
} & GenericAttributes &
    ComponentPropsWithoutRef<'button'>
export class IconSelection extends PureComponent<IconSelectionProps> {
    static iconTypes = IconTypes
    static defaultProps = {
        selected: false,
    }
    render(): React.ReactNode {
        const { icon, selected, onClick, ...props } = this.props
        return (
            <button type={'button'} {...props} data-test={`${this.props['data-test']}`} onClick={onClick} className={[style.iconSelection, selected ? style.selected : ''].join(' ')}>
                <Icon type={icon} size={30} />
            </button>
        )
    }
}
export default IconSelection

// eslint-disable-next-line css-modules/no-unused-class
import styleIcons from './style.module.scss'
import React, { PureComponent } from 'react'
import { GenericAttributes } from '../../types'

type IconProps = {
    size: number
    type: string | IconTypes
    className?: HTMLElement['className']
    onClick?: () => void
    customColor?: string
} & GenericAttributes

export enum IconTypes {
    'Account' = 'Account',
    'Add' = 'Add',
    'AddNote' = 'AddNote',
    'AddSubtask' = 'AddSubtask',
    'AddUser' = 'AddUser',
    'Alert' = 'Alert',
    'AlertCircle' = 'AlertCircle',
    'AlertTriangle' = 'AlertTriangle',
    'Archive01' = 'Archive01',
    'Archive' = 'Archive',
    'ArrowClose' = 'ArrowClose',
    'ArrowDown01' = 'ArrowDown01',
    'ArrowLeft' = 'ArrowLeft',
    'ArrowNarrowRight' = 'ArrowNarrowRight',
    'ArrowOpen' = 'ArrowOpen',
    'ArrowRight' = 'ArrowRight',
    'ArrowRight01' = 'ArrowRight01',
    'ArrowNarrowUp' = 'ArrowNarrowUp',
    'ArrowRightWithTail' = 'ArrowRightWithTail',
    'Asterisk' = 'Asterisk',
    'Attach' = 'Attach',
    'Balance' = 'Balance',
    'Bell01' = 'Bell01',
    'Bell' = 'Bell',
    'Blockchain' = 'Blockchain',
    'BookClosed' = 'BookClosed',
    'Calendar' = 'Calendar',
    'CalendarClock' = 'CalendarClock',
    'CaseDetails' = 'CaseDetails',
    'Chart' = 'Chart',
    'Check' = 'Check',
    'CheckSquareBroken' = 'CheckSquareBroken',
    'ChevronBack' = 'ChevronBack',
    'ChevronClose' = 'ChevronClose',
    'ChevronDown' = 'ChevronDown',
    'ChevronNext' = 'ChevronNext',
    'ChevronOpen' = 'ChevronOpen',
    'ChevronRight' = 'ChevronRight',
    'Circle' = 'Circle',
    'Check01' = 'Check01',
    'CheckCircle' = 'CheckCircle',
    'Clock' = 'Clock',
    'Close' = 'Close',
    'CloseCircle' = 'CloseCircle',
    'ContactMail' = 'ContactMail',
    'Copy' = 'Copy',
    'CreditCard' = 'CreditCard',
    'Crop' = 'Crop',
    'Distributions' = 'Distributions',
    'Division' = 'Division',
    'Doc' = 'Doc',
    'Documents' = 'Documents',
    'Duplicate' = 'Duplicate',
    'Down' = 'Down',
    'Download' = 'Download',
    'DownloadAll' = 'DownloadAll',
    'Drag' = 'Drag',
    'Dynamic' = 'Dynamic',
    'Edit' = 'Edit',
    'Ellipses' = 'Ellipses',
    'English' = 'English',
    'Envelope' = 'Envelope',
    'EstateCase' = 'EstateCase',
    'Excel' = 'Excel',
    'Expand' = 'Expand',
    'EyeHided' = 'EyeHided',
    'EyeVisible' = 'EyeVisible',
    'file' = 'file',
    'File' = 'File',
    'File01' = 'File01',
    'FilePlus' = 'FilePlus',
    'File06' = 'File06',
    'Filter' = 'Filter',
    'FilterLines' = 'FilterLines',
    'FolderShield' = 'FolderShield',
    'Foreign' = 'Foreign',
    'Folder' = 'Folder',
    'French' = 'French',
    'HelpCircle' = 'HelpCircle',
    'HelpIcon' = 'HelpIcon',
    'Home' = 'Home',
    'Image' = 'Image',
    'Info' = 'Info',
    'Inventory' = 'Inventory',
    'ItemDocuments' = 'ItemDocuments',
    'ItemInevntory' = 'ItemInevntory',
    'ItemList' = 'ItemList',
    'Language' = 'Language',
    'LayersThree01' = 'LayersThree01',
    'LayersTwo01' = 'LayersTwo01',
    'LetterForm' = 'LetterForm',
    'LineChartUp01' = 'LineChartUp01',
    'Link' = 'Link01',
    'List' = 'List',
    'Lock' = 'Lock01',
    'LogOut01' = 'LogOut01',
    'Logout' = 'Logout',
    'MilestonesOff' = 'MilestonesOff',
    'MilestonesOn' = 'MilestonesOn',
    'Minor' = 'Minor',
    'More' = 'More',
    'NewCode' = 'NewCode',
    'NewDocument' = 'NewDocument',
    'NotApplicable' = 'NotApplicable',
    'Note' = 'Note',
    'Notices' = 'Notices',
    'NoticeToCreditors' = 'NoticeToCreditors',
    'Partial' = 'Partial',
    'Pdf' = 'Pdf',
    'Plus' = 'Plus',
    'Power01' = 'Power01',
    'Printer' = 'Printer',
    'PuzzlePiece01' = 'PuzzlePiece01',
    'Rename' = 'Rename',
    'Reports' = 'Reports',
    'Save' = 'Save',
    'Search' = 'Search',
    'SearchLg' = 'SearchLg',
    'Settings01' = 'Settings01',
    'Settings' = 'Settings',
    'Share' = 'Share',
    'Signature' = 'Signature',
    'ShieldDollar' = 'ShieldDollar',
    'Subtask' = 'Subtask',
    'Suggestion' = 'Suggestion',
    'System' = 'System',
    'Thumbtack' = 'Thumbtack',
    'Trash' = 'Trash',
    'Trash01' = 'Trash01',
    'Tutorial' = 'Tutorial',
    'Union' = 'Union',
    'Unlink' = 'Unlink',
    'Up' = 'Up',
    'Update' = 'Update',
    'UserAdmin' = 'UserAdmin',
    'UserCompany' = 'UserCompany',
    'UserDefault' = 'UserDefault',
    'UserOthers' = 'UserOthers',
    'UserTeam' = 'UserTeam',
    'User01' = 'User01',
    'ViewCards' = 'ViewCards',
    'ViewCollapse' = 'ViewCollapse',
    'ViewDefault' = 'ViewDefault',
    'ViewList' = 'ViewList',
    'XClose' = 'XClose',
    'Zap' = 'Zap',
    'ZendeskHelp' = 'ZendeskHelp',
    'ZoomIn' = 'ZoomIn',
    'ZoomOut' = 'ZoomOut',
    'UploadCloud02' = 'UploadCloud02',
    'PinFilled' = 'PinFilled',
    'PinDefault' = 'PinDefault',
    'ArrowUp' = 'ArrowUp',
    'ArrowDown' = 'ArrowDown',
    'LinkExternal01' = 'LinkExternal01',
}

const newIcons = [IconTypes.Thumbtack] //IconTypes.MilestonesOff, IconTypes.MilestonesOn, IconTypes.Lock, IconTypes.Link

export class Icon extends PureComponent<IconProps> {
    static types = IconTypes
    static defaultProps = {
        type: Icon.types.Check,
        size: 35,
    }

    //FIXME: improve the generation of new icons, right now is manual process
    render(): React.ReactNode {
        const { type, size, customColor, className, onClick } = this.props
        let iconsClass = type
        if (!iconsClass) return null
        const classNameList = className ? [className] : []
        if (newIcons.includes(type as any)) {
            // if this is a "new" icon
            iconsClass = styleIcons[`icon-${iconsClass}`]
            classNameList.push(styleIcons.newIcons)
        } else if (!iconsClass.startsWith('icon-')) {
            // if this is a "old" icon
            iconsClass = styleIcons[`icon-${iconsClass}`]
            classNameList.push(styleIcons.icon)
        } else {
            // if this is a "old" icon
            iconsClass = styleIcons[type]
            classNameList.push(styleIcons.icon)
        }
        classNameList.push(iconsClass)

        return (
            <i
                onClick={onClick}
                data-iconType={type}
                data-test={this.props['data-test']}
                className={classNameList.join(' ')}
                style={{ fontSize: `${size}px`, color: customColor }}
                aria-label={this.props['aria-label']}
            />
        )
    }
}

export default Icon

import React from 'react'
import style from './style.module.scss'
import { GenericAttributes } from '../../types'

type HeaderLabelProps = {
    labels: string[]
    round?: boolean
} & GenericAttributes

export const HeaderLabel = ({ round = false, labels, ...props }: HeaderLabelProps) => {
    return (
        <tr {...props} data-test={`${props['data-test']}`} className={[style.headerLabel, round ? style.round : ''].join(' ')} role={'row'}>
            {labels.map((label, idx) => (
                <th data-test={`${props['data-test']}.label.${idx}`} className={idx === 0 ? style.column1 : style.otherColumns} key={idx} role={'columnheader'} scope={'col'}>
                    {label}
                </th>
            ))}
        </tr>
    )
}
export default React.memo(HeaderLabel)

/**
 * Estateably
 * @Author : Leo
 * @Date   : 2018-08-27, 7:57:17 AM
 */
export const ACTION_UPDATE_ORGANIZATION = 'ACTION_UPDATE_ORGANIZATION'
export const ACTION_FETCH_ORG = 'ACTION_FETCH_ORG'
export const ACTION_SAVE_PREFERENCE = 'ACTION_SAVE_PREFERENCE'

import network from '../../utils/network'

const { format, orgs } = network.api

export const updateOrganization = (id, values) => {
    return {
        type: ACTION_UPDATE_ORGANIZATION,
        payload: orgs.update({ id: id }, format(values)),
    }
}

export const updateUserPreference = (orgId, userId) => {
    return {
        type: ACTION_SAVE_PREFERENCE,
        payload: orgs.updateMember({ id: orgId, userId }),
    }
}

export const fetchOrgInfo = (orgId) => {
    return {
        type: ACTION_FETCH_ORG,
        payload: orgs.get({ id: orgId }),
    }
}

import React, { PropsWithChildren } from 'react'
import style from './style.module.scss'
import { GenericAttributes } from '../../types'

type PageNumberProps = {
    selected?: boolean
    onClick?: (ev: React.MouseEvent<HTMLElement>) => void
} & GenericAttributes

export const PageNumber = ({ selected = false, onClick, children, ...props }: PropsWithChildren<PageNumberProps>) => {
    return (
        <div {...props} data-selected={selected} onClick={onClick} className={[style.page].join(' ')} data-test={props['data-test']}>
            {selected ? (
                <>
                    <span className={style.selected}>{children}</span>
                    <div className={style.bar} />
                </>
            ) : (
                <span>{children}</span>
            )}
        </div>
    )
}
export default React.memo(PageNumber)

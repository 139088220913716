import style from './style.module.scss'
import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Tag, TagTypes } from '../../atoms/Tag'
import Link from '../../atoms/Link'
import { Tooltip } from '../../../../DesignSystem/Tooltip'

type SeeMoreTagsProps = { tags: string[]; itemsLimit?: number; tagLimit?: number; tagType?: TagTypes; 'data-test': string }
export const SeeMoreTags = ({ tags, itemsLimit = 2, tagLimit = 12, tagType = TagTypes.Default, ...rest }: SeeMoreTagsProps) => {
    const [isSeeMore, setIsSeeMore] = useState(false)
    const [showSeeMoreButton, setShowSeeMoreButton] = useState(false)
    const { t } = useTranslation()
    const adjustedTags = useMemo(() => {
        if (tags.length > itemsLimit) {
            setShowSeeMoreButton(true)
        }
        return tags.slice(0, itemsLimit).map((tag) => {
            if (tag.length > tagLimit) {
                setShowSeeMoreButton(true)
                return { tag: tag, adjustedTag: tag.slice(0, tagLimit) + '...' }
            }
            return { tag }
        })
    }, [tags])

    return (
        <ul data-test={`${rest['data-test']}.wrapper`} className={style.container}>
            {isSeeMore
                ? tags.map((tag, index) => {
                      if (index === tags.length - 1 && showSeeMoreButton) {
                          return (
                              <span className={style.linkWrapper}>
                                  <li data-test={`${rest['data-test']}.item.${index}`} key={`${tag}.${index}`} className={style.item}>
                                      <Tag data-test={`${rest['data-test']}.item.${index}.tag`} type={tagType}>
                                          <p data-test={`${rest['data-test']}.item.${index}.tag.label`}>{tag}</p>
                                      </Tag>
                                  </li>
                                  <span className={style.link} onClick={(e) => e.stopPropagation()}>
                                      <Link data-test={`${rest['data-test']}.seeLess`} onClick={() => setIsSeeMore(false)}>
                                          {t('See less')}
                                      </Link>
                                  </span>
                              </span>
                          )
                      }
                      return (
                          <li data-test={`${rest['data-test']}.item.${index}`} key={`${tag}.${index}`} className={style.item}>
                              <Tag data-test={`${rest['data-test']}.item.${index}.tag`} type={tagType}>
                                  <p data-test={`${rest['data-test']}.item.${index}.tag.label`}>{tag}</p>
                              </Tag>
                          </li>
                      )
                  })
                : adjustedTags.map((tag, index) => {
                      const adjustedTagName = tag.adjustedTag || tag.tag
                      if (index === adjustedTags.length - 1 && showSeeMoreButton) {
                          return (
                              <span key={`${tag}.${index}`} className={style.linkWrapper}>
                                  <li data-test={`${rest['data-test']}.item.${index}`} key={`${tag}.${index}`} className={style.item}>
                                      <Tooltip data-test={`${rest['data-test']}.item.${index}.tooltip`} content={tag.tag} disabled={!tag.adjustedTag}>
                                          <span>
                                              <Tag data-test={`${rest['data-test']}.item.${index}.tag`} type={tagType}>
                                                  <p data-test={`${rest['data-test']}.item.${index}.tag.label`}>{adjustedTagName}</p>
                                              </Tag>
                                          </span>
                                      </Tooltip>
                                  </li>
                                  <span className={style.link} onClick={(e) => e.stopPropagation()}>
                                      <Link data-test={`${rest['data-test']}.seeMore`} onClick={() => setIsSeeMore(true)}>
                                          {t('See more')}
                                      </Link>
                                  </span>
                              </span>
                          )
                      }
                      return (
                          <li data-test={`${rest['data-test']}.item.${index}`} key={`${tag}.${index}`} className={style.item}>
                              <Tooltip data-test={`${rest['data-test']}.item.${index}.tooltip`} content={tag.tag} disabled={!tag.adjustedTag}>
                                  <span>
                                      <Tag data-test={`${rest['data-test']}.item.${index}.tag`} type={tagType}>
                                          <p data-test={`${rest['data-test']}.item.${index}.tag.label`}>{adjustedTagName}</p>
                                      </Tag>
                                  </span>
                              </Tooltip>
                          </li>
                      )
                  })}
        </ul>
    )
}

export default React.memo(SeeMoreTags)

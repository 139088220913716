/**
 * Estateably
 * @Author :
 * @Date   : 2018-08-27, 7:57:17 AM
 */
export const ACTION_FETCH_PROFILE = 'ACTION_FETCH_PROFILE_1'
export const ACTION_FETCH_PROFILE_PICTURE = 'ACTION_FETCH_PROFILE_PICTURE'
export const ACTION_FETCH_ORGANIZATION = 'ACTION_FETCH_ORGANIZATION'
export const ACTION_SAVE_PROFILE = 'ACTION_SAVE_PROFILE_1'
export const EDIT_PROFILE = 'EDIT_PROFILE'
export const CLOSE_PROFILE = 'CLOSE_PROFILE'
export const OPEN_PROFILE = 'OPEN_PROFILE'
export const ACTION_FETCH_TEAMS = 'ACTION_FETCH_TEAMS'
export const ACTION_GET_AUTH_CONFIG = 'ACTION_GET_AUTH_CONFIG'

import history from '../App/history'

import network from '../../utils/network'
import { Buffer } from 'buffer'

const { orgs, format, getQuery, auth } = network.api

export const fetchProfile = (orgId, userId) => {
    return {
        type: ACTION_FETCH_PROFILE,
        payload: orgs.getMember({ id: orgId, userId }),
    }
}

export const fetchProfilePic = (orgId, userId) => {
    return {
        type: ACTION_FETCH_PROFILE_PICTURE,
        payload: orgs.getMemberPic({ orgId, userId }).then((response) => {
            if (response.data && response.data.byteLength > 0) {
                let tmp = new Buffer.from(response.data, 'binary')
                const blob = new Blob([tmp], { type: 'image/*' })
                let blobURL = window.URL.createObjectURL(blob)
                return blobURL
            }
            return null
        }),
    }
}

export const updateProfile = (orgId, userId, info) => {
    return {
        type: ACTION_SAVE_PROFILE,
        payload: orgs.updateMember({ id: orgId, userId }, format(info)).then((data) => {
            return orgs.getMemberPic({ orgId, userId }).then((response) => {
                if (response.data && response.data.byteLength > 0) {
                    let tmp = new Buffer.from(response.data, 'binary')
                    const blob = new Blob([tmp], { type: 'image/*' })
                    let blobURL = window.URL.createObjectURL(blob)
                    data.data.attributes.profilePic.image = blobURL
                    return data
                }
                data.data.attributes.profilePic = null
                return data
            })
        }),
    }
}

export const fetchTeams = (orgId) => {
    const filter = {
        onlyUserTeams: true,
    }
    return {
        type: ACTION_FETCH_TEAMS,
        payload: orgs.listTeam({
            id: orgId,
            ...getQuery(null, filter),
        }),
    }
}

export const openProfile = () => {
    history.push('/profile')
    return {
        type: OPEN_PROFILE,
        payload: {},
    }
}
export const onDashboard = () => {
    history.push('/estate')
    return {
        type: OPEN_PROFILE,
        payload: {},
    }
}

export const closeProfile = () => {
    return {
        type: CLOSE_PROFILE,
        payload: {},
    }
}

export const fetchOrganization = (orgId) => {
    return {
        type: ACTION_FETCH_ORGANIZATION,
        payload: orgs.get({ id: orgId }),
    }
}

export const actionGetAuthConfig = () => {
    return {
        type: ACTION_GET_AUTH_CONFIG,
        payload: auth.get(),
    }
}

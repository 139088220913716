import React, { PureComponent } from 'react'
import style from './style.module.scss'
import Icon, { IconTypes } from '../../particles/Icon'
import { GenericAttributes } from '../../types'

type SectionIconProps = {
    icon?: IconTypes
    selected?: boolean
    onClick?: (ev: React.MouseEvent<HTMLElement>) => void
} & GenericAttributes
export class SectionIcon extends PureComponent<SectionIconProps> {
    static iconTypes = IconTypes
    static defaultProps = {
        selected: false,
    }
    render(): React.ReactNode {
        const { children, icon, selected, onClick, ...props } = this.props
        return (
            <div {...props} onClick={onClick} className={[style.sectionIcon, selected ? style.selected : ''].join(' ')} data-test={this.props['data-test']}>
                <div className={style.iconWrapper}>
                    <Icon type={icon} size={30} />
                </div>
                <p>{children}</p>
            </div>
        )
    }
}
export default SectionIcon

import React from 'react'
import * as Switch from '@radix-ui/react-switch'
import styles from './styles.module.scss'

type SwitchProps = {
    leftLabel?: string
    rightLabel?: string
    leftBadge?: React.ReactElement
    rightBadge?: React.ReactElement
    disabled?: boolean
    'data-test': string
    active: boolean
    onCheck: (active: boolean) => void
}

const ToggleSwitch = (props: SwitchProps) => {
    const dataTest = props['data-test']

    return (
        <div className={styles.container} data-test={dataTest}>
            {props.leftLabel ? (
                <label className={styles.leftLabel} htmlFor='switch' data-test={dataTest ? `${dataTest}.left.label` : null}>
                    {props.leftLabel}
                </label>
            ) : null}

            {props.leftBadge && (
                <div data-test={dataTest ? `${dataTest}.left.badge` : null} className={styles.leftBadge}>
                    {props.leftBadge}
                </div>
            )}

            <Switch.Root
                className={[styles.switchRoot, props.disabled && styles.disabled].join(' ')}
                id='switch'
                data-test={dataTest ? `${dataTest}.switch` : null}
                onCheckedChange={(checked) => {
                    props.onCheck(checked)
                }}
                disabled={props.disabled}
                checked={props.active}
                aria-label={`${props.leftLabel} ${props.rightLabel}`}>
                <Switch.Thumb className={styles.switchThumb} />
            </Switch.Root>

            {props.rightLabel ? (
                <label className={styles.rightLabel} htmlFor='switch' data-test={dataTest ? `${dataTest}.right.label` : null}>
                    {props.rightLabel}
                </label>
            ) : null}

            {props.rightBadge && (
                <div className={styles.rightBadge} data-test={dataTest ? `${dataTest}.right.badge` : null}>
                    {props.rightBadge}
                </div>
            )}
        </div>
    )
}

export default React.memo(ToggleSwitch)

import { OrganizationRequest, OrganizationResponse, OrganizationResponseList, Page, UpdateOrganizationRequest, SubscribeOrganizationRequest } from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Organizations
 */
export class OrganizationsApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }

    //Methods

    /**
     *
     * ${orgId}/purchase/${addonId}
     **/

    async purchaseOrganizationBillingPlanAddonRequest(orgId: string, addonId: string, body: unknown): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.post<void, unknown>(`/orgs/${orgId}/purchase/${addonId}`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     *
     * ${orgId}/subscribe
     **/

    async subscribeOrganization(orgId: string, body: SubscribeOrganizationRequest): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.post<void, SubscribeOrganizationRequest>(`/orgs/${orgId}/subscribe`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Export all organization data
     * ${orgId}/download
     **/

    async exportOrgData(orgId: string): Promise<Buffer> {
        try {
            const res: AxiosResponse<Buffer> = await this.get<Buffer>(`/orgs/${orgId}/download`, {
                responseType: 'arraybuffer',
                params: {},
            })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Delete an Organization
     * ${orgId}
     **/

    async deleteOrganization(orgId: string): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.delete<void>(`/orgs/${orgId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     *
     * ${orgId}
     **/

    async updateOrganization(orgId: string, body: UpdateOrganizationRequest): Promise<OrganizationResponse> {
        try {
            const res: AxiosResponse<OrganizationResponse> = await this.patch<OrganizationResponse, UpdateOrganizationRequest>(`/orgs/${orgId}`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Get an organization
     * ${orgId}
     **/

    async getOrganization(orgId: string): Promise<OrganizationResponse> {
        try {
            const res: AxiosResponse<OrganizationResponse> = await this.get<OrganizationResponse>(`/orgs/${orgId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Create an organization
     *
     **/

    async createOrganization(body: OrganizationRequest): Promise<OrganizationResponse> {
        try {
            const res: AxiosResponse<OrganizationResponse> = await this.post<OrganizationResponse, OrganizationRequest>(`/orgs/`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * List organizations
     *
     **/

    async listOrganization(page?: Page, filter?: object, search?: string, sort?: string): Promise<OrganizationResponseList> {
        try {
            const res: AxiosResponse<OrganizationResponseList> = await this.get<OrganizationResponseList>(`/orgs/`, {
                params: {
                    page,
                    filter,
                    search,
                    sort,
                },
            })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

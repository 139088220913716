import React, { PureComponent } from 'react'
// eslint-disable-next-line css-modules/no-unused-class
import style from './style.module.scss'
import { GenericAttributes } from '../../types'
import { Icon, IconTypes } from '../../particles/Icon'
import { Tooltip } from '../../../../DesignSystem/Tooltip'

enum LabelPosition {
    top = 'top',
    left = 'left',
    bottom = 'bottom',
    right = 'right',
    none = 'none',
}
export type RadioTooltipProps = {
    content: React.ReactNode | string
    icon: IconTypes
}

type RadioProps = {
    value?: RadioSelectionValues
    onClick?: (ev: React.MouseEvent<HTMLElement>) => void
    disabled?: boolean
    label?: React.ReactNode | string
    labelPosition?: LabelPosition
    tooltip?: RadioTooltipProps
} & GenericAttributes
type RadioState = {
    selected: boolean
}
export enum RadioSelectionValues {
    SELECTED = 'SELECTED',
    NOT_SELECTED = 'NOT_SELECTED',
}
export class Radio extends PureComponent<RadioProps, RadioState> {
    static selectionValues = RadioSelectionValues
    static labelPositions = LabelPosition
    static defaultProps = {
        value: Radio.selectionValues.NOT_SELECTED,
    }
    constructor(props: RadioProps) {
        super(props)
        this.state = {
            selected: false,
        }
    }
    render(): React.ReactNode {
        const { value, onClick, disabled, label, labelPosition = LabelPosition.right, tooltip, ...props } = this.props
        const selectedStyle = value === Radio.selectionValues.SELECTED ? style.selected : null
        return (
            <div
                {...props}
                onClick={!disabled ? onClick : undefined}
                className={[style.container, labelPosition === LabelPosition.top || labelPosition === LabelPosition.bottom ? style.column : ''].join(' ')}
                data-test={this.props['data-test']}>
                {label && (labelPosition === LabelPosition.top || labelPosition === LabelPosition.left) ? (
                    <label className={[style[labelPosition], disabled ? style.disabled : ''].join(' ')}>{label}</label>
                ) : null}
                <div className={[style.radio].join(' ')}>
                    <svg className={[selectedStyle].join(' ')} width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <circle className={style.backgroundHover} cx='12' cy='12' r='12' fill='#9B9B9B' />
                        <ellipse className={style.blueFill} cx='12.0004' cy='12' rx='5.45455' ry='5.45455' fill='#0072DB' />
                        <path
                            d='M20.227 12C20.227 16.5438 16.5435 20.2272 11.9997 20.2272C7.45594 20.2272 3.77246 16.5438 3.77246 12C3.77246 7.45618 7.45594 3.77271 11.9997 3.77271C16.5435 3.77271 20.227 7.45618 20.227 12Z'
                            stroke='#9B9B9B'
                        />
                    </svg>
                </div>
                {label && (labelPosition === LabelPosition.right || labelPosition === LabelPosition.bottom) ? (
                    <>
                        <label className={[style[labelPosition], disabled ? style.disabled : ''].join(' ')}>{label}</label>
                        {tooltip ? (
                            <Tooltip content={tooltip.content} side={'right'}>
                                <span>
                                    <Icon size={16} type={tooltip.icon} className={style.icon} data-test={'radio.icon'} />
                                </span>
                            </Tooltip>
                        ) : null}
                    </>
                ) : null}
            </div>
        )
    }
}
export default Radio

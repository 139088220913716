import network from 'utils/network'
import { translations } from '../translations'

let unsaved = []

class Backend {
    constructor(services, backendOptions = {}, i18nextOptions = {}) {
        this.init(services, backendOptions, i18nextOptions)
        this.cache = {}
    }

    // eslint-disable-next-line no-unused-vars
    //init (services, backendOptions, i18nextOptions)
    init() {
        // Validate backendOptions
        this.cache = {}
        window.cleanLabels = () => {
            unsaved = []
        }
        window.getLabels = () => {
            let json = {}
            for (const unsavedElement of unsaved) {
                json[unsavedElement.namespace] = json[unsavedElement.namespace] || {}
                json[unsavedElement.namespace][unsavedElement.key] = unsavedElement.fallbackValue || unsavedElement.key
            }
            return JSON.stringify(json, null, 2)
        }
    }

    read(language, namespace, callback) {
        const frontTranslation = translations[language] || {}
        if (this.cache[language] && this.cache[language][namespace]) {
            return callback(null, this.cache[language][namespace])
        }
        return network.api.labels
            .list({ lang: language, ns: namespace || 'default' })
            .then((res) => {
                if (!this.cache[language]) {
                    this.cache[language] = {}
                }
                const all = { ...res.data.attributes, ...frontTranslation }
                this.cache[language][namespace] = all
                return callback(null, all)
            })
            .catch((err) => {
                return callback(err)
            })
    }

    // only used in backends acting as cache layer
    // eslint-disable-next-line no-unused-vars
    //save(language, namespace, data)
    save() {
        // store the translations
    }

    // eslint-disable-next-line no-unused-vars
    create(languages, namespace, key, fallbackValue) {
        /* save the missing translation */
        if (!unsaved.find((_) => _.key == key)) {
            unsaved && unsaved.push({ languages, namespace, key, fallbackValue })
        }
    }
}

Backend.type = 'backend'

export default Backend

import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import loginReducer from '../Login/reducer'
import registerReducer from '../Login/Register/reducer'
import forgotPassword from '../Login/ForgotPassword/reducer'
import resetPassword from '../Login/ResetPassword/reducer'
import trustReducer from '../TrustDashboard/reducer'
import profileReducer from '../Profile/reducer'
import adminSectionReducer from '../AdminSection/reducer'
import workflow from '../Workflow/reducer'

const menuReducer = (
    state = {
        currentUrl: '/',
        error: '',
    },
    action
) => {
    switch (action.type) {
        case 'NETWORK_ERROR':
            return {
                ...state,
                logged: false,
                errorMsg: '',
                userInfo: {},
                error: action.payload,
            }
        case 'ERROR_CLICK':
            return { ...state, error: '' }
        default:
            return state
    }
}

const i18nReducer = (
    state = {
        lang: null,
    },
    action
) => {
    switch (action.type) {
        case 'SET_LANG':
            return { ...state, lang: action.payload }

        default:
            return state
    }
}

const rootReducer = combineReducers({
    menu: menuReducer,
    context: loginReducer,
    register: registerReducer,
    forgotPassword,
    resetPassword,
    form: formReducer,
    i18n: i18nReducer,
    trust: trustReducer,
    profile: profileReducer,
    adminSection: adminSectionReducer,
    workflow,
})
export default (state, action) => (action.type === 'ACTION_LOGOFF' ? rootReducer(undefined, action) : rootReducer(state, action))

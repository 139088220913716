import { InvoicesResponse, PaymentMethodResponse, SetupPaymentRequest, UpdatePaymentDetailsRequest, BillingCycleDatesResponse } from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Billing-Plan
 */
export class BillingPlanApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * Get dates from a billing plan next payment information
     * ${orgId}/billingPlans/payment/cycle
     **/

    async getBillingPlanPaymentCycle(orgId: string, plan?: string, frequency?: string): Promise<BillingCycleDatesResponse> {
        try {
            const res: AxiosResponse<BillingCycleDatesResponse> = await this.get<BillingCycleDatesResponse>(`/orgs/${orgId}/billingPlans/payment/cycle`, { params: { plan, frequency } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Update a payment details, like address, expiry month/year and cardholder name
     * ${orgId}/billingPlans/payment
     **/

    async updatePaymentDetails(orgId: string, body: UpdatePaymentDetailsRequest): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.patch<void, UpdatePaymentDetailsRequest>(`/orgs/${orgId}/billingPlans/payment`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Get billing plan invoices
     * ${orgId}/billingPlans/invoices
     **/

    async getBillingPlanInvoices(orgId: string): Promise<InvoicesResponse> {
        try {
            const res: AxiosResponse<InvoicesResponse> = await this.get<InvoicesResponse>(`/orgs/${orgId}/billingPlans/invoices`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Remove a payment method from a billing plan
     * ${orgId}/billingPlans/payment
     **/

    async removePaymentMethod(orgId: string): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.delete<void>(`/orgs/${orgId}/billingPlans/payment`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Add Billing plan payment info
     * ${orgId}/billingPlans/payment
     **/

    async addBillingPlanPaymentInfo(orgId: string, body: SetupPaymentRequest): Promise<PaymentMethodResponse> {
        try {
            const res: AxiosResponse<PaymentMethodResponse> = await this.post<PaymentMethodResponse, SetupPaymentRequest>(`/orgs/${orgId}/billingPlans/payment`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Get billing plan payment information
     * ${orgId}/billingPlans/payment
     **/

    async getBillingPlanPaymentInfo(orgId: string): Promise<PaymentMethodResponse> {
        try {
            const res: AxiosResponse<PaymentMethodResponse> = await this.get<PaymentMethodResponse>(`/orgs/${orgId}/billingPlans/payment`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

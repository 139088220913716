import React, { PropsWithChildren } from 'react'
import style from './style.module.scss'
import { GenericAttributes } from '../../types'

type SectionSidebarProps = {
    selected?: boolean
    onClick?: (ev: React.MouseEvent<HTMLElement>) => void
} & GenericAttributes

export const SectionSidebar = ({ selected = false, onClick, children, ...props }: PropsWithChildren<SectionSidebarProps>) => {
    return (
        <button {...props} className={[style.sectionSidebar, selected ? style.selected : ''].join(' ')} onClick={onClick} data-test={props['data-test']}>
            {selected ? <div className={style.selectedMark} /> : null}
            <div className={[style.text, !selected ? style.textNotSelected : ''].join(' ')}>{children}</div>
        </button>
    )
}
export default React.memo(SectionSidebar)

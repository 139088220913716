import { LabelResponseList } from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Labels
 */
export class LabelsApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * Get all labels
     *
     **/

    async listLabels(ns?: string, lang?: string): Promise<LabelResponseList> {
        try {
            const res: AxiosResponse<LabelResponseList> = await this.get<LabelResponseList>(`/labels/`, { params: { ns, lang } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

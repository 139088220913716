import React, { PureComponent } from 'react'
// eslint-disable-next-line css-modules/no-unused-class
import style from './style.module.scss'
import Icon from '../../particles/Icon'
import SectionText from '../../atoms/SectionText'
import { GenericAttributes } from '../../types'

export interface SplitButtondOptions {
    disabled?: boolean
    label: string
}

type SplitButtonProps = {
    color?: SplitButtonColors
    options: SplitButtondOptions[]
    title: string
    selectedIndex: number
    disabled: boolean
    onClick?: (ev: React.MouseEvent<HTMLElement>) => void
    selectOption: (index: number) => void
} & GenericAttributes

type SplitButtonState = {
    isOpened: boolean
}

export enum SplitButtonColors {
    danger = 'danger',
    primary = 'primary',
    success = 'success',
}

export class SplitButton extends PureComponent<SplitButtonProps, SplitButtonState> {
    static colors = SplitButtonColors
    private myRef: React.RefObject<HTMLDivElement> = React.createRef()

    constructor(props: SplitButtonProps) {
        super(props)
        this.state = {
            isOpened: false,
        }
    }
    static defaultProps = {
        options: [],
    }

    handleOpenButton = (): void => {
        const { isOpened } = this.state
        this.setState({ isOpened: !isOpened })
        if (!isOpened) {
            document.addEventListener('mousedown', this.handleClickOutside)
        } else {
            document.removeEventListener('mousedown', this.handleClickOutside)
        }
    }

    handleSelectLabel = (index: number): void => {
        const { selectOption } = this.props
        this.setState({ isOpened: false })
        selectOption(index)
    }

    handleClickOutside = (event: MouseEvent): void => {
        if (this.myRef && this.myRef.current && !this.myRef.current.contains(event.target as Node)) {
            this.setState({ isOpened: false })
            document.removeEventListener('mousedown', this.handleClickOutside)
        }
    }

    render(): React.ReactNode {
        const { color = SplitButton.colors.success, options, title, selectedIndex, disabled, onClick, ...props } = this.props
        const { isOpened } = this.state
        return (
            <div {...props} data-test={this.props['data-test']} className={style.splitButton}>
                <div className={[style.button, style[color]].join(' ')}>
                    <button
                        disabled={disabled}
                        className={[style.label, disabled ? style.disabled : null, style[color]].join(' ')}
                        onClick={
                            !disabled
                                ? (ev) => {
                                      onClick && onClick(ev)
                                      this.handleOpenButton()
                                  }
                                : undefined
                        }
                        data-test={`${this.props['data-test']}.button`}>
                        {title}
                    </button>
                    <div className={style.buttonOptions}>
                        <div className={[style.separator, style[color]].join(' ')} />
                        <button
                            disabled={disabled}
                            className={[style.iconContainer, style[color], isOpened ? style.opened : null, disabled ? style.disabled : null].join(' ')}
                            onClick={!disabled ? this.handleOpenButton : undefined}
                            data-test={`${this.props['data-test']}.dropdown`}>
                            <Icon size={30} type={isOpened ? Icon.types.ArrowClose : Icon.types.ArrowOpen} />
                        </button>
                    </div>
                </div>
                {isOpened ? (
                    <div className={style.labelsContainer} ref={this.myRef}>
                        {options.map((option, index) => (
                            <SectionText
                                key={index}
                                selected={index === selectedIndex}
                                disabled={option.disabled}
                                onClick={() => this.handleSelectLabel(index)}
                                data-test={`${this.props['data-test']}.dropdown.options.${index}`}>
                                {option.label}
                            </SectionText>
                        ))}
                    </div>
                ) : null}
            </div>
        )
    }
}
export default SplitButton

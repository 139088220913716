import { StatsRequest, StatsResponse } from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Stats
 */
export class StatsApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * get list of relevant stats for the charts in the &#34;Reports&#34; section
     *
     **/

    async listStats(body: StatsRequest): Promise<StatsResponse> {
        try {
            const res: AxiosResponse<StatsResponse> = await this.post<StatsResponse, StatsRequest>(`/stats/`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

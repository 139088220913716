const DEFAULT_STATE = {
    errMsg: '',
    organizationInfo: null,
    organizationMeta: null,
}
import { ACTION_FETCH_ORG, ACTION_UPDATE_ORGANIZATION } from './actions'

export default (state = DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case `ACTION_LOGOFF`:
            return DEFAULT_STATE
        case `${ACTION_UPDATE_ORGANIZATION}_PENDING`:
            return { ...state }
        case `${ACTION_UPDATE_ORGANIZATION}_FULFILLED`:
            return {
                ...state,
                userInfo: payload,
                organizationInfo: payload.data.attributes,
            }
        case `${ACTION_UPDATE_ORGANIZATION}_REJECTED`:
            return { ...state }
        case `${ACTION_FETCH_ORG}_PENDING`:
            return { ...state, organizationInfo: null }
        case `${ACTION_FETCH_ORG}_FULFILLED`:
            return {
                ...state,
                organizationInfo: payload.data.attributes,
                organizationMeta: payload.data.meta,
            }
        default:
            return state
    }
}

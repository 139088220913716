import React from 'react'
import { GenericAttributes } from '../../types'
import Button, { ButtonSizes } from '../Button'

type ButtonNegativeProps = Omit<React.ComponentProps<'button'>, 'size'> & {
    size?: ButtonSizes
} & GenericAttributes

export const ButtonNegative = ({ size = 'large', children, ...props }: ButtonNegativeProps) => {
    return (
        <Button {...props} size={size} mode={'negative'}>
            {children}
        </Button>
    )
}
export default ButtonNegative

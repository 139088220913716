import React, { ComponentPropsWithoutRef } from 'react'
import Button from '../Button'
import style from './style.module.scss'
import Icon, { IconTypes } from '../../particles/Icon'

type ButtonCloseProps = { size: 'sm'; color: 'primary' | 'gray' } & ComponentPropsWithoutRef<'button'>
export const ButtonClose = (props: ButtonCloseProps) => {
    let colorClass: string
    switch (props.color) {
        case 'primary':
            colorClass = style.primary
            break
        case 'gray':
            colorClass = style.gray
            break
    }
    return (
        <Button mode={'unstyled'} className={[style.base, colorClass].join(' ')} {...props}>
            <Icon type={IconTypes.Close} size={20} />
        </Button>
    )
}

import Rox from 'rox-browser'
import featuresList from '../../features/features.json'
import { useMemo } from 'react'
import { NUMBER_FEATURE_FLAG_OPTIONS } from './constants'

export interface FeatureFlagsCustomProperties {
    user_name: string
    user_email: string
    userRole: string
    sso_enabled: boolean
    sso_strategy: string
    theme_name: string
    org_name: string
    org_domain: string
    billingPlanType?: string
    billingPlanName?: string
    location?: string
    billingPlanCountry?: string
    billingPlanLegacy?: boolean
}

class FeatureFlags {
    public booleanFlags: { [key: string]: Rox.Flag }
    public numberFlags: { [key: string]: Rox.RoxNumber }
    public orgFlags: { [key: string]: number }

    /**
     * Initializes the flags set in the featuresList separating them into booleanFlags and numberFlags
     * booleanFlags are the products flags to say whether something should be shown or not
     * the boolean flags come from rollout, and the number flags come for stripe through the backend
     * numberFlags are the products specific flags to say how something should be shown (FEATURE_NUMBER_FLAG_OPTIONS)
     * @constructor
     */
    constructor() {
        this.orgFlags = {}
        this.booleanFlags = {}
        this.numberFlags = {}
        Object.keys(featuresList).map((key) => {
            const flag = featuresList[key]
            if (flag.type === 'boolean') {
                this.booleanFlags[key] = new Rox.Flag(flag.default || false)
            } else if (flag.type === 'number') {
                this.numberFlags[key] = new Rox.RoxNumber(flag.default || NUMBER_FEATURE_FLAG_OPTIONS.NoAccess, Object.values(NUMBER_FEATURE_FLAG_OPTIONS))
            }
        })
        if ((window as any).__CONFIG__?.FEATURE_FLAG_KEY) {
            Rox.register('EAP', { ...this.booleanFlags, ...this.numberFlags })

            // Setup the Rollout key
            const options = {
                version: __VERSION__,
                fetchIntervalInSec: 60,
                platform: 'Browser',
            }
            Rox.setup((window as any).__CONFIG__.FEATURE_FLAG_KEY, options)
            if (__DEV__) {
                window.addEventListener('keypress', (e: KeyboardEvent) => {
                    if (e.code === 'KeyF' && e.ctrlKey && e.shiftKey) {
                        Rox.showOverrides()
                    }
                })
            }
        }
    }

    /**
     * Sets the org flags which are set based on the current organization using the app
     * this process is done during authentication
     * @param {string[]} features - All the features to be set as an orgFlag.
     */
    setOrgFlags(features: { [key: string]: number }) {
        Object.keys(features || []).map((key) => {
            this.orgFlags[key] = features[key]
        })
    }

    /**
     * Check the feature flag, calling the right method for each type of feature flag, number or boolean
     * @param {string} name - name of the feature flag being checked.
     */
    checkFeatureFlag(name: string): boolean | number {
        if (featuresList[name].type === 'boolean') {
            return this.isActive(name)
        } else if (featuresList[name].type === 'number') {
            return this.canAccess(name)
        }
        return false
    }

    /**
     * Checks whether the boolean flag is enabled or not and set the orgFlag as a fallback
     * @param {string} name - name of the feature flag being checked.
     */
    isActive(name: string): boolean {
        if ((window as any).__CONFIG__?.FEATURE_FLAG_KEY) {
            return this.booleanFlags?.[name]?.isEnabled() || this.orgFlags?.[name] === NUMBER_FEATURE_FLAG_OPTIONS.FullAccess
        } else {
            return false
        }
    }

    /**
     * Checks the level of access in a numberFlag set the orgFlag as a fallback, since they are boolean we convert it to
     * the number format
     * @param {string} name - name of the feature flag being checked.
     */
    canAccess(name: string): number {
        if ((window as any).__CONFIG__?.FEATURE_FLAG_KEY) {
            return this.numberFlags?.[name]?.getValue() || this.orgFlags?.[name]
        } else {
            return NUMBER_FEATURE_FLAG_OPTIONS.NoAccess
        }
    }

    setProperties(properties: FeatureFlagsCustomProperties) {
        Rox.setCustomStringProperty('userName', properties.user_name)
        Rox.setCustomStringProperty('userEmail', properties.user_email)
        Rox.setCustomStringProperty('userRole', properties.userRole)
        Rox.setCustomStringProperty('ssoStrategy', properties.sso_strategy)
        Rox.setCustomStringProperty('themeName', properties.theme_name)
        Rox.setCustomStringProperty('location', properties.location || '')
        Rox.setCustomStringProperty('orgName', properties.org_name)
        Rox.setCustomBooleanProperty('ssoEnabled', properties.sso_enabled)
        Rox.setCustomStringProperty('billingPlanType', properties.billingPlanType || '')
        Rox.setCustomStringProperty('billingPlanName', properties.billingPlanName || '')
        Rox.setCustomStringProperty('billingPlanCountry', properties.billingPlanCountry || '')
        Rox.setCustomBooleanProperty('billingPlanLegacy', properties.billingPlanLegacy ? true : false)
        if ((window as any).__CONFIG__?.FEATURE_FLAG_KEY) {
            // Setup the Rollout key
            const options = {
                version: __VERSION__,
                fetchIntervalInSec: 60,
                platform: 'Browser',
            }
            Rox.setup((window as any).__CONFIG__.FEATURE_FLAG_KEY, options)
            if (__DEV__) {
                window.addEventListener('keypress', (e: KeyboardEvent) => {
                    if (e.code === 'KeyF' && e.ctrlKey && e.shiftKey) {
                        Rox.showOverrides()
                    }
                })
            }
        }
    }

    getFeatureFlag(name: string): Rox.Flag | Rox.RoxNumber {
        const allFeatures = { ...this.booleanFlags, ...this.numberFlags }
        return allFeatures[name]
    }

    getFeatureFlags(): Array<string> {
        const allFeatures = { ...this.booleanFlags, ...this.numberFlags }
        return Object.keys(allFeatures)
    }
}

const singleton = new FeatureFlags()

export const useFeatureFlag = (featureFlags: string[]): Array<boolean | number> => {
    return useMemo(() => {
        return featureFlags.map((_) => {
            return singleton.checkFeatureFlag(_)
        })
    }, [featureFlags, singleton.orgFlags, singleton.booleanFlags, singleton.numberFlags])
}

export default singleton

import React, { PureComponent } from 'react'
import style from './style.module.scss'
import Icon, { IconTypes } from '../../particles/Icon'
import AvatarText from '../../atoms/AvatarText'
import { GenericAttributes } from '../../types'

type ChipItemProps = {
    label: string
    icon: IconTypes
    name: string
    img?: string
    iconClick?: (ev: React.MouseEvent<HTMLElement>) => void
} & GenericAttributes
export class ChipItem extends PureComponent<ChipItemProps> {
    static iconTypes = IconTypes
    render(): React.ReactNode {
        const { label, name, icon, iconClick, img, ...props } = this.props
        return (
            <div {...props} data-test={`${this.props['data-test']}`} className={[style.chipItem].join(' ')}>
                <AvatarText data-test={`${this.props['data-test']}.avatar`} img={img} text={name} size={AvatarText.sizeTypes.small} />
                <div data-test={`${this.props['data-test']}.label`} className={style.text}>
                    {label}
                </div>
                <span data-test={`${this.props['data-test']}.icon`} className={style.icon} onClick={iconClick}>
                    <Icon type={icon} size={30} />
                </span>
            </div>
        )
    }
}
export default ChipItem

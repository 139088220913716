const DEFAULT_STATE = {
    errorMsg: '',
    registerError: '',
    logged: false,
    theme: {},
}
import { ACTION_REGISTER, ACTION_RENDER_REGISTER_FORM } from './actions'

export default (state = DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case `${ACTION_REGISTER}_PENDING`:
            return { ...state, errorMsg: '' }
        case `${ACTION_REGISTER}_FULFILLED`:
            return {
                ...state,
                logged: true,
                registerError: '',
                userInfo: payload.userInfo,
                lastLogin: payload.lastLogin,
                theme: payload.theme || {},
            }
        case `${ACTION_REGISTER}_REJECTED`:
            return { ...state, logged: false, registerError: payload.error || payload.errors._error }
        case `${ACTION_RENDER_REGISTER_FORM}`:
            return { ...state, logged: false }

        case 'ERROR_CLICK':
            return { ...state }
        default:
            return state
    }
}

import React, { PureComponent } from 'react'
// eslint-disable-next-line css-modules/no-unused-class
import style from './style.module.scss'
import ButtonDanger from '../../atoms/ButtonDanger'
import ButtonNegative from '../../atoms/ButtonNegative'
import ButtonPositive from '../../atoms/ButtonPositive'
import ButtonPrimary from '../../atoms/ButtonPrimary'
import ButtonSuccess from '../../atoms/ButtonSuccess'
import { GenericAttributes } from '../../types'

export enum ActionBarButtonAlignment {
    left = 'left',
    center = 'center',
    right = 'right',
}
export type ActionBarButtonTypes = typeof ButtonNegative | typeof ButtonSuccess | typeof ButtonDanger | typeof ButtonPositive | typeof ButtonPrimary

type ActionBarProps = {
    children: React.ReactElement<ActionBarButtonTypes> | React.ReactElement<ActionBarButtonTypes>[]
    buttonsAlignment?: ActionBarButtonAlignment
} & GenericAttributes
export class ActionBar extends PureComponent<ActionBarProps> {
    static buttonsType = [ButtonNegative, ButtonSuccess, ButtonDanger, ButtonPositive, ButtonPrimary]
    static buttonsAlignment = ActionBarButtonAlignment
    render(): React.ReactNode {
        const { children, buttonsAlignment = ActionBarButtonAlignment.center, ...props } = this.props
        return (
            <div {...props} data-test={`${this.props['data-test']}`} className={[style.actionBar, style[buttonsAlignment]].join(' ')}>
                {children}
            </div>
        )
    }
}
export default ActionBar

import React, { Component } from 'react'
import { GenericAttributes } from '../../types'
import { Tooltip as TooltipPrimitive } from '../../../../DesignSystem/Tooltip'

/**
 * Generic styled Tooltips
 */
type TooltipProps = {
    /**
     * tooltipContent: Any element, atom or molecule used as a content of the info window
     */
    tooltipContent: React.ReactNode
    /** @deprecated Use Tooltip from src/DesignSystem/Tooltip/index.tsx if you need more control */
    onOpen?: () => void
    /** @deprecated Use Tooltip from src/DesignSystem/Tooltip/index.tsx if you need more control */
    onClose?: () => void
    /** @deprecated Use Tooltip from src/DesignSystem/Tooltip/index.tsx if you need more control */
    defaultOpened?: boolean
    renderToRight?: boolean
    renderToLeft?: boolean
    renderToTop?: boolean
    renderToBottom?: boolean
    /** @deprecated Use Tooltip from src/DesignSystem/Tooltip/index.tsx if you need more control */
    className?: string
} & GenericAttributes

/**
 * @deprecated Use Tooltip from src/DesignSystem/Tooltip/index.tsx instead.
 */
export class Tooltip extends Component<TooltipProps> {
    render(): React.ReactNode {
        const { children, tooltipContent, renderToRight, renderToLeft, renderToTop, renderToBottom, ...props } = this.props

        let sideFromProps: 'right' | 'left' | 'top' | 'bottom' | undefined
        if (renderToRight) {
            sideFromProps = 'right'
        } else if (renderToLeft) {
            sideFromProps = 'left'
        } else if (renderToTop) {
            sideFromProps = 'top'
        } else if (renderToBottom) {
            sideFromProps = 'bottom'
        } else {
            sideFromProps = undefined
        }
        return (
            <TooltipPrimitive content={tooltipContent} side={sideFromProps} data-test={this.props['data-test']} {...props}>
                {children}
            </TooltipPrimitive>
        )
    }
}

export default Tooltip

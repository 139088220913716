import React from 'react'
import style from './style.module.scss'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'
import Icon, { IconTypes } from '../../ds/components/particles/Icon'
import { Link as RouterLink } from 'react-router-dom'

export type ActionProps =
    | {
          title: string
          href: string
          target?: ('_href' | '_self') | string
          to?: never
          onClick?: never
      }
    | {
          title: string
          to: string
          href?: never
          target?: never
          onClick?: never
      }
    | {
          title: string
          onClick: () => void
          to?: never
          href?: never
          target?: never
      }

export type TooltipProps = {
    title?: string
    content: string | React.ReactNode
    hideArrow?: boolean
    dark?: boolean
    disabled?: boolean
    action?: ActionProps
    'data-test'?: string
} & TooltipPrimitive.TooltipProps &
    TooltipPrimitive.TooltipContentProps

export const Tooltip = ({
    children,
    title,
    content,
    open,
    defaultOpen,
    onOpenChange,
    hideArrow,
    dark,
    align = 'center',
    side = 'bottom',
    collisionPadding = 4,
    disabled = false,
    sideOffset = 4,
    asChild,
    ...props
}: TooltipProps): JSX.Element => {
    let ActionElement: React.ReactNode | null = null
    if ('action' in props && props.action) {
        const ArrowRightIcon = <Icon type={IconTypes.ArrowRightWithTail} size={20} />
        if (props.action.to) {
            ActionElement = (
                <span data-test={`${props['data-test']}.action`} className={style.action}>
                    <RouterLink data-test={`${props['data-test']}.action.link`} className={style.link} to={props.action.to}>
                        {props.action.title}
                        {ArrowRightIcon}
                    </RouterLink>
                </span>
            )
        }
        if (props.action.href) {
            ActionElement = (
                <span data-test={`${props['data-test']}.action`} className={style.action}>
                    <a data-test={`${props['data-test']}.action.link`} className={style.link} target={props.action.target || '__blank'} href={props.action.href}>
                        {props.action.title}
                        {ArrowRightIcon}
                    </a>
                </span>
            )
        }
        if (props.action.onClick) {
            // should be unstyled button, waiting for https://github.com/estateably/frontend/pull/1519 to be merged
            // after merged, can change this to a `<Button>` with `mode={'unstyled'}`
            ActionElement = (
                <span data-test={`${props['data-test']}.action`} className={style.action}>
                    <a data-test={`${props['data-test']}.action.link`} className={style.link} onClick={props.action.onClick}>
                        {props.action.title}
                        {ArrowRightIcon}
                    </a>
                </span>
            )
        }
    }
    const noContent = !content && !title
    return disabled || noContent ? (
        <>{children}</>
    ) : (
        <TooltipPrimitive.Provider>
            <TooltipPrimitive.Root open={open} defaultOpen={defaultOpen} onOpenChange={onOpenChange} delayDuration={150}>
                <TooltipPrimitive.Trigger asChild={asChild !== undefined ? asChild : undefined} tabIndex={0} data-test={`${props['data-test']}`}>
                    {children}
                </TooltipPrimitive.Trigger>
                <TooltipPrimitive.Portal>
                    <TooltipPrimitive.Content
                        className={[style.Content, dark ? style.Dark : null].join(' ')}
                        side={side}
                        align={align}
                        collisionPadding={collisionPadding}
                        sideOffset={sideOffset}
                        {...props}>
                        {title ? <h2 data-test={`${props['data-test']}.title`}>{title}</h2> : null}
                        <p data-test={`${props['data-test']}.content`}>{content}</p>
                        {ActionElement}
                        {hideArrow ? null : <TooltipPrimitive.Arrow className={[style.Arrow, dark ? style.Dark : null].join(' ')} width={12} height={6} />}
                    </TooltipPrimitive.Content>
                </TooltipPrimitive.Portal>
            </TooltipPrimitive.Root>
        </TooltipPrimitive.Provider>
    )
}

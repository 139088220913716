import React, { useMemo } from 'react'
// the styles are conditionally rendered based on the index of the option
// eslint-disable-next-line css-modules/no-unused-class
import style from './style.module.scss'
import LabeledToggle from '../../atoms/LabeledToggle'
import { GenericAttributes } from '../../types'

export type LabeledToggleSelectOptionItem = {
    id: string
    label: string
}

type LabeledToggleSelectProps = {
    name?: string
    label?: string
    bottomMessage?: string
    disabled?: boolean
    hasError?: boolean
    required?: boolean
    hasWarning?: boolean
    value?: string
    options: LabeledToggleSelectOptionItem[]
    onSelect?: (index: number, item: string) => void
} & GenericAttributes

export const LabeledToggleSelect = ({ hasError = false, disabled = false, label, bottomMessage, required, hasWarning, value, options, onSelect, ...props }: LabeledToggleSelectProps) => {
    const firstFiveOptions = useMemo(() => {
        return options && options.slice(0, 5) // This component works only up to 5 options
    }, [options])

    const handleSelect = (idx: number, item: LabeledToggleSelectOptionItem): void => {
        if (!disabled && onSelect) {
            onSelect(idx, item.id)
        }
    }
    return (
        <fieldset {...props} data-test={props['data-test']} onSelect={undefined} className={[style.labeledToggleSelect, hasError ? style.withError : hasWarning ? style.withWarning : ''].join(' ')}>
            <legend data-test={`${props['data-test']}.label`} id={`label-${label}`}>
                {required ? <span className={style.required}>*</span> : undefined}
                {label}
            </legend>
            <div
                className={[style.labeledToggleSelect, hasError ? style.withError : '', disabled ? style.disabled : ''].join(' ')}
                role={'radiogroup'}
                aria-labelledby={`label-${label}`}
                aria-label={`${label}`}>
                {firstFiveOptions &&
                    firstFiveOptions.map((option, i) => {
                        return (
                            <div
                                key={`${option.id}${i}`}
                                onClick={() => handleSelect(i, option)}
                                className={[style.option, style[`optionsAmount${options.length}`]].join(' ')}
                                data-test={`${props['data-test']}.options.${i}`}>
                                <LabeledToggle selected={option.id === value} disabled={disabled} data-test={`${props['data-test']}.options.${i}.label`}>
                                    {option.label}
                                </LabeledToggle>
                            </div>
                        )
                    })}
            </div>
            <span className={style.bottomMessage}>{bottomMessage}&nbsp;</span>
        </fieldset>
    )
}
export default React.memo(LabeledToggleSelect)

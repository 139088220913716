import { IntegrationRequest, IntegrationResponse, IntegrationsActivationResponse, IntegrationsAllowedResponse, IntegrationsResponse, Page } from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Integrations
 */
export class IntegrationsApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }

    //Methods

    /**
     * List allowed integrations
     * {orgId}/integrations
     **/

    async listAllowedIntegrations(orgId: string): Promise<IntegrationsAllowedResponse> {
        try {
            const res: AxiosResponse<IntegrationsAllowedResponse> = await this.get<IntegrationsAllowedResponse>(`/orgs/${orgId}/integrations`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Deactivate integrations
     * {orgId}/integrations
     **/

    async deactivateIntegrations(orgId: string): Promise<IntegrationsActivationResponse> {
        try {
            const res: AxiosResponse<IntegrationsActivationResponse> = await this.delete<IntegrationsActivationResponse>(`/orgs/${orgId}/integrations`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Activate integrations
     * {orgId}/integrations
     **/

    async activateIntegrations(orgId: string): Promise<IntegrationsActivationResponse> {
        try {
            const res: AxiosResponse<IntegrationsActivationResponse> = await this.post<IntegrationsActivationResponse, null>(`/orgs/${orgId}/integrations`, null)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * reloadIntegration
     * ${estateId}/integrations/${integrationId}/reload
     **/

    async reloadIntegration(estateId: string, integrationId: string): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.get<void>(`/estates/${estateId}/integrations/${integrationId}/reload`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Delete an integration
     * ${estateId}/integrations/${integrationId}
     **/

    async deleteIntegration(estateId: string, integrationId: string): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.delete<void>(`/estates/${estateId}/integrations/${integrationId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * updateIntegration
     * ${estateId}/integrations/${integrationId}
     **/

    async updateIntegration(estateId: string, integrationId: string, body: IntegrationRequest): Promise<IntegrationResponse> {
        try {
            const res: AxiosResponse<IntegrationResponse> = await this.patch<IntegrationResponse, IntegrationRequest>(`/estates/${estateId}/integrations/${integrationId}`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * getIntegration
     * ${estateId}/integrations/${integrationId}
     **/

    async getIntegration(estateId: string, integrationId: string, page?: Page, filter?: object, sort?: string): Promise<IntegrationResponse> {
        try {
            const res: AxiosResponse<IntegrationResponse> = await this.get<IntegrationResponse>(`/estates/${estateId}/integrations/${integrationId}`, { params: { page, filter, sort } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * ListIntegrations
     * ${estateId}/integrations
     **/

    async listIntegrations(estateId: string, page?: Page, filter?: object, sort?: string): Promise<IntegrationsResponse> {
        try {
            const res: AxiosResponse<IntegrationsResponse> = await this.get<IntegrationsResponse>(`/estates/${estateId}/integrations`, { params: { page, filter, sort } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Register an integration
     * ${estateId}/integrations
     **/

    async registerIntegration(estateId: string, body: IntegrationRequest): Promise<IntegrationsResponse> {
        try {
            const res: AxiosResponse<IntegrationsResponse> = await this.post<IntegrationsResponse, IntegrationRequest>(`/estates/${estateId}/integrations`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

import { EstateRequestCreate, EstateRequestUpdate, EstateResponse, EstateResponseList, EstateSummaryResponse, MentionsResponseList, Page } from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Estates
 */
export class EstatesApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     *
     * ${estateId}/purchase/${addonId}
     **/

    async purchaseCaseAddon(estateId: string, addonId: string, body: unknown): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.post<void, unknown>(`/estates/${estateId}/purchase/${addonId}`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     *
     * ${estateId}/purchase/${addonId}
     **/

    async purchaseCaseAddonRequest(estateId: string, addonId: string, body: unknown): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.post<void, unknown>(`/estates/${estateId}/purchase/${addonId}`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Pay for an estate
     * ${estateId}/pay
     **/

    async payEstate(estateId: string): Promise<unknown> {
        try {
            const res: AxiosResponse<void> = await this.get<void>(`/estates/${estateId}/pay`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Delete an estate
     * ${estateId}
     **/

    async deleteEstate(estateId: string): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.delete<void>(`/estates/${estateId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Update an estate
     * ${estateId}
     **/

    async updateEstate(estateId: string, body: EstateRequestUpdate): Promise<EstateResponse> {
        try {
            const res: AxiosResponse<EstateResponse> = await this.patch<EstateResponse, EstateRequestUpdate>(`/estates/${estateId}`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Get an individual estate
     * ${estateId}
     **/

    async getEstate(estateId: string): Promise<EstateResponse> {
        try {
            const res: AxiosResponse<EstateResponse> = await this.get<EstateResponse>(`/estates/${estateId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Get an individual estate summary
     * ${estateId}/summary
     **/

    async getEstateSummary(estateId: string): Promise<EstateSummaryResponse> {
        try {
            const res: AxiosResponse<EstateSummaryResponse> = await this.get<EstateSummaryResponse>(`/estates/${estateId}/summary`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Get a list of users that can be mentioned in a case note
     * ${estateId}/collaborators/members
     **/

    async getEstateMembers(estateId: string, page?: Page, filter?: object, search?: string, sort?: string): Promise<MentionsResponseList> {
        try {
            const res: AxiosResponse<MentionsResponseList> = await this.get<MentionsResponseList>(`/estates/${estateId}/collaborators/members`, { params: { page, filter, search, sort } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * List estate cases
     *
     **/

    async listEstates(page?: Page, filter?: object, search?: string, sort?: string): Promise<EstateResponseList> {
        try {
            const res: AxiosResponse<EstateResponseList> = await this.get<EstateResponseList>(`/estates/`, { params: { page, filter, search, sort } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Create an estate case
     * ${estateId}/workflow
     **/

    async createEstate(estateId: string, body: EstateRequestCreate): Promise<EstateResponse> {
        try {
            const res: AxiosResponse<EstateResponse> = await this.post<EstateResponse, EstateRequestCreate>(`/estates/${estateId}/workflow`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

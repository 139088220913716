import React, { PureComponent } from 'react'
// eslint-disable-next-line css-modules/no-unused-class
import style from './style.module.scss'
import { GenericAttributes } from '../../types'
import { v4 as uuidv4 } from 'uuid'
import Icon from '../../particles/Icon'

enum LabelPosition {
    top = 'top',
    left = 'left',
    bottom = 'bottom',
    right = 'right',
    none = 'none',
}

type CheckboxProps = {
    disabled?: boolean
    value?: CheckboxValues
    error?: boolean
    size?: CheckboxSizes
    onClick?: (ev: React.MouseEvent<HTMLElement>) => void
    label?: string
    labelPosition?: LabelPosition
} & GenericAttributes
type CheckboxState = {
    selected: boolean
}

export enum CheckboxValues {
    SELECTED = 'SELECTED',
    PARTIAL_SELECTED = 'PARTIAL_SELECTED',
    NOT_SELECTED = 'NOT_SELECTED',
}

export enum CheckboxSizes {
    small = 'small',
    medium = 'medium',
    large = 'large',
}

export class Checkbox extends PureComponent<CheckboxProps, CheckboxState> {
    static uniqueId = uuidv4()
    static selectionValues = CheckboxValues
    static sizes = CheckboxSizes
    static labelPositions = LabelPosition
    sizesInPx = {
        small: 16,
        medium: 20,
        large: 24,
    }
    static defaultProps = {
        disabled: false,
        value: Checkbox.selectionValues.NOT_SELECTED,
    }

    constructor(props: CheckboxProps) {
        super(props)
        this.state = {
            selected: false,
        }
    }

    render(): React.ReactNode {
        const { disabled = false, value, size = Checkbox.sizes.small, error, onClick, label, labelPosition = LabelPosition.right, ...props } = this.props
        const sizeInPx = this.sizesInPx[size] || this.sizesInPx.small
        let selectedStyle
        let icon = ''
        switch (value) {
            case Checkbox.selectionValues.SELECTED:
                selectedStyle = style.selected
                icon = Icon.types.Check
                break
            case Checkbox.selectionValues.NOT_SELECTED:
                selectedStyle = style.notSelected
                break
            case Checkbox.selectionValues.PARTIAL_SELECTED:
                selectedStyle = style.partialSelected
                icon = Icon.types.Partial
                break
        }
        return (
            <div
                {...props}
                data-test={this.props['data-test']}
                data-selected={value}
                data-disabled={disabled}
                onClick={!disabled ? onClick : undefined}
                className={[style.container, labelPosition === LabelPosition.top || labelPosition === LabelPosition.bottom ? style.column : ''].join(' ')}>
                {label && (labelPosition === LabelPosition.top || labelPosition === LabelPosition.left) ? (
                    <label data-test={`${this.props['data-test']}.label`} className={[style[labelPosition], disabled ? style.disabled : ''].join(' ')}>
                        {label}
                    </label>
                ) : null}
                <div data-test={`${this.props['data-test']}.checkIcon`} className={[style.checkBoxContainer, disabled ? style.disabled : ''].join(' ')}>
                    <input
                        className={[style.checkbox, selectedStyle, disabled ? style.disabled : '', error ? style.error : ''].join(' ')}
                        style={{ width: `${sizeInPx}px`, height: `${sizeInPx}px` }}
                        checked={value === 'SELECTED'}
                        id={`${Checkbox.uniqueId}`}
                        type='checkbox'
                        value={`${value}`}
                    />
                    {icon ? <i style={{ display: 'none' }} /> : null}
                </div>
                {label && (labelPosition === LabelPosition.right || labelPosition === LabelPosition.bottom) ? (
                    <label data-test={`${this.props['data-test']}.label`} className={[style[labelPosition], disabled ? style.disabled : ''].join(' ')}>
                        {label}
                    </label>
                ) : null}
            </div>
        )
    }
}

export default Checkbox

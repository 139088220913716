import React, { PureComponent } from 'react'
import style from './style.module.scss'
import Icon from '../../particles/Icon'
import { GenericAttributes } from '../../types'

const maxNumber = 9999
type LabeledIconIndicatorProps = {
    number?: number
    isUnread?: boolean
    isLabeled?: boolean
    onClick?: (ev: React.MouseEvent<HTMLElement>) => void
    icon?: string
} & GenericAttributes
export class LabeledIconIndicator extends PureComponent<LabeledIconIndicatorProps> {
    static iconTypes = Icon.types
    static defaultProps = {
        isUnread: false,
        icon: LabeledIconIndicator.iconTypes.AddNote,
        isLabeled: true,
    }
    render(): React.ReactNode {
        const { children, number = 0, isUnread, onClick, icon, isLabeled, ...props } = this.props
        const numberToShow = number > maxNumber ? `${maxNumber}+` : number
        return onClick ? (
            <button type={'button'} {...props} data-test={this.props['data-test']} onClick={onClick} className={[style.labeledIconIndicator].join(' ')}>
                {isLabeled ? (
                    <p data-test={`${this.props['data-test']}.label`}>
                        {children} ({numberToShow})
                    </p>
                ) : (
                    <div />
                )}
                <div data-test={`${this.props['data-test']}.icon`} className={style.icons} aria-hidden>
                    {isUnread ? <Icon type={Icon.types.Circle} size={14} /> : <div />}
                    <Icon type={icon} size={30} />
                </div>
            </button>
        ) : (
            <div {...props} data-test={this.props['data-test']} className={[style.labeledIconIndicator].join(' ')}>
                {isLabeled ? (
                    <p data-test={`${this.props['data-test']}.label`}>
                        {children} ({numberToShow})
                    </p>
                ) : (
                    <div />
                )}
                <div data-test={`${this.props['data-test']}.icon`} className={style.icons}>
                    {isUnread ? <Icon type={Icon.types.Circle} size={14} /> : <div />}
                    <Icon type={icon} size={30} />
                </div>
            </div>
        )
    }
}
export default LabeledIconIndicator

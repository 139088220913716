import React, { PureComponent } from 'react'
import style from './style.module.scss'
import IconSelection from '../../atoms/IconSelection'
import { IconTypes } from '../../particles/Icon'
import { GenericAttributes } from '../../types'

type IconInfo = {
    icon: IconTypes
    label: string
}
type ToggleIconProps = {
    icons: IconInfo[]
    value?: number
    onChange: (index: number) => void
} & GenericAttributes

export class ToggleIcon extends PureComponent<ToggleIconProps> {
    static iconTypes = IconSelection.iconTypes
    static defaultProps = {
        value: 0,
    }

    render(): React.ReactNode {
        const { icons = [], onChange, value, ...props } = this.props
        return (
            <div {...props} data-test={this.props['data-test']} className={style.toggleIcon}>
                {icons.map((iconInfo, idx) => {
                    return (
                        <IconSelection
                            {...props}
                            data-test={`${this.props['data-test']}.options.${idx}`}
                            aria-label={iconInfo.label}
                            selected={value === idx}
                            key={idx}
                            icon={iconInfo.icon}
                            onClick={() => {
                                onChange(idx)
                            }}
                        />
                    )
                })}
            </div>
        )
    }
}

export default ToggleIcon

import React, { PureComponent } from 'react'
import style from './style.module.scss'
import Icon from '../../particles/Icon'
import { GenericAttributes } from '../../types'

type TagProps = {
    type?: TagTypes
    onClick?: (ev: React.MouseEvent<HTMLElement>) => void
    onClickClose?: (ev: React.MouseEvent<HTMLElement>) => void
    disabled?: boolean
} & GenericAttributes

export enum TagTypes {
    Default = 'Default',
    ReadOnly = 'ReadOnly',
    Selected = 'Selected',
    SelectedMulti = 'SelectedMulti',
    Removable = 'Removable',
}

export class Tag extends PureComponent<TagProps> {
    static Types = TagTypes
    static defaultProps = {
        type: Tag.Types.Default,
    }
    getTypeStyle = (): string => {
        switch (this.props.type) {
            case Tag.Types.ReadOnly:
                return style.readOnly
            case Tag.Types.Selected:
                return style.selected
            case Tag.Types.SelectedMulti:
                return style.selected
            case Tag.Types.Removable:
                return style.removable
            default:
                return style.default
        }
    }

    render(): React.ReactNode {
        const { children, type, onClick, onClickClose, ...props } = this.props
        return (
            <div {...props} className={[props.className || '', style.tag, this.getTypeStyle(), props.disabled ? style.disabled : ''].join(' ')}>
                <div onClick={props.disabled ? undefined : onClick} className={style.label} data-test={this.props['data-test']}>
                    {children}
                </div>
                {type === Tag.Types.Selected || type === Tag.Types.Removable ? (
                    <div onClick={props.disabled ? undefined : onClickClose} className={style.icon} data-test={`${this.props['data-test']}.close`}>
                        <Icon type={Icon.types.CloseCircle} size={20} data-test={`${this.props['data-test']}.close.icon`} />
                    </div>
                ) : null}
            </div>
        )
    }
}

export default Tag

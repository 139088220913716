import { useEffect, useMemo } from 'react'
import { Api } from './api'
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios'

import { ExternalMessagesApi } from './entrypoints/ExternalMessages'

import { PublicNoticeApi } from './entrypoints/PublicNotice'

import { EmailDocumentApi } from './entrypoints/EmailDocument'

import { ApplicationConfigurationApi } from './entrypoints/ApplicationConfiguration'

import { NoticeApi } from './entrypoints/Notice'

import { OrgTaskTemplatesApi } from './entrypoints/OrgTaskTemplates'

import { AddonsApi } from './entrypoints/Addons'

import { EstateArchiveApi } from './entrypoints/EstateArchive'

import { DocumentsTagsApi } from './entrypoints/DocumentsTags'

import { PoAsSubstituteDecisionMakersApi } from './entrypoints/PoAsSubstituteDecisionMakers'

import { OrgTasksApi } from './entrypoints/OrgTasks'

import { GlobalContactsApi } from './entrypoints/GlobalContacts'

import { IntegrationsApi } from './entrypoints/Integrations'

import { CharitiesApi } from './entrypoints/Charities'

import { PlacesApi } from './entrypoints/Places'

import { StatsApi } from './entrypoints/Stats'

import { ExchangesApi } from './entrypoints/Exchanges'

import { LabelsApi } from './entrypoints/Labels'

import { ReportsApi } from './entrypoints/Reports'

import { DocumentsApi } from './entrypoints/Documents'

import { WorkflowResourcesApi } from './entrypoints/WorkflowResources'

import { EstateDistributionsApi } from './entrypoints/EstateDistributions'

import { AccountManagementApi } from './entrypoints/AccountManagement'

import { AccountTransactionsApi } from './entrypoints/AccountTransactions'

import { AccountsApi } from './entrypoints/Accounts'

import { InventoryManagementApi } from './entrypoints/InventoryManagement'

import { InventoryApi } from './entrypoints/Inventory'

import { EstateBeneficiariesApi } from './entrypoints/EstateBeneficiaries'

import { EstateExecutorsApi } from './entrypoints/EstateExecutors'

import { EstateContactsApi } from './entrypoints/EstateContacts'

import { DocumentTemplatesApi } from './entrypoints/DocumentTemplates'

import { FormsApi } from './entrypoints/Forms'

import { EstateCollaboratorsApi } from './entrypoints/EstateCollaborators'

import { EstateActivitiesApi } from './entrypoints/EstateActivities'

import { EstateCommentsApi } from './entrypoints/EstateComments'

import { EstateDocumentsApi } from './entrypoints/EstateDocuments'

import { WorkflowsApi } from './entrypoints/Workflows'

import { EstatesApi } from './entrypoints/Estates'

import { PermissionsApi } from './entrypoints/Permissions'

import { RolesApi } from './entrypoints/Roles'

import { TeamMembersApi } from './entrypoints/TeamMembers'

import { TeamsApi } from './entrypoints/Teams'

import { BillingPlanApi } from './entrypoints/BillingPlan'

import { OrganizationInvitationsApi } from './entrypoints/OrganizationInvitations'

import { OrganizationMembersApi } from './entrypoints/OrganizationMembers'

import { OrganizationsApi } from './entrypoints/Organizations'

import { NotificationsApi } from './entrypoints/Notifications'

import { UsersApi } from './entrypoints/Users'

import { AuthenticationApi } from './entrypoints/Authentication'

import { EstateTasksApi } from './entrypoints/EstateTasks'

import { JobsApi } from './entrypoints/Jobs'
import { GlobalSearchApi } from './entrypoints/GlobalSearch'

export function useCancelAPI<T extends Api>(api: T) {
    useEffect(() => {
        return () => {
            api.cancel()
        }
    }, [])
    return api
}

export function useCancelAPIs(config?: AxiosRequestConfig) {
    const api = useMemo(() => new APIClient(config), [config])
    useEffect(() => {
        return () => {
            api.cancel()
        }
    }, [])
    return api
}

/**
 * Class used to access the entire api entry points
 */
class APIClient {
    private readonly cancelTokenSource: CancelTokenSource

    public externalMessages: ExternalMessagesApi

    public emailDocument: EmailDocumentApi

    public publicNotice: PublicNoticeApi

    public applicationConfiguration: ApplicationConfigurationApi

    public notice: NoticeApi

    public orgTaskTemplates: OrgTaskTemplatesApi

    public addons: AddonsApi

    public estateArchive: EstateArchiveApi

    public documentsTags: DocumentsTagsApi

    public poAsSubstituteDecisionMakers: PoAsSubstituteDecisionMakersApi

    public orgTasks: OrgTasksApi

    public globalContacts: GlobalContactsApi

    public integrations: IntegrationsApi

    public charities: CharitiesApi

    public places: PlacesApi

    public stats: StatsApi

    public exchanges: ExchangesApi

    public labels: LabelsApi

    public reports: ReportsApi

    public documents: DocumentsApi

    public workflowResources: WorkflowResourcesApi

    public estateDistributions: EstateDistributionsApi

    public accountManagement: AccountManagementApi

    public accountTransactions: AccountTransactionsApi

    public accounts: AccountsApi

    public inventoryManagement: InventoryManagementApi

    public inventory: InventoryApi

    public estateBeneficiaries: EstateBeneficiariesApi

    public estateExecutors: EstateExecutorsApi

    public estateContacts: EstateContactsApi

    public documentTemplates: DocumentTemplatesApi

    public forms: FormsApi

    public estateCollaborators: EstateCollaboratorsApi

    public estateActivities: EstateActivitiesApi

    public estateComments: EstateCommentsApi

    public estateDocuments: EstateDocumentsApi

    public workflows: WorkflowsApi

    public estates: EstatesApi

    public permissions: PermissionsApi

    public roles: RolesApi

    public teamMembers: TeamMembersApi

    public teams: TeamsApi

    public billingPlan: BillingPlanApi

    public organizationInvitations: OrganizationInvitationsApi

    public organizationMembers: OrganizationMembersApi

    public organizations: OrganizationsApi

    public notifications: NotificationsApi

    public users: UsersApi

    public authentication: AuthenticationApi

    public estateTasks: EstateTasksApi

    public globalSearch: GlobalSearchApi

    public jobs: JobsApi

    constructor(config?: AxiosRequestConfig) {
        this.cancelTokenSource = axios.CancelToken.source()

        this.externalMessages = new ExternalMessagesApi(config, this.cancelTokenSource)

        this.emailDocument = new EmailDocumentApi(config, this.cancelTokenSource)

        this.publicNotice = new PublicNoticeApi(config, this.cancelTokenSource)

        this.applicationConfiguration = new ApplicationConfigurationApi(config, this.cancelTokenSource)

        this.notice = new NoticeApi(config, this.cancelTokenSource)

        this.orgTaskTemplates = new OrgTaskTemplatesApi(config, this.cancelTokenSource)

        this.addons = new AddonsApi(config, this.cancelTokenSource)

        this.estateArchive = new EstateArchiveApi(config, this.cancelTokenSource)

        this.documentsTags = new DocumentsTagsApi(config, this.cancelTokenSource)

        this.poAsSubstituteDecisionMakers = new PoAsSubstituteDecisionMakersApi(config, this.cancelTokenSource)

        this.orgTasks = new OrgTasksApi(config, this.cancelTokenSource)

        this.globalContacts = new GlobalContactsApi(config, this.cancelTokenSource)

        this.globalSearch = new GlobalSearchApi(config, this.cancelTokenSource)

        this.integrations = new IntegrationsApi(config, this.cancelTokenSource)

        this.charities = new CharitiesApi(config, this.cancelTokenSource)

        this.places = new PlacesApi(config, this.cancelTokenSource)

        this.stats = new StatsApi(config, this.cancelTokenSource)

        this.exchanges = new ExchangesApi(config, this.cancelTokenSource)

        this.labels = new LabelsApi(config, this.cancelTokenSource)

        this.reports = new ReportsApi(config, this.cancelTokenSource)

        this.documents = new DocumentsApi(config, this.cancelTokenSource)

        this.workflowResources = new WorkflowResourcesApi(config, this.cancelTokenSource)

        this.estateDistributions = new EstateDistributionsApi(config, this.cancelTokenSource)

        this.accountManagement = new AccountManagementApi(config, this.cancelTokenSource)

        this.accountTransactions = new AccountTransactionsApi(config, this.cancelTokenSource)

        this.accounts = new AccountsApi(config, this.cancelTokenSource)

        this.inventoryManagement = new InventoryManagementApi(config, this.cancelTokenSource)

        this.inventory = new InventoryApi(config, this.cancelTokenSource)

        this.estateBeneficiaries = new EstateBeneficiariesApi(config, this.cancelTokenSource)

        this.estateExecutors = new EstateExecutorsApi(config, this.cancelTokenSource)

        this.estateContacts = new EstateContactsApi(config, this.cancelTokenSource)

        this.documentTemplates = new DocumentTemplatesApi(config, this.cancelTokenSource)

        this.forms = new FormsApi(config, this.cancelTokenSource)

        this.estateCollaborators = new EstateCollaboratorsApi(config, this.cancelTokenSource)

        this.estateActivities = new EstateActivitiesApi(config, this.cancelTokenSource)

        this.estateComments = new EstateCommentsApi(config, this.cancelTokenSource)

        this.estateDocuments = new EstateDocumentsApi(config, this.cancelTokenSource)

        this.workflows = new WorkflowsApi(config, this.cancelTokenSource)

        this.estates = new EstatesApi(config, this.cancelTokenSource)

        this.permissions = new PermissionsApi(config, this.cancelTokenSource)

        this.roles = new RolesApi(config, this.cancelTokenSource)

        this.teamMembers = new TeamMembersApi(config, this.cancelTokenSource)

        this.teams = new TeamsApi(config, this.cancelTokenSource)

        this.billingPlan = new BillingPlanApi(config, this.cancelTokenSource)

        this.organizationInvitations = new OrganizationInvitationsApi(config, this.cancelTokenSource)

        this.organizationMembers = new OrganizationMembersApi(config, this.cancelTokenSource)

        this.organizations = new OrganizationsApi(config, this.cancelTokenSource)

        this.notifications = new NotificationsApi(config, this.cancelTokenSource)

        this.users = new UsersApi(config, this.cancelTokenSource)

        this.authentication = new AuthenticationApi(config, this.cancelTokenSource)

        this.estateTasks = new EstateTasksApi(config, this.cancelTokenSource)

        this.jobs = new JobsApi(config, this.cancelTokenSource)
    }

    public cancel = (message?: string) => this.cancelTokenSource.cancel(message)
}

export default APIClient

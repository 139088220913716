import React, { PropsWithChildren, useMemo } from 'react'
import style from './style.module.scss'
import AvatarSquare from '../../atoms/AvatarSquare'
import { IconTypes } from '../../particles/Icon'
import { GenericAttributes } from '../../types'

type SquareTitleProps = {
    icon?: IconTypes
    src?: string
} & GenericAttributes

export const SquareTitle = ({ icon, src, children, ...props }: PropsWithChildren<SquareTitleProps>) => {
    const isImageTitle = useMemo(() => icon || src, [icon, src])
    return (
        <div {...props} data-test={props['data-test']} className={[style.squareTitle].join(' ')}>
            {isImageTitle ? <AvatarSquare icon={icon} src={src} data-test={`${props['data-test']}.avatar`} /> : null}
            <div className={style.text}>{children}</div>
        </div>
    )
}

export default React.memo(SquareTitle)

import React, { PureComponent } from 'react'
import style from './style.module.scss'
import { GenericAttributes } from '../../types'

type AvatarTextProps = {
    size?: AvatarTextSizes
    img?: string
    text: string
    shadow?: boolean
} & GenericAttributes
type AvatarTextState = {
    loaded: boolean
}
export enum AvatarTextSizes {
    small = 'small',
    medium = 'medium',
    large = 'large',
}
export class AvatarText extends PureComponent<AvatarTextProps, AvatarTextState> {
    constructor(props: AvatarTextProps) {
        super(props)
        this.state = { loaded: false }
    }
    static sizeTypes = AvatarTextSizes
    static defaultProps = {
        size: AvatarText.sizeTypes.small,
        shadow: false,
    }
    renderSize = (): string => {
        switch (this.props.size) {
            case AvatarText.sizeTypes.small:
                return style.small
            case AvatarText.sizeTypes.medium:
                return style.medium
            case AvatarText.sizeTypes.large:
                return style.large
            default:
                return style.medium
        }
    }
    renderText = (): string | null => {
        const { text } = this.props
        if (text && text.length !== 0) {
            const splits = text.split(' ').filter((t) => t.length > 0)
            if (splits.length < 2) {
                return splits[0].length < 2 ? splits[0][0] : splits[0][0].toUpperCase() + splits[0][1].toLowerCase()
            } else {
                return splits[0][0].toUpperCase() + splits[1][0].toUpperCase()
            }
        } else {
            return null
        }
    }
    render(): React.ReactNode {
        const { img, text, shadow, ...props } = this.props
        const { loaded } = this.state
        return (
            <span {...props} data-test={`${this.props['data-test']}`} className={[style.avatarText, this.renderSize(), img && loaded ? style.hasImg : null, shadow ? style.shadow : null].join(' ')}>
                {img ? <img alt={text} src={img} style={loaded ? {} : { display: 'none' }} onLoad={() => this.setState({ loaded: true })} onError={() => this.setState({ loaded: false })} /> : null}
                {img && loaded ? null : this.renderText()}
            </span>
        )
    }
}
export default AvatarText

import React, { PureComponent } from 'react'
import style from './style.module.scss'
import { GenericAttributes } from '../../types'
import { gray100, primary600 } from '../../../../utils/DesignSystem/colors'

export enum LoadingSpinnerSizes {
    'small' = 'small',
    'large' = 'large',
}
type LoadingSpinnerProps = {
    startTime: number
    size: LoadingSpinnerSizes
    label?: string
} & GenericAttributes
type LoadingSpinnerState = {
    showing: boolean
}
export class LoadingSpinner extends PureComponent<LoadingSpinnerProps, LoadingSpinnerState> {
    constructor(props: LoadingSpinnerProps) {
        super(props)
        this.state = {
            showing: false,
        }
        this.timeoutRef = null
    }
    private timeoutRef: NodeJS.Timeout | null
    static sizes = LoadingSpinnerSizes
    clearTimeout = (): void => {
        if (this.timeoutRef) clearTimeout(this.timeoutRef)
        this.timeoutRef = null
    }
    componentDidMount(): void {
        this.timeoutRef = setTimeout(this.show, this.props.startTime * 1000)
    }
    componentWillUnmount(): void {
        this.clearTimeout()
    }
    componentDidUpdate(oldProps: LoadingSpinnerProps): void {
        if (oldProps.startTime !== this.props.startTime) {
            this.clearTimeout()
            this.setState({
                showing: false,
            })
            this.timeoutRef = setTimeout(this.show, this.props.startTime * 1000)
        }
    }
    show = (): void => {
        this.setState({
            showing: true,
        })
    }
    static defaultProps = {
        startTime: 1,
        size: LoadingSpinnerSizes.large,
    }
    render(): React.ReactNode {
        const { label, size, ...props } = this.props
        if (!this.state.showing) return null
        return (
            <div {...props} className={[style.loadingSpinner, size].join(' ')} data-test={this.props['data-test']}>
                <div className={style.imageBox}>
                    <svg width='100%' height='100%' viewBox='0 0 77 77' fill='none' xmlns='http://www.w3.org/2000/svg'>
                        <circle opacity='0.6' cx='38.6514' cy='38.6514' r='35' stroke={gray100} strokeWidth='6' strokeLinecap='round' strokeLinejoin='round' />
                        <path
                            d='M73.6514 38.6514C73.6514 57.9813 57.9813 73.6514 38.6514 73.6514C19.3214 73.6514 3.65137 57.9813 3.65137 38.6514C3.65137 19.3214 19.3214 3.65137 38.6514 3.65137'
                            stroke={primary600}
                            strokeWidth='6'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        />
                    </svg>
                </div>
                {label ? <div className={style.label}>{label}</div> : null}
            </div>
        )
    }
}
export default LoadingSpinner

import { Page, TeamCreateRequest, TeamResponse, TeamResponseList, TeamUpdateRequest } from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Teams
 */
export class TeamsApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * Check Envelope Prefix availability by getting all teams with a prefix
     * ${orgId}/teams/prefix/${prefix}
     **/

    async getTeamByPrefix(orgId: string, prefix: string): Promise<TeamResponseList> {
        try {
            const res: AxiosResponse<TeamResponseList> = await this.get<TeamResponseList>(`/orgs/${orgId}/teams/prefix/${prefix}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Delete an individual team
     * ${orgId}/teams/${teamId}
     **/

    async deleteTeam(teamId: string, orgId: string): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.delete<void>(`/orgs/${orgId}/teams/${teamId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Update an individual team
     * ${orgId}/teams/${teamId}
     **/

    async updateTeam(teamId: string, orgId: string, body: TeamUpdateRequest): Promise<TeamResponse> {
        try {
            const res: AxiosResponse<TeamResponse> = await this.patch<TeamResponse, TeamUpdateRequest>(`/orgs/${orgId}/teams/${teamId}`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Get an individual team
     * ${orgId}/teams/${teamId}
     **/

    async getTeam(teamId: string, orgId: string): Promise<TeamResponse> {
        try {
            const res: AxiosResponse<TeamResponse> = await this.get<TeamResponse>(`/orgs/${orgId}/teams/${teamId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Create a team
     * ${orgId}/teams
     **/

    async createTeam(orgId: string, body: TeamCreateRequest): Promise<TeamResponse> {
        try {
            const res: AxiosResponse<TeamResponse> = await this.post<TeamResponse, TeamCreateRequest>(`/orgs/${orgId}/teams`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * List teams
     * ${orgId}/teams
     **/

    async listTeams(orgId: string, page?: Page, filter?: object, search?: string, sort?: string): Promise<TeamResponseList> {
        try {
            const res: AxiosResponse<TeamResponseList> = await this.get<TeamResponseList>(`/orgs/${orgId}/teams`, { params: { page, filter, search, sort } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

import React from 'react'
import style from './style.module.scss'

export type ButtonSizes = 'small' | 'medium' | 'large' | 'auto' | 'full' | 'sm' | 'auto'
type ButtonProps = Omit<React.ComponentProps<'button'>, 'size'> & {
    size: ButtonSizes
    mode: 'success' | 'danger' | 'positive' | 'primary' | 'negative' | 'unstyled' | 'tertiary-gray' | 'secondary-gray'
}
export const Button = ({ children, size, mode = 'success', className, ...props }: ButtonProps) => {
    let sizeClass = ''
    switch (size) {
        case 'medium':
            sizeClass = style.sm
            break
        case 'large':
            sizeClass = style.large
            break
        case 'small':
            sizeClass = style.sm
            break
        case 'full':
            sizeClass = style.full
            break
        case 'sm':
            sizeClass = style.sm
            break
    }
    let modeClass = ''
    switch (mode) {
        case 'primary':
            modeClass = style.primary
            break
        case 'danger':
            modeClass = style.danger
            break
        case 'success':
            modeClass = style.success
            break
        case 'negative':
            modeClass = style.negative
            break
        case 'positive':
            modeClass = style.positive
            break
        case 'tertiary-gray':
            modeClass = style.tertiaryGray
            break
        case 'secondary-gray':
            modeClass = style.secondaryGray
            break
    }
    return (
        <button
            type={'button'}
            {...props}
            onClick={(e) => {
                e.preventDefault()
                props.onClick && props.onClick(e)
            }}
            data-disabled={props.disabled}
            data-mode={mode}
            className={[style.button, sizeClass, modeClass, className || ''].join(' ')}>
            {children}
        </button>
    )
}
export default Button

import React, { PureComponent } from 'react'
import Link from '../../atoms/Link'
import LoadingSpinner, { LoadingSpinnerSizes } from '../../atoms/LoadingSpinner'
import Icon from '../../particles/Icon'
import style from './style.module.scss'
import { GenericAttributes } from '../../types'

export enum AttachmentMode {
    Warning = 'warning',
    Success = 'success',
    Loading = 'loading',
    None = '',
}

export type AttachmentProps = {
    title: string
    subtitle?: string
    iconType?: string
    mode?: AttachmentMode
    onClose?: (ev: React.MouseEvent<HTMLElement, MouseEvent>) => unknown
    alwaysShowCloseIcon?: boolean
    link?: {
        title: string
        onClick: (ev: React.MouseEvent<HTMLElement, MouseEvent>) => unknown
    }
    downloadLink?: {
        title: string
        onDownload: () => void
    }
    disabled?: boolean
} & GenericAttributes

export class Attachment extends PureComponent<AttachmentProps> {
    constructor(props: AttachmentProps) {
        super(props)
    }

    static defaultProps = {
        iconType: 'File',
    }
    static Modes = AttachmentMode

    render(): React.ReactNode {
        const { title, subtitle, iconType, mode, onClose, alwaysShowCloseIcon = false, link, downloadLink, disabled = false, ...props } = this.props
        const closeIcon: boolean = alwaysShowCloseIcon || ((mode === AttachmentMode.Warning || mode == AttachmentMode.Loading) && !!onClose)
        const showSuccess: boolean = mode !== AttachmentMode.Warning && mode === AttachmentMode.Success
        return (
            <div {...props} data-test={`${this.props['data-test']}`} className={[style.attachment, disabled ? style.disabled : ''].join(' ')}>
                <div className={style.content}>
                    <span data-test={`${this.props['data-test']}.status.icon`} className={[style.iconContainer, mode === AttachmentMode.Warning ? style.warning : null].join(' ')}>
                        {mode === AttachmentMode.Warning ? (
                            <Icon type={'Alert'} />
                        ) : mode === AttachmentMode.Loading ? (
                            <LoadingSpinner data-test={`${this.props['data-test']}.loading`} size={LoadingSpinnerSizes.small} />
                        ) : (
                            <Icon type={iconType || 'File'} />
                        )}
                    </span>

                    <div data-test={`${this.props['data-test']}.label`} className={[style.text, !closeIcon && !showSuccess ? style.gap : null].join(' ')}>
                        <p className={[style.title, style.ellipsis].join(' ')}>{title}</p>
                        <div className={style.details}>
                            {subtitle && (
                                <p data-test={`${this.props['data-test']}.label.subtitle`} className={[style.subtitle, style.ellipsis].join(' ')}>
                                    {subtitle}
                                </p>
                            )}
                            <div className={style.actions}>
                                {link?.title && link?.onClick ? (
                                    <Link disabled={disabled} data-test={`${this.props['data-test']}.label.link`} onClick={link.onClick}>
                                        <p className={[style.ellipsis].join(' ')}>{link.title}</p>
                                    </Link>
                                ) : null}
                                {link?.title && downloadLink ? <div className={style.divider}>|</div> : null}
                                {downloadLink && (
                                    <Link disabled={disabled} data-test={`${this.props['data-test']}.label.link`} onClick={downloadLink.onDownload}>
                                        <p className={[style.ellipsis].join(' ')}>{downloadLink.title}</p>
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                {!disabled && (closeIcon || showSuccess) ? (
                    <span
                        data-test={`${this.props['data-test']}.icon`}
                        className={[style.rightOfText, onClose ? style.pointer : null, mode === AttachmentMode.Success && !closeIcon ? style.success : null].join(' ')}
                        onClick={onClose}>
                        <Icon type={closeIcon ? 'Close' : 'Check'} size={25} />
                    </span>
                ) : null}
            </div>
        )
    }
}

export default Attachment

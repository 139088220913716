import { GlobalSearchResponse, Page } from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

export class GlobalSearchApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    async listGlobalSearchResults(orgId: string | undefined, search?: string, page?: Page, filter?: object) {
        try {
            const res: AxiosResponse<GlobalSearchResponse> = await this.get<GlobalSearchResponse>(`/globalSearch`, { params: { search, page, filter } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

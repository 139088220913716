import React, { PureComponent } from 'react'
import style from './style.module.scss'
import { GenericAttributesWithRef } from '../../types'

type TextAreaProps = {
    name?: string
    hasError?: boolean
    hasWarning?: boolean
    disabled?: boolean
    value?: string
    placeholder?: string
    autoComplete?: string
    size?: TextAreaSizes
    autoFocus?: boolean
    className?: string
    onChange?: (ev: React.ChangeEvent<HTMLElement>) => void
    onFocus?: (ev: React.FocusEvent<HTMLTextAreaElement>) => void
    onBlur?: (ev: React.FocusEvent<HTMLTextAreaElement>) => void
    onClick?: (ev: React.MouseEvent<HTMLElement>) => void
    allowResize?: boolean
} & GenericAttributesWithRef<'textarea', HTMLTextAreaElement>
export enum TextAreaSizes {
    Small = 'Small',
    Medium = 'Medium',
    Large = 'Large',
}
export class TextArea extends PureComponent<TextAreaProps> {
    static Sizes = TextAreaSizes
    static defaultProps = {
        hasError: false,
        hasWarning: false,
        disabled: false,
    }
    render(): React.ReactNode {
        const { hasError, disabled, value, onFocus, onClick, onChange, size, placeholder, hasWarning, autoFocus, autoComplete, inputRef, className, allowResize, ...props } = this.props
        const events = {
            onFocus,
            onClick,
            onChange,
        }
        const errorStyle = hasError && disabled !== true ? style.error : null
        const warningStyle = hasWarning && disabled !== true ? style.warning : null
        let classSize = style.small
        switch (size) {
            case TextArea.Sizes.Large:
                classSize = style.large
                break
            case TextArea.Sizes.Medium:
                classSize = style.medium
                break
            case TextArea.Sizes.Small:
                classSize = style.small
                break
        }
        return (
            <div className={[style.textArea, errorStyle].join(' ')}>
                <textarea
                    {...props}
                    ref={inputRef}
                    {...events}
                    value={value}
                    className={[style.input, errorStyle, warningStyle, classSize, className, allowResize ? style.allowResize : ''].join(' ')}
                    disabled={disabled}
                    placeholder={placeholder}
                    autoComplete={autoComplete ? autoComplete : 'off'}
                    data-1p-ignore
                    autoFocus={autoFocus}
                    data-test={this.props['data-test']}
                />
            </div>
        )
    }
}
export default TextArea

import { ExecutionRequest, ExecutionResponse, Page, WorkflowCreateRequest, WorkflowResponse, WorkflowResponseList, WorkflowStartRequest, WorkflowUpdateRequest } from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Workflows
 */
export class WorkflowsApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }

    //Methods

    /**
     * Start the workflow to create an Estate case
     * workflow
     **/

    async startEstateWorkflow(body: WorkflowStartRequest): Promise<WorkflowResponse> {
        try {
            const res: AxiosResponse<WorkflowResponse> = await this.post<WorkflowResponse, WorkflowStartRequest>(`/estates/workflow`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Delete a workflow
     * ${workflowId}
     **/

    async deleteWorkflow(workflowId: string): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.delete<void>(`/workflows/${workflowId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Update a workflow
     * ${workflowId}
     **/

    async updateWorkflow(workflowId: string, body: WorkflowUpdateRequest): Promise<WorkflowResponse> {
        try {
            const res: AxiosResponse<WorkflowResponse> = await this.patch<WorkflowResponse, WorkflowUpdateRequest>(`/workflows/${workflowId}`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Get a workflow
     * ${workflowId}
     **/

    async getWorkflow(workflowId: string): Promise<WorkflowResponse> {
        try {
            const res: AxiosResponse<WorkflowResponse> = await this.get<WorkflowResponse>(`/workflows/${workflowId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Create a workflow
     *
     **/

    async createWorkflow(body: WorkflowCreateRequest): Promise<WorkflowResponse> {
        try {
            const res: AxiosResponse<WorkflowResponse> = await this.post<WorkflowResponse, WorkflowCreateRequest>(`/workflows/`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * List workflows
     *
     **/

    async listWorkflows(page?: Page, filter?: object, search?: string, sort?: string): Promise<WorkflowResponseList> {
        try {
            const res: AxiosResponse<WorkflowResponseList> = await this.get<WorkflowResponseList>(`/workflows/`, {
                params: {
                    page,
                    filter,
                    search,
                    sort,
                },
            })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Get estate workflow
     * ${estateId}/workflow
     **/

    async getEstateWorkflow(estateId: string): Promise<ExecutionResponse> {
        try {
            const res: AxiosResponse<ExecutionResponse> = await this.get<ExecutionResponse>(`/estates/${estateId}/workflow`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Execute estate workflow
     * ${estateId}/workflow
     **/

    async executeEstateWorkflow(estateId: string, body: ExecutionRequest): Promise<ExecutionResponse> {
        try {
            const res: AxiosResponse<ExecutionResponse> = await this.patch<ExecutionResponse, ExecutionRequest>(`/estates/${estateId}/workflow`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

import { AuthConfigurationResponse, LoginRequest, LoginResponse } from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Authentication
 */
export class AuthenticationApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * Destroy the authentication token (JWT)
     * logoff
     **/

    async logoff(): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.delete<void>(`/auth/logoff`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Request an authorization token (JWT) to use the API
     * login
     **/

    async login(body: LoginRequest): Promise<LoginResponse> {
        try {
            const res: AxiosResponse<LoginResponse> = await this.post<LoginResponse, LoginRequest>(`/auth/login`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Request the authentication sso configuration
     *
     **/

    async getAuthConfig(): Promise<AuthConfigurationResponse> {
        try {
            const res: AxiosResponse<AuthConfigurationResponse> = await this.get<AuthConfigurationResponse>(`/auth/`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

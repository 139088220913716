import {
    MyTasksPhasesNamesResponse, // Type import MyTasksPhasesNamesResponse
    MyTasksNamesResponse, // Type import MyTasksNamesResponse
    MyTaskResponseList, // Type import MyTaskResponseList
    Page, // Type Pagination of list
} from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api, ResponseWithHeader } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Org-tasks
 */
export class OrgTasksApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * List only names of tasks by org
     * names/default
     **/

    async listOrgDefaultTaskNames(page?: Page, filter?: object, search?: string, sort?: string): Promise<MyTasksNamesResponse> {
        try {
            const res: AxiosResponse<MyTasksNamesResponse> = await this.get<MyTasksNamesResponse>(`/tasks/names/default`, { params: { page, filter, search, sort } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * List only phases names of tasks by org
     * names
     **/

    async listOrgTasksPhasesNames(page?: Page, filter?: object, search?: string, sort?: string): Promise<MyTasksPhasesNamesResponse> {
        try {
            const res: AxiosResponse<MyTasksPhasesNamesResponse> = await this.get<MyTasksPhasesNamesResponse>(`/phases/names`, { params: { page, filter, search, sort } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * List only names of tasks by org
     * names
     **/

    async listOrgTasksNames(page?: Page, filter?: object, search?: string, sort?: string): Promise<MyTasksNamesResponse> {
        try {
            const res: AxiosResponse<MyTasksNamesResponse> = await this.get<MyTasksNamesResponse>(`/tasks/names`, { params: { page, filter, search, sort } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Download Exported Org Tasks
     * download/${fileId}
     **/

    async downloadExportedOrgTasks(fileId: string): Promise<Buffer> {
        try {
            const res: AxiosResponse<Buffer> = await this.get<Buffer>(`/tasks/download/${fileId}`, { responseType: 'arraybuffer', params: {} })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * List tasks by org
     *
     **/

    async listOrgTasks(page?: Page, filter?: object, search?: string, sort?: string): Promise<Buffer | MyTaskResponseList> {
        try {
            const res: AxiosResponse<Buffer | MyTaskResponseList> = await this.get<Buffer | MyTaskResponseList>(`/tasks/`, {
                responseType: this.api.defaults.headers.accept === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ? 'arraybuffer' : undefined,
                params: { page, filter, search, sort },
            })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

import React, { Fragment } from 'react'
import style from './style.module.scss'
import CategorySidebar from '../../atoms/CategorySidebar'
import SectionSidebar from '../../atoms/SectionSidebar'
import { GenericAttributes } from '../../types'

export interface SidebarGeneralSectionItem {
    label: string
}
export interface SidebarGeneralSection {
    title: string
    items: SidebarGeneralSectionItem[]
}
export interface SelectedItem {
    section: number
    item: number
}
type SidebarGeneralProps = {
    onSelect: (selected: SelectedItem) => void
    selected?: SelectedItem
    sections: SidebarGeneralSection[]
} & GenericAttributes

export const SidebarGeneral = ({ onSelect, selected, sections = [], ...props }: SidebarGeneralProps) => {
    return (
        <div {...props} data-test={props['data-test']} className={[style.sidebarGeneral].join(' ')}>
            {sections?.map(({ title, items }, idxSec) => (
                <Fragment key={idxSec}>
                    <div className={style.categorySidebars}>
                        <CategorySidebar data-test={`${props['data-test']}.categories.${idxSec}`}>{title}</CategorySidebar>
                    </div>
                    <ul className={style.list} role={'listbox'} tabIndex={0}>
                        {items.map((label, index) => {
                            return (
                                <li key={`${label}.${index}.key`} className={style.listItem} role={'option'}>
                                    <SectionSidebar
                                        data-test={`${props['data-test']}.categories.${idxSec}.sections.${index}`}
                                        onClick={() =>
                                            onSelect({
                                                section: idxSec,
                                                item: index,
                                            })
                                        }
                                        selected={selected?.section === idxSec && selected?.item === index}>
                                        {label}
                                    </SectionSidebar>
                                </li>
                            )
                        })}
                    </ul>
                </Fragment>
            ))}
        </div>
    )
}

export default React.memo(SidebarGeneral)

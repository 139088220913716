import React, { PureComponent, ReactNode, Ref } from 'react'
import style from './style.module.scss'
import TextField, { TextFieldTypes } from '../../atoms/TextField'
import TextArea, { TextAreaSizes } from '../../atoms/TextArea'
import Icon, { IconTypes } from '../../particles/Icon'
import Tooltip from '../../organisms/Tooltip'
import { GenericAttributesWithRef } from '../../types'

type TooltipContent = {
    content: string
}

type FieldProps = {
    maxLength?: number
    name?: string
    label?: string
    bottomMessage?: string
    disabled?: boolean
    hasError?: boolean
    hasWarning?: boolean
    value?: string
    mask?: string | (string | RegExp)[]
    maskChar?: string
    tooltipInfo?: string | TooltipContent
    placeholder?: string
    icon?: IconTypes
    type?: TextFieldTypes | typeof Field.Types.TextArea
    size?: TextAreaSizes
    rightLabel?: ReactNode
    required?: boolean
    onChange?: (ev: React.ChangeEvent) => void
    onFocus?: (ev: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    onBlur?: (ev: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    onClick?: (ev: React.MouseEvent<HTMLElement>) => void
    onClickIcon?: (ev: React.MouseEvent<HTMLElement>) => void
    onKeydown?: (ev: React.KeyboardEvent<HTMLElement>) => void
    component?: React.ComponentType
    options?: React.Attributes
    componentRef?: React.RefObject<HTMLDivElement>
    autoFocus?: boolean
    autoComplete?: string
    className?: string
    tag?: React.ReactNode
    showIconAriaLabelAsTooltip?: boolean
    fieldIconAriaLabel?: string
    allowResize?: boolean
} & GenericAttributesWithRef<'div', HTMLTextAreaElement | HTMLInputElement>

export class Field extends PureComponent<FieldProps> {
    static Types = { ...TextField.Types, TextArea: 'textArea' } as const
    static IconTypes = TextField.IconTypes
    static Sizes = TextAreaSizes
    static defaultProps = {
        type: Field.Types.Text,
    }

    render(): React.ReactNode {
        const {
            options,
            maskChar,
            placeholder,
            mask,
            component,
            name,
            label,
            bottomMessage,
            tooltipInfo,
            hasError,
            hasWarning,
            disabled,
            type,
            rightLabel,
            icon,
            onFocus,
            onClick,
            onClickIcon,
            onChange,
            onBlur,
            onKeydown,
            value,
            size,
            autoFocus,
            autoComplete,
            inputRef,
            required,
            className,
            tag,
            maxLength,
            fieldIconAriaLabel,
            showIconAriaLabelAsTooltip,
            ...props
        } = this.props

        return (
            <div
                {...props}
                data-test={`${this.props['data-test']}`}
                ref={this.props.componentRef}
                className={[style.field, hasError ? style.withError : '', hasWarning ? style.withWarning : ''].join(' ')}>
                <label htmlFor={name} data-test={`${this.props['data-test']}.label`}>
                    {required ? (
                        <span className={style.required} aria-hidden={true}>
                            *
                        </span>
                    ) : undefined}
                    {label}
                    {tooltipInfo && !bottomMessage ? (
                        <Tooltip data-test={`${this.props['data-test']}.label.tooltip`} tooltipContent={(tooltipInfo as TooltipContent).content || tooltipInfo || ''}>
                            <Icon size={15} type={IconTypes.Info} />
                        </Tooltip>
                    ) : undefined}
                </label>
                <div className={style.textField}>
                    {type !== Field.Types.TextArea ? (
                        <TextField
                            id={name}
                            required={required}
                            inputRef={inputRef as Ref<HTMLInputElement>}
                            name={name}
                            value={value}
                            disabled={disabled}
                            mask={mask}
                            maxLength={maxLength}
                            maskChar={maskChar}
                            placeholder={placeholder}
                            hasError={hasError}
                            hasWarning={hasWarning}
                            rightLabel={rightLabel}
                            icon={icon}
                            onFocus={onFocus}
                            onClick={onClick}
                            onClickIcon={onClickIcon}
                            onChange={onChange}
                            onKeydown={onKeydown}
                            onBlur={onBlur}
                            component={component}
                            options={options}
                            autoFocus={autoFocus}
                            // FIXME: find a way to disable autocomplete without changing the type from `text` to `search`
                            type={autoComplete === 'on' ? type || TextFieldTypes.Text : type === TextFieldTypes.Text ? TextFieldTypes.Search : type}
                            autoComplete={autoComplete}
                            data-test={`${this.props['data-test']}.textField`}
                            className={className}
                            tag={tag}
                            aria-describedby={`bottomMessage.${name}`}
                            aria-invalid={hasError || hasWarning}
                            aria-required={required}
                            min={(props as Partial<HTMLInputElement>).min}
                            max={(props as Partial<HTMLInputElement>).max}
                            showIconAriaLabelAsTooltip={showIconAriaLabelAsTooltip}
                            iconAriaLabel={fieldIconAriaLabel || icon}
                        />
                    ) : (
                        <TextArea
                            id={name}
                            inputRef={inputRef as Ref<HTMLTextAreaElement>}
                            placeholder={placeholder}
                            name={name}
                            size={size}
                            hasWarning={hasWarning}
                            hasError={hasError}
                            disabled={disabled}
                            value={value}
                            maxLength={maxLength}
                            onFocus={onFocus}
                            onClick={onClick}
                            onChange={onChange}
                            autoFocus={autoFocus}
                            autoComplete={autoComplete}
                            data-test={`${this.props['data-test']}.textArea`}
                            className={className}
                            aria-describedby={`bottomMessage.${name}`}
                            aria-invalid={hasError || hasWarning}
                            aria-required={required}
                            allowResize={props.allowResize}
                        />
                    )}
                </div>
                {hasError || hasWarning || !tooltipInfo ? (
                    <span id={`bottomMessage.${name}`} aria-live={'polite'} data-test={`${this.props['data-test']}.bottomMessage`} className={style.bottomMessage}>
                        {bottomMessage}&nbsp;
                    </span>
                ) : tooltipInfo ? (
                    <Tooltip data-test={`${this.props['data-test']}.bottomMessage.tooltip`} tooltipContent={(tooltipInfo && (tooltipInfo as TooltipContent).content) || tooltipInfo || ''}>
                        <span className={style.bottomMessage}>{bottomMessage}&nbsp;</span>
                    </Tooltip>
                ) : null}
            </div>
        )
    }
}

export default Field

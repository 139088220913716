import React, { useMemo } from 'react'
import style from './style.module.scss'
import AvatarText from '../../atoms/AvatarText'
import Counter from '../../atoms/Counter'
import { GenericAttributes } from '../../types'

export interface AvatarsGroupItem {
    img: string
    name: string
}
type AvatarsGroupProps = {
    list: AvatarsGroupItem[]
    max?: number
} & GenericAttributes

export const AvatarsGroup = ({ list, max = 5, ...props }: AvatarsGroupProps) => {
    const components = useMemo(() => {
        const newComponents: JSX.Element[] = []
        if (list) {
            if (list.length > max) {
                newComponents.push(<Counter data-test={`${props['data-test']}.counter`} key='Counter' count={list.length} />)
            }
            for (let i = 0; i < list.length && i < max && newComponents.length < max; i++) {
                newComponents.push(<AvatarText data-test={`${props['data-test']}.avatars.${i}`} key={i} img={list[i].img} text={list[i].name} />)
            }
        }
        return newComponents
    }, [list, max])

    return (
        <div {...props} data-test={`${props['data-test']}`} className={[style.avatarsGroup].join(' ')}>
            {components}
        </div>
    )
}

export default React.memo(AvatarsGroup)

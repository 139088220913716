import { ActivitiesResponse, Page } from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Estate-activities
 */
export class EstateActivitiesApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * List activities
     * ${estateId}/activities
     **/

    async listEstateActivities(estateId: string, page?: Page, filter?: object, sort?: string, search?: string, lang?: string): Promise<ActivitiesResponse> {
        try {
            const res: AxiosResponse<ActivitiesResponse> = await this.get<ActivitiesResponse>(`/estates/${estateId}/activities`, { params: { page, filter, sort, search, lang } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

import { AccountRequest, AccountResponse, AccountsResponse, Page } from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Accounts
 */
export class AccountsApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * Get an individual account
     * ${estateId}/accounts/${accountId}
     **/

    async getAccount(estateId: string, accountId: string): Promise<AccountResponse> {
        try {
            const res: AxiosResponse<AccountResponse> = await this.get<AccountResponse>(`/estates/${estateId}/accounts/${accountId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Delete an account
     * ${estateId}/accounts/${accountId}
     **/

    async deleteAccount(estateId: string, accountId: string): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.delete<void>(`/estates/${estateId}/accounts/${accountId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Update an account
     * ${estateId}/accounts/${accountId}
     **/

    async updateAccount(estateId: string, accountId: string, body: AccountRequest): Promise<AccountResponse> {
        try {
            const res: AxiosResponse<AccountResponse> = await this.patch<AccountResponse, AccountRequest>(`/estates/${estateId}/accounts/${accountId}`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * List accounts
     * ${estateId}/accounts
     **/

    async listAccounts(estateId: string, page?: Page, filter?: object, sort?: string): Promise<AccountsResponse> {
        try {
            const res: AxiosResponse<AccountsResponse> = await this.get<AccountsResponse>(`/estates/${estateId}/accounts`, { params: { page, filter, sort } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Create an account
     * ${estateId}/accounts
     **/

    async createAccount(estateId: string, body: AccountRequest): Promise<AccountResponse> {
        try {
            const res: AxiosResponse<AccountResponse> = await this.post<AccountResponse, AccountRequest>(`/estates/${estateId}/accounts`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

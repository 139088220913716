import { Page, RoleCreateRequest, RoleResponse, RoleResponseList, RoleUpdateRequest } from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Roles
 */
export class RolesApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * Delete a role
     * ${orgId}/roles/${roleId}
     **/

    async deleteRole(roleId: string, orgId: string): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.delete<void>(`/orgs/${orgId}/roles/${roleId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Update a specific role
     * ${orgId}/roles/${roleId}
     **/

    async updateRole(roleId: string, orgId: string, body: RoleUpdateRequest): Promise<RoleResponse> {
        try {
            const res: AxiosResponse<RoleResponse> = await this.patch<RoleResponse, RoleUpdateRequest>(`/orgs/${orgId}/roles/${roleId}`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Get an individual role
     * ${orgId}/roles/${roleId}
     **/

    async getRole(roleId: string, orgId: string): Promise<RoleResponse> {
        try {
            const res: AxiosResponse<RoleResponse> = await this.get<RoleResponse>(`/orgs/${orgId}/roles/${roleId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Create a role
     * ${orgId}/roles
     **/

    async createRole(orgId: string, body: RoleCreateRequest): Promise<RoleResponse> {
        try {
            const res: AxiosResponse<RoleResponse> = await this.post<RoleResponse, RoleCreateRequest>(`/orgs/${orgId}/roles`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * List roles
     * ${orgId}/roles
     **/

    async listRoles(orgId: string, page?: Page, filter?: object, search?: string, sort?: string): Promise<RoleResponseList> {
        try {
            const res: AxiosResponse<RoleResponseList> = await this.get<RoleResponseList>(`/orgs/${orgId}/roles`, { params: { page, filter, search, sort } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

import React from 'react'
import { connect } from 'react-redux'
import { ActionCard, ButtonPrimary, ButtonSuccess } from '../../ds/components'
import PropTypes from 'prop-types'
import history from './history'

import * as Sentry from '@sentry/react'

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = { hasError: false }
    }
    static propTypes = {
        error: PropTypes.any,
        onErrorClick: PropTypes.func.isRequired,
    }
    componentDidCatch(error) {
        // Display fallback UI
        this.setState({ hasError: true, error: error })
        // You can also log the error to an error reporting service
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            const error = this.state.error ? (this.state.error.err ? this.state.error.err.toString() : this.state.error.toString()) : 'Error'

            Sentry.captureException(error)
            if (error.startsWith('ChunkLoadError:')) {
                return (
                    <ActionCard
                        buttons={[
                            <ButtonSuccess
                                onClick={() => {
                                    let help_link = 'https://support.estateably.com/hc/en-ca'
                                    window.open(help_link, '_blank')
                                }}
                                key='button1'
                                size={'medium'}>
                                Contact us
                            </ButtonSuccess>,
                            <ButtonPrimary
                                onClick={() => {
                                    location.reload(true)
                                }}
                                key='button2'
                                size={'medium'}>
                                Refresh
                            </ButtonPrimary>,
                        ]}
                        title='Sorry something went wrong'
                        subtitle={'Please contact Estateably support or try again later'}
                        onClose={() => {
                            history.push('/')
                            location.reload(true)
                        }}
                    />
                )
            }
            return (
                <ActionCard
                    buttons={[
                        <ButtonSuccess
                            onClick={() => {
                                let help_link = 'https://support.estateably.com/hc/en-ca'
                                window.open(help_link, '_blank')
                            }}
                            key='button1'
                            size={'medium'}>
                            Contact us
                        </ButtonSuccess>,
                    ]}
                    title='Sorry something went wrong'
                    subtitle={'Please contact Estateably support or try again later'}
                    onClose={() => {
                        history.push('/')
                        location.reload(true)
                    }}
                />
            )
        }
        return this.props.children
    }
}

const mapStateToProps = (state) => ({
    error: state.menu.error,
})
const mapDispatchToProps = (dispatch) => ({
    onErrorClick: () => {
        // history.push('/login')
        dispatch({ type: 'ERROR_CLICK' })
    },
})
export default connect(mapStateToProps, mapDispatchToProps)(ErrorBoundary)

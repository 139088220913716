import React, { PureComponent } from 'react'
// eslint-disable-next-line css-modules/no-unused-class
import style from './style.module.scss'
import { GenericAttributes } from '../../types'

export enum StatusCategories {
    Complete = 'Complete',
    General = 'General',
    Reject = 'Reject',
    InProgress = 'InProgress',
    Incomplete = 'Incomplete',
}
type StatusProps = {
    category: StatusCategories
    selected: boolean
} & GenericAttributes
export class Status extends PureComponent<StatusProps> {
    static statusCategories = StatusCategories
    static defaultProps = {
        category: StatusCategories.General,
        selected: false,
    }
    render(): React.ReactNode {
        const { category, selected, ...props } = this.props
        return <div {...props} className={[style.status, selected ? style.selected : null, style[category.toLowerCase()]].join(' ')} data-test={this.props['data-test']} />
    }
}
export default Status

import React, { useCallback, useEffect, useState } from 'react'
import { Router } from 'react-router-dom'
import { connect } from 'react-redux'
import { acceptInvite, checkSession, logoff } from '../../Login/actions'
import history from '.././history'
import LabelsLoader from '../../../templates/LabelsLoader'
import queryString from 'query-string'
import { PermissionsProvider } from '../../../utils/Permissions'
/* global zE */
/* global window */
import FeatureFlags from '../../../utils/FeatureFlags'
import { LoadingSpinner } from '../../../ds/components'
import LogoutModal from '../LogoutModal'
import { Theme, UserInfo, UserInfoProps } from '../../../EstateablyContexts'
import { TimeCount } from '../TimeCount'
import { BillingPlan, Organization, PlanPrice } from '../../../system/api/types'
import { getCompanyLogo } from '../../../utils/DesignSystem/branding'

const AuthRoutes = React.lazy(() => import('./AuthRoutes'))
const PublicRoutes = React.lazy(() => import('./PublicRoutes'))

const loginRoutes = ['/login', '/register', '/forgot-password', '/reset-password']
const publicRoutes = ['/digitalSignDocument']

declare global {
    interface Window {
        zESettings: any
    }
}
type UserLoginInfo = {
    token: string
    name: string
    id: string
    email: string
    phoneNumber: string
    profilePic: Blob
    org: Organization & {
        userRole: string
        id: string
        currentPlan?: BillingPlan
    }
    theme: Theme
}
type RouterConfigProps = {
    acceptInvite: (hash: string) => Promise<unknown>
    checkSession: (redirectState?: string) => Promise<unknown>
    logoff: () => void
    logged: boolean
    logout?: boolean
    theme: Theme
    features: { [key: string]: number }
    prices: PlanPrice[]
    config?: {
        flinksIframeUrl?: string
    }
    userInfo?: UserLoginInfo
    lastLogin?: string
    language?: string
}
/**
 * React component to configure the base of the routes for the app
 * @param checkSession
 * @param logged
 * @param theme
 * @param userInfo
 * @param lastLogin
 * @param language
 * @param features
 * @param prices
 * @param acceptInvite
 * @param logout
 * @param logoff
 * @param config
 * @constructor
 */
const RouterConfig = ({ checkSession, logged, theme, userInfo, lastLogin, language, features, prices, acceptInvite, logout, logoff, config }: RouterConfigProps) => {
    const [isValidateLoginRequired, setIsValidateLoginRequired] = useState(true)
    const [logoutStatus, setLogoutStatus] = useState(false)
    const [userProvideInfo, setUserProvideInfo] = useState<UserInfoProps>()

    useEffect(() => {
        const pathName = history.location.pathname
        /**
         *  Run the check if are any other including the login
         *  if the user is on the login form, no need to check if they are logged in, as they are going to do so
         *  if the user is not on the login, and they are not logged in (`logged` is `false`)
         *    then we need to "check the session"
         */
        if (!logged && isValidateLoginRequired && loginRoutes.filter((_) => _ !== '/login').indexOf(pathName) == -1) {
            checkSession(pathName)
                .then(() => {
                    // if the check session passed as expected, then we can set the flag to `false`
                    setIsValidateLoginRequired(false)
                })
                .catch(() => {
                    if (publicRoutes.indexOf(pathName) == -1 && loginRoutes.indexOf(pathName) == -1) {
                        setLogoutStatus(true)
                    }
                })
                .finally(() => {
                    // either the check session passed, or we are in an error state. regardless, the validation of the login is not required anymore
                    setIsValidateLoginRequired(false)
                })
        } else {
            // if there was no need to check the session, then we can set the flag to `false`
            setIsValidateLoginRequired(false)
        }
    }, [logged, history.location?.search, isValidateLoginRequired])
    useEffect(() => {
        if (history.location?.search) {
            const values = queryString.parse(history.location.search)
            if (values.invitehash) {
                setTimeout(() => acceptInvite(values.invitehash as string), 1000)
            }
        }
    }, [history.location?.search])
    const addCustomThemeCSS = (theme: { name: string }) => {
        let styleTheme = document.getElementById('styleTheme')
        if (!styleTheme) styleTheme = document.createElement('style')
        styleTheme.id = 'styleTheme'
        let styleString = ''
        // In case Zendez is not loaded yet, call again when receive the Event comming from index.html
        window.addEventListener('zE:loaded', () => addCustomThemeCSS(theme))
        const updateZeSettings = (data: unknown) => {
            if (typeof zE != 'undefined') {
                zE('webWidget', 'updateSettings', data)
            } else {
                window.zESettings = data
            }
        }

        switch (theme.name) {
            case 'dark':
                styleString = `
                .customTheme{
                    --leftmenu-color-background: #4a4a4a;
                    --leftmenu-color: #7b7b7b;
                    --leftmenu-color-selected-background: ;
                    --leftmenu-color-selected: #FFFFFF;

                    --leftmenu-color-submenu: #e1e0dd;
                    --leftmenu-color-submenu-background: #3b3b3b;
                    --leftmenu-color-submenu-selected-mark: #ffffff;
                }
            `
                updateZeSettings({
                    webWidget: {
                        position: { vertical: 'top' },
                        color: { theme: '#0F322B' },
                    },
                })
                break
            case 'light':
                styleString = `
                .customTheme{
                    --leftmenu-color-background: #ffffff;
                    --leftmenu-color: #b5b5b5;
                    --leftmenu-color-selected-background: ;
                    --leftmenu-color-selected: #4a4a4a;

                    --leftmenu-color-submenu: #4a4a4a;
                    --leftmenu-color-submenu-background: #f5f8fa;
                    --leftmenu-color-submenu-selected-mark: #4a4a4a;
                }
            `
                updateZeSettings({
                    webWidget: {
                        position: { vertical: 'top' },
                        color: {
                            theme: '#0F322B',
                        },
                    },
                })
                break
            case 'blue':
            default:
                styleString = `
                .customTheme{
                    --leftmenu-color-background: #0072db;
                    --leftmenu-color: #19adf3;
                    --leftmenu-color-selected-background: ;
                    --leftmenu-color-selected: #FFFFFF;

                    --leftmenu-color-submenu: #e1e0dd;
                    --leftmenu-color-submenu-background: #0066c5;
                    --leftmenu-color-submenu-selected-mark: #ffffff;
                }
            `
                updateZeSettings({
                    webWidget: {
                        position: { vertical: 'top' },
                        color: { theme: '#0F322B' },
                    },
                })
        }
        styleTheme.appendChild(document.createTextNode(styleString))
        document.head.appendChild(styleTheme)
        if (theme.name) document.body.className = theme.name
    }

    useEffect(() => {
        addCustomThemeCSS(theme)
    }, [theme])
    useEffect(() => {
        const companyLogo = getCompanyLogo('logotype')
        setUserProvideInfo({
            config: config,
            token: userInfo?.token,
            user: userInfo
                ? {
                      name: userInfo?.name,
                      id: userInfo?.id,
                      email: userInfo?.email,
                      phoneNumber: userInfo?.phoneNumber,
                      profilePic: userInfo?.profilePic,
                      lastLogin: lastLogin,
                      language: language,
                  }
                : undefined,
            org: userInfo?.org,
            theme: {
                ...theme,
                leftMenu: { logo: theme?.leftMenu?.logo || companyLogo },
                dashboard: { logo: theme?.leftMenu?.logo || companyLogo, title: theme?.dashboard?.title },
            },
            features: features,
            prices: prices,
        })
        if (userInfo) {
            FeatureFlags.setProperties({
                user_name: userInfo.name,
                user_email: userInfo.email,
                userRole: userInfo.org.userRole,
                sso_enabled: (userInfo.org.sso && userInfo.org.sso.enabled) || false,
                sso_strategy: (userInfo.org.sso && userInfo.org.sso.strategy) || '',
                theme_name: (userInfo.theme && userInfo.theme.name) || '',
                org_name: userInfo.org.name,
                org_domain: userInfo.org.domain || '',
                billingPlanType: userInfo?.org.currentPlan?.id,
                billingPlanName: userInfo.org.currentPlan?.name,
                billingPlanCountry: userInfo.org.currentPlan?.country,
                location: userInfo.org.location,
                billingPlanLegacy: userInfo.org.currentPlan?.legacy,
            })
        }
    }, [userInfo, lastLogin, language, features, prices, config])
    useEffect(() => {
        addCustomThemeCSS(theme)
    }, [theme])

    /**
     * Function used to refresh session that is passed to Admin section to refresh prices/products set in Billing Management component
     */
    const refreshContext = useCallback(() => {
        const pathName = history.location.pathname
        return checkSession(pathName)
    }, [checkSession, history])

    /**
     * while `isValidateLoginRequired` is true, we are calling the `checkSession` action
     * that is calling the `/auth/login` endpoint
     * so we need to show a loading spinner
     */
    if (isValidateLoginRequired) {
        return <LoadingSpinner />
    }

    if (logout || logoutStatus) {
        return <LogoutModal logoff={logoff} />
    }
    return (
        <LabelsLoader userId={userInfo && userInfo.id} ns={'default'} showError={true}>
            <Router history={history}>
                {userProvideInfo && logged ? (
                    <UserInfo.Provider value={{ ...userProvideInfo, events: { checkSession } }}>
                        <PermissionsProvider userId={userProvideInfo.user?.id} orgId={userProvideInfo?.org?.id}>
                            <TimeCount logoff={logoff} onRenewSession={checkSession}></TimeCount>
                            <AuthRoutes logoff={logoff} refreshContext={refreshContext} />
                        </PermissionsProvider>
                    </UserInfo.Provider>
                ) : (
                    <PublicRoutes />
                )}
            </Router>
        </LabelsLoader>
    )
}

const mapDispatch = {
    checkSession,
    acceptInvite,
    logoff,
}

const mapStateToProps = (state: any) => {
    return {
        logout: state.context.logout,
        logged: state.context.logged,
        userInfo: state.context.userInfo,
        lastLogin: state.context.lastLogin,
        theme: state.context.theme,
        features: state.context.features,
        prices: state.context.prices,
        config: state.context.config,
        language: state.context.language,
    }
}

export default connect(mapStateToProps, mapDispatch)(RouterConfig)

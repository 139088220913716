import React from 'react'
import style from './style.module.scss'
import Segment from '../../atoms/Segment'
import { GenericAttributes } from '../../types'

type SegmentBarProps<T> = {
    segments?: T[]
    onChange?: (index: number, title: T) => void
    selectedIndex?: number
} & GenericAttributes

export const SegmentBar = <T,>({ selectedIndex = 0, segments, onChange, ...props }: SegmentBarProps<T>) => {
    return (
        <div
            {...props}
            data-test={props['data-test']}
            className={[style.segmentBar, props.className ?? ''].join(' ')}
            role='tablist'
            aria-orientation='horizontal'
            id={`id-${props['data-test']}-tablist-${selectedIndex}`}
            aria-labelledby={`id-${props['data-test']}-tablist-${selectedIndex}`}>
            {segments?.map((title, idx) => (
                <Segment data-test={`${props['data-test']}.segments.${idx}`} label={title} key={idx} onClick={() => onChange && onChange(idx, title)} selected={selectedIndex === idx} />
            ))}
        </div>
    )
}
export default React.memo(SegmentBar) as typeof SegmentBar

import React, { PureComponent } from 'react'
import Message from './images/Message.svg'
import NoNotification from './images/NoNotification.svg'
import Error from './images/Error.svg'
import Files from './images/Files.svg'
import Lamp from './images/Lamp.svg'
import DuplicateDocument from './images/DuplicateDocument.svg'
import Image from './images/Image.svg'
import EmptyStateMetrics from './images/EmptyStateMetrics.svg'
import EmptyStateDocument from './images/EmptyStateDocument.svg'
import EmptyStateDistribution from './images/EmptyStateDistribution.svg'
import Search from './images/Search.svg'
import EmptyStateTasks from './images/EmptyStateTasks.svg'
import EmptyStateContacts from './images/EmptyStateContacts.svg'
import EmptyStateInventory from './images/EmptyStateInventory.svg'
import EmptyStateArchived from './images/EmptyStateArchived.svg'
import EmptyStateEstateFile from './images/EmptyStateEstateFile.svg'
import EmptyStateBackground from './images/EmptyStateBackground.svg'
import EmptyStateWallet from './images/EmptyStateWallet.svg'
import Pattern from './images/Pattern.svg'
import TutorialPhoneNumber from './images/TutorialPhoneNumber.svg'
import TutorialDone from './images/TutorialDone.svg'
import EmptyStateEstateAccount from './images/EmptyStateEstateAccount.svg'
import BigPattern from './images/BigPattern.svg'
import Subtask from './images/Subtask.svg'
import EmptyStateCompensationFee from './images/EmptyStateCompensationFee.svg'
import Visa from './images/Visa.svg'
import MasterCard from './images/MasterCard.svg'
import Maestro from './images/Maestro.svg'
import Citrus from './images/Citrus.svg'
import Information from './images/Information.svg'
import UpcomingActivities from './images/UpcomingActivities.svg'
import AddNotes from './images/AddNotes.svg'
import FileDetails from './images/FileDetails.svg'
import FilePlan from './images/FilePlan.svg'
import Collaborators from './images/Collaborators.svg'
import UnlockFullEstateAdministration from './images/UnlockFullEstateAdministration.svg'
import UnlockVault from './images/UnlockVault.svg'
import UnlockVaultFR from './images/UnlockVaultFR.svg'
import VisaPaymentMethod from './images/VisaPaymentMethod.svg'
import MastercardPaymentMethod from './images/MastercardPaymentMethod.svg'
import AmexPaymentMethod from './images/AmexPaymentMethod.svg'
import Plus from './images/Plus.svg'
import PlusWhite from './images/PlusWhite.svg'
import Trash from './images/Trash.svg'
import TrashGrey from './images/TrashGrey.svg'
import Save from './images/Save.svg'
import Check from './images/Check.svg'
import NoticeIllustration from './images/Notice Illustration.svg'
import NoticeIllustrationPublished from './images/Notice Illustration-published.svg'
import NoticeIllustrationError from './images/Notice Illustration-error.svg'
import ArrowLeft from './images/ArrowLeft.svg'
import InfoCircle from './images/InfoCircle.svg'
import Close from './images/Close.svg'
import CheckVerified from './images/CheckVerified.svg'
import AlertCircle from './images/AlertCircle.svg'
import CheckCircle from './images/CheckCircle.svg'
import Expand from './images/Expand.svg'

type IllustrationProps = {
    size: number
    type: string
    imgProps?: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>
    'data-test'?: string
}

export class Illustration extends PureComponent<IllustrationProps> {
    static types = Object.freeze({
        'Message': Message,
        'NoNotification': NoNotification,
        'Error': Error,
        'Files': Files,
        'Lamp': Lamp,
        'DuplicateDocument': DuplicateDocument,
        'Image': Image,
        'EmptyStateMetrics': EmptyStateMetrics,
        'EmptyStateDocument': EmptyStateDocument,
        'EmptyStateDistribution': EmptyStateDistribution,
        'Search': Search,
        'EmptyStateTasks': EmptyStateTasks,
        'EmptyStateContacts': EmptyStateContacts,
        'EmptyStateInventory': EmptyStateInventory,
        'EmptyStateArchived': EmptyStateArchived,
        'EmptyStateEstateFile': EmptyStateEstateFile,
        'EmptyStateBackground': EmptyStateBackground,
        'EmptyStateWallet': EmptyStateWallet,
        'Pattern': Pattern,
        'TutorialPhoneNumber': TutorialPhoneNumber,
        'TutorialDone': TutorialDone,
        'EmptyStateEstateAccount': EmptyStateEstateAccount,
        'BigPattern': BigPattern,
        'Subtask': Subtask,
        'EmptyStateCompensationFee': EmptyStateCompensationFee,
        'Visa': Visa,
        'MasterCard': MasterCard,
        'Maestro': Maestro,
        'Citrus': Citrus,
        'Information': Information,
        'UpcomingActivities': UpcomingActivities,
        'AddNotes': AddNotes,
        'FileDetails': FileDetails,
        'FilePlan': FilePlan,
        'Collaborators': Collaborators,
        'UnlockFullEstateAdministration': UnlockFullEstateAdministration,
        'UnlockVault': UnlockVault,
        'UnlockVaultFR': UnlockVaultFR,
        'VisaPaymentMethod': VisaPaymentMethod,
        'MastercardPaymentMethod': MastercardPaymentMethod,
        'AmexPaymentMethod': AmexPaymentMethod,
        'Plus': Plus,
        'PlusWhite': PlusWhite,
        'Trash': Trash,
        'TrashGrey': TrashGrey,
        'Save': Save,
        'Check': Check,
        'NoticeIllustration': NoticeIllustration,
        'NoticeIllustrationPublished': NoticeIllustrationPublished,
        'NoticeIllustrationError': NoticeIllustrationError,
        'ArrowLeft': ArrowLeft,
        'InfoCircle': InfoCircle,
        'Close': Close,
        'CheckVerified': CheckVerified,
        'AlertCircle': AlertCircle,
        'CheckCircle': CheckCircle,
        'Expand': Expand,
    })
    static defaultProps = {
        type: Illustration.types.Error,
        size: 60,
    }

    render(): React.ReactNode {
        const { type, size, imgProps } = this.props
        return <img data-test={this.props['data-test']} style={{ width: imgProps?.width ?? `${size}px`, height: imgProps?.height ?? `${size}px` }} alt={imgProps?.alt} src={type} />
    }
}
export default Illustration

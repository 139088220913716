import React, { PureComponent } from 'react'
// eslint-disable-next-line css-modules/no-unused-class
import style from './style.module.scss'
import Logic from './logic'
import QuillMentionBlot from './QuillMentionBlot'
import SectionText from '../../atoms/SectionText'
import QuillController from './quillController'
// External imports
import ReactQuill from 'react-quill' // -> https://github.com/zenoamaro/react-quill#using-deltas
import 'react-quill/dist/quill.core.css'
import * as Quill from 'quill'
import { GenericAttributes } from '../../types'

export interface TextAreaAutocompleteOptionComponent {
    component: React.ComponentType
    trigger: string
}

type TextAreaAutocompleteValue = {
    id: string
    label: string
}

export interface TextAreaAutocompleteOption {
    id?: string
    trigger: string
    value: string | TextAreaAutocompleteValue
    props?: React.Attributes
}

type TextAreaAutocompleteProps = {
    triggers?: string[]
    options: TextAreaAutocompleteOption[]
    OptionsComponents?: TextAreaAutocompleteOptionComponent[]
    allowedChars?: RegExp
    defaultValue?: string
    value?: string
    size?: TextAreaAutocompleteSizes
    hasError?: boolean
    bottomMessage?: string
    disabled?: boolean
    maxChars?: number
    minChars?: number
    isolateTriggerCharacter?: boolean
    onFocus?: (range: Quill.RangeStatic, source: Quill.Sources, editor: unknown) => void
    onChange?: (html: string, inputSource: Quill.Sources, quillEditor: unknown) => void
    onSearch?: (textAfter: string, trigger: string, options: TextAreaAutocompleteOption[], renderListCallBack: (asyncFoundData: TextAreaAutocompleteOption[]) => void, triggerCharPos: number) => void
    onBlur?: (previousRange: Quill.RangeStatic, source: Quill.Sources, editor: unknown) => void
    onSelect?: (option: TextAreaAutocompleteOption, callback: (option: TextAreaAutocompleteOption) => void) => void
} & GenericAttributes
type TextAreaAutocompleteState = {
    filteredData: TextAreaAutocompleteOption[]
    isOpen: boolean
    mentionContainerStyle: {
        [key: string]: string
    }
}

export enum TextAreaAutocompleteSizes {
    Small = 'Small',
    Medium = 'Medium',
    Large = 'Large',
}

export class TextAreaAutocomplete extends PureComponent<TextAreaAutocompleteProps, TextAreaAutocompleteState> {
    private suggestionsRef: React.RefObject<HTMLDivElement> = React.createRef()
    private quillRef: React.RefObject<ReactQuill> = React.createRef()
    private editorRef: React.RefObject<HTMLDivElement> = React.createRef()
    private currentMentionCharPos: number
    private cursorPos: number
    private itemNavIndex: number
    private readonly optionsMaxHeight: number
    private triggerCharPos: number
    static Sizes = TextAreaAutocompleteSizes
    static QuillConfig = {
        Theme: undefined,
        Modules: {
            toolbar: false,
            keyboard: {
                bindings: {
                    tab: false,
                },
            },
        },
        Formats: [QuillMentionBlot.blotName, 'bold', 'italic', 'link'],
        Bounds: style.textAreaAutocomplete,
    }
    static defaultProps = {
        size: TextAreaAutocomplete.Sizes.Medium,
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        triggers: ['@'],
        options: [],
        OptionsComponents: [],
        hasError: false,
        disabled: false,
        maxChars: 30,
        minChars: 0,
        isolateTriggerCharacter: true,
        onSelect: (option: TextAreaAutocompleteOption, insertMentionCallback: (option: TextAreaAutocompleteOption) => void): void => {
            // async callback must return async data to be stored in the blot
            return insertMentionCallback(option)
        },
        onSearch: (textAfter: string, trigger: string, mentionsData: TextAreaAutocompleteOption[], renderListCallBack: (data: TextAreaAutocompleteOption[]) => void): void => {
            // async callback must return async data to be stored in the blot
            const filtered = mentionsData.filter((data) => data.trigger === trigger)
            return renderListCallBack(filtered)
        },
    }

    // ================================= UI LIFECYCLE =================================//
    constructor(props: TextAreaAutocompleteProps) {
        super(props)
        this.state = {
            filteredData: props.options || [],
            isOpen: false,
            mentionContainerStyle: {
                top: '0px',
                left: '0px',
                maxHeight: '0px',
            },
        }
        this.currentMentionCharPos = 0
        this.cursorPos = 0
        this.triggerCharPos = 0
        this.itemNavIndex = 0
        this.optionsMaxHeight = 250 // Used for facilitating keyboard navigation with scroll inside the dropdown
    }

    componentDidMount(): void {
        const quill = this.quillRef.current?.getEditor() // Get safe instance
        // SELECT SUGGESTION WITH KEYBOARD
        QuillController.registerBlots()
        QuillController.addKeyboardBindings(quill, this.selectHandler, this.escapeHandler, this.upHandler, this.downHandler)
    }

    // ================================= KEYBOARD HANDLERS =================================//
    selectHandler = (): boolean => {
        if (this.suggestionsIsOpen()) {
            const { filteredData } = this.state
            const optionData = filteredData[this.itemNavIndex]
            this.selectOption(optionData)
            return false
        }
        return true
    }
    escapeHandler = (): boolean => {
        if (this.suggestionsIsOpen()) {
            this.hideSuggestions()
            return false
        }
        return true
    }
    upHandler = (): boolean => {
        if (this.suggestionsIsOpen()) {
            this.prevItem()
            return false
        }
        return true
    }
    downHandler = (): boolean => {
        if (this.suggestionsIsOpen()) {
            this.nextItem()
            return false
        }
        return true
    }
    nextItem = (): void => {
        const { filteredData = [] } = this.state
        const itemNavIndex = (this.itemNavIndex + 1) % filteredData.length
        this.highlightItem(itemNavIndex)
    }
    prevItem = (): void => {
        const { filteredData = [] } = this.state
        const itemNavIndex = (this.itemNavIndex + filteredData.length - 1) % filteredData.length
        this.highlightItem(itemNavIndex)
    }
    highlightItem = (itemNavIndex: number): void => {
        const mentionList = this.suggestionsRef.current || { childNodes: [] }
        // TODO find a better way to guarantee that mentionList.childNodes is iterable
        for (const elem of mentionList.childNodes as any) {
            const mentionItemElement = elem as HTMLElement
            mentionItemElement.classList.remove(style.selected)
        }
        const mentionsListElement = mentionList.childNodes[itemNavIndex] as HTMLElement
        mentionsListElement.classList.add(style.selected)
        this.itemNavIndex = itemNavIndex
        Logic.navigateInScroll(mentionList as HTMLDivElement, itemNavIndex, this.optionsMaxHeight)
    }
    hideSuggestions = (): void => {
        this.setState({
            isOpen: false,
        })
    }
    suggestionsIsOpen = (): boolean => {
        return this.state.isOpen && this.state.filteredData && this.state.filteredData.length > 0
    }
    // ================================= EDITOR HANDLERS =================================//

    handleChange = (html: string, deltaLastChanges: Quill.Delta, inputSource: Quill.Sources, quillEditor: unknown): void => {
        const { triggers, onChange, maxChars, isolateTriggerCharacter, minChars, allowedChars } = this.props
        if (onChange) onChange(html, inputSource, quillEditor)
        const cursorPos = Logic.getCursorPosition(quillEditor)
        if (!cursorPos) {
            this.hideSuggestions()
            return
        }
        this.cursorPos = cursorPos
        const textBeforeCursor = Logic.getTextBeforeCursor(quillEditor, cursorPos, maxChars)
        if (!textBeforeCursor) {
            this.hideSuggestions()
            return
        }
        const { trigger, mentionCharIndex } = Logic.getMentionCharIndex(textBeforeCursor, triggers)
        if (!trigger) {
            this.hideSuggestions()
            return
        }
        const searchComponents = Logic.getSearchComponents(textBeforeCursor, cursorPos, mentionCharIndex, trigger, allowedChars, isolateTriggerCharacter, minChars)
        const lastTwoChars = textBeforeCursor.slice(cursorPos - 1)
        if (!searchComponents || searchComponents.textAfter.match(/[ ]{2,}/) || (!searchComponents.textAfter.trim() && lastTwoChars.match(/[ ]{1,}/))) {
            // Has more than 1 blank space char, close search
            this.hideSuggestions()
            return
        }
        this.handleSearch(searchComponents.textAfter, trigger, searchComponents.triggerCharPos, quillEditor)
    }
    handleSearch = (textAfter: string, trigger: string, triggerCharPos: number, quillEditor: unknown): void => {
        const { onSearch, options } = this.props
        const renderListCallBack = (asyncFoundData: TextAreaAutocompleteOption[]) => {
            if (asyncFoundData !== options) {
                this.setState({ filteredData: asyncFoundData }, () => this.updateSuggestionsPosition(textAfter, trigger, triggerCharPos, quillEditor))
            }
        }
        if (onSearch) onSearch(textAfter, trigger, options, renderListCallBack, triggerCharPos)
    }
    selectOption = (option: TextAreaAutocompleteOption): void => {
        const { onSelect } = this.props
        if (onSelect)
            onSelect(option, (asyncData: TextAreaAutocompleteOption) => {
                if ((asyncData.value as TextAreaAutocompleteValue).label !== undefined) {
                    this.insertMention({ ...asyncData, value: (asyncData.value as TextAreaAutocompleteValue).label })
                } else this.insertMention(asyncData)

                this.hideSuggestions()
            })
    }
    insertMention = (option: TextAreaAutocompleteOption): void => {
        const quill = this.quillRef.current as ReactQuill
        QuillController.insertMention(option, quill, this.triggerCharPos, this.cursorPos)
        this.hideSuggestions()
    }
    // ================================= STYLE MANIPULATION =================================//
    // eslint-disable-next-line
    updateSuggestionsPosition = (textAfter: string, trigger: string, triggerCharPos: number, quillEditor: any): void => {
        const containerPos = quillEditor.getBounds(triggerCharPos, textAfter.length + 1)
        this.triggerCharPos = triggerCharPos
        const triggerCharPosInContainer = quillEditor.getBounds(triggerCharPos)
        const mentionContainer = this.suggestionsRef.current as HTMLDivElement
        const parentContainerWidth = this.editorRef.current?.offsetWidth as number
        const offsetLeft = 0
        const offsetRight = 0
        const positionMeta = Logic.getSuggestionsPosition(offsetLeft, offsetRight, triggerCharPosInContainer, mentionContainer, containerPos, triggerCharPos, parentContainerWidth)
        const mentionContainerStyle = {
            top: '0px',
            left: '0px',
            maxHeight: '0px',
        }
        mentionContainerStyle.top = `${positionMeta.top}px`
        mentionContainerStyle.left = `${positionMeta.left}px`
        const suggestionContainerHeight = Math.min(positionMeta.childrenContentHeight, this.optionsMaxHeight)
        mentionContainerStyle.maxHeight = `${suggestionContainerHeight}px`
        this.setState({
            isOpen: true,
            mentionContainerStyle,
        })
    }
    getClassSize = (): string => {
        const { size } = this.props
        switch (size) {
            case TextAreaAutocomplete.Sizes.Large:
                return style.large
            case TextAreaAutocomplete.Sizes.Medium:
                return style.medium
            case TextAreaAutocomplete.Sizes.Small:
                return style.small
            default:
                return style.small
        }
    }

    // ================================= RENDERS =================================//
    renderMentionContainer(): React.ReactNode {
        const { OptionsComponents = [] } = this.props
        const { filteredData, isOpen, mentionContainerStyle, ...props } = this.state
        return (
            <div
                {...props}
                data-test={`${this.props['data-test']}.options`}
                role={'options'}
                style={{
                    visibility: isOpen ? 'visible' : 'hidden',
                    ...mentionContainerStyle,
                }}
                ref={this.suggestionsRef}
                className={[style.options].join(' ')}>
                {filteredData.map((option, i) => {
                    const _component = OptionsComponents.find((component) => component.trigger === option.trigger)
                    if (_component && _component.component) {
                        const CurrentOptionComponent: React.ComponentType = _component.component
                        return (
                            <div className={style.listItem} key={`${i}`} onClick={() => this.selectOption(option)}>
                                <CurrentOptionComponent {...option.props} data-test={`${this.props['data-test']}.options.${i}`} />
                            </div>
                        )
                    } else {
                        return (
                            <div className={style.listItem} key={`${i}`} onClick={() => this.selectOption(option)}>
                                <SectionText {...option.props} onClick={() => null} data-test={`${this.props['data-test']}.options.${i}`} />
                            </div>
                        )
                    }
                })}
            </div>
        )
    }

    render(): React.ReactNode {
        const { hasError, bottomMessage, disabled, defaultValue, onFocus, onBlur, value } = this.props
        const classSize = this.getClassSize()
        const classDisable = disabled ? style.disabled : null
        const classError = hasError ? style.error : null
        let controlledComponentProps = {}
        if (value !== undefined) {
            controlledComponentProps = { value }
        }
        return (
            <div ref={this.editorRef} className={[style.textAreaAutocomplete].join(' ')} data-test={this.props['data-test']}>
                {this.renderMentionContainer()}
                <ReactQuill
                    {...controlledComponentProps}
                    ref={this.quillRef}
                    className={[style.container, classSize, classDisable, classError].join(' ')}
                    theme={TextAreaAutocomplete.QuillConfig.Theme}
                    modules={TextAreaAutocomplete.QuillConfig.Modules}
                    formats={TextAreaAutocomplete.QuillConfig.Formats}
                    bounds={TextAreaAutocomplete.QuillConfig.Bounds}
                    defaultValue={defaultValue} // Use uncontrolled mode, so we can async filter the list
                    onChange={this.handleChange}
                    readOnly={disabled}
                    onFocus={onFocus}
                    onBlur={onBlur}
                />
                <span className={[style.bottomMessage, classError].join(' ')} data-test={`${this.props['data-test']}.message`}>
                    {bottomMessage}
                </span>
            </div>
        )
    }
}

export default TextAreaAutocomplete

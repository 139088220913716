import { Page, PermissionResponseList } from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Permissions
 */
export class PermissionsApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * List grants
     *
     **/

    async listGrants(): Promise<PermissionResponseList> {
        try {
            const res: AxiosResponse<PermissionResponseList> = await this.get<PermissionResponseList>(`/grants/`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * List permissions
     *
     **/

    async listPermissions(page?: Page, filter?: object, search?: string, sort?: string): Promise<PermissionResponseList> {
        try {
            const res: AxiosResponse<PermissionResponseList> = await this.get<PermissionResponseList>(`/permissions/`, { params: { page, filter, search, sort } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import moment from 'moment'
import { Illustration, ButtonSuccess, ActionCard } from '../../ds/components'
import { useTranslation } from 'react-i18next'
import utils from '../../utils/utils'
import { UserInfo } from '../../EstateablyContexts'

export const TimeCount = ({ onRenewSession, logoff }: { logoff: () => void; onRenewSession: (newPath?: string) => Promise<unknown> }) => {
    const { user } = useContext(UserInfo)
    const [countDown, setCountdown] = useState<number | undefined>(undefined)
    const [loading, setLoading] = useState(false)
    const timeout = useRef<ReturnType<typeof setTimeout>>()
    const interval = useRef<ReturnType<typeof setInterval>>()
    const { t } = useTranslation()
    const checkCookie = useMemo(() => {
        let cookieEnabled = navigator.cookieEnabled
        if (!cookieEnabled) {
            document.cookie = 'testcookie'
            cookieEnabled = document.cookie.indexOf('testcookie') != -1
        }
        return cookieEnabled
    }, [])
    useEffect(() => {
        if (checkCookie && !loading) {
            const loginExpiration = moment.unix(parseFloat(utils.getCookie('loginExpiration')) || 5 * 60 * 1000)
            let startCoundDown = loginExpiration?.diff(moment().add(1, 'minute'), 'milliseconds')
            if (startCoundDown < 0) startCoundDown = 0
            // Setup the interval to count down
            timeout.current = setTimeout(() => {
                // Setup the interval to count down
                interval.current = setInterval(() => {
                    const loginExpiration = moment.unix(parseFloat(utils.getCookie('loginExpiration')) || 5 * 60 * 1000)
                    const timeoutDate = loginExpiration?.diff(moment(), 'seconds')

                    if (timeoutDate != undefined && timeoutDate < 60) {
                        setCountdown(timeoutDate || 0)
                    } else {
                        setCountdown(undefined)
                    }
                }, 1000)
            }, startCoundDown)
        }
        return () => {
            if (interval.current) clearInterval(interval.current)
            if (timeout.current) clearInterval(timeout.current)
        }
    }, [user, checkCookie, loading])
    const handleRenewSession = useCallback(async () => {
        setLoading(true)
        if (interval.current) clearInterval(interval.current)
        await onRenewSession('./')
        setLoading(false)
        setCountdown(undefined)
    }, [onRenewSession])
    if (countDown != undefined && countDown < 60) {
        if (countDown <= 0) {
            if (interval.current) clearInterval(interval.current)
            if (timeout.current) clearInterval(timeout.current)
            logoff()
        }
        return (
            <ActionCard
                illustrationType={countDown < 10 ? Illustration.types.Error : Illustration.types.Information}
                title={t('You session is about to expire')}
                subtitle={t('{{seconds}} Seconds', { seconds: countDown })}
                buttons={[
                    <ButtonSuccess disabled={loading} onClick={handleRenewSession} key={'here'} data-test={'countDown.button'}>
                        {t("I'm here")}
                    </ButtonSuccess>,
                ]}
                onClose={handleRenewSession}></ActionCard>
        )
    }
    return null
}

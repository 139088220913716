import { DocumentRequest, DocumentResponse, DocumentsResponseBulk, DocumentsRequest, DocumentPreviewResponse } from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api, ResponseWithHeader } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Documents
 */
export class DocumentsApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * Download the document file
     * ${documentId}/filePreview
     **/
    //manual change due to generator issue
    async getDocumentFileForPreview(documentId: string): Promise<ResponseWithHeader<DocumentPreviewResponse>> {
        try {
            const res: AxiosResponse<DocumentPreviewResponse> = await this.get<DocumentPreviewResponse>(`/documents/${documentId}/filePreview`)
            return this.successWithHeader(res) //manual change due to generator issue
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * delete multiple documents
     * bulk
     **/

    async deleteBulkFile(ids?: string): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.delete<void>(`/documents/bulk`, { params: { ids } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Move multiple documents
     * bulk
     **/

    async updateBulkDocument(body: DocumentsRequest): Promise<DocumentsResponseBulk> {
        try {
            const res: AxiosResponse<DocumentsResponseBulk> = await this.patch<DocumentsResponseBulk, DocumentsRequest>(`/documents/bulk`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Download multiple document
     * download
     **/

    async downloadDocumentContentBulk(ids?: string): Promise<Buffer> {
        try {
            const res: AxiosResponse<Buffer> = await this.get<Buffer>(`/documents/download`, { responseType: 'arraybuffer', params: { ids } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Download the document file
     * ${documentId}/file
     **/

    async getDocumentFile(documentId: string): Promise<Buffer> {
        try {
            const res: AxiosResponse<Buffer> = await this.get<Buffer>(`/documents/${documentId}/file`, { responseType: 'arraybuffer', params: {} })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Delete the document file
     * ${documentId}/file
     **/

    async deleteFile(documentId: string): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.delete<void>(`/documents/${documentId}/file`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Upload the document file
     * ${documentId}/file
     **/

    async uploadFile(documentId: string, body: FormData): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.put<void, FormData>(`/documents/${documentId}/file`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Download a document archive
     * files
     **/

    async getDocumentFiles(ids?: string): Promise<Buffer> {
        try {
            const res: AxiosResponse<Buffer> = await this.get<Buffer>(`/documents/files`, { responseType: 'arraybuffer', params: { ids } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Upload a file to parse
     * parse
     **/

    async parseFile(body: FormData): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.post<void, FormData>(`/documents/parse`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Delete a document
     * ${documentId}
     **/

    async deleteDocument(documentId: string): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.delete<void>(`/documents/${documentId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Update a document
     * ${documentId}
     **/

    async updateDocument(documentId: string, body: DocumentRequest): Promise<DocumentResponse> {
        try {
            const res: AxiosResponse<DocumentResponse> = await this.patch<DocumentResponse, DocumentRequest>(`/documents/${documentId}`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Get a document
     * ${documentId}
     **/

    async getDocument(documentId: string): Promise<DocumentResponse> {
        try {
            const res: AxiosResponse<DocumentResponse> = await this.get<DocumentResponse>(`/documents/${documentId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Create a document
     *
     **/

    async createDocument(body: DocumentRequest): Promise<DocumentResponse> {
        try {
            const res: AxiosResponse<DocumentResponse> = await this.post<DocumentResponse, DocumentRequest>(`/documents/`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

import React, { PureComponent, ReactNode } from 'react'
import style from './style.module.scss'
import Icon, { IconTypes } from '../../particles/Icon'
import InputMask from 'react-input-mask'
import { GenericAttributesWithRef } from '../../types'
import { Tooltip } from '../../../../DesignSystem/Tooltip'

type TextFieldProps = {
    hasError?: boolean
    hasWarning?: boolean
    disabled?: boolean
    autoComplete?: string
    readOnly?: boolean
    value?: string
    mask?: string | (string | RegExp)[]
    maskChar?: string
    placeholder?: string
    icon?: IconTypes
    type?: TextFieldTypes
    rightLabel?: ReactNode
    onChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void
    onFocus?: (ev: React.FocusEvent<HTMLInputElement>) => void
    onBlur?: (ev: React.FocusEvent<HTMLInputElement>) => void
    onClick?: (ev: React.MouseEvent<HTMLElement>) => void
    onClickIcon?: (ev: React.MouseEvent<HTMLElement>) => void
    onKeydown?: (ev: React.KeyboardEvent<HTMLElement>) => void
    onKeyUp?: (ev: React.KeyboardEvent<HTMLElement>) => void
    name?: string
    component?: React.ComponentType
    options?: React.Attributes
    autoFocus?: boolean
    className?: string
    tag?: React.ReactNode
    iconAriaLabel?: string
    showIconAriaLabelAsTooltip?: boolean
} & GenericAttributesWithRef<'input'>

export enum TextFieldTypes {
    Text = 'text',
    Search = 'search',
    Number = 'number',
    Color = 'color',
    Email = 'email',
    Hidden = 'hidden',
    Password = 'password',
    Reset = 'reset',
    Tel = 'tel',
    Url = 'url',
    TextArea = 'textArea',
}

export class TextField extends PureComponent<TextFieldProps> {
    static IconTypes = Icon.types
    static Types = TextFieldTypes
    static defaultProps = {
        hasError: false,
        hasWarning: false,
        disabled: false,
        type: TextFieldTypes.Text,
    }

    render(): React.ReactNode {
        const {
            mask = '',
            placeholder,
            options,
            component,
            hasError,
            hasWarning,
            disabled,
            icon,
            rightLabel,
            value,
            onFocus,
            onKeyUp,
            onClick,
            onClickIcon,
            onChange,
            name,
            type,
            readOnly,
            onBlur,
            autoFocus,
            autoComplete,
            inputRef,
            onKeydown,
            className,
            tag,
            iconAriaLabel,
            showIconAriaLabelAsTooltip,
            ...props
        } = this.props

        const events = {
            onFocus,
            onClick,
            onChange,
            onKeyUp,
            onBlur,
        }

        const errorStyle = hasError && disabled !== true ? style.error : null
        const warnStyle = hasWarning && disabled !== true ? style.warning : null
        let rightColumn = null
        if (icon) {
            const iconButton = (
                <button
                    aria-label={icon}
                    type={'button'}
                    data-test={`${this.props['data-test']}.icon`}
                    className={[style.right, style.icon, disabled ? style.disabled : ''].join(' ')}
                    onClick={onClickIcon}>
                    <Icon type={icon} aria-label={iconAriaLabel || icon} />
                </button>
            )

            rightColumn = showIconAriaLabelAsTooltip ? <Tooltip content={iconAriaLabel || icon}>{iconButton}</Tooltip> : iconButton
        } else if (rightLabel) {
            rightColumn = (
                <div data-test={`${this.props['data-test']}.label`} className={[style.right, style.rightLabel, disabled ? style.disabled : ''].join(' ')}>
                    {rightLabel}
                </div>
            )
        }

        const maskProps: { mask: string | (string | RegExp)[]; formatChars?: { [key: string]: string } } = { mask }
        if (value?.startsWith && value.startsWith('***')) {
            maskProps.formatChars = {
                '9': '[0-9\\*]',
                'a': '[A-Za-z\\*]',
                '*': '[A-Za-z0-9]',
            }
        }
        return (
            <div className={[style.textField, errorStyle, warnStyle].join(' ')}>
                {tag ? tag : null}
                {component ? (
                    React.createElement(component, {
                        ...props,
                        ...this.props,
                        ...options,
                        ref: inputRef,
                        className: [style.input, errorStyle, warnStyle].join(' '),
                        'data-test': this.props['data-test'],
                    } as React.Attributes)
                ) : mask ? (
                    /*
                     * FIXME: This check is temporary solution because of hooks forms, when reset InputMask is not updating the value
                     * */
                    <InputMask
                        {...props}
                        inputRef={inputRef}
                        autoComplete={autoComplete ? autoComplete : 'off'}
                        {...events}
                        {...maskProps}
                        placeholder={placeholder}
                        readOnly={readOnly}
                        name={name}
                        value={value}
                        // FIXME: find a way to disable autocomplete without changing the type from `text` to `search`
                        type={autoComplete === 'on' ? type || TextFieldTypes.Text : type === TextFieldTypes.Text ? TextFieldTypes.Search : type}
                        className={[style.input, errorStyle, warnStyle, className].join(' ')}
                        disabled={disabled}
                        autoFocus={autoFocus}
                        data-test={this.props['data-test']}
                        onKeyDown={onKeydown}
                    />
                ) : (
                    <input
                        {...props}
                        ref={inputRef}
                        autoComplete={autoComplete ? autoComplete : 'off'}
                        {...events}
                        {...maskProps}
                        placeholder={placeholder}
                        readOnly={readOnly}
                        name={name}
                        value={value}
                        // FIXME: find a way to disable autocomplete without changing the type from `text` to `search`
                        type={autoComplete === 'on' ? type || TextFieldTypes.Text : type === TextFieldTypes.Text ? TextFieldTypes.Search : type}
                        className={[style.input, errorStyle, warnStyle, className].join(' ')}
                        disabled={disabled}
                        autoFocus={autoFocus}
                        data-test={this.props['data-test']}
                        onKeyDown={onKeydown}
                    />
                )}
                {rightColumn}
            </div>
        )
    }
}

export default TextField

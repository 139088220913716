import { FormResponse, GenerateFormRequest } from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Forms
 */
export class FormsApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * Generate a form filled with the given information
     * ${formNameOrId}
     **/

    async generateForm(formNameOrId: string, body: GenerateFormRequest): Promise<FormResponse> {
        try {
            const res: AxiosResponse<FormResponse> = await this.post<FormResponse, GenerateFormRequest>(`/forms/${formNameOrId}`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Get a form definition
     * ${formNameOrId}
     **/

    async getForm(formNameOrId: string, estateId?: string): Promise<FormResponse> {
        try {
            const res: AxiosResponse<FormResponse> = await this.get<FormResponse>(`/forms/${formNameOrId}`, { params: { estateId } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

import { AddEstateCollaboratorRequest, EstateCollaboratorsResponse, Page } from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Estate-collaborators
 */
export class EstateCollaboratorsApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * Remove a collaborator from a estate
     * ${estateId}/collaborators/${collaboratorId}
     **/

    async removeEstateCollaborator(estateId: string, collaboratorId: string): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.delete<void>(`/estates/${estateId}/collaborators/${collaboratorId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Update an organization member
     * ${estateId}/collaborators
     **/

    async addEstateCollaborator(estateId: string, body: AddEstateCollaboratorRequest): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.post<void, AddEstateCollaboratorRequest>(`/estates/${estateId}/collaborators`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Get an organization member
     * ${estateId}/collaborators
     **/

    async listEstateCollaborators(estateId: string, page?: Page, filter?: object, search?: string, sort?: string): Promise<EstateCollaboratorsResponse> {
        try {
            const res: AxiosResponse<EstateCollaboratorsResponse> = await this.get<EstateCollaboratorsResponse>(`/estates/${estateId}/collaborators`, { params: { page, filter, search, sort } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

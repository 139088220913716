import React, { PureComponent } from 'react'
import style from './style.module.scss'
import Icon from '../../particles/Icon'
import { CSSTransition } from 'react-transition-group'
import { GenericAttributes } from '../../types'

type CollapsablePanelProps = {
    onSelect?: (ev: React.MouseEvent<HTMLElement>) => void
    onClick?: (ev: React.MouseEvent<HTMLElement>) => void
    collapsed?: boolean
    title: string
    subtitle?: string
    className?: string
    labelsRight?: React.ReactNode
    children: React.ReactNode
} & GenericAttributes

export class CollapsablePanel extends PureComponent<CollapsablePanelProps> {
    static defaultProps = {
        collapsed: false,
    }

    render(): React.ReactNode {
        const { title, subtitle, labelsRight, children, onClick, collapsed, className } = this.props
        return (
            <div data-test={`${this.props['data-test']}`} className={[style.collapsableHeader, className].join(' ')} id={`collapsable-area-${title}`}>
                <button
                    type={'button'}
                    data-test={`${this.props['data-test']}.header`}
                    onClick={onClick}
                    className={style.header}
                    id={`collapsable-button-${this.props['data-test'] || title}`}
                    aria-expanded={collapsed}
                    aria-controls={`content-${title}`}
                    aria-label={title}>
                    <div data-test={`${this.props['data-test']}.header.title`}>{title}</div>
                    <div data-test={`${this.props['data-test']}.header.subtitle`} className={style.subtitle}>
                        {subtitle}
                    </div>
                    <div className={style.grow} />
                    <div className={style.labelsRight} data-test={`${this.props['data-test']}.labels`}>
                        {labelsRight}
                    </div>
                    <Icon size={30} type={collapsed ? Icon.types.ChevronOpen : Icon.types.ChevronClose} />
                </button>
                {
                    <section className={[style.content, collapsed ? style.collapsed : ''].join(' ')} id={`content-${this.props['data-test'] || title}`} aria-label={title}>
                        <CSSTransition in={collapsed} classNames={'collapsePanelAnimation'} timeout={250}>
                            {collapsed ? <div /> : <div className={'collapse-body'}>{children}</div>}
                        </CSSTransition>
                    </section>
                }
            </div>
        )
    }
}

export default CollapsablePanel

import React, { PureComponent } from 'react'
// eslint-disable-next-line css-modules/no-unused-class
import style from './style.module.scss'
import Icon from '../../particles/Icon'
import { GenericAttributes } from '../../types'
import { Link as RouterLink } from 'react-router-dom'

type ActionProps =
    | {
          href: string
          target?: ('_href' | '_self') | string
          to?: never
          onClick?: never
      }
    | {
          to: string
          href?: never
          target?: never
          onClick?: never
      }
    | {
          onClick: (ev: React.MouseEvent<HTMLElement>) => void
          to?: never
          href?: never
          target?: never
      }

type LinkProps = {
    size?: LinkSizes
    fontStyle?: LinkFontStyle
    fontWeight?: LinkFontWeights
    marker?: LinkMarker
    disabled?: boolean
} & GenericAttributes &
    ActionProps
export enum LinkSizes {
    small = 'small',
    normal = 'normal',
}
export enum LinkFontStyle {
    normal = 'normal',
    underline = 'underline',
}
export enum LinkFontWeights {
    regular = 'Regular',
    bold = 'Bold',
    semibold = 'Semibold',
}
export enum LinkMarker {
    Breadcrumbs = 'breadcrumbs',
    Separator = 'separator',
}
export class Link extends PureComponent<LinkProps> {
    static markerTypes = LinkMarker
    static sizes = LinkSizes
    static fontWeights = LinkFontWeights
    static fontStyles = LinkFontStyle
    static defaultProps = {
        size: Link.sizes.normal,
        fontStyle: Link.fontStyles.normal,
        fontWeight: Link.fontWeights.regular,
    }
    render(): React.ReactNode {
        const { children, marker, onClick, fontWeight, fontStyle, size, disabled = false, ...props } = this.props
        const _class = `font${fontWeight}${size === Link.sizes.normal ? 12 : 10}${fontStyle}`
        let Marker = null
        switch (marker) {
            case Link.markerTypes.Separator:
                Marker = <Icon size={8} type={Icon.types.Circle} />
                break
            case Link.markerTypes.Breadcrumbs:
                Marker = <Icon size={18} type={Icon.types.ChevronNext} />
                break
        }
        /**
         * if we have the `to` prop, this should be rendered as a router link
         */
        if (props.to) {
            return (
                <RouterLink
                    {...props}
                    data-disabled={disabled}
                    className={[style.text, style.link, disabled ? style.disabled : '', this.props.className].join(' ')}
                    data-test={this.props['data-test']}
                    to={props.to}
                    role={'link'}>
                    <span className={[style[_class], fontStyle === Link.fontStyles.underline ? style.underline : ''].join('')}>{children}</span>
                    {Marker}
                </RouterLink>
            )
        }
        /**
         * if we have the `href` prop, this should be rendered as an anchor tag
         */
        if (props.href) {
            return (
                <a
                    {...props}
                    data-disabled={disabled}
                    className={[style.text, style.link, disabled ? style.disabled : '', this.props.className].join(' ')}
                    data-test={this.props['data-test']}
                    href={props.href}
                    target={props.target || '_blank'}
                    role={'link'}>
                    <span className={[style[_class], fontStyle === Link.fontStyles.underline ? style.underline : ''].join('')}>{children}</span>
                    {Marker}
                </a>
            )
        }
        /**
         * if we have the `onClick` prop, this should be rendered as a button
         */
        if (onClick) {
            return (
                <button
                    type={'button'}
                    {...props}
                    data-disabled={disabled}
                    onClick={!disabled ? onClick : undefined}
                    className={[style.text, style.link, disabled ? style.disabled : '', this.props.className].join(' ')}
                    data-test={this.props['data-test']}
                    role={'link'}>
                    <span className={[style[_class], fontStyle === Link.fontStyles.underline ? style.underline : ''].join('')}>{children}</span>
                    {Marker}
                </button>
            )
        }

        /**
         * default behaviour
         */
        return (
            <button
                type={'button'}
                {...props}
                data-disabled={disabled}
                onClick={!disabled ? onClick : undefined}
                className={[style.text, style.link, disabled ? style.disabled : '', this.props.className].join(' ')}
                data-test={this.props['data-test']}
                role={'link'}>
                <span className={[style[_class], fontStyle === Link.fontStyles.underline ? style.underline : ''].join('')}>{children}</span>
                {Marker}
            </button>
        )
    }
}
export default Link

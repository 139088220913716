/**
 * Identifier
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Identifier".
 */
export type Identifier = string
/**
 * JSON:API response returning a transaction
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "AdvanceImportResponse".
 */
export type AdvanceImportResponse = Item & {
    data?: AdvanceImportResource
}
/**
 * A link **MUST** be represented as either: a string containing the link's URL or a link object.
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "link".
 */
export type Link =
    | string
    | {
          /**
           * A string containing the link's URL.
           */
          href: string
          meta?: Meta
      }
/**
 * JSON:API response returning a transaction
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "AccountResponse".
 */
export type AccountResponse = Item & {
    data?: AccountResource
}
/**
 * Account Meta information
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "MetaAccount".
 */
export type MetaAccount = ItemMeta & {
    balance?: {
        value?: number
        bequest?: number
        probate?: number
        notBequest?: number
        probateNotBequest?: number
    }
    /**
     * Account Nickname
     */
    accountNickname?: string
    holdings?: Holding[]
    inventory?: InventoryItem
    transactions?: {
        value?: number
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "InventoryItem".
 */
export type InventoryItem = InventoryItemBase &
    (
        | IncomeTaxDue
        | CashOnHands
        | BankAccount
        | EstateBankAccount
        | TrustBankAccount
        | IncomeReceivable
        | RealEstate
        | InvestmentAccount
        | EstateInvestmentAccount
        | GrantorInvestmentAccounts
        | TrustInvestmentAccount
        | LifeInsuranceProceeds
        | LoanDueToDeceased
        | StakeInPrivateCompany
        | Collectable
        | Vehicle
        | PersonalEffectsAndHousehold
        | AccruedInterest
        | OtherAsset
        | IncomeTaxReceivable
        | AccruedInterestLiability
        | LoansPayable
        | SpouseOrDependencies
        | CivilLiability
        | OutstandingBill
        | Guarantee
        | BusinessDebt
        | ContractsForServices
        | TaxHoldback
        | ExecutorCompensation
        | TrusteeCompensation
        | OtherLiability
        | Holding
        | CaseBankAccount
        | CaseInvestmentAccount
        | GrantorBankAccounts
        | AccountsReceivable
        | Annuities
        | DigitalCurrency
        | GIC
        | IntellectualPropertyRights
        | OilGasMineralRight
        | PublicCompanyCertificate
        | PooledMortgageFund
    )
/**
 * JSON:API response returning accounts
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "AccountsResponse".
 */
export type AccountsResponse = Items & {
    data?: AccountResource[]
}
/**
 * JSON:API response returning a list of activity
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "ActivitiesResponse".
 */
export type ActivitiesResponse = Items & {
    data?: ActivityResource[]
}
/**
 * Resource representing an activity
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "ActivityResource".
 */
export type ActivityResource = Item & {
    type?: string
    /**
     * Identifier
     */
    id?: string
    attributes?: Activity
}
/**
 * Email address
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Email".
 */
export type Email = string
/**
 * Phone number
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "PhoneNumber".
 */
export type PhoneNumber = string
export type UserInvitationMeta = ItemMeta & {
    acceptedAt?: string
    declinedAt?: string
}
/**
 * An object composed by expiration month and year
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "CardExpiration".
 */
export type CardExpiration = {
    /**
     * The month that the credit card expires (from 1 to 12)
     */
    month?: number
    /**
     * The year that the credit card expires
     */
    year?: number
} | null
/**
 * the billing address details of the credit card
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "BillingAddress".
 */
export type BillingAddress = {
    city?: string
    country?: string
    line1?: string
    line2?: string
    postal_code?: string
    state?: string
} | null
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "DeactivateAddons".
 */
export type DeactivateAddons = Items
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "ListAddons".
 */
export type ListAddons = Items & {
    data?: AddonsListResource[]
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "getAddons".
 */
export type GetAddons = Item & {
    data?: AddonsResource
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "GetCases".
 */
export type GetCases = Items & {
    data?: AddonsCasesResource[]
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "GetCaseDetail".
 */
export type GetCaseDetail = Item & {
    data?: AddonsCaseDetailResource
}
/**
 * Token
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Token".
 */
export type Token = string
/**
 * The document's "primary data" is a representation of the resource or collection of resources targeted by a request.
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "data".
 */
export type Data = Resource | Resource[] | null
/**
 * References to other resource objects in a to-one ("relationship"). Relationships can be specified by including a member in a resource's links object.
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "relationshipToOne".
 */
export type RelationshipToOne = Empty | Linkage
/**
 * Describes an empty to-one relationship.
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "empty".
 */
export type Empty = null
/**
 * An array of objects each containing "type" and "id" members for to-many relationships.
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "relationshipToMany".
 */
export type RelationshipToMany = Linkage[]
/**
 * JSON:API response returning a list of benficiaries
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "BeneficiariesResponse".
 */
export type BeneficiaryResponseList = Items & {
    data?: BeneficiaryResource[]
}
/**
 * Notice limitations and prices information
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "NoticeMeta".
 */
export type NoticeMeta = {
    /**
     * the `allow` will be used to control if the org has access to notice,and if has access, should they be charged for a notice posting
     */
    allow?: 'Pay' | 'Free' | 'NotAllowed'
    prices?: {
        id: string
        currency: string
        value: number
        product: string
        metadata: {
            addons?: 'affidavit'
            product: 'notice'
        }
    }[]
} | null
/**
 * JSON:API response returning a list of invoices for a giving org
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "InvoicesResponse".
 */
export type InvoicesResponse = Items & {
    data?: InvoiceResource[]
}
/**
 * JSON:API response returning a list of charities
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "CharitiesResponse".
 */
export type CharityResponseList = Items & {
    data?: CharityResponse[]
}
/**
 * JSON:API response to list collaborators from a estate
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "EstateCollaboratorsResponse".
 */
export type EstateCollaboratorsResponse = Items & {
    data?: EstateCollaboratorsResource[]
}
/**
 * JSON:API response returning a comment
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "CommentResponse".
 */
export type CommentResponse = Item & {
    data?: CommentResource
}
/**
 * JSON:API response returning a list of comments
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "CommentsResponse".
 */
export type CommentResponseList = Items & {
    data?: CommentResource[]
}
/**
 * JSON:API response returning a list of contacts
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "ContactsResponse".
 */
export type ContactsResponse = Items & {
    data?: ContactResource[]
}
/**
 * JSON:API response returning a list of contacts
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "ContactResponse".
 */
export type ContactResponse = Item & {
    data?: ContactResource
}
/**
 * Identifier
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "IdentifierOrNull".
 */
export type IdentifierOrNull = string | null
/**
 * User password
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Password".
 */
export type Password = string
/**
 * Birth date
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "BirthDate".
 */
export type BirthDate = string
/**
 * JSON:API response returning a distributionRequest
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TrustDistributionRequestResponse".
 */
export type TrustDistributionRequestResponse = Item & {
    data?: TrustDistributionRequestResource
}
/**
 * JSON:API response returning a list of distributionRequest
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TrustDistributionRequestsResponse".
 */
export type TrustDistributionRequestsResponse = Items & {
    data?: TrustDistributionRequestResource[]
}
/**
 * JSON:API response returning a distribution
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "DistributionResponse".
 */
export type DistributionResponse = Item & {
    data?: AssetDistributionResource | CashDistributionResource
}
/**
 * JSON:API response returning a distribution
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "DistributionsResponse".
 */
export type DistributionResponseList = Items & {
    data?: (Item & (AssetDistributionResource | CashDistributionResource))[]
}
/**
 * JSON:API response returning a template
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TemplateResponse".
 */
export type TemplateResponse = Item & {
    data?: TemplateResource
}
/**
 * JSON:API response returning a list of template
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TemplatesResponse".
 */
export type TemplateResponseList = Items & {
    data?: TemplatesResource[]
}
/**
 * JSON:API response returning an document
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "DocumentResponse".
 */
export type DocumentResponse = Item & {
    data?: DocumentResource
}
/**
 * JSON:API response returning the bulk actions for document
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "DocumentsResponseBulk".
 */
export type DocumentsResponseBulk = {
    data?: {
        success?: number
        failed?: number
    }
}
/**
 * JSON:API response returning a list of documents
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "DocumentsResponse".
 */
export type DocumentResponseList = Items & {
    data?: DocumentResource[]
}
/**
 * JSON:API response returning a list of documents
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "DocumentPreviewResponse".
 */
export type DocumentPreviewResponse = Item & {
    data?: DocumentPreviewResource
}
/**
 * JSON:API response returning an estate
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "EstateResponse".
 */
export type EstateResponse = Item & {
    meta?: ItemMeta & {
        /**
         * Date indicating when the case was archived
         */
        archivedAt?: string
        caseAddons?: CaseAddons[]
    }
    data?: EstateResource
}
/**
 * JSON:API response returning an estate
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "EstateSummaryResponse".
 */
export type EstateSummaryResponse = Item & {
    meta?: ItemMeta
    data?: EstateSummaryResource
}
/**
 * JSON:API response returning a list of estates
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "EstatesResponse".
 */
export type EstateResponseList = Items & {
    data?: EstateResourceList[]
}
/**
 * JSON:API response returning an Exchanges
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "ExchangeResponse".
 */
export type ExchangeResponse = Item & {
    data?: Exchanges
}
/**
 * JSON:API response returning a list of Exchanges
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "ExchangesResponse".
 */
export type ExchangeResponseList = Items & {
    data?: ExchangesResource[]
}
/**
 * JSON:API response returning a execution
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "ExecutionResponse".
 */
export type ExecutionResponse = Item & {
    data?: ExecutionResource
}
/**
 * JSON:API response returning a list of executors
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "ExecutorsResponse".
 */
export type ExecutorResponseList = Items & {
    data?: ExecutorResource[]
}
/**
 * JSON:API response returning the email sent
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "EmailDocumentResponse".
 */
export type EmailDocumentResponse = Item & {
    data?: EmailDocumentResource
}
/**
 * JSON:API response returning a form
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "FormResponse".
 */
export type FormResponse = Item & {
    data?: FormResource
}
/**
 * JSON:API response returning a list of globalContacts
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "GlobalContactsResponse".
 */
export type GlobalContactsResponse = Items & {
    data?: GlobalContactResource[]
}
/**
 * JSON:API response returning a globalContacts
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "GlobalContactResponse".
 */
export type GlobalContactResponse = Item & {
    data?: GlobalContactResource
}
/**
 * JSON:API response returning a list of Global Search results
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "GlobalSearchResponse".
 */
export type GlobalSearchResponse = Items & {
    data?: GlobalSearchResource[]
    meta?: ItemsMeta
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "ContainerItem".
 */
export type ContainerItem = InventoryItemBase &
    Holding & {
        /**
         * Identifier
         */
        _id?: string
        /**
         * Identifier
         */
        id?: string
        meta?: ItemMeta & {
            total?: {
                current?: number
                atDeath?: number
                book?: number
                residual?: {
                    value?: number
                    book?: number
                    quantity?: number
                }
            }
            transactions?: {
                category?: number
                total?: number
            }
        }
    }
/**
 * Integrations info object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "IntegrationInfo".
 */
export type IntegrationInfo = DataAggregationInfo
/**
 * JSON:API response returning an Integration
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "IntegrationResponse".
 */
export type IntegrationResponse = Item & {
    meta?: IntegrationMeta
    data?: IntegrationResource
}
/**
 * JSON:API meta of Integration
 *
 *  This interface was referenced by `EstateablyAPI`'s JSON-Schema
 *  via the `definition` "IntegrationMeta".
 */
export type IntegrationMeta = ItemMeta & {
    /**
     * Date indicating when the item has been created
     */
    lastSyncedAt?: string
}
/**
 * JSON:API response returning a list of Integration
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "IntegrationsResponse".
 */
export type IntegrationsResponse = Items & {
    data?: IntegrationsResource[]
}
/**
 * JSON:API request to create or update an inventory item
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "InventoryItemResponse".
 */
export type InventoryItemResponse = Item & {
    meta?: ItemMeta & InventoryMeta
    data?: InventoryResource
}
/**
 * JSON:API response returning a list of inventory items
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "InventoryItemsResponse".
 */
export type InventoryItemResponseList = Items & {
    data?: InventoryResource[]
}
/**
 * JSON:API response returning an invitation
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "InvitationResponse".
 */
export type InvitationResponse = Item & {
    data?: InvitationResource
}
/**
 * JSON:API response returning a list of invitations
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "InvitationsResponse".
 */
export type InvitationResponseList = Items & {
    data?: InvitationResource[]
}
/**
 * JSON:API response returning a job
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "JobResponse".
 */
export type JobResponse = Item & {
    data?: JobResource
}
/**
 * JSON:API response returning a list of jobs
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "JobsResponse".
 */
export type JobsResponse = Items & {
    data?: JobResource[]
}
/**
 * JSON:API response returning a list of posible mentions for this case
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "MentionsResponse".
 */
export type MentionsResponseList = Items & {
    data?: MentionResource[]
}
/**
 * JSON:API response returning a notification
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "NotificationResponse".
 */
export type NotificationResponse = Item & {
    data?: NotificationResource
}
/**
 * JSON:API response returning a list of notifications
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "NotificationsResponse".
 */
export type NotificationResponseList = Items & {
    meta?: ItemsMeta & {
        items?: {
            /**
             * The total number of items on the collection
             */
            count?: number
            /**
             * The total number of unread notifications
             */
            unread?: number
        }
    }
    data?: NotificationResource[]
}
export type PlanTypes = 'Basic' | 'Pro' | 'Legacy'
/**
 * JSON:API response returning an organization
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "OrganizationResponse".
 */
export type OrganizationResponse = Item & {
    data?: OrganizationResource
} & {
    meta?: {
        currentPlan?: BillingPlan
    }
}
/**
 * JSON:API response returning a list of organizations
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "OrganizationsResponse".
 */
export type OrganizationResponseList = Items & {
    data?: OrganizationResource[]
    meta?: OrganizationMeta
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "OrganizationMeta".
 */
export type OrganizationMeta = ItemMeta & {
    security?: {
        rules?: {
            id?: string
            name?: string
            description?: string
        }[]
    }
    plans?: BillingPlan[]
}
/**
 * JSON:API response returning an Organization member
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "OrganizationMemberResponse".
 */
export type OrganizationMemberResponse = Item & {
    data?: OrganizationMemberResource
}
/**
 * JSON:API response returning a list of organization members
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "OrganizationMembersResponse".
 */
export type OrganizationMemberResponseList = Items & {
    data?: OrganizationMemberResource[]
}
/**
 * JSON:API response returning a list of permission
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "PermissionsResponse".
 */
export type PermissionResponseList = Items & {
    data?: PermissionResource[]
}
/**
 * Permission identifier
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "PermissionIdentifier".
 */
export type PermissionIdentifier = string
/**
 * JSON:API response returning a phase
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "PhaseResponse".
 */
export type PhaseResponse = Item & {
    data?: PhaseResource
}
/**
 * JSON:API response returning a list of phase
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "PhasesResponse".
 */
export type PhasesResponse = Items & {
    data?: PhaseResource[]
}
/**
 * JSON:API response returning a list of tasks phases names
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "MyTasksPhasesNamesResponse".
 */
export type MyTasksPhasesNamesResponse = Items & {
    data?: TasksPhasesNamesResource[]
}
/**
 * JSON:API response returning the status of the integrations activation
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "IntegrationsActivationResponse".
 */
export type IntegrationsActivationResponse = Item & {
    meta?: IntegrationsMetadata
}
/**
 * JSON:API meta returning the status of the integrations activation
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "IntegrationsMetadata".
 */
export type IntegrationsMetadata = ItemMeta & {
    /**
     * Indicates if the integrations are enabled (a Prismatic customer has been created)
     */
    enabled?: boolean
}
/**
 * JSON:API response returning the list of integrations allowed for the organization
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "IntegrationsAllowedResponse".
 */
export type IntegrationsAllowedResponse = Items & {
    meta?: IntegrationsMetadata
    data?: IntegrationData[]
}
/**
 * JSON:API meta returning the status of the integration
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "IntegrationMetadata".
 */
export type IntegrationMetadata = ItemMeta & {
    /**
     * Indicates if the integration is installed (an instance exists for that integration and that customer)
     */
    installed?: boolean
}
/**
 * JSON:API response returning a resource
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "ResourceResponse".
 */
export type ResourceResponse = Item & {
    data?: ResourceResource
}
/**
 * JSON:API response returning a list of resource
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "ResourcesResponse".
 */
export type ResourcesResponse = Items & {
    data?: ResourcesResource[]
}
/**
 * JSON:API response returning a role
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "RoleResponse".
 */
export type RoleResponse = Item & {
    data?: RoleResource
}
/**
 * JSON:API response returning a list of role
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "RolesResponse".
 */
export type RoleResponseList = Items & {
    data?: RoleResource[]
}
/**
 * JSON:API response returning a list of Substitute Decision Makers
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "SubstituteDecisionMakersResponse".
 */
export type SubstituteDecisionMakerResponseList = Items & {
    data?: SubstituteDecisionMakerResource[]
}
/**
 * JSON:API response returning a taskTemplate
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TaskTemplateResponse".
 */
export type TaskTemplateResponse = Item & {
    data?: TaskTemplateResource
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TaskTemplateResourceMeta".
 */
export type TaskTemplateResourceMeta = ItemMeta & {
    taskCount?: number
    createdBy?: {
        name?: string
        _id?: string
    }
    updatedBy?: {
        name?: string
        _id?: string
    }
    deletedBy?: {
        name?: string
        _id?: string
    }
}
/**
 * JSON:API response returning a list of task templates
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TaskTemplateListResponse".
 */
export type TaskTemplateResponseList = Items & {
    data?: TaskTemplateResource[]
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TodoCompletedBy".
 */
export type TodoCompletedBy = {
    id: Identifier
    firstName?: string | null
    lastName?: string | null
    role?: string | null
} | null
/**
 * JSON:API response returning a bulk task update
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "BulkTaskResponse".
 */
export type BulkTasksResponse = Item & {
    data?: TasksResource[]
    meta?: {
        /**
         * failed updated tasks
         */
        failUpdated?: string
        /**
         * Successfully updated tasks
         */
        successfullyUpdated?: string
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TaskResourceMeta".
 */
export type TaskResourceMeta = ItemMeta & {
    estateName?: string
    estateTeamId?: string
    roleName?: string
    phaseName?: string
    approvedByName?: string | null
    completedAt?: string
    estateOwner?: string
    statusLog?: {
        from?: TaskStatus1
        to?: TaskStatus1
        date?: string
        updatedBy?: string
    }[]
    estateCollaborators?: string[]
}
/**
 * JSON:API response returning a task
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TaskResponse".
 */
export type TaskResponse = Item & {
    data?: TaskResource
}
/**
 * JSON:API response returning a task
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TaskCreateResponse".
 */
export type TaskCreateResponse = Item & {
    data?: TaskCreateResource
}
/**
 * JSON:API response returning a list of tasks created
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "CreateTasksFromTemplatesResponse".
 */
export type CreateTasksFromTemplatesResponse = Item & {
    data?: TaskCreateResource[]
}
/**
 * JSON:API response returning a list of tasks
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TasksResponse".
 */
export type TaskResponseList = Items & {
    data?: TasksResource[]
}
/**
 * JSON:API response returning a list of tasks
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "MyTasksResponse".
 */
export type MyTaskResponseList = Items & {
    data?: MyTasksResource[]
}
/**
 * JSON:API response returning a list of tasks names
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "MyTasksNamesResponse".
 */
export type MyTasksNamesResponse = Items & {
    data?: TasksNamesResource[]
}
/**
 * JSON:API response returning a team
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TeamResponse".
 */
export type TeamResponse = Item & {
    data?: TeamResource
}
/**
 * JSON:API response returning a list of team
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TeamsResponse".
 */
export type TeamResponseList = Items & {
    meta?: ItemsMeta & {
        managers?: User[]
    }
    data?: TeamResource[]
}
/**
 * JSON:API response returning a list of team members
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TeamMembersResponse".
 */
export type TeamMemberResponseList = Items & {
    data?: TeamMemberResource[]
}
/**
 * JSON:API request to transfer an asset
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "AssetTransferRequest".
 */
export type AssetTransferRequest = Item & {
    data?: {
        type: 'transactions'
        attributes: AssetTransfer
    }
}
/**
 * JSON:API request to transfer an asset
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "CashTransferRequest".
 */
export type CashTransferRequest = Item & {
    data?: {
        type: 'transactions'
        attributes: CashTransfer
    }
}
/**
 * JSON:API request to pay a debt (or liability)
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "DebtPaymentRequest".
 */
export type DebtPaymentRequest = Item & {
    data?: {
        type: 'transactions'
        attributes: DebtPayment
    }
}
/**
 * JSON:API request to create or update a debt forgiveness
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "DebtForgivenessRequest".
 */
export type DebtForgivenessRequest = Item & {
    data?: {
        type: 'transactions'
        attributes: DebtForgiveness
    }
}
/**
 * JSON:API request to create a transaction or update in the estate account
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TransactionRequest".
 */
export type TransactionRequest = Item & {
    data?: {
        type: 'transactions'
        attributes: Transaction
    }
}
/**
 * JSON:API request to create transactions or update in the estate account
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TransactionsRequest".
 */
export type TransactionsRequest = Item & {
    data?: {
        type: 'transactions'
        attributes: Transaction
    }[]
}
/**
 * JSON:API request to split a stock
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "SplitStockRequest".
 */
export type SplitStockRequest = Item & {
    data?: {
        type: 'transactions'
        attributes: SplitStock
    }
}
/**
 * JSON:API request to transfer a holding
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TransferCurrentHoldingRequest".
 */
export type TransferCurrentHoldingRequest = Item & {
    data?: {
        type: 'transactions'
        attributes: TransferCurrentHolding
    }
}
/**
 * JSON:API request to split a stock
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "SplitTransactionRequest".
 */
export type SplitTransactionRequest = Item & {
    data?: {
        type: 'transactions'
        attributes: {
            transactions: SplitTransaction[]
        }
    }
}
/**
 * JSON:API response returning a transaction
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TransactionResponse".
 */
export type TransactionResponse = Item & {
    data?: TransactionResource
}
/**
 * JSON:API response returning transactions
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TransactionsResponse".
 */
export type TransactionsResponse = Items & {
    data?: TransactionResource[]
}
/**
 * JSON:API response returning bulk transactions
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "BulkTransactionsResponse".
 */
export type BulkTransactionsResponse = Items & {
    data?: BulkTransactionResource[]
}
/**
 * JSON:API response returning a list of users
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "UsersResponse".
 */
export type UsersResponse = Items & {
    data?: UserResource[]
}
/**
 * Types of subscription addons
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "SubscriptionAddonsType".
 */
export type SubscriptionAddonsType = 'estateAccountingPlus' | 'trustFullAdministration'
/**
 * JSON:API response returning a workflow
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "WorkflowResponse".
 */
export type WorkflowResponse = Item & {
    data?: WorkflowResource
}
/**
 * JSON:API response returning a list of workflow
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "WorkflowsResponse".
 */
export type WorkflowResponseList = Items & {
    data?: WorkflowResourceList[]
}

export interface EstateablyAPI {}
/**
 * JSON:API request to create an account or update
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "AccountRequest".
 */
export interface AccountRequest {
    data?: {
        type: 'accounts'
        attributes: Account & {
            /**
             * Account Nickname
             */
            accountNickname?: string
        }
    }
}
/**
 * Account Object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Account".
 */
export interface Account {
    accountType: AccountTypes
    /**
     * Account institution
     */
    institution: string
    /**
     * Account Description
     */
    description?: string
    /**
     * Account identification
     */
    number: string
    /**
     * Account Transit Number
     */
    transitNumber?: string
    /**
     * Nickname of the Account
     */
    accountNickname?: string
    fields?: {}
    inventoryId?: Identifier
    estateId?: Identifier
    /**
     * Data aggregator synchronization settings
     */
    sync?: {
        enabled?: boolean
        /**
         * params used to connect on the data aggregator
         */
        params?: {}
        /**
         * Date time of the last update
         */
        lastUpdate?: string
    }
}
/**
 * Represent a response with a single item
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Item".
 */
export interface Item {
    data?: {}
    meta?: ItemMeta
    links?: Links
}
/**
 * Represent generic metadata
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "ItemMeta".
 */
export interface ItemMeta {
    /**
     * Date indicating when the item has been created
     */
    createdAt?: string
    /**
     * Date indicating the last time the item has been modified
     */
    updatedAt?: string
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "links".
 */
export interface Links {
    [k: string]: Link
}
/**
 * Non-standard meta-information that can not be represented as an attribute or relationship.
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "meta".
 */
export interface Meta {
    [k: string]: unknown
}
/**
 * Resource representing result of advance import
 */
export interface AdvanceImportResource {
    type: 'accounts'
    meta?: AdvanceImportMeta
    attributes: {}
}
export interface AdvanceImportMeta {
    transactions?: {
        create?: number
        transfer?: number
        stockSplit?: number
    }
    inventories?: {
        create?: number
    }
    accounts?: {
        create?: number
    }
}
/**
 * Resource representing an account
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "AccountResource".
 */
export interface AccountResource {
    type: 'accounts'
    id: Identifier
    meta?: MetaAccount
    attributes: Account
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Holding".
 */
export interface Holding {
    category?: InventoryCategory.Asset
    inventoryType?: InventoryType.Holding
    fields?: {
        class: Class
        subClass?: SubClass
        isIdentical?: boolean
        tickerSymbol?: string | {}
        description: string
        targetCurrency?: string
        /**
         * Identifier
         */
        containerId: string
    }
}
/**
 * Inventory Object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "InventoryItemBase".
 */
export interface InventoryItemBase {
    /**
     * The current value of the inventory
     */
    value: number
    /**
     * The value on the moment of death of the inventory
     */
    deathValue?: number
    /**
     * The value payed for the inventory
     */
    bookValue?: number
    category: InventoryCategory
    inventoryType: InventoryType
    /**
     * Ownership percentage of the inventory
     */
    percentage: number
    /**
     * Quantity for this inventory (e.g Jewellery . Quantity 15)
     */
    quantity?: number
    /**
     * Define if the inventory has been probated (https://en.wikipedia.org/wiki/Probate)
     */
    probate?: boolean
    /**
     * Define if the inventory has been bequeathed (https://en.wikipedia.org/wiki/Bequest)
     */
    bequest?: boolean
    fields?: InventoryFieldsBase
    /**
     * Type of allocation for inventory, only when bequest
     */
    inventoryAllocationType?: 'percentage' | 'fraction' | 'evenly'
    /**
     * Some notes the executor can let regarding this inventory
     */
    notes?: string
    /**
     * Identifier
     */
    containerId?: string
    /**
     * Identifier
     */
    inventoryLinked?: string
    forgiven?: {
        /**
         * The value forgiven
         */
        value?: number
        /**
         * Some notes about forgiven
         */
        notes?: string
        /**
         * The date of the forgiven
         */
        date?: string
    }
    /**
     * Field to describe bequest to beneficiaries
     */
    beneficiaries?: InventoryItemBeneficiaries[]
    integration?: {
        id?: string
        type?: string
    }
}
/**
 * Additional fields (e.g For a Bank account - Account number)
 */
export interface InventoryFieldsBase {
    ownership?: string
    currency?: string
    originalValue?: number
    originalCurrentValue?: number
    currencyExchangeRate?: number
    transferInKind?: boolean
    /**
     * The date of the transfer
     */
    transferInDate?: string
    /**
     * Value of the cost base
     */
    adjustedCostBase?: number
    /**
     * Value of the cost in original currency if applicable
     */
    adjustedCostBaseOther?: number
    /**
     * Id of the account where should be add transaction ( this is applicable to investment on assets made by the Trust)
     */
    accountId?: string
    /**
     * Value to be added on the transaction created ( this is applicable to investment on assets made by the Trust)
     */
    transactionValue?: number
    /**
     * Value to be added on the transaction created as second currency ( this is applicable to investment on assets made by the Trust)
     */
    transactionValueOther?: number
    /**
     * Description to be added on the transaction created ( this is applicable to investment on assets made by the Trust)
     */
    transactionDescription?: string
    [k: string]: unknown
}
export interface InventoryItemBeneficiaries {
    /**
     * Identifier
     */
    beneficiary?: string
    percentage?: number
    allocationFractionNumerator?: number
    allocationFractionDenominator?: number
    quantity?: number
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "IncomeTaxDue".
 */
export interface IncomeTaxDue {
    category?: InventoryCategory.Liability
    inventoryType?: InventoryType.IncomeTaxDue
    fields?: {
        /**
         * the type of income tax due (i.e. federal, provincial or other)
         */
        incomeTaxDueType: string
        description: string
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "CashOnHands".
 */
export interface CashOnHands {
    category?: InventoryCategory.Asset
    inventoryType?: InventoryType.CashOnHand
    fields?: {
        location: string
        excludeFromTaxation?: boolean
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "BankAccount".
 */
export interface BankAccount {
    category?: InventoryCategory.Asset
    inventoryType?: InventoryType.BankAccounts
    fields?: {
        accountType: string
        institution: string
        accountNumber: string
        transitNumber?: string
        phone?: string
        address?: string
        city?: string
        state?: string
        zipCode?: string
        country?: string
        excludeFromTaxation?: boolean
        accountNickname?: string
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "EstateBankAccount".
 */
export interface EstateBankAccount {
    category?: InventoryCategory.Asset
    inventoryType?: InventoryType.EstateBankAccounts
    fields?: {
        accountType: string
        holdingInstitution: string
        accountNumber: string
        transitNumber: string
        accountNickname?: string
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TrustBankAccount".
 */
export interface TrustBankAccount {
    category?: InventoryCategory.Asset
    inventoryType?: InventoryType.TrustBankAccounts
    fields?: {
        accountType: string
        holdingInstitution: string
        accountNumber: string
        transitNumber: string
        accountNickname?: string
        bankAccountType?: string
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "IncomeReceivable".
 */
export interface IncomeReceivable {
    category?: InventoryCategory.Asset
    inventoryType?: InventoryType.IncomeReceivable
    fields?: {
        incomeType: string
        name: string
        accountNumber?: string
        excludeFromTaxation?: boolean
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "RealEstate".
 */
export interface RealEstate {
    category?: InventoryCategory.Asset
    inventoryType?: InventoryType.RealEstate
    fields?: {
        assetSubType?: string
        propertyType: string
        address: string
        city: string
        state: string
        zipCode: string
        country: string
        lotNumber?: string
        cadastreNumber?: string
        excludeFromTaxation?: boolean
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "InvestmentAccount".
 */
export interface InvestmentAccount {
    category?: InventoryCategory.Asset
    inventoryType?: InventoryType.InvestmentAccount
    fields?: {
        accountType: 'rrsp' | 'lif' | 'tfsa' | 'lira' | 'rrif' | 'rdsp' | 'resp' | 'dpsp' | 'nonRegistered' | 'alternativeInvestment'
        holdingInstitution: string
        phone?: string
        accountNumber: string
        address?: string
        city?: string
        state?: string
        zipCode?: string
        country?: string
        excludeFromTaxation?: boolean
        accountNickname?: string
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "EstateInvestmentAccount".
 */
export interface EstateInvestmentAccount {
    category?: InventoryCategory.Asset
    inventoryType?: InventoryType.EstateInvestmentAccounts
    fields?: {
        accountType: 'rrsp' | 'lif' | 'tfsa' | 'lira' | 'rrif' | 'rdsp' | 'resp' | 'dpsp' | 'nonRegistered' | 'alternativeInvestment'
        holdingInstitution: string
        accountNumber: string
        accountNickname?: string
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "GrantorInvestmentAccounts".
 */
export interface GrantorInvestmentAccounts {
    category?: InventoryCategory.Asset
    inventoryType?: InventoryType.GrantorInvestmentAccounts
    fields?: {
        accountType: string
        holdingInstitution: string
        phone?: string
        accountNumber: string
        address?: string
        city?: string
        state?: string
        zipCode?: string
        country?: string
        excludeFromTaxation?: boolean
        accountNickname?: string
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TrustInvestmentAccount".
 */
export interface TrustInvestmentAccount {
    category?: InventoryCategory.Asset
    inventoryType?: InventoryType.TrustInvestmentAccounts
    fields?: {
        accountType: 'rrsp' | 'lif' | 'tfsa' | 'lira' | 'rrif' | 'rdsp' | 'resp' | 'dpsp' | 'nonRegistered' | 'alternativeInvestment'
        holdingInstitution: string
        accountNumber: string
        accountNickname?: string
        bankAccountType?: string
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "LifeInsuranceProceeds".
 */
export interface LifeInsuranceProceeds {
    category?: InventoryCategory.Asset
    inventoryType?: InventoryType.LifeInsuranceProceeds
    fields?: {
        assetSubType?: string
        nameInsurer: string
        policyNumber: string
        excludeFromTaxation?: boolean
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "LoanDueToDeceased".
 */
export interface LoanDueToDeceased {
    category?: InventoryCategory.Asset
    inventoryType?: InventoryType.LoanDueToDeceased
    fields?: {
        loanType: 'promissoryNotes' | 'agreementsForSale' | 'mortgage'
        nameOfBorrower: string
        interestRate?: number
        securityDetails?: string
        termDate: string
        excludeFromTaxation?: boolean
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "StakeInPrivateCompanies".
 */
export interface StakeInPrivateCompany {
    category?: InventoryCategory.Asset
    inventoryType?: InventoryType.StakeInPrivateCompanies
    fields?: {
        assetSubType?: string
        securityType: string
        nameOfCompany: string
        excludeFromTaxation?: boolean
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Collectables".
 */
export interface Collectable {
    category?: InventoryCategory.Asset
    inventoryType?: InventoryType.Collectables
    fields?: {
        contactInfo?: string
        description: string
        excludeFromTaxation?: boolean
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Vehicles".
 */
export interface Vehicle {
    category?: InventoryCategory.Asset
    inventoryType?: InventoryType.Vehicles
    fields?: {
        typeOfVehicle: string
        make: string
        model: string
        year: string
        vehicleIdentificationNumber?: string
        sourceOfValuation?: string
        excludeFromTaxation?: boolean
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "PersonalEffectsAndHousehold".
 */
export interface PersonalEffectsAndHousehold {
    category?: InventoryCategory.Asset
    inventoryType?: InventoryType.PersonalEffectsAndHousehold
    fields?: {
        name: string
        excludeFromTaxation?: boolean
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "AccruedInterest".
 */
export interface AccruedInterest {
    category?: InventoryCategory.Asset
    inventoryType?: InventoryType.AccruedInterest
    fields?: {
        description: string
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "OtherAsset".
 */
export interface OtherAsset {
    category?: InventoryCategory.Asset
    inventoryType?: InventoryType.OtherAsset
    fields?: {
        name: string
        excludeFromTaxation?: boolean
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "IncomeTaxReceivable".
 */
export interface IncomeTaxReceivable {
    category?: InventoryCategory.Asset
    inventoryType?: InventoryType.IncomeTaxReceivable
    fields?: {
        name: string
        excludeFromTaxation?: boolean
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "AccruedInterestLiability".
 */
export interface AccruedInterestLiability {
    category?: InventoryCategory.Liability
    inventoryType?: InventoryType.AccruedInterestLiability
    fields?: {
        description: string
        excludeFromTaxation?: boolean
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "LoansPayable".
 */
export interface LoansPayable {
    category?: InventoryCategory.Liability
    inventoryType?: InventoryType.LoansPayable
    fields?: {
        /**
         * Define if the item can be linked
         */
        loansecured?: boolean
        typeOfLoan: string
        rate?: number
        nameOfLender?: string
        accountNumber?: string
        scheduleOfRepayment?: string
        dueDate: string
        excludeFromTaxation?: boolean
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "SpouseOrDependencies".
 */
export interface SpouseOrDependencies {
    category?: InventoryCategory.Liability
    inventoryType?: InventoryType.SpouseOrDependencies
    fields?: {
        paymentSchedule: string
        typeSpouse: string
        recipientFullName: string
        excludeFromTaxation?: boolean
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "CivilLiabilities".
 */
export interface CivilLiability {
    category?: InventoryCategory.Liability
    inventoryType?: InventoryType.CivilLiabilities
    fields?: {
        descriptionLegalClaim: string
        nameOfRecipient: string
        excludeFromTaxation?: boolean
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "OutstandingBills".
 */
export interface OutstandingBill {
    category?: InventoryCategory.Liability
    inventoryType?: InventoryType.OutstandingBills
    fields?: {
        name: string
        nameOfCreditor: string
        dueDate: string
        accountOrPolicyNumber?: string
        excludeFromTaxation?: boolean
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Guarantees".
 */
export interface Guarantee {
    category?: InventoryCategory.Liability
    inventoryType?: InventoryType.Guarantees
    fields?: {
        descriptionOfGuarantee: string
        nameOfRecipient: string
        excludeFromTaxation?: boolean
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "BusinessDebts".
 */
export interface BusinessDebt {
    category?: InventoryCategory.Liability
    inventoryType?: InventoryType.BusinessDebts
    fields?: {
        /**
         * Define if the item can be linked
         */
        loansecured?: boolean
        descOfBusinessDebt: string
        businessName: string
        businessOrTaxId: string
        excludeFromTaxation?: boolean
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "ContractsForServices".
 */
export interface ContractsForServices {
    category?: InventoryCategory.Liability
    inventoryType?: InventoryType.ContractsForServices
    fields?: {
        descOfServiceContract: string
        dueDate: string
        excludeFromTaxation?: boolean
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TaxHoldbacks".
 */
export interface TaxHoldback {
    category?: InventoryCategory.Liability
    inventoryType?: InventoryType.TaxHoldbacks
    fields?: {
        name: string
        excludeFromTaxation?: boolean
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "ExecutorCompensation".
 */
export interface ExecutorCompensation {
    category?: InventoryCategory.Liability
    inventoryType?: InventoryType.ExecutorCompensation
    fields?: {
        name: string
        excludeFromTaxation?: boolean
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TrusteeCompensation".
 */
export interface TrusteeCompensation {
    category?: InventoryCategory.Liability
    inventoryType?: InventoryType.TrusteeCompensation
    fields?: {
        name: string
        excludeFromTaxation?: boolean
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "OtherLiability".
 */
export interface OtherLiability {
    category?: InventoryCategory.Liability
    inventoryType?: InventoryType.OtherLiability
    fields?: {
        name: string
        excludeFromTaxation?: boolean
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "CaseBankAccount".
 */
export interface CaseBankAccount {
    category?: InventoryCategory.Asset
    inventoryType?: InventoryType.CaseBankAccounts
    fields?: {
        accountType: string
        holdingInstitution: string
        accountNumber: string
        transitNumber: string
        accountNickname?: string
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "CaseInvestmentAccount".
 */
export interface CaseInvestmentAccount {
    category?: InventoryCategory.Asset
    inventoryType?: InventoryType.CaseInvestmentAccounts
    fields?: {
        accountType: string
        holdingInstitution: string
        accountNumber: string
        accountNickname?: string
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "GrantorBankAccounts".
 */
export interface GrantorBankAccounts {
    category?: InventoryCategory.Asset
    inventoryType?: InventoryType.GrantorBankAccounts
    fields?: {
        accountType: string
        institution: string
        accountNumber: string
        transitNumber?: string
        phone?: string
        address?: string
        city?: string
        state?: string
        zipCode?: string
        country?: string
        excludeFromTaxation?: boolean
        accountNickname?: string
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "AccountsReceivable".
 */
export interface AccountsReceivable {
    category?: InventoryCategory.Asset
    inventoryType?: InventoryType.AccountsReceivable
    fields?: {
        name: string
        accountNumber?: string
        excludeFromTaxation?: boolean
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Annuities".
 */
export interface Annuities {
    category?: InventoryCategory.Asset
    inventoryType?: InventoryType.Annuities
    fields?: {
        description: string
        excludeFromTaxation?: boolean
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "DigitalCurrency".
 */
export interface DigitalCurrency {
    category?: InventoryCategory.Asset
    inventoryType?: InventoryType.DigitalCurrency
    fields?: {
        quantity: number
        description: string
        bookValue: number
        excludeFromTaxation?: boolean
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "GIC".
 */
export interface GIC {
    category?: InventoryCategory.Asset
    inventoryType?: InventoryType.GIC
    fields?: {
        quantity: number
        description: string
        bookValue: number
        excludeFromTaxation?: boolean
        issuanceType?: IssuanceType
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "IntellectualPropertyRights".
 */
export interface IntellectualPropertyRights {
    category?: InventoryCategory.Asset
    inventoryType?: InventoryType.IntellectualPropertyRights
    fields?: {
        description: string
        excludeFromTaxation?: boolean
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "OilGasMineralRight".
 */
export interface OilGasMineralRight {
    category?: InventoryCategory.Asset
    inventoryType?: InventoryType.OilGasMineralRight
    fields?: {
        description: string
        excludeFromTaxation?: boolean
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "PublicCompanyCertificate".
 */
export interface PublicCompanyCertificate {
    category?: InventoryCategory.Asset
    inventoryType?: InventoryType.PublicCompanyCertificate
    fields?: {
        securityType: string
        nameOfCompany: string
        excludeFromTaxation?: boolean
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "PooledMortgageFund".
 */
export interface PooledMortgageFund {
    category?: InventoryCategory.Asset
    inventoryType?: InventoryType.PooledMortgageFund
    fields?: {
        description: string
        excludeFromTaxation?: boolean
    }
}
/**
 * Represent a response with items
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Items".
 */
export interface Items {
    meta?: ItemsMeta
    links?: Links
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "ItemsMeta".
 */
export interface ItemsMeta {
    items?: {
        /**
         * The total number of items on the collection
         */
        count?: number
    }
    page?: {
        /**
         * The total number of pages
         */
        count?: number
        /**
         * The actual page number
         */
        number?: number
        /**
         * The number of items per page
         */
        size?: number
    }
}
/**
 * Activity object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Activity".
 */
export interface Activity {
    estateId?: Identifier
    /**
     * Message params to be interpolated
     */
    params?: {}
    userId?: Identifier
    user?: User
    type: 'COMMENT' | 'TASK' | 'SYSTEM_ACTIVITY' | 'SAVED' | 'UPLOAD' | 'SIGNATURE' | 'WORKFLOW'
    /**
     * if the activity is pinned as important in overview
     */
    isPinned?: boolean
    message: string
    /**
     * Namespace group of the activity
     */
    ns?: string
    /**
     * Url path of the activity
     */
    path?: string
    /**
     * Url path name of the activity
     */
    pathName?: string
    /**
     * category of comment on overview
     */
    notesCategory?: string
    [k: string]: unknown
}
/**
 * User object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "User".
 */
export interface User {
    /**
     * Id of the user
     */
    id?: string
    /**
     * If the user is active or not
     */
    active?: boolean
    /**
     * The user full name computed by firstName and lastName
     */
    name?: string
    /**
     * The user first name
     */
    firstName: string
    lastName: string
    /**
     * User rule on the organization
     */
    role?: string
    /**
     * Title of the user
     */
    title?: string
    preferences?: UserPreferences
    orgId?: Identifier
    email: Email
    phoneNumber?: PhoneNumber
    address?: Address
    org?: Organization & {
        id?: Identifier
        userRole?: string
    }
    profilePic?: {
        image?: string
        filename?: string
    }
    [k: string]: unknown
}
/**
 * User preferences
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "UserPreferences".
 */
export interface UserPreferences {
    /**
     * Notifications preferences
     */
    notifications?: {
        desktop?: Desktop
        caseAssignments?: CaseAssignments
        caseNotes?: CaseNotes
        deadlineReminder?: DeadlineReminder
        digitalSignature?: DigitalSignature
        exportComplete?: ExportComplete
        importComplete?: ImportComplete
        productCommunications?: ProductCommunications
        reminderFrequency?: ReminderFrequency
        reminderFrequencies?: ('twoDays' | 'fiveDays' | 'sevenDays' | 'fourteenDays' | 'oneMonth' | 'threeMonths' | 'sixMonths' | 'oneYear')[]
        taskAssignments?: TaskAssignments
        taskReviews?: TaskReviews
        taskStatusUpdate?: TaskStatusUpdate
        workflowError?: WorkflowError
    }
    /**
     * Email preferences
     */
    email?: {
        portal?: Portal
        /**
         * Email configuration
         */
        config?: {
            /**
             * default signature of email body
             */
            signature?: string
        }
    }
}
/**
 * Postal address
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Address".
 */
export interface Address {
    /**
     * unit
     */
    unit?: string | null
    /**
     * Street
     */
    street?: string | null
    /**
     * City
     */
    city?: string | null
    /**
     * Country
     */
    country?: string | null
    /**
     * State
     */
    state?: string | null
    /**
     * Zip Code
     */
    zipCode?: string | null
}
/**
 * Organization object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Organization".
 */
export interface Organization {
    /**
     * Location of the organization. eg: US or CA
     */
    location?: 'CA' | 'US'
    /**
     * active status of this organization
     */
    active?: boolean
    /**
     * base64 image encoded to internally identify this organization
     */
    profileLogo?: string
    /**
     * true if organization is a main system org
     */
    systemOrganization?: boolean
    /**
     * The default role of the member of this organization
     */
    defaultRole?: string
    deleteScheduled?: string
    /**
     * Language selected
     */
    language?: string
    /**
     * Identifier
     */
    owner?: string
    /**
     * The organization name
     */
    name: string
    /**
     * The organization domain (e.g acme for acme.estateably.com)
     */
    domain?: string
    theme?: Theme
    sso?: Sso
    /**
     * Inform sample that want to be created, non existent sample will be ignored
     */
    sample?: string
    /**
     * invitations
     */
    invitations?: UserInvitation[]
    address?: Address
    /**
     * The compensation fees of the organization
     */
    compensationFees?: {
        estates?: CompensationFees
        trusts?: CompensationFees
        poas?: CompensationFees
    }
    billingPlan?: PaymentMethod
    cityType?: string
    countyType?: string
    phone?: string
    faxNumber?: string
    hstNumber?: string
    additionalSalesTaxRegistrationNumber?: string
    info?: {}
    tokenize?: Tokenize
    /**
     * Vault Settings
     */
    vaultSettings?: {
        setup: OrgVaultSettingsSetup
        /**
         * Starting number for the autogeneration
         */
        vaultWillEnvelopeStartNumber?: string
    }
    currentPlan?: BillingPlan
}
/**
 * Theme variables
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Theme".
 */
export interface Theme {
    name?: 'blue' | 'dark' | 'light'
    useWideLogo?: boolean
    leftMenu?: {
        logo?: string
    }
    report?: {
        logo?: string
        title?: string
    }
    dashboard?: {
        logo?: string
        title?: string
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "sso".
 */
export interface Sso {
    /**
     * If the sso is enabled
     */
    enabled?: boolean
    /**
     * strategy used for the sso
     */
    strategy?: string
    /**
     * provider used for the sso
     */
    provider?: string
    /**
     * forget password url when sso is enabled
     */
    forgetPasswordUrl?: string
    /**
     * provide manual login option as well, when sso is on
     */
    displayManualLogin?: boolean
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "UserInvitation".
 */
export interface UserInvitation {
    /**
     * Person to invite
     */
    invitee: {
        name?: string
        /**
         * Invitee email
         */
        email?: string
    }
    teams?: Identifier[]
    /**
     * The invitation message
     */
    message?: string
    /**
     * The invitation role
     */
    role?: string
    _id?: Identifier
    meta?: UserInvitationMeta
}
/**
 * Compensation fee sub object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "CompensationFees".
 */
export interface CompensationFees {
    isDefault?: boolean | null
    /**
     * A compensation fee of type "By Account"
     */
    byAccount?: {
        capitalReceipts: number
        revenueReceipts: number
        capitalDisbursements: number
        revenueDisbursements: number
        careAndManagementFee: number
        calculationMethod: string
    }
    byTierPercentage?: {
        feeOnExcess: number
        brackets?: {
            fee: number
            value: number
        }[]
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "PaymentMethod".
 */
export interface PaymentMethod {
    /**
     * Identifier
     */
    orgId?: string
    /**
     * the type of subscriptions of the org
     */
    subscriptionTypes?: string[]
    plan?: BillingPlanName
    planLimitations?: PlanLimitations
    freeCases?: FreeCases
    paymentProvider?: PaymentProviderTypes
    /**
     * Billing email from the client
     */
    billingEmail?: string
    /**
     * Duration of the trial period in days
     */
    trialDays?: number | null
    /**
     * Identifier of the org customer on the stripe plataform
     */
    stripeCustomerId?: string
    /**
     * Identifier of the subscription on the stripe plataform
     */
    stripeOrgSubscriptionId?: string
    stripeCustomerEmail?: Email
    /**
     * date of when the trial will be over
     */
    trialEndsAt?: string | null
    billingFrequency?: BillingFrequency
    /**
     * the payment method information
     */
    paymentMethod?: {
        /**
         * last four digits of the credit card
         */
        last4Digits?: string
        /**
         * brand of the credit card
         */
        brand?: string
        cardExpiration?: CardExpiration
        /**
         * the billing details of the credit card
         */
        billingDetails?: {
            /**
             * credit card owner name
             */
            name?: string
            email?: Email
            /**
             * Phone number
             */
            phoneNumber?: string
            address?: BillingAddress
        }
    } | null
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "PlanLimitations".
 */
export interface PlanLimitations {
    totalUsers?: number
}
/**
 * number of cases that should be flag as free once free trial it's finished.
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "FreeCases".
 */
export interface FreeCases {
    estate?: number
    probate?: number
    trust?: number
    powerOfAttorney?: number
}
/**
 * Tokenization config
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Tokenize".
 */
export interface Tokenize {
    enabled?: boolean
    rules?: string[]
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "BillingPlan".
 */
export interface BillingPlan {
    id: string
    name: BillingPlanName
    legacy?: boolean
    displayName?: string
    features?: {}
    currency?: 'cad' | 'usd'
    country?: 'CA' | 'US'
    plan?: BillingPlanName
    billingFrequency?: BillingFrequency
    paymentProvider?: 'stripe' | 'orgTiers' | 'free' | 'sample'
    planLimitations?: {
        totalUsers?: number
        limitSsoDashboardAccess?: boolean
        limitTaskDashboardAccess?: boolean
        limitReportsDashboardAccess?: boolean
        totalJurisdictions?: number
        haveTaskTemplatesAccess?: boolean
        haveOrgIntegrationsAccess?: boolean
    }
    prices: PlanPrice[]
    addons?: {
        addonId: string
        stripeSubscriptionId: string
    }[]
    vaultSubscriptionId?: string
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Price".
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "PlanPrice".
 */
export interface PlanPrice {
    value: string
    billingScheme?: string
    name: string
    currency?: string
    type?: string
    id?: string
    active?: boolean
    metadata?: {
        type?: string
    }
    product?: Product
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Product".
 */
export interface Product {
    name?: string
    interval?: string
    metadata?: {
        name?: string
        type?: string
        addons?: string
        planName?: string
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "activateAddons".
 */
export interface ActivateAddons {
    type: 'addons'
    data?: {
        attributes?: AddonsConfig
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "AddonsConfig".
 */
export interface AddonsConfig {
    authCode?: string
    redirect_uri?: string
    config?: CaseImportConfig
}
/**
 * Specific map for import/update case detail from one external system
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "CaseImportConfig".
 */
export interface CaseImportConfig {
    intake?: {
        /**
         * Number of the case file
         */
        fileNumber?: string
        /**
         * State/Province for this case file ( Jurisdiction )
         */
        state?: string
        /**
         * Name of client (Deceased, Settlor, Grantor)
         */
        clientName?: string
        /**
         * Date of the death [Estates]
         */
        dateOfDeath?: string
        /**
         * Client Marital Status
         */
        maritalStatus?: string
        /**
         * Client Birth Date
         */
        birthDate?: string
        /**
         * Client tax identification Number for current
         */
        sinNumber?: string
        /**
         * Client tax identification Number
         */
        taxIdentificationNumber?: string
        /**
         * Client street address
         */
        'address.street'?: string
        /**
         * Client state address
         */
        'address.state'?: string
        /**
         * Client street address
         */
        'address.city'?: string
        /**
         * Client country address
         */
        'address.country'?: string
        /**
         * Client zip/postal code address
         */
        'address.zipCode'?: string
    }
    contact?: {
        /**
         * Type of the contact. It's will be used to define if this contact it's a Beneficiary, trustee, etc.
         */
        typeContact?: string
        /**
         * Name of the Contact (Beneficiary, Executor, Trustee, Other Contact)
         */
        name?: string
        /**
         * Name of the contact on the will
         */
        willname?: string
        /**
         * Beneficiary - Define if this beneficiary it's a universal or particular beneficiary
         */
        legacyType?: string
        /**
         * Beneficiary - Value cash bequest on the will
         */
        cashBequest?: string
        /**
         * Beneficiary - Cash owned to the deceased
         */
        cashOwedToDeceased?: string
        /**
         * Contact prefix name
         */
        prefix?: string
        /**
         * Contact phone number
         */
        phone?: string
        /**
         * Contact Birth Date
         */
        birth?: string
        /**
         * Contact Date of Death
         */
        death?: string
        /**
         * Contact tax identification Number for current
         */
        sinNumber?: string
        /**
         * Contact tax identification Number
         */
        taxIdentificationNumber?: string
        /**
         * Contact street address
         */
        'address.street'?: string
        /**
         * Contact state address
         */
        'address.state'?: string
        /**
         * Contact street address
         */
        'address.city'?: string
        /**
         * Contact country address
         */
        'address.country'?: string
        /**
         * Contact zip/postal code address
         */
        'address.zipCode'?: string
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "AddonsListResource".
 */
export interface AddonsListResource {
    type: 'addons'
    id: Identifier
    attributes: AddonsItem
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "AddonsItem".
 */
export interface AddonsItem {
    uuid?: string
    name?: string
    logoURL?: string
    type?: string[]
    enabled?: string
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "AddonsResource".
 */
export interface AddonsResource {
    type: 'addons'
    id: Identifier
    attributes: Addons
    meta?: AddonsMeta
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Addons".
 */
export interface Addons {
    uuid?: string
    name?: string
    type?: string[]
    description?: string
    logoURL?: string
    shortDescription?: string
    enabled?: string
    needToReauthorize?: boolean
    authorizeURL?: string
    config?: CaseImportConfig
    fields?: AddonsOptions
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "AddonsOptions".
 */
export interface AddonsOptions {
    intake?: {
        id: string
        label: string
    }[]
    contact?: {
        id: string
        label: string
    }[]
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "AddonsMeta".
 */
export interface AddonsMeta {
    capabilities?: AddonsCapabilities
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "AddonsCapabilities".
 */
export interface AddonsCapabilities {
    intake?: {
        name: string
        label: string
        disabled?: boolean
    }[]
    contact?: {
        name: string
        label: string
        disabled?: boolean
    }[]
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "AddonsCasesResource".
 */
export interface AddonsCasesResource {
    type: 'addons'
    id: Identifier
    attributes: AddonsCaseItem
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "AddonsCaseItem".
 */
export interface AddonsCaseItem {
    name?: string
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "AddonsCaseDetailResource".
 */
export interface AddonsCaseDetailResource {
    type: 'addons'
    id: Identifier
    attributes: AddonsCaseDetailItem
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "AddonsCaseDetailItem".
 */
export interface AddonsCaseDetailItem {
    intake?: {
        name?: string
        fileNumber?: string
        firstName?: string
        lastName?: string
    }
    contacts?: {
        firstName?: string
        lastName?: string
        name?: string
    }[]
}
/**
 * JSON:API response returning an all requested Application Configurations
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "GetApplicationConfigurationResponse".
 */
export interface GetApplicationConfigurationResponse {
    data?: {
        type: 'applicationConfiguration'
        attributes: GetApplicationConfiguration
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "GetApplicationConfiguration".
 */
export interface GetApplicationConfiguration {
    noticeToCreditors?: {
        baseUrl?: string
    }
    prismatic?: {
        /**
         * Prismatic URL. Defaults to US commercial https://app.prismatic.io. Can be overridden for custom domains or other regions
         */
        url?: string
        /**
         * JWT token to initialize the Prismatic Embedded SDK on the Frontend
         */
        token?: string
    }
}

/**
 * JSON:API request to authenticate a user
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "LoginRequest".
 */
export interface LoginRequest {
    data?: {
        type: 'auth'
        attributes: Credentials
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Credentials".
 */
export interface Credentials {
    /**
     * The username to authenticate (might be an email)
     */
    username: string
    /**
     * The password of the user to authenticate
     */
    password: string
}
/**
 * JSON:API response when a user is authenticated successfully
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "LoginResponse".
 */
export interface LoginResponse {
    data?: {
        type?: 'auth'
        id?: Identifier
        attributes?: Authorization
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Authorization".
 */
export interface Authorization {
    token: Token
    features?: {}
    prices?: PlanPrice[]
    /**
     * Identifier
     */
    _id?: string
}
/**
 * JSON:API response with sso configuration for a given org domain
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "AuthConfigurationResponse".
 */
export interface AuthConfigurationResponse {
    data?: {
        type?: 'auth'
        attributes?: Sso & {
            /**
             * Location of the organization. eg: US or CA
             */
            location?: 'CA' | 'US'
            address?: Address
        }
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "page".
 */
export interface Page {
    count?: number
    number?: number
    size?: number
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "success".
 */
export interface Success {
    data: Data
    /**
     * To reduce the number of HTTP requests, servers **MAY** allow responses that include related resources along with the requested primary resources. Such responses are called "compound documents".
     */
    included?: Resource[]
    meta?: Meta
    /**
     * Link members related to the primary data.
     */
    links?: Links & Pagination
    jsonapi?: Jsonapi
}
/**
 * "Resource objects" appear in a JSON:API document to represent resources.
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "resource".
 */
export interface Resource {
    type: string
    id: string
    attributes?: Attributes
    relationships?: Relationships
    links?: Links
    meta?: Meta
}
/**
 * Members of the attributes object ("attributes") represent information about the resource object in which it's defined.
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "attributes".
 */
export interface Attributes {
    /**
     * Attributes may contain any valid JSON value.
     *
     * This interface was referenced by `Attributes`'s JSON-Schema definition
     * via the `patternProperty` "^(?!relationships$|links$|id$|type$)\w[-\w_]*$".
     */
    [k: string]: {
        [k: string]: unknown
    }
}
/**
 * Members of the relationships object ("relationships") represent references from the resource object in which it's defined to other resource objects.
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "relationships".
 */
export interface Relationships {
    /**
     * This interface was referenced by `Relationships`'s JSON-Schema definition
     * via the `patternProperty` "^(?!id$|type$)\w[-\w_]*$".
     */
    [k: string]: {
        [k: string]: unknown
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "pagination".
 */
export interface Pagination {
    /**
     * The first page of data
     */
    first?: string | null
    /**
     * The last page of data
     */
    last?: string | null
    /**
     * The previous page of data
     */
    prev?: string | null
    /**
     * The next page of data
     */
    next?: string | null
}
/**
 * An object describing the server's implementation
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "jsonapi".
 */
export interface Jsonapi {
    version?: string
    meta?: Meta
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "failure".
 */
export interface Failure {
    errors: Error[]
    meta?: Meta
    jsonapi?: Jsonapi
    links?: Links
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "error".
 */
export interface Error {
    /**
     * A unique identifier for this particular occurrence of the problem.
     */
    id?: string
    links?: Links
    /**
     * The HTTP status code applicable to this problem, expressed as a string value.
     */
    status?: string
    /**
     * An application-specific error code, expressed as a string value.
     */
    code?: string
    /**
     * A short, human-readable summary of the problem. It **SHOULD NOT** change from occurrence to occurrence of the problem, except for purposes of localization.
     */
    title?: string
    /**
     * A human-readable explanation specific to this occurrence of the problem.
     */
    detail?: string
    source?: {
        /**
         * A JSON Pointer [RFC6901] to the associated entity in the request document [e.g. "/data" for a primary data object, or "/data/attributes/title" for a specific attribute].
         */
        pointer?: string
        /**
         * A string indicating which query parameter caused the error.
         */
        parameter?: string
    }
    meta?: Meta
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "info".
 */
export interface Info {
    meta: Meta
    links?: Links
    jsonapi?: Jsonapi
}
/**
 * A resource object **MAY** contain references to other resource objects ("relationships"). Relationships may be to-one or to-many. Relationships can be specified by including a member in a resource's links object.
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "relationshipLinks".
 */
export interface RelationshipLinks {
    /**
     * A link **MUST** be represented as either: a string containing the link's URL or a link object.
     */
    self?:
        | string
        | {
              /**
               * A string containing the link's URL.
               */
              href: string
              meta?: Meta
          }
    related?: Link
    [k: string]: unknown
}
/**
 * The "type" and "id" to non-empty members.
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "linkage".
 */
export interface Linkage {
    type: string
    id: string
    meta?: Meta
}
/**
 * JSON:API request to create or update a beneficiary
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "BeneficiaryRequest".
 */
export interface BeneficiaryRequest {
    data?: {
        type: 'beneficiaries'
        attributes: Beneficiary
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Beneficiary".
 */
export interface Beneficiary {
    /**
     * The beneficiary first name(s)
     */
    firstName?: string | null
    /**
     * The beneficiary middle name(s)
     */
    middleName?: string | null
    /**
     * The beneficiary last name(s)
     */
    lastName: string
    /**
     * The beneficiary name
     */
    name?: string
    /**
     * prefix for name
     */
    prefix?: string
    /**
     * The name as it appears in the will
     */
    willname?: string
    /**
     * Is it a universal beneficiary ?
     */
    universal: boolean
    /**
     * Percentage that should be distributed if the beneficiary is a universal
     */
    universalPercentage?: number | null
    /**
     * Fraction Numerator if allocation type is fraction
     */
    allocationFractionNumerator?: number
    /**
     * Fraction Denominator if allocation type is fraction
     */
    allocationFractionDenominator?: number
    /**
     * Amount of cash reserved to this beneficiary
     */
    cashBequest?: number
    /**
     * Amount of cash owned to the deceased
     */
    cashOwedToDeceased?: number
    estateId?: Identifier
    /**
     * The type of beneficiary
     */
    typeOfBeneficiary?: string
    /**
     * Family member type, required when type is familyMember
     */
    familyMemberType?: string | null
    /**
     * Email address
     */
    email?: string | null
    /**
     * The beneficiary phone number (*DEPRECATED*, use phoneNumbers instead)
     */
    phone?: string | null
    /**
     * List of phone numbers belonging to the beneficiary
     */
    phoneNumbers?: string[]
    /**
     * Used only when the type is legalPerson
     */
    registrationNumber?: string
    /**
     * Used only when the type is familyMember or nonFamilyMember
     */
    sinNumber?: string
    /**
     * The beneficiary date of birth
     */
    birth?: string | null
    address?: Address
    /**
     * The beneficiary addresses
     */
    addresses?: AddressWithType[]
    /**
     * Is it a minor person ?
     */
    isMinor?: boolean
    /**
     * Is this person deceased ?
     */
    isDeceased?: boolean
    /**
     * Is it a mentally Incapable person ?
     */
    isMentallyIncapable?: boolean
    /**
     * Has this beneficiary been served?
     */
    hasBeenServed?: string | boolean
    /**
     * Reason why beneficiary has not been served
     */
    hasNotBeenServedReason?: string | null
    /**
     * Has this beneficiary a legal representative?
     */
    hasLegalRepresentative?: boolean | null
    /**
     * Type of representative
     */
    typeOfRepresentative?: string
    /**
     * name of Law firm, if representative is lawyer
     */
    lawFirmName?: string
    /**
     * First name of representative
     */
    representativeFirstName?: string
    /**
     * Last name of representative
     */
    representativeLastName?: string
    /**
     * Phone of representative
     */
    representativePhone?: string
    /**
     * Email of representative
     */
    representativeEmail?: string
    representativeAddress?: Address
    /**
     * Dynamic fields to store custom data (like secondary legal representative)
     */
    fields?: {
        [k: string]: unknown
    }
}
/**
 * Postal address with type
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "AddressWithType".
 */
export interface AddressWithType {
    addressType: AddressTypes
    /**
     * unit/suite/apt
     */
    unit?: string | null
    /**
     * Street
     */
    street?: string | null
    /**
     * City
     */
    city?: string | null
    /**
     * Country
     */
    country?: string | null
    /**
     * State
     */
    state?: string | null
    /**
     * Zip Code
     */
    zipCode?: string | null
}
/**
 * JSON:API response returning an Beneficiary
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "BeneficiaryResponse".
 */
export interface BeneficiaryResponse {
    data?: BeneficiaryResource
}
/**
 * Resource representing a Beneficiary
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "BeneficiaryResource".
 */
export interface BeneficiaryResource {
    type?: 'beneficiaries'
    id?: Identifier
    meta?: ItemMeta & {
        /**
         * Amount of cash bequest not distribued
         */
        cashBequestLeftToDistribute?: number
        createdBy?: Identifier
    }
    attributes?: Beneficiary
}
/**
 * JSON:API request to create a payment method within a billing plan
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "SetupPaymentRequest".
 */
export interface SetupPaymentRequest {
    data?: {
        type?: 'billingPlans'
        attributes: {
            /**
             * The id of the payment method created on stripe
             */
            paymentMethodId?: string
            plan?: BillingPlanName
            address?: BillingAddress
        }
    }
}
/**
 * JSON:API request to update a payment detail
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "UpdatePaymentDetailsRequest".
 */
export interface UpdatePaymentDetailsRequest {
    data?: {
        type?: 'billingPlans'
        attributes: {
            address?: BillingAddress
            cardExpiration?: CardExpiration
            /**
             * Cardholder name
             */
            cardholderName?: string | null
            /**
             * Email in the payment method
             */
            email?: string
        }
    }
}
/**
 * JSON:API response returning an billing plan with set payment method
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "PaymentMethodResponse".
 */
export interface PaymentMethodResponse {
    data?: PaymentMethodResource & {
        meta?: {
            currentPlan?: BillingPlan
            orgSubscription?: {
                currentBillingCycleDate?: string
                nextBillingCycleDate?: string
            }
            plans?: BillingPlan[]
            autotax?: boolean
            stripeKey?: string
            address?: BillingAddress
            notice?: NoticeMeta
        }
    }
}
/**
 * Resource representing a billing plan with payment method
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "PaymentMethodResource".
 */
export interface PaymentMethodResource {
    type?: 'billingPlans'
    id?: Identifier
    attributes?: PaymentMethod
}
/**
 * Resource representing a invoice for a org
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "InvoiceResource".
 */
export interface InvoiceResource {
    type?: 'billingPlans'
    id?: Identifier
    attributes?: Invoice
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Invoice".
 */
export interface Invoice {
    description?: string
    /**
     * The invoice number
     */
    number?: string
    /**
     * Timestamp of when the invoice was paid
     */
    paidAt?: number
    /**
     * amount paid on the invoice
     */
    amountPaid?: number
    /**
     * currency of the invoice
     */
    currency?: string
    /**
     * status of the invoice
     */
    status?: string
    /**
     * invoice pdf url
     */
    pdf?: string
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "BillingCycleDatesResponse".
 */
export interface BillingCycleDatesResponse {
    data?: {
        type?: 'billingPlans'
        attributes: {
            /**
             * Represents the day when the new plan will be charged
             */
            newBillingDateCycle: string
            /**
             * Represents the next billing date, after subscribing to the new plan, considering the latest billing frequency selected.
             */
            nextBillingDateCycle: string
        }
    }
}
/**
 * JSON:API response returning a full charity object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "CharityResponse".
 */
export interface CharityResponse {
    meta?: ItemMeta
    type?: 'charities'
    id?: Identifier
    attributes?: Charity
}
/**
 * Charity object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Charity".
 */
export interface Charity {
    name?: string
    registry?: string
    address?: Address
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "EstateCollaboratorsResource".
 */
export interface EstateCollaboratorsResource {
    type: 'collaborators'
    id: Identifier
    attributes: Collaborator
}
/**
 * Collaborator object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Collaborator".
 */
export interface Collaborator {
    user?: User
}
/**
 * JSON:API request to add a estate collaborator
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "AddEstateCollaboratorRequest".
 */
export interface AddEstateCollaboratorRequest {
    data?: {
        type: 'collaborators'
        attributes: {
            userId?: Identifier
        }
    }
}
/**
 * JSON:API request to create or update a comment
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "CommentRequest".
 */
export interface CommentRequest {
    data?: {
        /**
         * A binary file attached to the comment
         */
        file?: string
        /**
         * The comment message in the {{var1}} template format
         */
        message: string
        isTrusteeMinutes?: boolean
        isPinned?: boolean
        /**
         * The comment namespace(ex. taskId)
         */
        ns: string
        /**
         * Path of the task associated with this comment
         */
        path: string
        /**
         * Path name or task name associated with this comment
         */
        pathName: string
        /**
         * JSON Object string containing the params that will be processed into the message template
         */
        params?: string
    }
}
/**
 * JSON:API request to create or update a comment
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "CommentRequestUpdate".
 */
export interface CommentRequestUpdate {
    data?: {
        type: 'comments'
        attributes?: {
            /**
             * The comment message in the {{var1}} template format
             */
            message: string
            /**
             * JSON Object string containing the params that will be processed into the message template
             */
            params?: string
        }
    }
}
/**
 * Resource representing an comment
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "CommentResource".
 */
export interface CommentResource {
    type: 'comments'
    meta?: ItemMeta
    id: Identifier
    attributes: Comment
}
/**
 * Comment object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Comment".
 */
export interface Comment {
    /**
     * A binary file attached to the comment
     */
    attachment?: {
        /**
         * The name of the file attached to the comment
         */
        originalname?: string
        /**
         * The size of the file attached to the comment
         */
        size?: number
        /**
         * The size of the file attached to the comment
         */
        mimetype?: string
        storeId?: string
    }
    /**
     * The comment message
     */
    message?: string
    isTrusteeMinutes?: boolean
    isPinned?: boolean
    /**
     * JSON Object containing params that will be processed into the message template
     */
    params?: {}
    /**
     * The comment namespace(ex. taskId)
     */
    ns?: string
    /**
     * Path of the task associated with this comment
     */
    path?: string
    /**
     * Path name or task name associated with this comment
     */
    pathName?: string
    user?: User
    [k: string]: unknown
}
/**
 * JSON:API response to update a list of contacts
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "ContactsRequest".
 */
export interface ContactsRequest {
    data?: {
        type?: 'contacts'
        attributes?: ContactList
    }
}
/**
 * Contacts
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Contacts".
 */
export interface ContactList {
    contacts?: Contact[]
}
/**
 * Contact
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Contact".
 */
export interface Contact {
    /**
     * The contact category (ie. lawyer, accountant, ...)
     */
    category?: string
    /**
     * The contact phone number DEPRECATED - use `phoneNumbers` instead
     */
    phoneNumber?: string
    /**
     * The contact`s phone numbers
     */
    phoneNumbers?: string[]
    /**
     * The contact name
     */
    name?: string
    /**
     * The name referred to in the will
     */
    willname?: string
    /**
     * The contact prefix
     */
    prefix?: string
    /**
     * Email address
     */
    email?: string
    /**
     * The contact addresses
     */
    addresses?: AddressWithType[]
}
/**
 * Resource representing an Contact
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "ContactResource".
 */
export interface ContactResource {
    type: 'contacts'
    id: Identifier
    attributes: Contact
}
/**
 * JSON:API request to create a distributionRequest
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TrustDistributionRequestCreateRequest".
 */
export interface TrustDistributionRequestCreateRequest {
    data?: TrustDistributionRequestResource
}
/**
 * Resource representing a distributionRequest
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TrustDistributionRequestResource".
 */
export interface TrustDistributionRequestResource {
    type: 'distributionRequests'
    id: Identifier
    attributes: TrustDistributionRequest
    meta?: ItemMeta & {
        beneficiaryName?: string
        approvedOrRejectedBy?: {
            id?: string
            name?: string
        }[]
    }
}
/**
 * DistributionRequest object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TrustDistributionRequest".
 */
export interface TrustDistributionRequest {
    date: string
    /**
     * Identifier
     */
    beneficiary: string
    value: number
    accountType: string
    approved?: boolean
    approvedOrRejectedBy?: Identifier[]
    requestNote?: string
    answerNote?: string
    estateId?: Identifier
}
/**
 * JSON:API request to update a distributionRequest
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TrustDistributionRequestUpdateRequest".
 */
export interface TrustDistributionRequestUpdateRequest {
    data?: TrustDistributionRequestResource
}
/**
 * JSON:API request to distribute asset to one or many beneficiaries
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "AssetDistributionRequest".
 */
export interface AssetDistributionRequest {
    data?: AssetDistributionResource
}
/**
 * Resource representing an asset distribution
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "AssetDistributionResource".
 */
export interface AssetDistributionResource {
    type?: string
    id?: string
    attributes?: AssetDistributionItem
}
/**
 * Asset Distribution item
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "AssetDistributionItem".
 */
export interface AssetDistributionItem {
    estateId?: Identifier
    inventoryId?: Identifier
    accountId?: Identifier
    /**
     * Category of the Distribution
     */
    category?: string
    /**
     * Id of the account source of the distribution
     */
    from?: string
    /**
     * Additional informations of beneficiaries
     */
    beneficiaryInfo?: unknown[]
    /**
     * Define if the asset is probate
     */
    probate?: boolean
    /**
     * Define if the asset has been bequeathed (https://en.wikipedia.org/wiki/Bequest)
     */
    bequest?: boolean
    /**
     * Value to be distributed
     */
    value?: number
    /**
     * Quantity of the asset  distributed (e.g Jewelry . Quantity 4)
     */
    quantity?: number
    /**
     * The identifier of the beneficiaries the asset will be distributed
     */
    beneficiaries?: BeneficiaryDistribution[]
    /**
     * Type of allocation for transaction
     */
    transactionAllocationType?: 'percentage' | 'fraction' | 'evenly'
    /**
     * Asset Distribution description (e.g Jewelry distributed to Jim)
     */
    description?: string
    accountType?: 'Income' | 'Capital'
    /**
     * Date of the asset distribution
     */
    date?: string
    /**
     * Additional fields (e.g For a Bank account - Account number)
     */
    fields?: {}
    /**
     * Some notes the executor can let regarding this asset distribution
     */
    notes?: string
    /**
     * Additional calculated fields
     */
    meta?: {}
    /**
     * Update current value of the item for the value of this distribution (full distribution with price update)
     */
    finalValue?: boolean
}
/**
 * Beneficiary description
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "BeneficiaryDistribution".
 */
export interface BeneficiaryDistribution {
    /**
     * ObjectId of referencce of beneficiary resource
     */
    beneficiary?: string
    quantity?: number
    percentage?: number
    allocationFractionNumerator?: number
    allocationFractionDenominator?: number
}
/**
 * JSON:API request to distribute cash to a one or many beneficiaries
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "CashDistributionRequest".
 */
export interface CashDistributionRequest {
    data?: CashDistributionResource
}
/**
 * Resource representing a cash distribution
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "CashDistributionResource".
 */
export interface CashDistributionResource {
    type?: string
    id?: string
    attributes?: CashDistributionItem
}
/**
 * Cash Distribution item
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "CashDistributionItem".
 */
export interface CashDistributionItem {
    estateId?: Identifier
    accountId?: Identifier
    /**
     * The value of the asset distributed
     */
    value?: number
    /**
     * The identifier of the beneficiaries the asset will be distributed
     */
    beneficiaries?: BeneficiaryDistribution[]
    /**
     * Asset Distribution description (e.g Jewelry distributed to Jim)
     */
    description?: string
    /**
     * Category of the Distribution
     */
    category?: string
    /**
     * Id of the account source of the distribution
     */
    from?: string
    accountType?: 'Income' | 'Capital'
    /**
     * Define if the asset has been bequeathed (https://en.wikipedia.org/wiki/Bequest)
     */
    bequest?: boolean
    /**
     * Quantity of the asset  distributed (e.g Jewelry . Quantity 4)
     */
    quantity?: number
    /**
     * Define if the asset is probate
     */
    probate?: boolean
    /**
     * Date of the asset distribution
     */
    date?: string
    /**
     * Additional fields (e.g For a Bank account - Account number)
     */
    fields?: {}
    /**
     * Additional calculated fields
     */
    meta?: {}
    /**
     * Additional informations of beneficiaries
     */
    beneficiaryInfo?: unknown[]
    /**
     * Some notes the executor can let regarding this asset distribution
     */
    notes?: string
}
/**
 * JSON:API request to bulk distribute cash to many beneficiaries
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "BulkCashDistributionRequest".
 */
export interface BulkCashDistributionRequest {
    data?: BulkCashDistributionResource
}
/**
 * Resource representing a bulk cash distribution
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "BulkCashDistributionResource".
 */
export interface BulkCashDistributionResource {
    type?: string
    id?: string
    attributes?: {
        list?: CashDistributionItem[]
    }
}
/**
 * JSON:API request to update a distribution
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "DistributionRequest".
 */
export interface DistributionRequest {
    data?: AssetDistributionResource | CashDistributionResource
}
/**
 * JSON:API request to create a template
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TemplateRequest".
 */
export interface TemplateRequest {
    /**
     * Template
     */
    data?: {
        type: 'documentTemplates'
        attributes: TemplatePost
    }
}
/**
 * Template definition
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TemplatePost".
 */
export interface TemplatePost {
    /**
     * Estate identifier
     */
    estateId?: string
    /**
     * Document name
     */
    name?: string
    /**
     * Content to represent the variables
     */
    variables: {
        variableType?: 'date' | 'text' | 'list'
        value?: string
        items?: unknown[]
        'ui-component'?: {
            name?: string
            label?: string
        }
    }
    /**
     * Format supported for this template
     */
    formats: ('PDF' | 'DOCX')[]
}
/**
 * Template
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TemplateResource".
 */
export interface TemplateResource {
    type: 'documentTemplates'
    id: Identifier
    attributes: TemplateGet
    meta?: ItemMeta
}
/**
 * Template definition
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TemplateGet".
 */
export interface TemplateGet {
    /**
     * The document template name
     */
    name: string
    ref?: string
    /**
     * The document template description
     */
    description?: string
    /**
     * Define list of tags (labels) of the template
     */
    tags?: string[]
    /**
     * Content to represent the variables
     */
    variables?: {
        variableType?: 'date' | 'text' | 'list'
        value?: string
        items?: unknown[]
        'ui-component'?: {
            name?: string
            label?: string
        }
    }
    /**
     * Define list of tags (labels) of the template
     */
    formats: string[]
}
/**
 * Resource representing a list of templates
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TemplatesResource".
 */
export interface TemplatesResource {
    type: 'documentTemplates'
    id: Identifier
    attributes: {
        /**
         * The document template name
         */
        name?: string
        /**
         * The document template description
         */
        description?: string
        /**
         * Define list of tags (labels) of the template
         */
        tags?: string[]
    }
    meta?: ItemMeta
}
/**
 * JSON:API request to create or update an document
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "DocumentRequest".
 */
export interface DocumentRequest {
    data?: {
        attributes?: Document
    }
}
/**
 * Document object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Document".
 */
export interface Document {
    type?: 'support' | 'workflow' | 'comment' | 'folder'
    docId?: string
    resourceId?: Identifier
    order?: number
    name: string
    status?: 'INCOMPLETE' | 'COMPLETE' | 'DRAFT' | 'SIGNATURE' | 'REJECTED'
    required?: boolean
    fields?: {}
    owner?: Identifier
    estateId?: Identifier
    folderId?: IdentifierOrNull
    file?: {
        originalname?: string
        path?: string
        mimetype?: string
    }
}
/**
 * JSON:API request to update or delete multiple document
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "DocumentsRequest".
 */
export interface DocumentsRequest {
    data?: {
        id?: Identifier
        attributes?: {
            action?: 'delete' | 'update'
            value?: Document
        }
    }[]
}
/**
 * Resource representing an document
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "DocumentResource".
 */
export interface DocumentResource {
    type: 'documents'
    id: Identifier
    meta?: ItemMeta & {
        userId?: Identifier
        user?: {
            name?: string
            firstName?: string
            lastName?: string
        }
    }
    attributes: Document
}
/**
 * Resource representing document preview
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "DocumentPreviewResource".
 */
export interface DocumentPreviewResource {
    attributes?: {
        buff?: {}
        mimetype?: string
        name?: string
    }
}
/**
 * JSON:API request to add a tag to documentsTags
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "DocumentsTagCreateRequest".
 */
export interface DocumentsTagCreateRequest {
    data?: {
        attributes?: {
            tag?: string
        }
    }
}
/**
 * JSON:API response returning a list of documentsTags
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "DocumentsTagsResponse".
 */
export interface DocumentsTagsResponse {
    data?: {
        type?: string
        attributes?: string
    }[]
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "BadRequest".
 */
export interface BadRequest {
    errors?: {
        status?: string
        code?: string
        title?: string
    }[]
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "NotImplemented".
 */
export interface NotImplemented {
    errors?: {
        status?: string
        code?: string
        title?: string
    }[]
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Unauthorized".
 */
export interface Unauthorized {
    errors?: {
        status?: string
        code?: string
        title?: string
    }[]
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Forbidden".
 */
export interface Forbidden {
    errors?: {
        status?: string
        code?: string
        title?: string
    }[]
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "NotFound".
 */
export interface NotFound {
    errors?: {
        status?: string
        code?: string
        title?: string
    }[]
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "MethodNotAllowed".
 */
export interface MethodNotAllowed {
    errors?: {
        status?: string
        code?: string
        title?: string
    }[]
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "NotAcceptable".
 */
export interface NotAcceptable {
    errors?: {
        status?: string
        code?: string
        title?: string
    }[]
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Conflict".
 */
export interface Conflict {
    errors?: {
        status?: string
        code?: string
        title?: string
    }[]
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Gone".
 */
export interface Gone {
    errors?: {
        status?: string
        code?: string
        title?: string
    }[]
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "EstateUnarchiveRequest".
 */
export interface EstateUnarchiveRequest {
    data?: {
        type: 'estates'
        attributes: EstateUnarchive
    }
}
export interface EstateUnarchive {
    restoreArchiveReason: string
}
/**
 * JSON:API request to create an estate
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "EstateRequestCreate".
 */
export interface EstateRequestCreate {
    data?: {
        type: 'estates'
        attributes: {
            /**
             * The estate name
             */
            name: string
            /**
             * The province name
             */
            province: string
            /**
             * Estate Type
             */
            estateType?: string
            /**
             * If true the company is a executor
             */
            corporateExecutor: boolean
            [k: string]: unknown
        }
    }
}
/**
 * JSON:API request to update an estate
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "EstateRequestUpdate".
 */
export interface EstateRequestUpdate {
    data?: {
        type: 'estates'
        attributes: {
            /**
             * The estate name
             */
            name?: string
            /**
             * The province name
             */
            province?: string
            /**
             * If true the company is a executor
             */
            corporateExecutor?: boolean
            location?: EstateLocation
            estateType?: CaseTypes
            fields?: {
                lockingTransactionPeriodStartDate?: string
                lockingTransactionPeriodEndDate?: string
                accountingConfiguration?: {
                    defaultAccount?: string
                    defaultAccountType?: string
                    /**
                     * A list of actions that the case has pinned
                     */
                    pinnedActions?: string[]
                }
                /**
                 * The compensation fees of the estate
                 */
                compensationFees?: {
                    /**
                     * A compensation fee of type "By Account"
                     */
                    byAccount?: {
                        capitalReceipts?: number
                        revenueReceipts?: number
                        capitalDisbursements?: number
                        revenueDisbursements?: number
                        careAndManagementFee?: number
                        calculationMethod?: string
                    }
                    byTierPercentage?: {
                        feeOnExcess?: number
                        brackets?: {
                            fee?: number
                            value?: number
                        }[]
                    }
                }
                /**
                 * The tax year end date of the trust
                 */
                yearEndDate?: string | null
                /**
                 * The primary trustee of the trust
                 */
                primaryTrustee?: string | null
                /**
                 * The tax number of the trust
                 */
                trustAccountTaxNumber?: string | null
                /**
                 * A list of addons that subscription currently has
                 */
                subscriptionAddons?: string[]
            }
            /**
             * The start date of the trust
             */
            startDate?: string
            [k: string]: unknown
        }
    }
}
/**
 * Estate location object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "EstateLocation".
 */
export interface EstateLocation {
    /**
     * Country of the state processing
     */
    country: string
    /**
     * Province or State of the state processing
     */
    province: string
    /**
     * County of the state processing
     */
    county?: string
}
/**
 * Resource representing an estate
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "EstateResource".
 */
export interface EstateResource {
    type: 'estates'
    id: Identifier
    attributes: Estate
}
/**
 * Estate object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Estate".
 */
export interface Estate {
    addons?: {
        uuid?: string
        link?: string
        [k: string]: unknown
    }[]
    /**
     * the type of allocation to universal beneficiaries
     */
    beneficiaryAllocationType?: 'percentage' | 'fraction' | 'evenly'
    estateType?: CaseTypes
    /**
     * The case id
     */
    caseId?: number
    /**
     * The estate name
     */
    name?: string
    /**
     * The estate status
     */
    status: 'SIGNER_REJECTED' | 'NEW' | 'IN_REVIEW' | 'PENDING_APPROVAL' | 'IN_PROGRESS' | 'COMPLETE' | 'INCOMPLETE'
    allocationType?: 'percentage' | 'fraction' | 'evenly'
    /**
     * The collaborators within this case
     */
    collaborators?: Identifier[]
    contacts?: ContactList[]
    /**
     * The province where the estate is located
     */
    province?: string
    /**
     * If true the company is a executor
     */
    corporateExecutor?: boolean
    /**
     * The executors within this case
     */
    executors?: Executor[]
    /**
     * The invitations within this case
     */
    invitations?: (Invitation & {
        _id?: Identifier
        meta?: ItemMeta
    })[]
    /**
     * Identifier
     */
    orgId?: string
    /**
     * Identifier
     */
    owner?: string
    /**
     * Identifier
     */
    teamId?: string
    location?: EstateLocation
    /**
     * The user assigned to the case
     */
    assignee?: {
        /**
         * User name
         */
        name?: string
        /**
         * Identifier
         */
        id?: string
    }
    /**
     * The fields of a case
     */
    fields?: {
        noticeToCreditors?: {
            id: string
            postedAt: string
            noticeInfo?: NoticeInput
            noticeInvoiceId?: string
        }
        publicNotice?: {
            /**
             * id of the user who created the notice
             */
            createdBy?: string
            /**
             * The date when the public notice was first posted on the integration (e.g. Column)
             */
            postedAt: string
            /**
             * The date when the public notice was last synced with the integration (e.g. Column)
             */
            lastSyncedAt?: string
            publicNoticeInfo?: PublicNoticeInput
            /**
             * The invoice id after is paid
             */
            stripeInvoiceId?: string
            /**
             * The requested id returned by column that will be used for tracking the notice
             */
            publicNoticeIntegrationId?: string
            noticeUsWebsite?: PublicNoticeWebsiteInfoUS
        }
        lockingTransactionPeriodStartDate?: string
        lockingTransactionPeriodEndDate?: string
        accountingConfiguration?: {
            defaultAccount?: string
            defaultAccountType?: string
            /**
             * A list of actions that the case has pinned
             */
            pinnedActions?: string[]
        }
        /**
         * The compensation fees of the estate
         */
        compensationFees?: {
            /**
             * A compensation fee of type "By Account"
             */
            byAccount?: {
                capitalReceipts?: number
                revenueReceipts?: number
                capitalDisbursements?: number
                revenueDisbursements?: number
                careAndManagementFee?: number
                calculationMethod?: string
            }
            byTierPercentage?: {
                feeOnExcess?: number
                brackets?: {
                    fee?: number
                    value?: number
                }[]
            }
        }
        /**
         * The tax year end date of the trust
         */
        yearEndDate?: string | null
        /**
         * The primary trustee of the trust
         */
        primaryTrustee?: string | null
        /**
         * The tax number of the trust
         */
        trustAccountTaxNumber?: string | null
        /**
         * A list of addons that subscription currently has
         */
        subscriptionAddons?: string[]
    }
    /**
     * the base currency for the case
     */
    baseCurrency?: string
    /**
     * The start date of the trust
     */
    startDate?: string
}
/**
 * Executor object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Executor".
 */
export interface Executor {
    executorType?: 'Individual' | 'Corporate'
    /**
     * The beneficiary first name(s)
     */
    firstName?: string
    /**
     * The beneficiary middle name(s)
     */
    middleName?: string
    /**
     * The beneficiary last name(s)
     */
    lastName?: string
    name?: string
    willname?: string
    prefix?: string
    /**
     * The beneficiary addresses
     */
    addresses?: AddressWithType[]
    /**
     * Invitee email
     */
    email?: string
    birth?: string
    isActing?: boolean
    noActingReason?: string
    occupation?: string
    phoneNumber?: string
    phoneNumbers?: string[]
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Invitation".
 */
export interface Invitation {
    /**
     * Identifier
     */
    inviter: string
    invitee: {
        name?: string
        email?: string
        birth?: string
        isActing?: boolean
        noActingReason?: string
        occupation?: string
        phoneNumber?: string
    }
    permission: 'READ_ONLY'
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "NoticeInput".
 */
export interface NoticeInput {
    firstName: string
    lastName: string
    middleName?: string
    alias?: string
    dateBirth: string
    dateDeath: string
    dueDate: string
    location1: string
    location2?: string
    location3?: string
    includeAffidavit: boolean
    affidavitPublicationEmail?: string
    includeNewspaper?: boolean
    content?: string
    personOfContact: string
    personOfContactAddress: string
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "PublicNoticeInput".
 */
export interface PublicNoticeInput {
    location: {
        state: string
        county: string
    }
    deceasedInfo?: {
        firstName: string
        middleName?: string
        secondMiddleName?: string
        lastName: string
    }
    /**
     * Identifier
     */
    publicNoticeDocumentsFolderId?: string
    /**
     * The document that the user will send to column
     */
    officialDocument?: {
        /**
         * Identifier
         */
        documentId?: string
        /**
         * private eternal url to download the document
         */
        externalUrl?: string
        file?: {}
    }
    status?: 'pending' | 'approved' | 'published' | 'cancelled' | 'completed'
    publicationDates?: string[]
    newspaper?: {
        newspaperId?: string
        newspaperName?: string
    }
    /**
     * This document is what the newspaper will publish, and we receive it from Column
     */
    noticePreview?: {
        /**
         * Identifier
         */
        noticePreviewDocumentId?: string
        noticeContent?: string
        noticePreviewUrl?: string
    }
    affidavit?: {
        affidavitUrl?: string
        /**
         * Identifier
         */
        affidavitDocumentId?: string
    }
    noticeInvoice?: {
        publicNoticeInvoiceId?: string
        publicNoticeInvoiceDueDate?: string
    }
    /**
     * total price of the notice in cents
     */
    price?: number
    /**
     * Identify when Column marks the notice as paid
     */
    paid?: boolean
    metadata?: {
        orgAddress?: Address
        userEmail?: Email
    }
}
/**
 * All the information related to the publication of the notice in the Estateably`s notice website
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "PublicNoticeWebsiteInfoUS".
 */
export interface PublicNoticeWebsiteInfoUS {
    /**
     * The slug to be used to construct the url of the published notice on the Estateably Notice Website
     */
    noticeSlug: string
    /**
     * the date on which the notice has been published on the website
     */
    publishedAt: string
    /**
     * the id of the created notice on the notice website DB as reference
     */
    noticeId: string
}
/**
 * Resource representing an estate summary
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "EstateSummaryResource".
 */
export interface EstateSummaryResource {
    type: 'estates'
    id: Identifier
    attributes: EstateSummary
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "EstateSummary".
 */
export interface EstateSummary {
    accounts?: (Account & {
        meta?: MetaAccount
    })[]
    /**
     * The balance of the estate case accounts
     */
    balance?: number
    /**
     * The residual balance of the estate account
     */
    residual?: number
    /**
     * The balance of the current inventory assets/liabilities
     */
    currentInventory?: number
    /**
     * The balance of the estate account
     */
    estateValue?: number
    /**
     * Total of tax payable for this estate
     */
    taxPayable?: number
    /**
     * Total value to used to calculate tax payable fot this estate
     */
    taxableEstateValue?: number
    inventory?: {
        /**
         * Total inventory balance at death
         */
        atDeath?: number
        /**
         * Total bequest inventory balance at death
         */
        atDeathBequest?: number
        /**
         * Total inventory residual
         */
        residual?: number
        /**
         * Total inventory asset value at date of death
         */
        grossAssetValueAtDeath?: number
    }
}
/**
 * Resource representing an estate of estates response
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "EstatesResource".
 */
export interface EstateResourceList {
    type: 'estates'
    id: Identifier
    attributes: Estate
    meta?: ItemMeta & {
        caseAddons?: CaseAddons[]
        /**
         * Date indicating when the case was archived
         */
        archivedAt?: string
        collaborators?: {
            /**
             * Identifier
             */
            id?: string
            /**
             * The user full name computed by firstName and lastName
             */
            name?: string
            /**
             * The user first name
             */
            firstName?: string
            lastName?: string
        }[]
    }
}
/**
 * Exchanges object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Exchanges".
 */
export interface Exchanges {
    attributes?: {
        symbolCode?: string
        type?: string
        fields?: {}
        symbolName?: string
        exchangeName?: string
        pricesHistory?: PriceHistory[]
    }
}
/**
 * Price History object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "PriceHistory".
 */
export interface PriceHistory {
    prices?: {
        low?: number
        high?: number
        average?: number
        open?: number
        close?: number
        weightAverage?: number
    }
    /**
     * Currency of the price provided
     */
    currency?: string
    date?: string
    /**
     * Date when data provider set the quotation
     */
    quotationDate?: string
}
/**
 * Resource representing an Exchanges
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "ExchangesResource".
 */
export interface ExchangesResource {
    type?: string
    id?: string
    attributes?: {
        symbolCode?: string
        symbolName?: string
        type?: string
        exchangeName?: string
        fields?: {
            foreignId?: string
        }
    }
}
/**
 * JSON:API request to execute a workflow
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "ExecutionRequest".
 */
export interface ExecutionRequest {
    data?: {
        type: 'workflows'
        attributes: {
            body: {
                pathName?: string
                province?: string
                uuidPath?: string
            }
            taskToExecute: {
                nodes?: unknown[]
            }
        }
    }
}
/**
 * Resource representing a execution
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "ExecutionResource".
 */
export interface ExecutionResource {
    type: 'workflows'
    meta?: {
        status?: string
        /**
         * Date indicating when the workflow has been started
         */
        startedAt?: string
    }
    id: string
    attributes: Execution
}
/**
 * Return the current workflow executions, including context and filtering the active tasks and sub workflows
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Execution".
 */
export interface Execution {
    nodes?: unknown[]
    context: {}
    uuid?: string
    name?: string
    template?: {}
    type?: string
}
/**
 * JSON:API request to change executor
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "ExecutorRequest".
 */
export interface ExecutorRequest {
    data?: {
        attributes?: {
            executorType?: 'Individual' | 'Corporate'
            /**
             * The beneficiary first name(s)
             */
            firstName?: string
            /**
             * The beneficiary middle name(s)
             */
            middleName?: string
            /**
             * The beneficiary last name(s)
             */
            lastName: string
            willname?: string
            prefix?: string
            /**
             * The beneficiary addresses
             */
            addresses?: AddressWithType[]
            /**
             * Invitee email
             */
            email?: string
            birth?: string
            isActing?: boolean
            noActingReason?: string
            occupation?: string
            phoneNumber?: string
            phoneNumbers?: string[]
            taskId?: string
        }
    }
}
/**
 * Resource representing an executor
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "ExecutorResource".
 */
export interface ExecutorResource {
    meta?: ItemMeta
    type: 'executors'
    id: Identifier
    attributes: Executor
}
/**
 * JSON:API request to send a document through email
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "EmailDocumentRequest".
 */
export interface EmailDocumentRequest {
    data?: {
        attributes?: EmailDocument
    }
}
/**
 * Email Document object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "EmailDocument".
 */
export interface EmailDocument {
    from: string
    to: string
    cc?: string
    bcc?: string
    subject?: string
    body?: string
    file?: {
        originalname?: string
        path?: string
        mimetype?: string
        documentId?: string
    }
    estateId?: Identifier
}
/**
 * Resource representing a document email
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "EmailDocumentResource".
 */
export interface EmailDocumentResource {
    type: 'externalMessages'
    id: Identifier
    attributes: EmailDocument
}
/**
 * JSON:API request to generate a form
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "GenerateFormRequest".
 */
export interface GenerateFormRequest {
    data?: {
        type: 'forms'
        attributes: {
            estateId: string
            formPrefix: string
            formGenerate: {}
        }
    }
}
/**
 * Resource representing a template
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "FormResource".
 */
export interface FormResource {
    type: 'forms'
    id: Identifier
    attributes: FormGet
    meta?: ItemMeta
}
/**
 * Resource object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "FormGet".
 */
export interface FormGet {
    /**
     * Form name
     */
    name: string
    /**
     * Form description
     */
    description?: string
    /**
     * Form reference
     */
    ref?: string
    /**
     * Define list of tags (labels) of the form
     */
    tags?: string[]
    /**
     * The form itself
     */
    content: {}
}
/**
 * JSON:API request to update a list of globalContacts
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "GlobalContactsRequest".
 */
export interface GlobalContactsRequest {
    data?: {
        type?: 'globalContacts'
        attributes?: GlobalContact
    }
}
/**
 * GlobalContact
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "GlobalContact".
 */
export interface GlobalContact {
    /**
     * The global Contact category (ie. lawyer, accountant, ...)
     */
    category: string
    /**
     * The globalContact phone number
     */
    phoneNumber?: string | null
    /**
     * The global Contact name
     */
    name: string
    /**
     * Email address
     */
    email?: string | null
    /**
     * The global Contact address
     */
    address?: Address & ({} | null)
    /**
     * The contact prefix
     */
    prefix?: string | null
}
/**
 * Resource representing an GlobalContact
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "GlobalContactResource".
 */
export interface GlobalContactResource {
    meta: ItemMeta
    type: 'globalContacts'
    id: Identifier
    attributes: GlobalContact
}
/**
 * GlobalContacts
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "GlobalContacts".
 */
export interface GlobalContactList {
    globalContacts?: GlobalContact[]
}
/**
 * Resource representing a global search result
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "GlobalSearchResource".
 */
export interface GlobalSearchResource {
    id: Identifier
    type: 'globalSearch'
    attributes: {
        entityId?: string
        fields?: {
            caseId: string
            caseName?: string
            caseType?: string
            contactName?: string
            contactType?: string
            beneficiaryName?: string
            inventoryType?: string
        }
        orgId: string
        type?: string
        value?: string
    }
}
/**
 * JSON:API request to sync transactions and holdings extracted from the Holdings Parser API
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "SyncRequest".
 */
export interface SyncRequest {
    data?: {
        type: 'holdingsParser'
        attributes: SyncRequestData
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "SyncRequestData".
 */
export interface SyncRequestData {
    jobId: string
    accountId: string
    uploadType?: UploadType
    statementEndDate: string
    statementStartDate?: string
    transactions: Transaction[]
    holdings: InventoryItem[]
}
/**
 * Transaction Object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Transaction".
 */
export interface Transaction {
    /**
     * The transaction value, can be negative
     */
    value: number
    /**
     * The quantity  (e.g Stocks - Quantity 200)
     */
    quantity?: number
    /**
     * Define if the transaction has been bequeathed (https://en.wikipedia.org/wiki/Bequest)
     */
    bequest?: boolean
    /**
     * Define if the transaction has been probate
     */
    probate?: boolean
    category: TransactionCategory
    /**
     * The transaction description
     */
    description?: string
    accountType: TransactionAccountType
    /**
     * Type of allocation for transaction
     */
    transactionAllocationType?: 'percentage' | 'fraction' | 'evenly'
    /**
     * The date of the transaction
     */
    date: string
    /**
     * Additional fields (e.g For a Business Debt - Tax ID number)
     */
    fields?: {
        cashflowType?: TransactionCashFlowType
        /**
         * The ROC value. It needs to be populated when the transaction's category is `ReturnOfCapital`
         */
        rocValue?: number
        /**
         * The adjustedCostBase is used when user manually edit the Adjusted Cost Base value
         */
        adjustedCostBase?: number
        /**
         * Transaction's currency
         */
        currency?: string
        /**
         * Transaction's link with inventory item
         */
        linkedInventory?: string | null
        /**
         * Should exclude transaction from compensation
         */
        excludeFromExecutorCompensation?: boolean
        [k: string]: unknown
    }
    /**
     * Identify if the transaction is the opening balance transaction of the account
     */
    isInitialBalanceTransaction?: boolean
    /**
     * Identify if the transaction is the opening balance transaction of the account and if there is no subsequent transaction, allowing delete the initial balance transaction
     */
    canDeleteInitialBalanceTransaction?: boolean
    /**
     * Field to describe distribution to beneficiaries
     */
    beneficiaries?: {
        beneficiary?: Identifier
        percentage?: number
        allocationFractionNumerator?: number
        allocationFractionDenominator?: number
        quantity?: number
    }[]
    integration?: {
        id?: string
        type?: string
    }
    /**
     * Some notes the executor can let regarding this transaction
     */
    notes?: string
    cashflowType?: 'Deposit' | 'Withdraw'
    /**
     * Identifier
     */
    inventoryId?: string
    /**
     * Identifier
     */
    from?: string
    /**
     * Identifier
     */
    accountId?: string
    estateId?: Identifier
    /**
     * Identifier
     */
    fromAccount?: string
}
/**
 * JSON:API response returning the synced transactions and holdings
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "SyncResponse".
 */
export interface SyncResponse {
    data?: SyncResponseData
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "SyncResponseData".
 */
export interface SyncResponseData {
    type: 'inventories'
    meta?: ItemMeta
    attributes: {
        transactions: TransactionResource[]
        holdings: InventoryResource[]
    }
}
/**
 * Resource representing a transaction
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TransactionResource".
 */
export interface TransactionResource {
    type: 'transactions'
    id: Identifier
    meta?: MetaTransaction
    attributes: Transaction
}
/**
 * Transaction Meta information
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "MetaTransaction".
 */
export interface MetaTransaction {
    inventory?: InventoryItem
}
/**
 * Resource representing a inventory item
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "InventoryResource".
 */
export interface InventoryResource {
    type?: 'inventories'
    id?: Identifier
    attributes?: InventoryItem
    meta?: ItemMeta & InventoryMeta
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "InventoryMeta".
 */
export interface InventoryMeta {
    total?: {
        current?: number
        atDeath?: number
        book?: number
        residual?: {
            value?: number
            quantity?: number
        }
    }
    transactions?: {
        category?: string
        total?: number
        /**
         * sum all purchase transactions
         */
        cost?: number
    }
    container?: {
        total?: {
            current?: number
            atDeath?: number
            book?: number
            residual?: {
                value?: number
                book?: number
                quantity?: number
            }
        }
        list?: ContainerItem[]
    }
    liabilitiesLinked?: InventoryItem[]
}
/**
 * JSON:API request to create an integration
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "IntegrationRequest".
 */
export interface IntegrationRequest {
    data?: {
        type: 'integrations'
        attributes: {
            service?: IntegrationServices
            provider?: IntegrationProviders
            /**
             * Token
             */
            token?: string
            info?: IntegrationInfo
        }
    }
}
/**
 * Integrations info object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "DataAggregationInfo".
 */
export interface DataAggregationInfo {
    /**
     * Field to describe distribution to beneficiaries
     */
    accounts?: {
        /**
         * ID in the integration provider
         */
        id?: string
        /**
         * Account id of linked estateably account
         */
        estateablyAccountId?: string
        /**
         * Account description in the integration provider
         */
        description?: string
        /**
         * Transit number in the integration provider
         */
        transitNumber?: string
        /**
         * Institution number in the integration provider
         */
        institutionNumber?: string
        /**
         * account number in the integration provider
         */
        accountNumber: string
        /**
         * Account Type in the integration provider
         */
        type?: string
        /**
         * Account currency
         */
        currency?: string
    }[]
    institution?: {
        /**
         * Id of the institution
         */
        id?: string
        /**
         * name of the institution
         */
        name?: string
    }
}
/**
 * Resource representing an Integration
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "IntegrationResource".
 */
export interface IntegrationResource {
    type: 'integrations'
    id: Identifier
    attributes: Integration
    meta?: IntegrationMeta
}
/**
 * Integration object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Integration".
 */
export interface Integration {
    enabled: boolean
    status: IntegrationStatus
    service?: IntegrationServices
    provider?: IntegrationProviders
    /**
     * Integrations info object
     */
    info: DataAggregationInfo
}
/**
 * Resource representing an Integration
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "IntegrationsResource".
 */
export interface IntegrationsResource {
    type: 'integrations'
    id: 'transactions' | 'investments'
    attributes: IntegrationListItem
    meta: IntegrationMeta
}
/**
 * Integration object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "IntegrationListItem".
 */
export interface IntegrationListItem {
    enabled: boolean
    status: IntegrationStatus
    service: IntegrationServices
    provider: IntegrationProviders
    info?: IntegrationInfo
}
/**
 * Integrations Account object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "DataAggregationAccount".
 */
export interface DataAggregationAccount {
    /**
     * ID in the integration provider
     */
    id?: string
    /**
     * Account id of linked estateably account
     */
    estateablyAccountId?: string
    /**
     * Account description in the integration provider
     */
    description?: string
    /**
     * Transit number in the integration provider
     */
    transitNumber?: string
    /**
     * Institution number in the integration provider
     */
    institutionNumber?: string
    /**
     * account number in the integration provider
     */
    accountNumber: string
    /**
     * Account Type in the integration provider
     */
    type?: string
    /**
     * Account currency
     */
    currency?: string
}
/**
 * JSON:API request to create or update an inventory item
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "InventoryItemRequest".
 */
export interface InventoryItemRequest {
    data?: {
        type: 'inventories'
        attributes?: InventoryItem
    }
}
/**
 * JSON:API request or update an inventory item opening balance
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "InventoryItemEditOpeningBalanceRequest".
 */
export interface InventoryItemEditOpeningBalanceRequest {
    data?: {
        type: 'inventories'
        attributes?: InventoryItemOpeningBalance
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "InventoryItemOpeningBalance".
 */
export interface InventoryItemOpeningBalance {
    /**
     * The current value of the inventory
     */
    value: number
    /**
     * The value on the moment of death of the inventory
     */
    deathValue: number
}
/**
 * JSON:API request to repatriate an investment account
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "RepatriateRequest".
 */
export interface RepatriateRequest {
    data?: {
        type?: 'inventories'
        id?: Identifier
        attributes?: RepatriateData
    }
}
/**
 * Repatriate Object
 */
export interface RepatriateData {
    /**
     * id of the account to repatriate
     */
    toAccount: string
    /**
     * date of the transaction for cash
     */
    date?: string
    /**
     * Notes of the transfer
     */
    notes?: string
}
/**
 * JSON:API request to create or update an invitation
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "InvitationRequest".
 */
export interface InvitationRequest {
    data?: {
        attributes?: {
            /**
             * Person to invite
             */
            invitee: {
                name?: string
                /**
                 * Invitee email
                 */
                email?: string
            }
            /**
             * The invitation message
             */
            message?: string
            /**
             * The invitation permission
             */
            permission: 'read' | 'write'
        }
    }
}
/**
 * JSON:API request to change invitation permission
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "InvitationPermissionRequest".
 */
export interface InvitationPermissionRequest {
    data?: {
        attributes?: {
            permission: 'read' | 'write'
            name?: string
            prefix?: string
            /**
             * Invitee email
             */
            email?: string
            birth?: string
            isActing?: boolean
            noActingReason?: string
            occupation?: string
            phoneNumber?: string
        }
    }
}
/**
 * Resource representing an invitation
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "InvitationResource".
 */
export interface InvitationResource {
    type?: string
    id?: Identifier
    attributes?: UserInvitation
}
/**
 * Resource representing a job
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "JobResource".
 */
export interface JobResource {
    type: 'jobs'
    id: Identifier
    attributes: Job
}
/**
 * Job object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Job".
 */
export interface Job {
    status: JobStatus
    payload?: Payload
    result?: Result
    estateId: Identifier
    sessionId?: string
}
/**
 * Payload object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Payload".
 */
export interface Payload {
    docParserJobId?: string
    userId?: string
    /**
     * Content JSON with result of the job
     */
    userName?: string
    /**
     * size of the file
     */
    fileSize?: number
    /**
     * name of the file
     */
    fileName?: string
    documentType?: DocumentType
    documentCurrency?: string
    [k: string]: unknown
}
/**
 * Result object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Result".
 */
export interface Result {
    transactions?: JobResultTransaction[]
    holdings?: JobResultHolding[]
    accountNumber?: string
    parseStatus?: ParseStatus
    [k: string]: unknown
}
/**
 * The transactions returned by the document parser
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "JobResultTransaction".
 */
export interface JobResultTransaction {
    accountability?: string
    amount?: {
        currency?: string
        value?: number
    }
    category?: string
    description?: string
    descriptionValue?: number
    quantity?: number
    transactionDate?: string
    unitPrice?: number
}
/**
 * The holdings returned by the document parser
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "JobResultHolding".
 */
export interface JobResultHolding {
    id?: string
    annualIncome?: number
    bookValue?: number
    assetClass?: string
    currency?: string
    cusips?: string[]
    description?: string
    gainLoss?: number
    marketDate?: string
    marketValue?: number
    pricePerUnit?: number
    pricePerUnitMarketValue?: number
    pricePerUnitBookValue?: number
    quantity?: number
    symbol?: string[]
    symbolName?: string
    currentYield?: number
}
/**
 * JSON:API response returning a list of labels
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "LabelsResponse".
 */
export interface LabelResponseList {
    data?: LabelsResource
    meta?: {
        ns?: string
        lang?: string
    }
}
/**
 * Resource representing labels
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "LabelResource".
 */
export interface LabelsResource {
    type?: 'labels'
    attributes?: {}
}
/**
 * Label object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Label".
 */
export interface Label {
    key?: string
    value?: string
    ns?: string
    lang?: string
    orgId?: Identifier
}
/**
 * Resource representing a mention
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "MentionResource".
 */
export interface MentionResource {
    meta?: ItemMeta
    type: 'members'
    id: Identifier
    attributes: Mention
}
/**
 * Mention user object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Mention".
 */
export interface Mention {
    /**
     * The user full name computed by firstName and lastName
     */
    name?: string
    /**
     * The user first name
     */
    firstName?: string
    /**
     * The user last name
     */
    lastName?: string
    /**
     * User role on the organization
     */
    role?: string
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "NoticeResponse".
 */
export interface NoticeResponse {
    data?: {
        type?: 'notice'
        attributes: {
            url?: string
        }
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "NoticeRequestCreate".
 */
export interface NoticeRequestCreate {
    data?: {
        type?: 'notice'
        attributes: NoticeInput
    }
}
/**
 * JSON:API request to update a notification
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "NotificationRequest".
 */
export interface NotificationRequest {
    /**
     * Notification object only with status
     */
    data?: {
        type: 'notifications'
        attributes: {
            /**
             * The notification status
             */
            status: 'read' | 'unread'
        }
    }
}
/**
 * JSON:API request to update many notifications
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "NotificationsRequest".
 */
export interface NotificationRequestList {
    /**
     * Notification object only with status and id
     */
    data?: {
        type: 'notifications'
        attributes: {
            /**
             * The notification status
             */
            status: 'read' | 'unread'
        }
    }
}
/**
 * Resource representing a notification
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "NotificationResource".
 */
export interface NotificationResource {
    meta?: ItemMeta & {
        notifier?: User & {
            /**
             * Identifier
             */
            _id?: string
            meta?: ItemMeta & {
                /**
                 * Date indicating when the item was activated
                 */
                activatedAt?: string
                /**
                 * Date indicating when was the last login of this user
                 */
                lastLogingAt?: string
            }
        }
    }
    type: 'notifications'
    id: Identifier
    attributes: Notification
}
/**
 * Notification object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Notification".
 */
export interface Notification {
    /**
     * The notification title
     */
    title?: string
    /**
     * The notification message
     */
    message: string
    /**
     * Identifier
     */
    userId: string
    /**
     * Identifier
     */
    notifierId?: string
    /**
     * The path for the screen
     */
    path?: string
    /**
     * The path name for the screen
     */
    pathName?: string
    /**
     * The tag with additional information of the notification
     */
    tag?: string
    /**
     * Identifier
     */
    estateId?: string
    /**
     * The notification status
     */
    status: 'read' | 'unread'
    /**
     * The notification category
     */
    category?:
        | 'caseAssignments'
        | 'taskReviews,'
        | 'taskStatusUpdate'
        | 'taskAssignments'
        | 'caseNotes'
        | 'digitalSignature'
        | 'deadlineReminder'
        | 'importComplete'
        | 'exportComplete'
        | 'workflowError'
        | 'productCommunications'
    /**
     * Params used to interpolate in the notification message
     */
    params?: {}
}
/**
 * JSON:API request to create or update an organization
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "OrganizationRequest".
 */
export interface OrganizationRequest {
    data?: {
        type: 'orgs'
        attributes?: {
            /**
             * The organization name
             */
            name: string
            address?: Address
            /**
             * The organization domain (e.g acme for acme.estateably.com)
             */
            domain: string
            /**
             * Inform sample that want to be created, non existent sample will be ignored
             */
            sample?: string
            billingPlan?: BillingPlanRequest
            theme?: Theme
            /**
             * Person to invite
             */
            invitee: {
                name: string
                /**
                 * Invitee email
                 */
                email: string
            }
            /**
             * base64 image encoded to internally identify this organization
             */
            profileLogo?: string
            /**
             * city type
             */
            cityType?: string
            /**
             * county type
             */
            countyType?: string
            /**
             * phone number
             */
            phone?: string
            /**
             * fax number
             */
            faxNumber?: string
            /**
             * JSON stringified with entreprise info
             */
            info?: {}
            /**
             * HST registration number
             */
            hstNumber?: string
            /**
             * Additional Sales Tax Registration Number
             */
            additionalSalesTaxRegistrationNumber?: string
            tokenize?: Tokenize
            /**
             * List of allowed jurisdictions
             */
            jurisdictions?: string[]
            /**
             * Vault Settings
             */
            vaultSettings?: {
                setup: OrgVaultSettingsSetup
                /**
                 * Starting number for the autogeneration
                 */
                vaultWillEnvelopeStartNumber?: string
            }
            [k: string]: unknown
        }
    }
}
/**
 * BillingPlanRequest object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "BillingPlanRequest".
 */
export interface BillingPlanRequest {
    paymentProvider?: PaymentProviderTypes
    perCase?: boolean
    billingEmail?: string
    billingFrequency?: BillingFrequency
    perSeat?: boolean
    trialDays?: number
    freeCases?: FreeCases
    plan?: PlanTypes
}
/**
 * JSON:API request to subscribe an organization to a plan
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "SubscribeOrganizationRequest".
 */
export interface SubscribeOrganizationRequest {
    data?: {
        type: 'orgs'
        attributes?: {
            /**
             * The organization plan
             */
            plan?: string
            billingFrequency?: BillingFrequency
        }
    }
}
/**
 * JSON:API request to update an organization
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "UpdateOrganizationRequest".
 */
export interface UpdateOrganizationRequest {
    data?: {
        type: 'orgs'
        attributes?: {
            /**
             * The organization name
             */
            name?: string
            theme?: Theme
            /**
             * The organization domain (e.g acme for acme.estateably.com)
             */
            domain?: string
            /**
             * Language of the platform
             */
            language?: string
            address?: Address
            /**
             * List of allowed jurisdictions
             */
            jurisdictions?: string[]
            sso?: Sso
            /**
             * The compensation fees of the organization
             */
            compensationFees?: {
                estates?: CompensationFees
                trusts?: CompensationFees
                poas?: CompensationFees
            }
            billingPlan?: BillingPlanRequest
            /**
             * Org active
             */
            active?: boolean
            /**
             * base64 image encoded to internally identify this organization
             */
            profileLogo?: string
            /**
             * city type
             */
            cityType?: string
            /**
             * county type
             */
            countyType?: string
            /**
             * phone number
             */
            phone?: string
            /**
             * fax number
             */
            faxNumber?: string
            /**
             * JSON stringified with entreprise info
             */
            info?: {}
            /**
             * HST registration number
             */
            hstNumber?: string
            /**
             * Additional Sales Tax Registration Number
             */
            additionalSalesTaxRegistrationNumber?: string
            tokenize?: Tokenize
            /**
             * Vault Settings
             */
            vaultSettings?: {
                setup: OrgVaultSettingsSetup
                /**
                 * Starting number for the autogeneration
                 */
                vaultWillEnvelopeStartNumber?: string
            }
            [k: string]: unknown
        }
    }
}
/**
 * Resource representing an organization
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "OrganizationResource".
 */
export interface OrganizationResource {
    type: 'orgs'
    id: Identifier
    attributes: Organization
    meta: ItemsMeta & {
        /**
         * Date indicating when the item has been created
         */
        activatedAt?: string
        createdBy?: Identifier
    }
}
/**
 * JSON:API request to create or update an Organization member
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "OrganizationMemberRequest".
 */
export interface OrganizationMemberRequest {
    data?: {
        type?: 'members'
        attributes?: {
            /**
             * The user first name
             */
            firstName?: string
            /**
             * The user last name
             */
            lastName?: string
            phoneNumber?: PhoneNumber
            /**
             * the role of the member that defined the permission
             */
            role?: string
            /**
             * The title of the member
             */
            title?: string
            /**
             * user password
             */
            password?: string
            /**
             * when updating the password, need to send the old one as well
             */
            oldPassword?: string
            profilePic?: {
                image?: {}
                filename?: string
            }
            preferences?: UserPreferences
        }
    }
}
/**
 * JSON:API request to update an Organization member active status
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "OrganizationMemberActiveStatusRequest".
 */
export interface OrganizationMemberActiveStatusRequest {
    data?: {
        type?: string
        attributes?: {
            /**
             * Use to activate or deactivate users
             */
            active?: boolean
        }
    }
}
/**
 * Resource representing an organization member
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "OrganizationMemberResource".
 */
export interface OrganizationMemberResource {
    type: string
    id: Identifier
    meta?: ItemMeta & {
        /**
         * Date indicating when the item was activated
         */
        activatedAt?: string
        /**
         * Date indicating when was the last login of this user
         */
        lastLogingAt?: string
    }
    attributes: OrganizationMember
}
/**
 * OrganizationMember object
 */
export interface OrganizationMember {
    /**
     * Id of the user
     */
    id?: string
    /**
     * If the user is active or not
     */
    active?: boolean
    /**
     * The user full name computed by firstName and lastName
     */
    name?: string
    /**
     * The user first name
     */
    firstName: string
    lastName: string
    /**
     * User rule on the organization
     */
    role?: string
    /**
     * Title of the user
     */
    title?: string
    preferences?: UserPreferences
    orgId?: Identifier
    email: Email
    phoneNumber?: PhoneNumber
    address?: Address
    org?: Organization & {
        id?: Identifier
        userRole?: string
    }
    profilePic?: {
        image?: string
        filename?: string
    }
}
/**
 * User object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "OrganizationMember".
 */
export interface User1 {
    /**
     * Id of the user
     */
    id?: string
    /**
     * If the user is active or not
     */
    active?: boolean
    /**
     * The user full name computed by firstName and lastName
     */
    name?: string
    /**
     * The user first name
     */
    firstName: string
    lastName: string
    /**
     * User rule on the organization
     */
    role?: string
    /**
     * Title of the user
     */
    title?: string
    preferences?: UserPreferences
    orgId?: Identifier
    email: Email
    phoneNumber?: PhoneNumber
    address?: Address
    org?: Organization & {
        id?: Identifier
        userRole?: string
    }
    profilePic?: {
        image?: string
        filename?: string
    }
    [k: string]: unknown
}
/**
 * JSON:API request to create an invitation
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "InvitationCreateRequest".
 */
export interface InvitationCreateRequest {
    data?: {
        attributes?: {
            /**
             * Person to invite
             */
            invitee: {
                name?: string
                /**
                 * Invitee email
                 */
                email?: string
            }
            teams?: Identifier[] | null
            /**
             * The invitation message
             */
            message?: string
            /**
             * The invitation role
             */
            role: string
        }
    }
}
/**
 * JSON:API request to update an invitation (change role)
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "InvitationUpdateRequest".
 */
export interface InvitationUpdateRequest {
    data?: {
        attributes?: {
            role: string
        }
    }
}
/**
 * Resource representing a permission
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "PermissionResource".
 */
export interface PermissionResource {
    type: 'permissions'
    id: PermissionIdentifier
    attributes: Permission
}
/**
 * Permission object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Permission".
 */
export interface Permission {
    resource: string
    role?: string
    action: string
    group?: string
    category?: string
    attributes?: string[]
    /**
     * Conditional used to validate the permissions using role-acl
     */
    condition?: {
        Fn?: string
        args?: unknown[] | {}
    }
    scope?: PermissionScope
    description?: string
}
/**
 * JSON:API request to create a phase
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "PhaseCreateRequest".
 */
export interface PhaseCreateRequest {
    data?: PhaseResourceToSave
}
/**
 * Resource representing a phase
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "PhaseResourceToSave".
 */
export interface PhaseResourceToSave {
    type: 'phases'
    id: Identifier
    attributes: PhaseToSave
}
/**
 * Phase object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "PhaseToSave".
 */
export interface PhaseToSave {
    name: string
    /**
     * The order of the phase in the list
     */
    order?: number
}
/**
 * JSON:API request to update a phase
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "PhaseUpdateRequest".
 */
export interface PhaseUpdateRequest {
    data?: PhaseResourceToSave
}
/**
 * Resource representing a phase
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "PhaseResource".
 */
export interface PhaseResource {
    meta?: ItemMeta
    type: 'phases'
    id: Identifier
    attributes: Phase
}
/**
 * Phase object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Phase".
 */
export interface Phase {
    name: string
    /**
     * The order of the phase in the list
     */
    order?: number
    estateId?: Identifier
}
/**
 * Resource representing a list of task phases names
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TasksPhasesNamesResource".
 */
export interface TasksPhasesNamesResource {
    attributes: {
        name: string
    }
}
/**
 * JSON:API response returning a list of addresses
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "PlacesResponse".
 */
export interface PlaceResponseList {
    data?: {
        type?: 'places'
        attributes?: {
            address?: string
            placeId?: string
        }
    }[]
}
/**
 * JSON:API response returning a full address object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "PlaceResponse".
 */
export interface PlaceResponse {
    data?: {
        type: 'places'
        attributes: Place
    }
}
/**
 * Place object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Place".
 */
export interface Place {
    address?: PlaceAddress
}
export interface PlaceAddress {
    houseNumber?: string
    streetName?: string
    fullStreet?: string
    city?: string
    town?: string
    state?: string
    stateShort?: string
    country?: string
    countryShort?: string
    postalCode?: string
}

/**
 * Resource representing an Integration
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "IntegrationData".
 */
export interface IntegrationData {
    type: 'integrations'
    id: string
    attributes: IntegrationAttributes
    meta: IntegrationMetadata
}

/**
 * Integration attributes
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "IntegrationAttributes".
 */
export interface IntegrationAttributes {
    /**
     * The name of the Integration.
     */
    name: string
    /**
     * Additional notes about the Integration.
     */
    description?: string
    /**
     * Specifies the category of the Integration.
     */
    category?: string
    /**
     * Rich text documentation to accompany the Integration.
     */
    documentation?: string
    /**
     * The URL for the avatar image.
     */
    avatarUrl?: string
    /**
     * The labels that are associated with the object.
     */
    labels?: unknown[]
    /**
     * A JSON list of objects where each object has a key for name and URL that together describe the Attachment.
     */
    attachments?: string

    [k: string]: unknown
}

/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "PublicNoticeResponse".
 */
export interface PublicNoticeResponse {
    data?: {
        type?: 'publicNotice'
        attributes: PublicNoticeInput
    }
}

/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "PublicNoticeRequestCreate".
 */
export interface PublicNoticeRequestCreate {
    data?: {
        firstName?: string
        middleName?: string
        secondMiddleName?: string
        lastName?: string
        location?: {
            state: string
            county?: string
        }
        /**
         * The document that the user will send to column
         */
        officialDocument?: {
            /**
             * A binary file attached
             */
            file: string
        }
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "PublicNoticePayRequest".
 */
export interface PublicNoticePayRequest {
    data?: {
        type?: 'publicNotice'
        attributes: PayForPublicNotice
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "PayForPublicNotice".
 */
export interface PayForPublicNotice {}
/**
 * JSON:API response returning a team
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "ReportResponse".
 */
export interface ReportResponse {
    data?: {
        type?: 'reports'
        attributes?: {
            report?: {}[]
        }
    }
}
/**
 * JSON:API request to create a resource
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "ResourceCreateRequest".
 */
export interface ResourceCreateRequest {
    /**
     * Resource representing a resource
     */
    data?: {
        type: 'resources'
        attributes: Resource1
    }
}
/**
 * Resource object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Resource".
 */
export interface Resource1 {
    /**
     * Identifier
     */
    orgId?: string | null
    ref?: string
    name: string
    order?: number | null
    province?: string | null
    caseType?: CaseTypes[]
    description?: string
    tags?: unknown[]
    resourceType: ResourceType
    /**
     * Content JSON of the resource
     */
    content: {}
}
/**
 * JSON:API request to update a resource
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "ResourceUpdateRequest".
 */
export interface ResourceUpdateRequest {
    /**
     * Resource representing a resource
     */
    data?: {
        type: 'resources'
        attributes: {
            /**
             * Identifier
             */
            orgId?: string | null
            ref?: string
            name?: string
            order?: number
            province?: string | null
            caseType?: CaseTypes[]
            description?: string
            resourceType?: ResourceType
        }
    }
}
/**
 * Resource representing a resource
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "ResourceResource".
 */
export interface ResourceResource {
    type: 'resources'
    id: Identifier
    attributes: Resource1
    meta?: ItemMeta
}
/**
 * Resource representing a resource
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "ResourcesResource".
 */
export interface ResourcesResource {
    type: 'resources'
    id: Identifier
    attributes: ResourceItemList
    meta?: ItemMeta
}
export interface ResourceItemList {
    orgId?: IdentifierOrNull
    ref?: string
    name: string
    order?: number | null
    province?: string | null
    caseType?: CaseTypes[]
    tags?: unknown[]
    description?: string
    resourceType: ResourceType
}
/**
 * JSON:API request to create a role
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "RoleCreateRequest".
 */
export interface RoleCreateRequest {
    data?: {
        type: 'roles'
        attributes: {
            /**
             * The role name
             */
            name: string
            /**
             * If it is the org default member role
             */
            isDefault?: boolean
            /**
             * The description of the role
             */
            description?: string
            /**
             * The list of permissions attached to that role
             */
            permissions: PermissionIdentifier[]
        }
    }
}
/**
 * JSON:API request to update a role
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "RoleUpdateRequest".
 */
export interface RoleUpdateRequest {
    data?: {
        type: 'roles'
        attributes: {
            /**
             * The role name
             */
            name?: string
            /**
             * If it is the org default member role
             */
            isDefault?: boolean
            /**
             * The description of the role
             */
            description?: string
            /**
             * The list of permissions attached to that role
             */
            permissions: PermissionIdentifier[]
        }
    }
}
/**
 * Resource representing a role
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "RoleResource".
 */
export interface RoleResource {
    type: 'roles'
    id: Identifier
    meta?: ItemMeta
    attributes: Role
}
/**
 * Role object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Role".
 */
export interface Role {
    /**
     * Identifier
     */
    orgId?: string
    /**
     * Is it a default role?
     */
    isDefault: boolean
    /**
     * The role name
     */
    name: string
    /**
     * The description of the role
     */
    description?: string
    /**
     * The list of permissions attached to that role
     */
    permissions: PermissionIdentifier[]
}
/**
 * JSON:API request stats for org reports
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "StatsRequest".
 */
export interface StatsRequest {
    /**
     * statistics
     */
    data?: {
        type: 'stats'
        attributes: StatsRequestData
    }
}
export interface StatsRequestData {
    time?: string
    estateId?: string
    teams?: string[]
    compare?: boolean
    type?: StatsType
}
/**
 * JSON:API response returning a team
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "StatsResponse".
 */
export interface StatsResponse {
    data?: (CasePerUserInOrg | CasePerUserInOrg[])[]
}
/**
 * todo
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "CasePerUserInOrg".
 */
export interface CasePerUserInOrg {
    type?: 'stats'
    attributes?: {
        firstName?: string
        lastName?: string
        cases?: number
    }
}
/**
 * Number of active cases per team
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "CasePerTeam".
 */
export interface CasePerTeam {
    type?: 'stats'
    attributes?: {
        cases?: number
        teamName?: string
    }
}
/**
 * JSON:API request to create or update a substitute decision maker
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "SubstituteDecisionMakerRequest".
 */
export interface SubstituteDecisionMakerRequest {
    data?: {
        type: 'substituteDecisionMakers'
        attributes: SubstituteDecisionMaker
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "SubstituteDecisionMaker".
 */
export interface SubstituteDecisionMaker {
    /**
     * The substitute decision make first name(s)
     */
    firstName?: string | null
    /**
     * The substitute decision make middle name(s)
     */
    middleName?: string | null
    /**
     * The substitute decision make last name(s)
     */
    lastName: string
    /**
     * The name as it appears in the PoA document
     */
    nameInCaseDocument?: string | null
    /**
     * Occupation of the Substitute Decision Maker
     */
    occupation?: string | null
    /**
     * prefix for name
     */
    prefix?: string | null
    caseId?: Identifier
    /**
     * Email address
     */
    email?: string | null
    /**
     * The substitute decision make phone number (*DEPRECATED*, use phoneNumbers instead)
     */
    phoneNumber?: string | null
    /**
     * List of phone numbers belonging to the beneficiary
     */
    phoneNumbers?: string[]
    /**
     * The substitute decision make date of birth
     */
    dateOfBirth?: string | null
    /**
     * The beneficiary addresses
     */
    addresses?: AddressWithType[]
    /**
     * Dynamic fields to store custom data (like secondary legal representative)
     */
    fields?: {} | null
}
/**
 * JSON:API response returning an Substitute Decision Maker
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "SubstituteDecisionMakerResponse".
 */
export interface SubstituteDecisionMakerResponse {
    data?: SubstituteDecisionMakerResource
}
/**
 * Resource representing a Substitute Decision Maker
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "SubstituteDecisionMakerResource".
 */
export interface SubstituteDecisionMakerResource {
    type: 'substituteDecisionMakers'
    id: Identifier
    meta: ItemMeta
    attributes: SubstituteDecisionMaker
}
/**
 * JSON:API request to create a taskTemplate
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TaskTemplatePostRequest".
 */
export interface TaskTemplatePostRequest {
    /**
     * TaskTemplate object to be created
     */
    data?: {
        type: 'taskTemplates'
        attributes: TaskTemplate
    }
}
/**
 * TaskTemplate object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TaskTemplate".
 */
export interface TaskTemplate {
    orgId?: string
    name: string
    fileType: 'Estate' | 'Trust' | 'PowerOfAttorney'
    tasks: TemplateTask[]
    teams?: string[]
}
/**
 * TemplateTask object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TemplateTask".
 */
export interface TemplateTask {
    uniqueId: string
    name: string
    order: number
    phaseName: string
    description: string
    checkList?: TemplateTaskCheckList[]
    selectTaskOrigin?: TaskTemplateOriginType
    parent?: {
        key: string
        phaseName?: string
    }
    dueDate?: {
        isoDuration?: string
        weekdaysOnly?: boolean
        basedOn?: '' | 'parentDueDate' | 'parentCompletedAt'
    }
}
/**
 * Task template checklist item
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TemplateTaskCheckListItem".
 */
export interface TemplateTaskCheckList {
    title: string
}
/**
 * JSON:API request to update a taskTemplate
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TaskTemplateUpdateRequest".
 */
export interface TaskTemplateUpdateRequest {
    /**
     * TaskTemplate object only with status
     */
    data?: {
        type: 'taskTemplates'
        attributes: TaskTemplate
    }
}
/**
 * Resource representing a taskTemplate
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TaskTemplateResource".
 */
export interface TaskTemplateResource {
    meta?: TaskTemplateResourceMeta
    type: 'taskTemplates'
    id: Identifier
    attributes: TaskTemplate
}
/**
 * JSON:API request to review a task
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TaskReviewRequest".
 */
export interface TaskReviewRequest {
    /**
     * Task object only with status
     */
    data?: {
        type: 'tasks'
        attributes: TaskReviewRequestData
    }
}
export interface TaskReviewRequestData {
    rejectMessage?: string
    taskStatus?: TaskReviewStatus
}
/**
 * JSON:API request to update a task
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TaskPatchRequest".
 */
export interface TaskPatchRequest {
    /**
     * Task object only with status
     */
    data?: {
        type: 'tasks'
        attributes: TaskUpdateData
    }
}
export interface TaskUpdateData {
    /**
     * The task deadline date
     */
    deadline?: string | null
    /**
     * The task assignee id
     */
    assignee?: string | null
    title?: string
    isMilestone?: boolean
    /**
     * Markdown content
     */
    body?: string
    /**
     * The number of the task in the list of task of the relevant phaseId
     */
    order?: number
    phaseId?: string
    checkList?: Todo[]
    taskStatus?: TaskStatus
    uniqueId?: string
    parent?: string
    dueDate?: {
        weekdaysOnly?: boolean
        isoDuration?: string
        basedOn?: '' | 'parentDueDate' | 'parentCompletedAt'
        rule?: DueDateRule
    }
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Todo".
 */
export interface Todo {
    id?: Identifier
    completedBy?: TodoCompletedBy
    completedOn?: string | null
    checked?: boolean
    title: string
}
/**
 * JSON:API request to update a task
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TaskPostRequest".
 */
export interface TaskPostRequest {
    /**
     * Task object only with status
     */
    data?: {
        type: 'tasks'
        attributes: TaskCreateData
    }
}
export interface TaskCreateData {
    /**
     * The task deadline date
     */
    deadline?: string | null
    /**
     * The task assignee id
     */
    assignee?: string
    title?: string
    isMilestone?: boolean
    /**
     * Markdown content
     */
    body?: string
    /**
     * The number of the task in the list of task of the relevant phaseId
     */
    order?: number
    phaseId?: string
    inventoryId?: string
    checkList?: Todo[]
    taskStatus?: TaskStatus
    uniqueId?: string
    parent?: string
    dueDate?: {
        weekdaysOnly?: boolean
        isoDuration?: string
        basedOn?: '' | 'parentDueDate' | 'parentCompletedAt'
    }
}
/**
 * JSON:API request to create tasks based on templates
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "CreateTasksFromTemplatesPostRequest".
 */
export interface CreateTasksFromTemplatesPostRequest {
    /**
     * Create tasks from template request
     */
    data?: {
        type: 'tasks'
        attributes: TaskCreateFromTemplateData
    }
}
export interface TaskCreateFromTemplateData {
    templatesIds: string[]
    /**
     * The order where the tasks from templates will be inserted
     */
    isAscending: boolean
}
/**
 * JSON:API request to update bulk tasks
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "BulkTaskRequest".
 */
export interface BulkTaskRequest {
    /**
     * Task object containing the list of tasks to be updated
     */
    data?: {
        type: 'tasks'
        id?: string
        attributes: {
            list?: BulkRequestUpdateItemResource[]
        }
    }
}
/**
 * Resource of bulk request item
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "BulkRequestUpdateItemResource".
 */
export interface BulkRequestUpdateItemResource {
    /**
     * The task id
     */
    taskId: string
    /**
     * The task deadline date
     */
    deadline?: string | null
    /**
     * The task assignee id
     */
    assignee?: string | null
    taskStatus?: TaskStatus
}
/**
 * Resource representing a list of tasks
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TasksResource".
 */
export interface TasksResource {
    meta?: TaskResourceMeta
    type: 'tasks'
    id: Identifier
    attributes: Task
}
/**
 * Task object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Task".
 */
export interface Task {
    /**
     * Date indicating the task deadline
     */
    deadline?: string | null
    estateId?: Identifier
    taskType?: TaskType
    priority?: boolean
    isMilestone?: boolean
    roleId?: string
    orgId?: string
    approvedBy?: string
    requestedBy?: IdentifierOrNull
    approvedAt?: string
    rejectMessage?: string
    assignee?: User
    rejectBy?: User
    rejectedAt?: string
    /**
     * The task workflow uuid
     */
    uuid?: string
    lastReminder?: string
    title?: string
    /**
     * Markdown content
     */
    body?: string
    phaseId?: Identifier
    inventoryId?: Identifier
    /**
     * The number of the task in the list of task of the relevant phaseId
     */
    order?: number
    checkList?: Todo[]
    taskStatus?: TaskStatus | TaskReviewStatus
    uniqueId?: string
    parent?: string
    resources?: {
        ref: string
    }[]
    dueDate?: {
        isoDuration?: string
        weekdaysOnly?: boolean
        basedOn?: '' | 'parentDueDate' | 'parentCompletedAt' | 'dateOfDeath'
    }
}
/**
 * Resource representing a task
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TaskResource".
 */
export interface TaskResource {
    meta?: TaskResourceMeta
    type: 'tasks'
    id: Identifier
    attributes: Task
}
/**
 * Resource representing a task
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TaskCreateResource".
 */
export interface TaskCreateResource {
    meta?: TaskResourceMeta
    type: 'tasks'
    id: Identifier
    attributes: TaskCreate
}
/**
 * Task object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TaskCreate".
 */
export interface TaskCreate {
    /**
     * Date indicating the task deadline
     */
    deadline?: string | null
    estateId?: Identifier
    taskType?: TaskType
    priority?: boolean | null
    isMilestone?: boolean | null
    roleId?: string | null
    orgId?: string
    approvedBy?: IdentifierOrNull
    approvedAt?: string
    rejectMessage?: string | null
    assignee?: IdentifierOrNull
    /**
     * The task workflow uuid
     */
    uuid?: string | null
    lastReminder?: string | null
    title?: string
    /**
     * Markdown content
     */
    body?: string
    phaseId?: Identifier
    inventoryId?: Identifier
    /**
     * The number of the task in the list of task of the relevant phaseId
     */
    order?: number
    checkList?: Todo[] | null
    taskStatus?: TaskStatus
    resources?: {
        ref: string
    }[]
}
/**
 * Resource representing a list of my tasks
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "MyTasksResource".
 */
export interface MyTasksResource {
    meta?: TaskResourceMeta
    type: 'tasks'
    id: Identifier
    attributes: MyTask
}
/**
 * MyTask object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "MyTask".
 */
export interface MyTask {
    /**
     * Date indicating the task deadline
     */
    deadline?: string | null
    estateId?: Identifier
    taskType?: TaskType
    priority?: boolean
    isMilestone?: boolean
    roleId?: string
    orgId?: string
    approvedBy?: string
    approvedAt?: string
    rejectMessage?: string
    assignee?: User
    rejectBy?: User
    rejectedAt?: string
    /**
     * The task workflow uuid
     */
    uuid?: string
    lastReminder?: string
    title?: string
    /**
     * Markdown content
     */
    body?: string
    phaseId?: Identifier
    /**
     * The number of the task in the list of task of the relevant phaseId
     */
    order?: number
    checkList?: Todo[]
    resources?: {
        ref: string
    }[]
    taskStatus?: TaskStatus | TaskReviewStatus
    /**
     * type of case
     */
    fileType?: 'Probate' | 'Trust' | 'EstateAdministration' | 'PowerOfAttorney'
}
/**
 * Resource representing a list of task names
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TasksNamesResource".
 */
export interface TasksNamesResource {
    attributes: {
        title: string
        phaseName?: string
    }
}
/**
 * Resource representing an a list of skeletons to create a personalized task based on an inventory type
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "PersonalizedTaskSkeletons".
 */
export interface PersonalizedTaskSkeleton {
    data: {
        attributes: {
            name?: string
            tasks?: PersonalizedTaskSkeletonResource[]
        }
    }
}
/**
 * Resource representing an skeleton to create a personalized task based on an inventory type
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "PersonalizedTaskSkeletonResource".
 */
export interface PersonalizedTaskSkeletonResource {
    id: Identifier
    title: string
    body?: string
    phaseId?: Identifier
    checkList?: Todo[] | null
}
/**
 * JSON:API request to create a team
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TeamCreateRequest".
 */
export interface TeamCreateRequest {
    data?: {
        type: 'teams'
        attributes: {
            /**
             * The name of the team
             */
            name: string
            phoneNumber?: PhoneNumber
            /**
             * city type
             */
            cityType?: string
            /**
             * county type
             */
            countyType?: string
            address?: Address
            /**
             * List of IDs for team members.
             */
            members?: Identifier[]
            /**
             * fax number
             */
            faxNumber?: string
            /**
             * The team's prefix for vault envelopes
             */
            vaultWillEnvelopeTeamPrefix?: string
            /**
             * The team's start number for vault envelopes
             */
            vaultWillEnvelopeStartNumber?: string
        }
    }
}
/**
 * JSON:API request to update a team
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TeamUpdateRequest".
 */
export interface TeamUpdateRequest {
    data?: {
        type: 'teams'
        attributes: {
            /**
             * The name of the team
             */
            name?: string
            phoneNumber?: PhoneNumber
            address?: Address
            /**
             * List of IDs for team members.
             */
            members?: Identifier[]
            /**
             * The team's prefix for vault envelopes
             */
            vaultWillEnvelopeTeamPrefix?: string
            /**
             * The team's start number for vault envelopes
             */
            vaultWillEnvelopeStartNumber?: string
        }
    }
}
/**
 * Resource representing a team
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TeamResource".
 */
export interface TeamResource {
    type: 'teams'
    id: Identifier
    meta?: ItemMeta
    attributes: Team
}
/**
 * Team object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Team".
 */
export interface Team {
    /**
     * The name of the team
     */
    name: string
    phoneNumber?: PhoneNumber
    faxNumber?: PhoneNumber
    address?: Address
    /**
     * List of IDs for team members.
     */
    members?: Identifier[]
    /**
     * Type of city
     */
    cityType?: string
    /**
     * Type of county
     */
    countyType?: string
    /**
     * The team's prefix for vault envelopes
     */
    vaultWillEnvelopeTeamPrefix?: string
    /**
     * The team's start number for vault envelopes
     */
    vaultWillEnvelopeStartNumber?: string
}
/**
 * JSON:API request to add a member to a team
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TeamMemberAddRequest".
 */
export interface TeamMemberAddRequest {
    data?: {
        type: 'members'
        attributes: {
            /**
             * Identifier
             */
            userId: string
        }
    }
}
/**
 * Resource representing a team member
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TeamMemberResource".
 */
export interface TeamMemberResource {
    type: 'members'
    id: Identifier
    attributes: TeamMember
}
/**
 * Team member object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TeamMember".
 */
export interface TeamMember {
    user?: {
        /**
         * The user full name computed by firstName and lastName
         */
        name?: string
        /**
         * The user first name
         */
        firstName?: string
        /**
         * The user last name
         */
        lastName?: string
        email?: string
        profilePic?: {
            image?: {}
            filename?: string
        }
        /**
         * User role on the organization
         */
        role?: string
    }
}
/**
 * Asset Transfer transaction
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "AssetTransfer".
 */
export interface AssetTransfer {
    /**
     * Identifier
     */
    accountId?: string
    /**
     * The value transfered
     */
    value: number
    /**
     * The quantity from the asset transfered (e.g Stocks - Quantity 200)
     */
    quantity?: number
    /**
     * The description for the transfer
     */
    description?: string
    accountType: TransactionAccountType
    /**
     * The date of the transfer
     */
    date: string
    /**
     * Additional fields (e.g For a Business Debt - Tax ID number)
     */
    fields?: {
        cashflowType?: TransactionCashFlowType
        [k: string]: unknown
    }
    /**
     * Some notes the executor can let regarding this transaction
     */
    notes?: string
}
/**
 * Cash Transfer transaction
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "CashTransfer".
 */
export interface CashTransfer {
    /**
     * The date of the transfer
     */
    date: string
    fromAccount: Identifier
    toAccount: Identifier
    /**
     * The value transfered
     */
    value: number
    /**
     * The description for the transfer
     */
    description?: string
    /**
     * Some notes the executor can let regarding this transaction
     */
    notes?: string
}
/**
 * Debt (or liability) Payment transaction
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "DebtPayment".
 */
export interface DebtPayment {
    /**
     * The transaction value
     */
    value: number
    /**
     * The interest paid
     */
    interest?: number
    /**
     * The quantity from the liability paid (e.g Stocks - Quantity 200)
     */
    quantity?: number
    /**
     * The transaction description
     */
    description?: string
    accountType: TransactionAccountType
    /**
     * The date of the transaction
     */
    date: string
    /**
     * Additional fields (e.g For a Business Debt - Tax ID number)
     */
    fields?: {}
    /**
     * Some notes the executor can let regarding this transaction
     */
    notes?: string
}
/**
 * Debt Forgiveness transaction
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "DebtForgiveness".
 */
export interface DebtForgiveness {
    /**
     * The value forgiven
     */
    value: number
    /**
     * The description for the debt forgiveness (reason)
     */
    description?: string
    accountType: TransactionAccountType
    /**
     * The date of the transaction
     */
    date: string
    /**
     * Additional fields (e.g For a Business Debt - Tax ID number)
     */
    fields?: {}
    /**
     * Some notes the executor can let regarding this transaction
     */
    notes?: string
}
/**
 * Split Stock definition
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "SplitStock".
 */
export interface SplitStock {
    /**
     * The date of the transfer
     */
    date: string
    /**
     * New quantity to be set
     */
    quantity: number
    /**
     * The description for the transfer
     */
    description: string
}
/**
 * Transfer Holding definition
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TransferCurrentHolding".
 */
export interface TransferCurrentHolding {
    toAccount: Identifier
    /**
     * The date of the transfer
     */
    date: string
    /**
     * The description for the transfer
     */
    description?: string
}
/**
 * JSON:API request to update or delete multiple transactions
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "BulkTransactionsRequest".
 */
export interface BulkTransactionsRequest {
    data?: {
        id?: Identifier
        attributes?: {
            action: 'delete' | 'update'
            value?: BulkUpdateTransaction
        }
    }[]
}
/**
 * BulkUpdateTransaction Object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "BulkUpdateTransaction".
 */
export interface BulkUpdateTransaction {
    status?: 'fulfilled' | 'rejected'
    value?: {
        estateId?: Identifier
        /**
         * The date of the transaction
         */
        date?: string
        /**
         * Identifier
         */
        accountId?: string
        category?: TransactionCategory
        accountType?: TransactionAccountType
        /**
         * The transaction value, can be negative
         */
        value?: number
        /**
         * New quantity to be set
         */
        quantity?: number
        /**
         * Define if the transaction has been probate
         */
        probate?: boolean
        /**
         * Define if the transaction has been bequeathed (https://en.wikipedia.org/wiki/Bequest)
         */
        bequest?: boolean
        /**
         * The transaction description
         */
        description?: string
        /**
         * Some notes the executor can let regarding this transaction
         */
        notes?: string
        /**
         * Field to describe distribution to beneficiaries
         */
        beneficiaries?: {
            beneficiary?: Identifier
            percentage?: number
            allocationFractionNumerator?: number
            allocationFractionDenominator?: number
            quantity?: number
        }[]
        /**
         * Additional fields (e.g excludeFromExecutorCompensation)
         */
        fields?: {
            [k: string]: unknown
        }
    }
}
/**
 * Split Transaction definition
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "SplitTransaction".
 */
export interface SplitTransaction {
    /**
     * New value to be set
     */
    value: number
    /**
     * New transaction Account type
     */
    type?: 'Income' | 'Capital'
    /**
     * The description for the transfer
     */
    description: string
}
/**
 * Resource representing a transaction
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "BulkTransactionResource".
 */
export interface BulkTransactionResource {
    type: string
    id: Identifier
    meta?: MetaTransaction
    attributes: BulkUpdateTransaction
}
/**
 * JSON:API request to create a user
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "UserCreateRequest".
 */
export interface UserCreateRequest {
    data?: {
        type: 'users'
        attributes: {
            email: Email
            password: Password
            /**
             * The user first name
             */
            firstName: string
            lastName: string
            /**
             * The user title
             */
            title: string
            invitationToken: Token
        }
    }
}
/**
 * JSON:API request to validte a user
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "UserValidationRequest".
 */
export interface UserValidationRequest {
    data?: {
        type: 'users'
        attributes: {
            token: Token
        }
    }
}
/**
 * JSON:API request to request to reset user password
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "RequestToPasswordResetRequest".
 */
export interface RequestToPasswordResetRequest {
    data?: {
        type: 'users'
        attributes: {
            email: Email
        }
    }
}
/**
 * JSON:API request to reset user password
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "PasswordResetRequest".
 */
export interface PasswordResetRequest {
    data?: {
        type: 'users'
        attributes: {
            token: Token
            email: Email
            password: Password
        }
    }
}
/**
 * JSON:API response returning a user
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "UserResponse".
 */
export interface UserResponse {
    data?: UserResource
}
/**
 * Resource representing a user
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "UserResource".
 */
export interface UserResource {
    meta?: ItemMeta & {
        /**
         * Date indicating when the item was activated
         */
        activatedAt?: string
        /**
         * Date indicating when was the last login of this user
         */
        lastLogingAt?: string
    }
    type: 'users'
    id: Identifier
    attributes: User
}
/**
 * JSON:API request to start an estate workflow
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "WorkflowStartRequest".
 */
export interface WorkflowStartRequest {
    data?: {
        type: 'workflows'
        attributes: {
            /**
             * Identifier
             */
            teamId: string
            /**
             * Identifier
             */
            estateIdToCopy?: string
            estateType?: string
            addons?: {
                uuid: string
                link: string
            }
            subscriptionAddons?: SubscriptionAddonsType[]
        }
    }
}
/**
 * JSON:API request to create a workflow
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "WorkflowCreateRequest".
 */
export interface WorkflowCreateRequest {
    data?: WorkflowCreateResource
}
/**
 * Resource representing a workflow
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "WorkflowCreateResource".
 */
export interface WorkflowCreateResource {
    type: 'workflows'
    id?: Identifier
    attributes: Workflow
}
/**
 * Workflow object
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "Workflow".
 */
export interface Workflow {
    /**
     * Identifier
     */
    orgId?: string
    name: string
    description?: string
    /**
     * Workflow Schema definition
     */
    definition: {}
    /**
     * Workflow type, define if this is a main workflow or if can be used just as a subWorkflow
     */
    workflowType: 'Workflow' | 'SubWorkflow'
}
/**
 * JSON:API request to update a workflow
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "WorkflowUpdateRequest".
 */
export interface WorkflowUpdateRequest {
    data?: WorkflowCreateResource
}
/**
 * Resource representing a workflow
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "WorkflowResource".
 */
export interface WorkflowResource {
    meta?: ItemMeta
    type: 'workflows'
    id: Identifier
    attributes: Workflow
}
/**
 * Resource representing a workflow
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "WorkflowResourceList".
 */
export interface WorkflowResourceList {
    type: 'workflows'
    id: Identifier
    meta?: ItemMeta
    attributes: {
        /**
         * Identifier
         */
        orgId?: string
        name: string
        description?: string
        /**
         * Workflow type, define if this is a main workflow or if can be used just as a subWorkflow
         */
        workflowType: 'Workflow' | 'SubWorkflow'
    }
}

/**
 * Define the account type. Estate Bank account should only contains transactions but Trust and Investment accounts can contains transactions and holdings.
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "AccountTypes".
 */
export enum AccountTypes {
    EstateBankAccount = 'Estate Bank Account',
    SecondaryEstateBankAccount = 'Secondary Estate Bank Account',
    TrustAccount = 'Trust Account',
    InvestmentAccount = 'Investment Account',
    Distribution = 'Distribution',
    POAAccount = 'Power Of Attorney Account',
}
export enum Class {
    AccruedInterest = 'Accrued Interest',
    Cash = 'cash',
    Stocks = 'stocks',
    Bonds = 'bonds',
    MutualFund = 'mutual fund',
    Etf = 'etf',
    Derivative = 'derivative',
    Equity = 'equity',
    Gic = 'gic',
    Debentures = 'debentures',
    FixedIncome = 'fixedIncome',
    Other = 'other',
}
export enum SubClass {
    Bond = 'Bond',
    BondFund = 'Bond Fund',
    CloselyHeldCorporation = 'Closely Held Corporation',
    CoOperativeApartment = 'Co-Operative Apartment',
    Common = 'Common',
    Corporate = 'Corporate',
    Debenture = 'Debenture',
    Forward = 'Forward',
    Future = 'Future',
    GIC = 'GIC',
    Municipal = 'Municipal',
    Note = 'Note',
    OptionCall = 'Option - Call',
    OptionPut = 'Option - Put',
    Preferred = 'Preferred',
    Right = 'Right',
    Swap = 'Swap',
    UsSavings = 'US Savings',
    UsTreasury = 'US Treasury',
    UsTreasuryNote = 'US Treasury Note',
    Warrant = 'Warrant',
}
/**
 * Category of the inventory
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "InventoryCategory".
 */
export enum InventoryCategory {
    Asset = 'Asset',
    Liability = 'Liability',
}
/**
 * Classification of on inventory
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "InventoryType".
 */
export enum InventoryType {
    AccruedInterest = 'Accrued Interest',
    IncomeTaxDue = 'Income Tax Due',
    CashOnHand = 'Cash on Hand',
    BankAccounts = 'Bank Accounts',
    EstateBankAccounts = 'Estate Bank Accounts',
    GrantorInvestmentAccounts = 'Grantor Investment Accounts',
    TrustBankAccounts = 'Trust Bank Accounts',
    IncomeReceivable = 'Income Receivable',
    RealEstate = 'Real Estate',
    InvestmentAccount = 'Investment Account',
    EstateInvestmentAccounts = 'Estate Investment Accounts',
    TrustInvestmentAccounts = 'Trust Investment Accounts',
    LifeInsuranceProceeds = 'Life Insurance Proceeds',
    LoanDueToDeceased = 'Loan Due To Deceased',
    StakeInPrivateCompanies = 'Stake in Private Companies',
    Collectables = 'Collectables',
    Vehicles = 'Vehicles',
    PersonalEffectsAndHousehold = 'Personal Effects and Household',
    Holding = 'Holding',
    LoansPayable = 'Loans Payable',
    CivilLiabilities = 'Civil Liabilities',
    OutstandingBills = 'Outstanding Bills',
    Guarantees = 'Guarantees',
    BusinessDebts = 'Business Debts',
    ContractsForServices = 'Contracts For Services',
    AccruedInterestLiability = 'Accrued Interest Liability',
    OtherLiability = 'Other Liability',
    OtherAsset = 'Other Asset',
    IncomeTaxReceivable = 'Income Tax Receivable',
    SpouseOrDependencies = 'Spouse or Dependencies',
    TaxHoldbacks = 'Tax Holdbacks',
    ExecutorCompensation = 'Executor Compensation',
    TrusteeCompensation = 'Trustee Compensation',
    CaseBankAccounts = 'Case Bank Accounts',
    CaseInvestmentAccounts = 'Case Investment Accounts',
    GrantorBankAccounts = 'Grantor Bank Accounts',
    AccountsReceivable = 'Accounts Receivable',
    Annuities = 'Annuities',
    DigitalCurrency = 'Digital Currency',
    GIC = 'GIC',
    IntellectualPropertyRights = 'Intellectual Property Rights',
    OilGasMineralRight = 'Oil, Gas, Mineral Right',
    PublicCompanyCertificate = 'Public Company Certificate',
    PooledMortgageFund = 'Pooled Mortgage Fund',
}
export enum IssuanceType {
    Government = 'Government',
    Corporation = 'Corporation',
}
export enum Desktop {
    none = 'none',
    Empty = '',
}
export enum CaseAssignments {
    none = 'none',
    email = 'email',
    inApp = 'inApp',
    Empty = '',
}
export enum CaseNotes {
    none = 'none',
    email = 'email',
    inApp = 'inApp',
    Empty = '',
}
export enum DeadlineReminder {
    none = 'none',
    email = 'email',
    inApp = 'inApp',
    Empty = '',
}
export enum DigitalSignature {
    none = 'none',
    email = 'email',
    inApp = 'inApp',
    Empty = '',
}
export enum ExportComplete {
    none = 'none',
    email = 'email',
    inApp = 'inApp',
    Empty = '',
}
export enum ImportComplete {
    none = 'none',
    email = 'email',
    inApp = 'inApp',
    Empty = '',
}
export enum ProductCommunications {
    none = 'none',
    email = 'email',
    inApp = 'inApp',
    Empty = '',
}
export enum ReminderFrequency {
    Empty = '',
    twoDays = 'twoDays',
    fiveDays = 'fiveDays',
    sevenDays = 'sevenDays',
    fourteenDays = 'fourteenDays',
    oneMonth = 'oneMonth',
    threeMonths = 'threeMonths',
    sixMonths = 'sixMonths',
    oneYear = 'oneYear',
}
export enum TaskAssignments {
    none = 'none',
    email = 'email',
    inApp = 'inApp',
    Empty = '',
}
export enum TaskReviews {
    none = 'none',
    email = 'email',
    inApp = 'inApp',
    Empty = '',
}
export enum TaskStatusUpdate {
    none = 'none',
    email = 'email',
    inApp = 'inApp',
    Empty = '',
}
export enum WorkflowError {
    none = 'none',
    email = 'email',
    inApp = 'inApp',
    Empty = '',
}
export enum Portal {
    none = 'none',
    gmail = 'gmail',
    outlook = 'outlook',
    All = '',
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "BillingPlanName".
 */
export enum BillingPlanName {
    Basic = 'Basic',
    BasicLegacy = 'Basic (Legacy)',
    Pro = 'Pro',
    ProLegacy = 'Pro (Legacy)',
    Essentials = 'Essentials',
    Core = 'Core',
    Advanced = 'Advanced',
}
/**
 * The type of payment provider or plan for an org
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "PaymentProviderTypes".
 */
export enum PaymentProviderTypes {
    free = 'free',
    stripe = 'stripe',
    sample = 'sample',
    orgTiers = 'orgTiers',
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "BillingFrequency".
 */
export enum BillingFrequency {
    month = 'month',
    year = 'year',
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "OrgVaultSettingsSetup".
 */
export enum OrgVaultSettingsSetup {
    None = 'None',
    Team = 'Team',
    Organization = 'Organization',
}
/**
 * Define the address type.
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "AddressTypes".
 */
export enum AddressTypes {
    Primary = 'Primary (Tax Residency)',
    Secondary = 'Secondary',
    Mailing = 'Mailing',
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "CaseTypes".
 */
export enum CaseTypes {
    Probate = 'Probate',
    EstateAdministration = 'EstateAdministration',
    Trust = 'Trust',
    TrustAccounting = 'TrustAccounting',
    Empty = '',
    PowerOfAttorney = 'PowerOfAttorney',
    EstateAccounting = 'EstateAccounting',
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "CaseAddons".
 */
export enum CaseAddons {
    EstateAccountingPlus = 'estateAccountingPlus',
    TrustFullAdministration = 'trustFullAdministration',
    ProbateAddon = 'probateAddon',
}
export enum UploadType {
    INITIAL_POSITION = 'INITIAL_POSITION',
    FILE_UPDATE = 'FILE_UPDATE',
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TransactionCategory".
 */
export enum TransactionCategory {
    CashDistribution = 'CashDistribution',
    AssetDistribution = 'AssetDistribution',
    LiabilityDistribution = 'LiabilityDistribution',
    AssetSale = 'AssetSale',
    LoanRepayment = 'LoanRepayment',
    Income = 'Income',
    Interest = 'Interest',
    DebtPayment = 'DebtPayment',
    DebtForgiveness = 'DebtForgiveness',
    InterestCharge = 'InterestCharge',
    Dividend = 'Dividend',
    EstateExpense = 'EstateExpense',
    ExecutorLoanInterest = 'ExecutorLoanInterest',
    ExecutorLoanRepayment = 'ExecutorLoanRepayment',
    ExecutorReimbursement = 'ExecutorReimbursement',
    TrustExpense = 'TrustExpense',
    TrusteeLoanInterest = 'TrusteeLoanInterest',
    TrusteeLoanRepayment = 'TrusteeLoanRepayment',
    TrusteeReimbursement = 'TrusteeReimbursement',
    MiscellaneousDeposit = 'MiscellaneousDeposit',
    MiscellaneousWithdraw = 'MiscellaneousWithdraw',
    Uncategorized = 'Uncategorized',
    InvestmentPurchase = 'InvestmentPurchase',
    TransferHolding = 'TransferHolding',
    RepatriatedFunds = 'RepatriatedFunds',
    ProfessionalFees = 'ProfessionalFees',
    Fees = 'Fees',
    LegalFees = 'LegalFees',
    LiquidatorFees = 'LiquidatorFees',
    TrusteeFees = 'TrusteeFees',
    POAFees = 'POAFees',
    InvestmentFees = 'InvestmentFees',
    LossOnForeignExchange = 'LossOnForeignExchange',
    InterestAndBankCharges = 'InterestAndBankCharges',
    FuneralExpenses = 'FuneralExpenses',
    IncomeTaxes = 'IncomeTaxes',
    GainOnForeignExchange = 'GainOnForeignExchange',
    OtherDeposit = 'OtherDeposit',
    OtherWithdraw = 'OtherWithdraw',
    ForeignExchangeWithdraw = 'ForeignExchangeWithdraw',
    ForeignExchangeDeposit = 'ForeignExchangeDeposit',
    ForeignTaxPaid = 'ForeignTaxPaid',
    PreviousYearIncomeTaxesWithdraw = 'PreviousYearIncomeTaxesWithdraw',
    PreviousYearIncomeTaxesDeposit = 'PreviousYearIncomeTaxesDeposit',
    StockSplit = 'StockSplit',
    Note = 'Note',
    ReturnOfCapital = 'ReturnOfCapital',
    POAExpense = 'POAExpense',
    SubstituteDecisionMakerLoanInterest = 'SubstituteDecisionMakerLoanInterest',
    SubstituteDecisionMakerLoanRepayment = 'SubstituteDecisionMakerLoanRepayment',
    SubstituteDecisionMakerReimbursement = 'SubstituteDecisionMakerReimbursement',
    SubstituteDecisionMakerFees = 'SubstituteDecisionMakerFees',
    ManualUpdateAdjustedCostBase = 'ManualUpdateAdjustedCostBase',
    IncomeRemittances = 'IncomeRemittances',
    CapitalRemittances = 'CapitalRemittances',
    RealEstate = 'RealEstate',
    Transfer = 'Transfer',
    TransferToAccount = 'TransferToAccount',
    OtherInvestmentIncome = 'OtherInvestmentIncome',
    SaleAndMaturityOfInvestments = 'SaleAndMaturityOfInvestments',
    LongTermDebtToPay = 'LongTermDebtToPay',
    LongTermDebtToReceive = 'LongTermDebtToReceive',
    CapitalizationOfIncomeAccount = 'CapitalizationOfIncomeAccount',
    CapitalGainDistribution = 'CapitalGainDistribution',
}
/**
 * The type of account (Capital or Income account)
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TransactionAccountType".
 */
export enum TransactionAccountType {
    Income = 'Income',
    Capital = 'Capital',
    Investment = 'Investment',
    Other = 'Other',
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TransactionCashFlowType".
 */
export enum TransactionCashFlowType {
    Deposit = 'Deposit',
    Withdraw = 'Withdraw',
}
/**
 * Services avaliable
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "IntegrationServices".
 */
export enum IntegrationServices {
    Transactions = 'transactions',
    Investments = 'investment',
}
/**
 * Providers avaliable
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "IntegrationProviders".
 */
export enum IntegrationProviders {
    Flinks = 'flinks',
    Plaid = 'plaid',
}
/**
 * Services avaliable
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "IntegrationStatus".
 */
export enum IntegrationStatus {
    Pending = 'pending',
    Connected = 'connected',
    Updating = 'updating',
    Disconnected = 'disconnected',
    Error = 'error',
}
/**
 * Job Status
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "JobStatus".
 */
export enum JobStatus {
    Started = 'Started',
    Finished = 'Finished',
    InProgress = 'InProgress',
    Canceled = 'Canceled',
    Failed = 'Failed',
    Synced = 'Synced',
}
/**
 * Document Type
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "DocumentType".
 */
export enum DocumentType {
    Statements = 'Statements',
    Holdings = 'Holdings',
    FinancialStatement = 'FinancialStatement',
}
/**
 * Parse Status
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "ParseStatus".
 */
export enum ParseStatus {
    Pending = 'Pending',
    Completed = 'Completed',
    Cancelled = 'Cancelled',
    Failed = 'Failed',
}
export enum PermissionScope {
    org = 'org',
    team = 'team',
    mine = 'mine',
    self = 'self',
    restricted = 'restricted',
    Empty = '',
}
/**
 * Define the type of the resource
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "ResourceType".
 */
export enum ResourceType {
    Form = 'Form',
    DocumentTemplate = 'DocumentTemplate',
    TaskList = 'TaskList',
    DynamicFields = 'DynamicFields',
    Other = 'Other',
}
/**
 * enum of stats types that are accepted
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "StatsType".
 */
export enum StatsType {
    CasesPerTeam = 'casesPerTeam',
    CasesPerUserInOrg = 'casesPerUserInOrg',
    Cases = 'cases',
    Teams = 'teams',
    Users = 'users',
}
/**
 * Type of Primary task origin
 *
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TaskTemplateOriginType".
 */
export enum TaskTemplateOriginType {
    DefaultTaskList = 'defaultTaskList',
    TemplateTaskList = 'templateTaskList',
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TaskReviewStatus".
 */
export enum TaskReviewStatus {
    InReview = 'In Review',
    Rejected = 'Rejected',
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TaskStatus".
 */
export enum TaskStatus {
    Incomplete = '',
    Complete = 'Complete',
    NotApplicable = 'Not applicable',
    InProgress = 'In Progress',
}
export enum DueDateRule {
    DATE_OF_DEATH_RULE_1 = 'dateOfDeath_rule1',
    DATE_OF_DEATH_RULE_2 = 'dateOfDeath_rule2',
    DATE_OF_DEATH_RULE_3 = 'dateOfDeath_rule3',
    DATE_OF_DEATH_RULE_4 = 'dateOfDeath_rule4',
    DATE_OF_DEATH_RULE_5 = 'dateOfDeath_rule5',
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TaskLogStatus".
 */
export enum TaskStatus1 {
    IncompleteLegacy = '',
    Incomplete = 'Incomplete',
    Complete = 'Complete',
    NotApplicable = 'Not applicable',
    InProgress = 'In Progress',
    InReview = 'In Review',
    Rejected = 'Rejected',
}
/**
 * This interface was referenced by `EstateablyAPI`'s JSON-Schema
 * via the `definition` "TaskType".
 */
export enum TaskType {
    WORKFLOW = '',
    MANAGEABLE = 'Manageable',
}

import {
    EstateUnarchiveRequest, // Type import EstateUnarchiveRequest
    Page, // Type Pagination of list
} from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api, ResponseWithHeader } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/EstateArchive
 */
export class EstateArchiveApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * Unarchive Estate case
     * ${estateId}/unarchive
     **/

    async estateUnarchive(estateId: string, body: EstateUnarchiveRequest): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.post<void, EstateUnarchiveRequest>(`/estates/${estateId}/unarchive`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

import React from 'react'
import style from './style.module.scss'
import AvatarText from '../../atoms/AvatarText'
import Link from '../../atoms/Link'
import { GenericAttributes } from '../../types'

type AvatarInfoProps = {
    title: string
    subtitle?: string
    img?: string
    linkLabel?: string
    onClick?: (ev: React.MouseEvent<HTMLElement>) => void
} & GenericAttributes

export const AvatarInfo = ({ title, subtitle, img, linkLabel, onClick, ...props }: AvatarInfoProps) => {
    return (
        <div {...props} data-test={`${props['data-test']}`} className={[style.avatarInfo].join(' ')}>
            <div className={style.col1}>
                <AvatarText data-test={`${props['data-test']}.avatar`} text={title} size={AvatarText.sizeTypes.small} img={img} />
            </div>
            <div className={style.col2} data-test={`${props['data-test']}.labels`}>
                <div className={style.title}>{title}</div>
                <div className={style.subtitle}>{subtitle}</div>
            </div>
            {onClick && linkLabel ? (
                <div className={style.col3}>
                    <Link data-test={`${props['data-test']}.link`} onClick={onClick}>
                        {linkLabel}
                    </Link>
                </div>
            ) : null}
        </div>
    )
}
export default React.memo(AvatarInfo)

import { DocumentResponseList, Page, TemplateRequest, TemplateResponse, TemplateResponseList } from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api, ResponseWithHeader } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'
import { Buffer } from 'buffer'

/*
 * https://app.estateably.com/api-docs/#tag/Document-templates
 */
export class DocumentTemplatesApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * Generate documents from a template
     * ${docTemplateId}
     **/

    async generateTemplate(docTemplateId: string, body: TemplateRequest): Promise<DocumentResponseList> {
        try {
            const res: AxiosResponse<DocumentResponseList> = await this.post<DocumentResponseList, TemplateRequest>(`/document-templates/${docTemplateId}`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Get a document template
     * ${docTemplateId}
     **/

    async getTemplate(docTemplateId: string, lang?: string, estateId?: string): Promise<TemplateResponse> {
        try {
            const res: AxiosResponse<TemplateResponse> = await this.get<TemplateResponse>(`/document-templates/${docTemplateId}`, { params: { lang, estateId } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Generate documents from a template and don&#39;t save on the documents sections
     * ${docTemplateId}/preview
     **/

    async generateTemplatePreview(docTemplateId: string, body: TemplateRequest): Promise<ResponseWithHeader<Buffer>> {
        try {
            const res: AxiosResponse<Buffer> = await this.post<Buffer, TemplateRequest>(`/document-templates/${docTemplateId}/preview`, body, { responseType: 'arraybuffer', params: {} })
            return this.successWithHeader(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * List document templates
     *
     **/

    async listTemplates(page?: Page, filter?: object, search?: string, sort?: string, lang?: string): Promise<TemplateResponseList> {
        try {
            const res: AxiosResponse<TemplateResponseList> = await this.get<TemplateResponseList>(`/document-templates/`, { params: { page, filter, search, sort, lang } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

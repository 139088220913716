export const translations = {
    'fr': {
        'French': 'Français',
        'English': 'English',
        'Help': 'Aide',
        'help_link': 'https://support.estateably.com/hc/fr-ca',
        'help_privacy': 'https://support.estateably.com/hc/fr-ca/articles/360038424872',
    },
    'en': {
        // Commons
        'French': 'Français',
        'English': 'English',
        'help_link': 'https://support.estateably.com/hc/en-ca',
        'help_privacy': 'https://support.estateably.com/hc/en-ca/articles/360038424872',
    },
}
export const contextToTest = (lang) => {
    return {
        t: (text) => translations[lang][text] || text,
    }
}

import network from 'utils/network'

const { users } = network.api
export const ACTION_REQUEST_TO_RESET_PASSWORD = 'ACTION_REQUEST_TO_RESET_PASSWORD'
export const ACTION_RENDER_FORGOT_PASSWORD_FORM = 'ACTION_RENDER_FORGOT_PASSWORD_FORM'
export const requestToResetPassword = (values) => {
    return {
        type: ACTION_REQUEST_TO_RESET_PASSWORD,
        payload: users.requestToResetPassword({ email: values.email }, {}).then(() => values.email),
    }
}

import React from 'react'
import style from './style.module.scss'
import Icon from '../../particles/Icon'
import moment from 'moment'
import { GenericAttributes } from '../../types'
import { useTranslation } from 'react-i18next'

type LabelTimestampProps = {
    date?: string | Date
} & GenericAttributes

export const LabelTimestamp = ({ date = new Date(), ...props }: LabelTimestampProps) => {
    const { i18n } = useTranslation()
    const momentDate = moment(date)
    momentDate.locale(i18n.language)
    const day = momentDate.format('MMMM DD, YYYY')

    const formattedDay = day.charAt(0).toUpperCase() + day.slice(1)
    const hour = momentDate.format('LT')
    return (
        <div {...props} className={[style.labelTimestamp].join(' ')}>
            <span data-test={`${props['data-test']}.day`}>{formattedDay} </span>
            <Icon type={Icon.types.Circle} size={6} />
            <span data-test={`${props['data-test']}.hour`}>{hour} </span>
        </div>
    )
}
export default React.memo(LabelTimestamp)

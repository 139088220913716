import React, { useEffect, useMemo, useState } from 'react'
import style from './style.module.scss'
import Link from '../../atoms/Link'
import { GenericAttributes } from '../../types'
import SelectActionDropdown from '../SelectActionDropdown'
import Icon, { IconTypes } from '../../particles/Icon/index'

type BreadcrumbsProps = {
    options: string[]
    onSelect?: (index: number, item: string) => void
} & GenericAttributes

export const Breadcrumbs = ({ options, onSelect, ...props }: BreadcrumbsProps) => {
    /** memoized Values **/
    const lastOption = useMemo(() => options && options[options.length - 1], [options])
    const linkedOptions = useMemo(() => options && options.slice(0, options.length - 1), [options])

    /** states **/
    const [optionsLessLast, setOptionsLessLast] = useState<JSX.Element | null>(null)

    /** useEffect **/
    useEffect(() => {
        let newOptionsLessLast: JSX.Element | null = null
        if (linkedOptions.length > 2) {
            const dropdownList = linkedOptions.slice(1, linkedOptions.length).map((item, index) => ({
                title: item,
                onClick: onSelect ? () => onSelect(index + 1, item) : () => null,
            }))
            const first = linkedOptions[0]
            newOptionsLessLast = (
                <>
                    <li data-test={`${props['data-test']}.options.0`} className={style.firstItem}>
                        <Link data-test={`${props['data-test']}.options.0.link`} onClick={() => onSelect && onSelect(1, first)}>
                            <p>{first}</p>
                        </Link>
                        <Icon size={18} type={Icon.types.ChevronNext} />
                    </li>
                    <li data-test={`${props['data-test']}.options.1`} className={style.ellipsis}>
                        <SelectActionDropdown data-test={`${props['data-test']}.options.1.dropdown`} actionsList={dropdownList} icon={IconTypes.Ellipses} />
                        <Icon size={18} type={Icon.types.ChevronNext} />
                    </li>
                </>
            )
        } else {
            newOptionsLessLast = (
                <>
                    {linkedOptions.map((item, index) => (
                        <li data-test={`${props['data-test']}.options.${index}`} key={index + item} className={style.firstItem}>
                            <Link data-test={`${props['data-test']}.options.${index}.link`} onClick={() => onSelect && onSelect(options.indexOf(item), item)}>
                                <p>{item}</p>
                            </Link>
                            <Icon size={18} type={Icon.types.ChevronNext} />
                        </li>
                    ))}
                </>
            )
        }
        setOptionsLessLast(newOptionsLessLast)
    }, [linkedOptions, onSelect])

    if (!options || !options.length) {
        return null
    }

    return (
        <div {...props} data-test={`${props['data-test']}`} className={[style.breadcrumbs].join(' ')}>
            <ol className={[style.list].join(' ')}>
                {optionsLessLast}
                <li data-test={`${props['data-test']}.current`} className={style.lastItem}>
                    <p className={style.current}>{lastOption}</p>
                </li>
            </ol>
        </div>
    )
}
export default React.memo(Breadcrumbs)

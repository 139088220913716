import React, { PureComponent } from 'react'
import style from './style.module.scss'
import Icon, { IconTypes } from '../../particles/Icon'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import SectionText from '../../atoms/SectionText'
import { GenericAttributes } from '../../types'
import { Tooltip } from '../../../../DesignSystem/Tooltip'

export interface SelectActionItemDropDown {
    title: string
    disabled?: boolean
    tooltipContent?: string
    onClick: (item: SelectActionItemDropDown) => void
}
type SelectActionDropdownProps = {
    actionsList: SelectActionItemDropDown[]
    opened?: boolean
    size?: string
    title?: string
    icon?: IconTypes
    disabled?: boolean
} & GenericAttributes
type SelectActionDropdownState = ((prevState: React.Attributes) => { opened: boolean }) & {
    opened: boolean | undefined
}
export class SelectActionDropdown extends PureComponent<SelectActionDropdownProps, SelectActionDropdownState> {
    private myRef: React.RefObject<HTMLDivElement> = React.createRef()
    private top = false
    private left = false
    static sizeTypes = {
        small: 'small',
        medium: 'medium',
        large: 'large',
    }
    static iconTypes = Icon.types
    static defaultProps = {
        opened: false,
        size: SelectActionDropdown.sizeTypes.small,
        icon: SelectActionDropdown.iconTypes.More,
    }
    constructor(props: SelectActionDropdownProps) {
        super(props)
        this.state = {
            opened: props.opened,
        }
        this.handleClick = this.handleClick.bind(this)
        this.toggleList = this.toggleList.bind(this)
        this.calculateListPosition = this.calculateListPosition.bind(this)
    }
    componentDidMount(): void {
        document.addEventListener('mousedown', this.handleClick, false)
    }
    componentWillUnmount(): void {
        document.removeEventListener('mousedown', this.handleClick, false)
    }
    handleClick = (event: MouseEvent): void => {
        // Close when click outside div.
        if (this.myRef && this.myRef.current && !this.myRef.current.contains(event.target as Node)) {
            this.setState({ opened: false })
        }
    }
    calculateListPosition(): void {
        if (this.myRef && this.myRef.current) {
            const ref = this.myRef.current
            const windowHeight = window.innerHeight
            const topRenderSpaceLeft = ref.getBoundingClientRect().top // Distance from div top to the window top
            const bottomRenderedSpace = windowHeight - ref.getBoundingClientRect().top // Distance from div top to the window bottom
            if (topRenderSpaceLeft > bottomRenderedSpace) {
                this.top = true
            } else {
                this.top = false
            }
            const windowWidth = window.innerWidth
            const leftRenderSpaceLeft = ref.getBoundingClientRect().left // Distance from div left to the window right
            const rightRenderedSpace = windowWidth - ref.getBoundingClientRect().right // Distance from div right to the window right
            if (leftRenderSpaceLeft >= rightRenderedSpace) {
                this.left = true
            } else {
                this.left = false
            }
        }
    }
    getSizeStyle = (): string => {
        switch (this.props.size) {
            case SelectActionDropdown.sizeTypes.small:
                return style.small
            case SelectActionDropdown.sizeTypes.medium:
                return style.medium
            case SelectActionDropdown.sizeTypes.large:
                return style.large
            default:
                return style.medium
        }
    }
    getIsOpenStyle = (): string => {
        const { opened } = this.state
        return opened ? style.open : style.close
    }
    getPositionStyle = (): string => {
        this.calculateListPosition()
        return [this.top ? style.top : style.bottom, this.left ? style.left : style.right].join(' ')
    }
    toggleList(event: React.MouseEvent): void {
        const { disabled = false } = this.props
        if (disabled) {
            return
        }
        event.stopPropagation()
        this.setState(
            {
                opened: !this.state.opened,
            },
            () => {
                const { opened } = this.state
                if (opened) {
                    // Calculate dropdown actionsList position
                    this.calculateListPosition()
                }
            }
        )
    }
    selectItem(item?: SelectActionItemDropDown, event?: React.MouseEvent): void {
        event?.stopPropagation()
        this.setState({ opened: false }, () => {
            item?.onClick(item)
        })
    }

    renderList(): React.ReactNode {
        const { opened } = this.state
        const { actionsList, ...props } = this.props
        if (!opened) return null
        return (
            <div {...props} data-test={this.props['data-test']}>
                <div role='actionsList' className={[style.actionsList, this.getSizeStyle(), this.getPositionStyle()].join(' ')}>
                    <TransitionGroup component={null} appear={true} enter={true} exit={true}>
                        {actionsList.map((item, idx) => (
                            <CSSTransition key={idx} timeout={100} classNames={'dropdownAnimation'}>
                                <Tooltip
                                    data-test={`${this.props['data-test']}.options.${idx}.tooltip`}
                                    content={item.tooltipContent}
                                    open={item.tooltipContent ? undefined : false}
                                    // this action item is always at the right end of the line, which means that there is always
                                    // "more space" on the left side of the tooltip
                                    side={'left'}
                                    asChild>
                                    <div className={[style.listItem, this.getSizeStyle()].join(' ')} key={item.title}>
                                        <SectionText disabled={item.disabled} onClick={(e) => this.selectItem(item, e)} data-test={`${this.props['data-test']}.options.${idx}`}>
                                            {item.title}
                                        </SectionText>
                                    </div>
                                </Tooltip>
                            </CSSTransition>
                        ))}
                    </TransitionGroup>
                </div>
            </div>
        )
    }
    renderToggle(): React.ReactNode {
        const { icon, title, disabled = false } = this.props
        const { opened } = this.state
        return (
            <button
                type={'button'}
                className={[style.toggle, this.getIsOpenStyle(), disabled ? style.disabled : ''].join(' ')}
                onClick={this.toggleList}
                data-test={`${this.props['data-test']}.toggle`}
                aria-haspopup='dialog'
                id={`action-button-${this.props['data-test']}`}
                aria-expanded={opened}
                aria-controls={`content-${title}`}
                aria-label={title}>
                {title ? <span className={[icon ? style.iconMargin : null].join(' ')}>{title}</span> : null}
                {icon ? <Icon type={icon} size={30} /> : null}
            </button>
        )
    }
    render(): React.ReactNode {
        return (
            <div className={[style.selectActionDropdown, this.getSizeStyle()].join(' ')} ref={this.myRef}>
                {this.renderToggle()}
                {this.renderList()}
            </div>
        )
    }
}
export default SelectActionDropdown

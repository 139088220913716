import React, { PureComponent, ReactNode } from 'react'
import style from './style.module.scss'
import Icon, { IconTypes } from '../../particles/Icon'
import LabelTimestamp from '../../atoms/LabelTimestamp'
import Link from '../../atoms/Link'
import { GenericAttributes } from '../../types'
import { ButtonClose } from '../../atoms/ButtonClose'

export enum MessageMode {
    ERROR = 'error',
    SUCCESS = 'success',
}

type ToastProps = {
    id: number
    message?: string | ReactNode
    mode?: MessageMode
    leftIcon?: IconTypes
    link?: { title: string; onClick: (ev: React.MouseEvent<HTMLElement>) => void }
    timeout?: number
    timestamp?: Date
    onClose?: () => void
    onMouseEnter?: () => void
    onMouseLeave?: () => void
} & GenericAttributes

type ToastState = {
    stopTimeout?: boolean
}

export class Toast extends PureComponent<ToastProps, ToastState> {
    static IconTypes = IconTypes

    constructor(props: ToastProps | Readonly<ToastProps>) {
        super(props)
        this.state = {
            stopTimeout: false,
        }
    }

    stopTimeout = (): void => {
        const { onMouseEnter } = this.props
        if (onMouseEnter) onMouseEnter()
        this.setState({
            stopTimeout: true,
        })
    }

    startTimeout = (): void => {
        const { onMouseLeave } = this.props
        if (onMouseLeave) onMouseLeave()
        this.setState({
            stopTimeout: false,
        })
    }

    render(): React.ReactNode {
        const { message, link, onClose, mode, timestamp = new Date(), ...props } = this.props
        const { stopTimeout } = this.state
        return (
            <div {...props} data-test={this.props['data-test']} className={style.toast} onMouseOver={this.stopTimeout} onMouseLeave={this.startTimeout}>
                <div className={style.line1}>
                    <div className={[style.icon1, mode === MessageMode.ERROR && style.alert, mode === MessageMode.SUCCESS && style.check].join(' ')}>
                        <Icon size={24} type={mode === MessageMode.ERROR ? Icon.types.Alert : mode === MessageMode.SUCCESS ? Icon.types.Check : Icon.types.Dynamic} />
                    </div>
                    <div className={style.label} data-test={`${this.props['data-test']}.message`}>
                        {message}
                    </div>
                    <div className={style.icon2}>
                        <ButtonClose size={'sm'} color={'primary'} onClick={onClose} data-test={`${this.props['data-test']}.close`} />
                    </div>
                </div>

                <div className={style.line2}>
                    <div className={style.timestamp}>
                        <LabelTimestamp date={timestamp} data-test={`${this.props['data-test']}.timestamp`} />
                    </div>
                    <div className={style.link}>
                        {link?.title && link.onClick ? (
                            <Link onClick={link.onClick} data-test={`${this.props['data-test']}.link`}>
                                <span>{link.title} </span>
                            </Link>
                        ) : null}
                    </div>
                </div>
                {mode !== MessageMode.ERROR ? (
                    <div className={style.line3}>
                        <div className={[style.timer, stopTimeout ? style.stopTimeout : ''].join(' ')} />
                    </div>
                ) : null}
            </div>
        )
    }
}

export default Toast

import React, { PureComponent } from 'react'
import style from './style.module.scss'
import AvatarInfo from '../../molecules/AvatarInfo'
import Checkbox, { CheckboxValues } from '../../atoms/Checkbox'
import Radio, { RadioSelectionValues } from '../../atoms/Radio'
import { GenericAttributes } from '../../types'

export enum ItemAvatarInfoTypes {
    CHECKBOX = 'CHECKBOX',
    RADIO = 'RADIO',
}

type ItemAvatarInfoProps = {
    title: string
    subtitle?: string
    img?: string
    disabled?: boolean
    error?: boolean
    value?: CheckboxValues | RadioSelectionValues
    type?: ItemAvatarInfoTypes.CHECKBOX | ItemAvatarInfoTypes.RADIO | null
    onClick?: (ev: React.MouseEvent<HTMLElement>) => void
    onSelect?: (ev: React.MouseEvent<HTMLElement>) => void
} & GenericAttributes

export class ItemAvatarInfo extends PureComponent<ItemAvatarInfoProps> {
    static selectionValues = { ...CheckboxValues, ...RadioSelectionValues }
    static types = ItemAvatarInfoTypes
    static defaultProps = {
        value: ItemAvatarInfo.selectionValues.NOT_SELECTED,
        disabled: false,
        type: ItemAvatarInfo.types.CHECKBOX,
    }

    render(): React.ReactNode {
        const { title, subtitle, img, disabled, value, error, type, onClick, onSelect, ...props } = this.props
        return (
            <fieldset {...props} data-test={`${this.props['data-test']}`} onClick={!disabled ? onClick : undefined} className={[style.itemAvatarInfo].join(' ')}>
                <legend>{title}</legend>
                <div className={[style.col1, type == null ? '' : style.hasType].join(' ')}>
                    {type === ItemAvatarInfo.types.CHECKBOX ? (
                        <Checkbox
                            data-test={`${this.props['data-test']}.checkbox`}
                            onClick={!disabled ? onSelect : undefined}
                            disabled={disabled}
                            value={value as CheckboxValues}
                            error={error}
                            size={Checkbox.sizes.small}
                        />
                    ) : null}
                    {type === ItemAvatarInfo.types.RADIO ? (
                        <Radio data-test={`${this.props['data-test']}.radio`} onClick={!disabled ? onSelect : undefined} value={value as RadioSelectionValues} />
                    ) : null}
                </div>
                <div className={style.col2}>
                    <AvatarInfo data-test={`${this.props['data-test']}.avatarInfo`} title={title} subtitle={subtitle} img={img} />
                </div>
            </fieldset>
        )
    }
}
export default ItemAvatarInfo

import { PlaceResponse, PlaceResponseList } from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Places
 */
export class PlacesApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * Get a place detail from google maps api
     * ${placeId}
     **/

    async getPlace(placeId: string, session?: string): Promise<PlaceResponse> {
        try {
            const res: AxiosResponse<PlaceResponse> = await this.get<PlaceResponse>(`/places/${placeId}`, { params: { session } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Search places
     *
     **/

    async listPlaces(search?: string, session?: string): Promise<PlaceResponseList> {
        try {
            const res: AxiosResponse<PlaceResponseList> = await this.get<PlaceResponseList>(`/places/`, { params: { search, session } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

import React from 'react'
import style from './Loader.module.scss'
import { getCompanyLogo } from '../../utils/DesignSystem/branding'
import { gray100, primary600 } from '../../utils/DesignSystem/colors'

export default ({ error }: { error?: any }) => {
    const companyLogo = getCompanyLogo()

    if (error) {
        return (
            <div className={style.overlay}>
                <div>
                    <div>
                        <img src={companyLogo} />
                        <br />
                        Error connecting to host, try again in a few minutes
                        {JSON.stringify(error)}
                    </div>
                </div>
            </div>
        )
    }
    return (
        <div className='loader'>
            <div style={{ position: 'fixed', top: '50%', left: '50%', marginTop: '-150px', marginLeft: '-44px' }}>
                {/* FIXME: After the new brand launch this feature flag should be removed to avoid flashing the old logo */}
                <img src={companyLogo} style={{ height: '100px', width: '87px' }} />
                <div style={{ width: '48px', height: '48px', marginLeft: '18px' }}>
                    <svg
                        style={{
                            boxSizing: 'border-box',
                            display: 'block',
                            position: 'absolute',
                            width: '48px',
                            height: '48px',
                            borderRadius: '50%',
                            animation: 'lds-ring 0.8s ease infinite',
                            borderColor: '#fff transparent transparent transparent',
                        }}
                        viewBox='0 0 77 77'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'>
                        <circle opacity='0.6' cx='38.6514' cy='38.6514' r='35' stroke={gray100} strokeWidth='6' strokeLinecap='round' strokeLinejoin='round' />
                        <path
                            d='M73.6514 38.6514C73.6514 57.9813 57.9813 73.6514 38.6514 73.6514C19.3214 73.6514 3.65137 57.9813 3.65137 38.6514C3.65137 19.3214 19.3214 3.65137 38.6514 3.65137'
                            stroke={primary600}
                            strokeWidth='6'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                        />
                    </svg>
                </div>
            </div>
        </div>
    )
}

import moment from 'moment'
import { BillingPlanName, PaymentProviderTypes } from '../system/api/types'

const utils = {
    getCookie(cname) {
        var name = cname + '='
        var decodedCookie = decodeURIComponent(document.cookie)
        var ca = decodedCookie.split(';')
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i]
            while (c.charAt(0) == ' ') {
                c = c.substring(1)
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length)
            }
        }
        return ''
    },
    createUUID() {
        var dt = new Date().getTime()
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (dt + Math.random() * 16) % 16 | 0
            dt = Math.floor(dt / 16)
            return (c == 'x' ? r : (r & 0x3) | 0x8).toString(16)
        })
        return uuid
    },
    createUID: () => {
        return Date.now().toString(36) + Math.random().toString(36).substring(2)
    },
    isDateInAccountingLockedPeriod(date, estate) {
        if (date && estate.fields?.lockingTransactionPeriodStartDate && estate.fields?.lockingTransactionPeriodEndDate) {
            const StartDateToValidate = moment(date).endOf('day')
            const EndDateToValidate = moment(date).startOf('day')
            const StartPeriod = moment(estate.fields?.lockingTransactionPeriodStartDate).startOf('day')
            const EndPeriod = moment(estate.fields?.lockingTransactionPeriodEndDate).endOf('day')
            if (StartDateToValidate.isSameOrAfter(StartPeriod) && EndDateToValidate.isSameOrBefore(EndPeriod)) {
                return true
            } else {
                return false
            }
        }
        return false
    },
    allowOrgTiersFeatureForCurrentPlan(currentPaymentProvider, currentPlan) {
        if ([PaymentProviderTypes.orgTiers, PaymentProviderTypes.free].includes(currentPaymentProvider)) return true

        if (currentPaymentProvider === PaymentProviderTypes.stripe) {
            return [BillingPlanName.Basic, BillingPlanName.Pro, BillingPlanName.BasicLegacy].includes(currentPlan)
        }
        return false
    },
    checkIfPlanIsLegacyBasicOrPro(plan) {
        return [BillingPlanName.BasicLegacy, BillingPlanName.ProLegacy].includes(plan)
    },
    isPlanBasicOrProOrLegacyBasic(plan) {
        return [BillingPlanName.Basic, BillingPlanName.Pro, BillingPlanName.BasicLegacy].includes(plan)
    },
}
export default utils

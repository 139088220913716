import { OrganizationMemberActiveStatusRequest, OrganizationMemberRequest, OrganizationMemberResponse, OrganizationMemberResponseList, Page } from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Organization-members
 */
export class OrganizationMembersApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * Activate / deactivate an organization member
     * ${orgId}/members/${userId}/activate
     **/

    async updateMemberActiveStatus(orgId: string, userId: string, body: OrganizationMemberActiveStatusRequest): Promise<OrganizationMemberResponse> {
        try {
            const res: AxiosResponse<OrganizationMemberResponse> = await this.patch<OrganizationMemberResponse, OrganizationMemberActiveStatusRequest>(
                `/orgs/${orgId}/members/${userId}/activate`,
                body
            )
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Get the organization member picture
     * ${orgId}/members/${userId}/picture
     **/

    async getMemberPic(orgId: string, userId: string): Promise<Buffer> {
        try {
            const res: AxiosResponse<Buffer> = await this.get<Buffer>(`/orgs/${orgId}/members/${userId}/picture`, { responseType: 'arraybuffer', params: {} })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Delete an organization member
     * ${orgId}/members/${userId}
     **/

    async deleteMember(orgId: string, userId: string): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.delete<void>(`/orgs/${orgId}/members/${userId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Update an organization member
     * ${orgId}/members/${userId}
     **/

    async updateMember(orgId: string, userId: string, body: OrganizationMemberRequest): Promise<OrganizationMemberResponse> {
        try {
            const res: AxiosResponse<OrganizationMemberResponse> = await this.patch<OrganizationMemberResponse, OrganizationMemberRequest>(`/orgs/${orgId}/members/${userId}`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Get an organization member
     * ${orgId}/members/${userId}
     **/

    async getMember(orgId: string, userId: string): Promise<OrganizationMemberResponse> {
        try {
            const res: AxiosResponse<OrganizationMemberResponse> = await this.get<OrganizationMemberResponse>(`/orgs/${orgId}/members/${userId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * List organization members
     * ${orgId}/members
     **/

    async listMembers(orgId: string, search?: string, page?: Page, filter?: object, sort?: string): Promise<OrganizationMemberResponseList> {
        try {
            const res: AxiosResponse<OrganizationMemberResponseList> = await this.get<OrganizationMemberResponseList>(`/orgs/${orgId}/members`, { params: { search, page, filter, sort } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

import {
    GetCaseDetail, // Type import GetCaseDetail
    GetCases, // Type import GetCases
    DeactivateAddons, // Type import DeactivateAddons
    GetAddons, // Type import getAddons
    ActivateAddons, // Type import activateAddons
    ListAddons, // Type import ListAddons
    Page, // Type Pagination of list
} from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api, ResponseWithHeader } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Addons
 */
export class AddonsApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * Get detail list to link cases with one addons
     * ${orgId}/addons/${uuid}/cases/${id}
     **/

    async getAddonsCasesDetail(orgId: string, uuid: string, id: string): Promise<GetCaseDetail> {
        try {
            const res: AxiosResponse<GetCaseDetail> = await this.get<GetCaseDetail>(`/orgs/${orgId}/addons/${uuid}/cases/${id}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Get detail list to link cases with one addons
     * ${orgId}/addons/${uuid}/cases
     **/

    async getAddonsCases(orgId: string, uuid: string): Promise<GetCases> {
        try {
            const res: AxiosResponse<GetCases> = await this.get<GetCases>(`/orgs/${orgId}/addons/${uuid}/cases`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Deactivate a new add-on
     * ${orgId}/addons/${uuid}/deactivate
     **/

    async deactivateAddons(orgId: string, uuid: string, body: unknown): Promise<DeactivateAddons> {
        try {
            const res: AxiosResponse<DeactivateAddons> = await this.post<DeactivateAddons, unknown>(`/orgs/${orgId}/addons/${uuid}/deactivate`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Activate a new add-on
     * ${orgId}/addons/${uuid}/activate
     **/

    async activateAddons(orgId: string, uuid: string, body: ActivateAddons): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.post<void, ActivateAddons>(`/orgs/${orgId}/addons/${uuid}/activate`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Get detail about one add-on by uuid
     * ${orgId}/addons/${uuid}
     **/

    async getAddons(orgId: string, uuid: string): Promise<GetAddons> {
        try {
            const res: AxiosResponse<GetAddons> = await this.get<GetAddons>(`/orgs/${orgId}/addons/${uuid}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * List avaliable add-ons to be added to organization
     * ${orgId}/addons
     **/

    async listAddons(orgId: string): Promise<ListAddons> {
        try {
            const res: AxiosResponse<ListAddons> = await this.get<ListAddons>(`/orgs/${orgId}/addons`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

const DEFAULT_STATE = {
    error: '',
    submitted: false,
    email: '',
}
import { ACTION_RENDER_FORGOT_PASSWORD_FORM, ACTION_REQUEST_TO_RESET_PASSWORD } from './actions'

export default (state = DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case `${ACTION_REQUEST_TO_RESET_PASSWORD}_FULFILLED`:
            return { ...state, submitted: true, email: payload, error: '' }
        case `${ACTION_REQUEST_TO_RESET_PASSWORD}_REJECTED`:
            return { ...state, submitted: false, error: payload.error || payload.errors._error }
        case `${ACTION_RENDER_FORGOT_PASSWORD_FORM}`:
            return { ...state, submitted: false }
        default:
            return state
    }
}

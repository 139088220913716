import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import Loader from './modules/App/Loader'
import LoadableApp from './modules/App/App'

import './default.scss'
import PACKAGE from '../package.json'
const release = PACKAGE.version

try {
    if (window.__CONFIG__.Sentry) {
        if (!window.__CONFIG__.Sentry.release) {
            window.__CONFIG__.Sentry.release = release
        }
        window.__CONFIG__.Sentry.integrations = [
            new Sentry.Replay(),
            new BrowserTracing({
                beforeNavigate: (context) => {
                    if (window.location.hash) {
                        return {
                            ...context,
                            // Fix navigation to replace id
                            name: window.location.hash.replace(/[a-f0-9]{24}/g, ':id') || '/',
                        }
                    } else {
                        return context
                    }
                },
            }),
        ]

        if (!window.__CONFIG__.Sentry.tracesSampleRate != undefined) {
            window.__CONFIG__.Sentry.tracesSampleRate = 0.2
        }

        Sentry.init({
            // This sets the sample rate to be 10%. You may want this to be 100% while
            // in development and sample at a lower rate in production
            replaysSessionSampleRate: 0.0,
            // If the entire session is not sampled, use the below sample rate to sample
            // sessions when an error occurs.
            replaysOnErrorSampleRate: 1.0,
            ...window.__CONFIG__.Sentry,
            ignoreErrors: [
                ///validation fail, when the backend returns 400 for required parameter to show on the form for the user
                'Submit Validation Failed',
                //Canceled call to backend, when the user change screens while the API is loading
                'Non-Error promise rejection captured with keys: cancelled, message',
                // Ignore Errors on telemetry
                'AxiosError',
            ],
        })
    }
} catch (e) {
    // console.error(e)
    // Ignore Sentry error not configured
}
ReactDOM.hydrate(
    <React.Suspense fallback={<Loader></Loader>}>
        <LoadableApp />
    </React.Suspense>,
    document.getElementById('app')
)

import React, { PureComponent } from 'react'
import style from './style.module.scss'
import Status, { StatusCategories } from '../../atoms/Status'
import Tag from '../../atoms/Tag'
import { GenericAttributes } from '../../types'
import Icon, { IconTypes } from '../../particles/Icon'
import Tooltip from '../../organisms/Tooltip'

type StatusItemProps = {
    category: StatusCategories
    selected: boolean
    title: string
    icon?: IconTypes
    interactiveIcon?: {
        icon: IconTypes
        tooltipContent?: React.ReactNode
        onClick?: (ev: React.MouseEvent<HTMLDivElement>) => void
    }
    tag: React.ReactNode
    onClick: (ev: React.MouseEvent<HTMLDivElement>) => void
} & GenericAttributes
export class StatusItem extends PureComponent<StatusItemProps> {
    static statusCategories = StatusCategories
    static iconTypes = IconTypes
    static defaultProps = {
        category: StatusCategories.General,
        selected: false,
    }
    render(): React.ReactNode {
        const { category, selected, title, tag, onClick, icon, interactiveIcon, ...props } = this.props
        return (
            <div {...props} onClick={onClick} className={[style.statusItem].join(' ')} data-test={this.props['data-test']}>
                <div className={[style.container, selected ? style.selected : ''].join(' ')}>
                    <div className={style.status}>
                        <Status data-test={`${this.props['data-test']}.status`} category={category} selected={selected} />
                    </div>
                    <div className={style.content}>
                        <div className={style.title}>{title}</div>
                        <div className={style.bottom}>
                            {tag ? (
                                <div className={style.tag}>
                                    <Tag type={Tag.Types.ReadOnly} data-test={`${this.props['data-test']}.tag`}>
                                        {tag}
                                    </Tag>
                                </div>
                            ) : null}
                            {icon ? (
                                <div className={style.icon}>
                                    <Icon size={18} type={icon} />
                                </div>
                            ) : null}
                            {interactiveIcon ? (
                                <Tooltip tooltipContent={interactiveIcon?.tooltipContent}>
                                    <div className={style.icon2} onClick={interactiveIcon?.onClick}>
                                        <Icon size={18} type={interactiveIcon?.icon} />
                                    </div>
                                </Tooltip>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className={style.separator} />
            </div>
        )
    }
}
export default StatusItem

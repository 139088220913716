import { ExchangeResponse, ExchangeResponseList } from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Exchanges
 */
export class ExchangesApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * Get currency rate at date
     * currency/${sourceCurrency}/${targetCurrency}/${date}
     **/

    async getCurrencyPriceClosePriceAtDate(sourceCurrency: string, targetCurrency: string, date: string): Promise<ExchangeResponse> {
        try {
            const res: AxiosResponse<ExchangeResponse> = await this.get<ExchangeResponse>(`/exchanges/currency/${sourceCurrency}/${targetCurrency}/${date}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Get exchange bonds price at date
     * bonds/${foreignId}/${date}
     **/

    async getBondsClosePriceAtDate(foreignId: string, date: string): Promise<ExchangeResponse> {
        try {
            const res: AxiosResponse<ExchangeResponse> = await this.get<ExchangeResponse>(`/exchanges/bonds/${foreignId}/${date}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Get exchange price at date
     * ${exchangeName}/${symbolCode}/${date}
     **/

    async getExchangeSymbolClosePriceAtDate(exchangeName: string, symbolCode: string, date: string): Promise<ExchangeResponse> {
        try {
            const res: AxiosResponse<ExchangeResponse> = await this.get<ExchangeResponse>(`/exchanges/${exchangeName}/${symbolCode}/${date}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Get price history for an exchange
     * ${exchangeName}/${symbolCode}
     **/

    async getExchangeSymbol(exchangeName: string, symbolCode: string): Promise<ExchangeResponse> {
        try {
            const res: AxiosResponse<ExchangeResponse> = await this.get<ExchangeResponse>(`/exchanges/${exchangeName}/${symbolCode}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Search exchange
     *
     **/

    async listExchangesSymbols(symbolCode?: string, type?: string): Promise<ExchangeResponseList> {
        try {
            const res: AxiosResponse<ExchangeResponseList> = await this.get<ExchangeResponseList>(`/exchanges/`, { params: { symbolCode, type } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

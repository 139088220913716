import {
    PhaseResponse, // Type import PhaseResponse
    PhasesResponse, // Type import PhasesResponse
    CreateTasksFromTemplatesPostRequest, // Type import CreateTasksFromTemplatesPostRequest
    CreateTasksFromTemplatesResponse, // Type import CreateTasksFromTemplatesResponse
    PersonalizedTaskSkeleton, // Type import PersonalizedTaskSkeleton
    BulkTaskRequest,
    BulkTasksResponse,
    Page,
    TaskCreateResponse,
    TaskPatchRequest,
    TaskPostRequest,
    TaskResponse,
    TaskResponseList,
    TaskReviewRequest,
} from '../types'

/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Estate-tasks
 */
export class EstateTasksApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }

    //Methods

    /**
     * get the estate tasks by Id
     * ${estateId}/tasks/${taskId}
     **/

    async getTask(estateId: string, taskId: string): Promise<TaskResponse> {
        try {
            const res: AxiosResponse<TaskResponse> = await this.get<TaskResponse>(`/estates/${estateId}/tasks/${taskId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Create a phase
     * ${estateId}/phases
     **/

    async createPhase(estateId: string, body: unknown): Promise<PhaseResponse> {
        try {
            const res: AxiosResponse<PhaseResponse> = await this.post<PhaseResponse, unknown>(`/estates/${estateId}/phases`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * List phases
     * ${estateId}/phases
     **/

    async listPhases(estateId: string, page?: Page, filter?: object, search?: string, sort?: string): Promise<PhasesResponse> {
        try {
            const res: AxiosResponse<PhasesResponse> = await this.get<PhasesResponse>(`/estates/${estateId}/phases`, { params: { page, filter, search, sort } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Create tasks from templates
     * ${estateId}/tasks/templates
     **/

    async createEstateTasksFromTemplates(estateId: string, body: CreateTasksFromTemplatesPostRequest): Promise<CreateTasksFromTemplatesResponse> {
        try {
            const res: AxiosResponse<CreateTasksFromTemplatesResponse> = await this.post<CreateTasksFromTemplatesResponse, CreateTasksFromTemplatesPostRequest>(
                `/estates/${estateId}/tasks/templates`,
                body
            )
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Bulk update my tasks
     * bulk
     **/

    async updateBulkMyTasks(body: BulkTaskRequest, page?: Page, filter?: object, search?: string, sort?: string): Promise<BulkTasksResponse> {
        try {
            const res: AxiosResponse<BulkTasksResponse> = await this.patch<BulkTasksResponse, BulkTaskRequest>(`/tasks/bulk`, body, { params: { page, filter, search, sort } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Bulk update estate tasks
     * ${estateId}/tasks/bulk
     **/

    async updateBulkEstateTasks(estateId: string, body: BulkTaskRequest): Promise<BulkTasksResponse> {
        try {
            const res: AxiosResponse<BulkTasksResponse> = await this.patch<BulkTasksResponse, BulkTaskRequest>(`/estates/${estateId}/tasks/bulk`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * List the personalized tasks for an asset
     * ${estateId}/tasks/${inventoryId}/personalized
     **/

    async getPersonalizedTasks(estateId: string, inventoryId: string): Promise<PersonalizedTaskSkeleton> {
        try {
            const res: AxiosResponse<PersonalizedTaskSkeleton> = await this.get<PersonalizedTaskSkeleton>(`/estates/${estateId}/tasks/${inventoryId}/personalized`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Review a task
     * ${estateId}/tasks/${taskId}/review
     **/

    async reviewEstateTask(estateId: string, taskId: string, body: TaskReviewRequest): Promise<TaskResponse> {
        try {
            const res: AxiosResponse<TaskResponse> = await this.patch<TaskResponse, TaskReviewRequest>(`/estates/${estateId}/tasks/${taskId}/review`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Update a task
     * ${estateId}/tasks/${uuid}
     **/

    async updateEstateTaskLegacy(estateId: string, uuid: string, body: TaskPatchRequest): Promise<TaskResponse> {
        try {
            const res: AxiosResponse<TaskResponse> = await this.patch<TaskResponse, TaskPatchRequest>(`/estates/${estateId}/tasks/${uuid}`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Get an estate task
     * ${estateId}/tasks/${uuid}
     **/

    async getEstateTaskLegacy(estateId: string, uuid: string, page?: Page, filter?: object, search?: string, sort?: string): Promise<TaskResponse> {
        try {
            const res: AxiosResponse<TaskResponse> = await this.get<TaskResponse>(`/estates/${estateId}/tasks/${uuid}`, { params: { page, filter, search, sort } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Update a task
     * ${estateId}/tasks/${taskId}
     **/

    async updateEstateTask(estateId: string, taskId: string, body: TaskPatchRequest): Promise<TaskCreateResponse> {
        try {
            const res: AxiosResponse<TaskCreateResponse> = await this.patch<TaskCreateResponse, TaskPatchRequest>(`/estates/${estateId}/tasks/${taskId}`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * List the estate tasks
     * ${estateId}/tasks
     **/

    async listEstateTasks(estateId: string, page?: Page, filter?: object, search?: string, sort?: string): Promise<TaskResponseList> {
        try {
            const res: AxiosResponse<TaskResponseList> = await this.get<TaskResponseList>(`/estates/${estateId}/tasks`, { params: { page, filter, search, sort } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Create a task
     * ${estateId}/tasks
     **/

    async createEstateTask(estateId: string, body: TaskPostRequest): Promise<TaskCreateResponse> {
        try {
            const res: AxiosResponse<TaskCreateResponse> = await this.post<TaskCreateResponse, TaskPostRequest>(`/estates/${estateId}/tasks`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * List tasks by user
     *
     **/

    async listTasksByUser(page?: Page, filter?: object, search?: string, sort?: string): Promise<TaskResponseList> {
        try {
            const res: AxiosResponse<TaskResponseList> = await this.get<TaskResponseList>(`/tasks/`, { params: { page, filter, search, sort } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Delete a phase
     * ${estateId}/phases/${phaseId}
     **/

    async deletePhase(phaseId: string, estateId: string): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.delete<void>(`/estates/${estateId}/phases/${phaseId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

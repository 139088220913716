import React from 'react'
import { BillingPlan, Organization, PlanPrice } from './system/api/types'
import { ReadVaultCaseDto } from './system/vault_api/types'
import { getCompanyLogo } from './utils/DesignSystem/branding'
import { CaseDetailsData } from './templates/types'

export const CURRENCIES = ['CAD', 'USD', 'AUD', 'EUR', 'GBP', 'NZD']

export type CurrencyTypes = 'CAD' | 'USD' | 'AUD' | 'EUR' | 'GBP' | 'NZD'

export type WorkflowContextType = {
    'dateOfDeath'?: string
    'estateReturnFilingDeadline'?: string
    'executorType'?: string
    'firstName'?: string
    'haveBequest'?: boolean
    'haveWill'?: boolean
    'lastName'?: string
    'maritalStatus'?: string
    'province'?: string
    'sinNumber'?: string
    'terminalReturnFilingDeadline'?: string // Date
    'wasMarried'?: boolean
    'birthDate'?: string // '1988-02-02'
    'caseDetailsFormValues'?: {
        'AKA'?: 'No' | 'Yes'
        'AKA1e'?: 'No' | 'Yes'
        'assetSecurityGiven'?: string
        'assignedEmail'?: string
        'assignedFax'?: string
        'assignedLawyer'?: string
        'assignedLawyerManual'?: string
        'assignedPhone'?: string
        'bond'?: 'Not required' | string
        'bondDispenseCreditors'?: string
        'bondDispenseReason'?: string
        'codicil1firstWitnessGender'?: string
        'codicil1secondWitnessGender'?: string
        'codicil2firstWitnessGender'?: string
        'codicil2secondWitnessGender'?: string
        'codicilq'?: string //'2'
        'codicilq2'?: string
        'compensationAsked'?: 'No' | 'Yes'
        'deceasedBlind'?: 'Yes' | 'No'
        'deceasedOccupation'?: string
        'execChangedByCodicil'?: string
        'fileNumber'?: string
        'firstWitnessGender'?: 'Female' | string
        'fullWillName'?: string
        'gender'?: 'Female' | string
        'haveWill'?: 'No' | 'Yes'
        'judicialCenter'?: 'Dauphin' | string
        'lawyerFileNumber'?: string
        'originalWill'?: string
        'placeOfBirth'?: string
        'placeOfDeath'?: string
        'probateGranted'?: string // '2023-12-31'
        'secondWitnessGender'?: 'Female' | string
        'spouseAfterWill'?: 'Yes' | 'No'
        'swearType'?: 'Affirmed' | string
        'testatorThirdParty'?: 'Yes' | 'No'
        'unrevokedWill'?: 'No' | 'Yes'
        'useOrgAddress'?: 'Yes' | 'No'
        'willInterlineation'?: 'beforeWill' | string
        'witnessBeneficiaryJustification'?: string
        'witnessNotBeneficiary'?: 'True' | string
        'AKA1a'?: string
        'AKA1b'?: string
        'AKA1c'?: string
        'AKA1d'?: string
        'AKA2a'?: string
        'AKA2b'?: string
        'AKA2c'?: string
        'AKA2d'?: string
        'AKA2e'?: string
        'AKA3a'?: string
        'AKA3b'?: string
        'AKA3c'?: string
        'AKA3d'?: string
    }
    'city'?: string
    'country'?: 'United States' | string
    'firstMiddleName'?: string
    'secondMiddleName'?: string
    'state'?: 'California' | string
    'street'?: string
    'taxIdentificationNumber'?: string //'222-222-222'
    'zipCode'?: string
}

export type EstateContextProps = {
    estateId?: string
    notifyEstateValueChanged?: () => void
    // TODO Release a Estate instance once refactor WorkflowContainer
    caseDetails?: CaseDetailsData
    formsAndLettersTags?: Array<string>
    workflowContext?: WorkflowContextType
}
export const EstateContext = React.createContext<EstateContextProps>({})

export type Theme = {
    displayName?: string
    name: string
    leftMenu: {
        logo: any
    }
    dashboard: {
        logo: any
        title: ''
    }
}

export type assignedLawyer = {
    name?: string
    phone?: string
    lso?: string
    email?: string
    fax?: string
    solePractitioner?: boolean
    orgName?: string
}

export type UserInfoProps = {
    config?: {
        flinksIframeUrl?: string
    }
    token?: string
    user?: {
        name: string
        id: string
        email: string
        phoneNumber: string
        profilePic: Blob
        lastLogin?: string
        language?: string
    }
    features?: { [key: string]: number }
    prices?: PlanPrice[]
    org?: Organization & {
        userRole: string
        id: string
        currentPlan?: BillingPlan
        jurisdictions?: string[]
        info?: {
            notesCategory?: string[]
            lawyers?: assignedLawyer[]
        }
    }
    theme?: Theme
    events?: {
        checkSession: (pathName: string) => void
    }
}
const companyLogo = getCompanyLogo()
export const UserInfo = React.createContext<UserInfoProps>({
    theme: {
        name: 'blue',
        leftMenu: {
            logo: companyLogo,
        },
        dashboard: {
            logo: companyLogo,
            title: '',
        },
    },
})

export type VaultCaseContextProps = {
    caseId?: string
    notifyVaultCaseValuesChanged?: () => void
    caseDetails?: ReadVaultCaseDto
}

export const VaultCaseContext = React.createContext<VaultCaseContextProps>({})

import React, { PureComponent } from 'react'
import style from './style.module.scss'
import Icon from '../../particles/Icon'
import Checkbox, { CheckboxValues } from '../../atoms/Checkbox'
import { GenericAttributes } from '../../types'

type CheckboxLabeledItemProps = {
    value: CheckboxValues
    label: React.ReactNode
    readonly?: boolean
    background?: boolean
    onClick?: (ev: React.MouseEvent<HTMLElement>) => void
} & GenericAttributes

export class CheckboxLabeledItem extends PureComponent<CheckboxLabeledItemProps> {
    static selectionValues = Checkbox.selectionValues
    static defaultProps = {
        readonly: false,
        background: true,
    }

    render(): React.ReactNode {
        const { label, background, onClick, readonly, value, ...props } = this.props
        const icon = value === CheckboxValues.SELECTED
        return (
            <div {...props} data-test={`${this.props['data-test']}`} className={[style.checkboxLabeledItem, background ? style.background : null].join(' ')}>
                <div className={style.checkbox}>
                    {readonly ? (
                        icon ? (
                            <Icon type={Icon.types.Check} size={30} />
                        ) : null
                    ) : (
                        <Checkbox data-test={`${this.props['data-test']}.checkbox`} onClick={onClick} value={value} disabled={false} size={Checkbox.sizes.small} />
                    )}
                </div>
                <div data-test={`${this.props['data-test']}.label`} onClick={onClick} className={style.label}>
                    {label}
                </div>
            </div>
        )
    }
}

export default CheckboxLabeledItem

import { DocumentResponseList, Page } from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Estate-documents
 */
export class EstateDocumentsApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * List estate documents
     * ${estateId}/documents
     **/

    async listEstateDocuments(estateId: string, page?: Page, filter?: object, sort?: string): Promise<DocumentResponseList> {
        try {
            const res: AxiosResponse<DocumentResponseList> = await this.get<DocumentResponseList>(`/estates/${estateId}/documents`, { params: { page, filter, sort } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

import React, { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react'
import style from './style.module.scss'
import Icon, { IconTypes } from '../../particles/Icon'
import Link from '../../atoms/Link'
import { GenericAttributes } from '../../types'
import Popover, { positionDefault, positionMatchWidth } from '@reach/popover'

type DropdownProps = {
    title: string
    /**
     * return true if keep open on click
     */
    onClick?: (index: number) => boolean | void
    iconLeft?: boolean
    linkTitle?: string
    linkAction?: (ev: React.MouseEvent<HTMLElement, MouseEvent>) => void
    isLabelTruncatedWithEllipsis?: boolean
    matchParentWidth?: boolean
    disabled?: boolean
} & GenericAttributes

export const Dropdown = ({
    onClick,
    iconLeft = false,
    children,
    title,
    linkTitle,
    linkAction,
    isLabelTruncatedWithEllipsis,
    matchParentWidth,
    disabled = false,
    ...props
}: PropsWithChildren<DropdownProps>) => {
    const myRef = useRef<HTMLDivElement>(null)
    const divRef = useRef<HTMLDivElement>(null)
    const [open, setOpen] = useState(false)

    useEffect(() => {
        document.addEventListener('mousedown', handleClick, false)
        return () => {
            document.removeEventListener('mousedown', handleClick, false)
        }
    }, [myRef, divRef, open])

    const handleClick = (event: MouseEvent): void => {
        // Close when click outside <popover> and outside header
        if (!disabled && open && divRef?.current && !divRef?.current?.contains(event.target as Node) && !myRef?.current?.contains(event.target as Node)) {
            setOpen(false)
        }
    }

    const handleClickHeader = (): void => {
        if (!disabled) {
            setOpen(!open)
        }
    }

    const handleClickItem = (index: number): void => {
        if (!disabled && onClick) {
            const keepOpen = onClick(index)
            if (!keepOpen) {
                setOpen(false)
            }
        }
    }
    const position = useMemo(() => (matchParentWidth ? positionMatchWidth : positionDefault), [matchParentWidth])
    return (
        <div {...props} data-test={`${props['data-test']}`} onClick={undefined} className={[style.dropdown].join(' ')}>
            <div
                ref={myRef}
                className={[style.header, iconLeft ? style.iconLeft : null, disabled ? style.disabled : null].join(' ')}
                onClick={handleClickHeader}
                data-test={`${props['data-test']}.label`}>
                {iconLeft ? <Icon type={open ? IconTypes.ArrowClose : IconTypes.ArrowOpen} size={30} /> : null}
                <span className={isLabelTruncatedWithEllipsis ? style.truncated : undefined}>{title}</span>
                {iconLeft ? null : <Icon type={open ? IconTypes.ArrowClose : IconTypes.ArrowOpen} size={30} />}
            </div>
            <Popover className={[style.dropdown].join(' ')} ref={divRef} hidden={!open} targetRef={myRef} position={position}>
                <ul data-test={`${props['data-test']}.itemList`} className={[style.list, style.reset].join(' ')}>
                    {linkTitle && linkAction ? (
                        <li data-test={`${props['data-test']}.itemList.link`} className={[style.link, style.reset].join(' ')}>
                            <Link onClick={linkAction} data-test={`${props['data-test']}.link`}>
                                {linkTitle}
                            </Link>
                        </li>
                    ) : null}
                    {React.Children.map(children, (child, index) => (
                        <li
                            key={`${props['data-test']}.itemList.item.${index}`}
                            className={[style.item, style.reset].join(' ')}
                            onClick={() => handleClickItem(index)}
                            data-test={`${props['data-test']}.options.${index}`}>
                            {child}
                        </li>
                    ))}
                </ul>
            </Popover>
        </div>
    )
}

export default Dropdown

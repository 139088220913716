import {
    EmailDocumentRequest, // Type import EmailDocumentRequest
    EmailDocumentResponse, // Type import EmailDocumentResponse
    Page, // Type Pagination of list
} from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api, ResponseWithHeader } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/ExternalMessages
 */
export class ExternalMessagesApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * Email to an external email
     * email
     **/

    async email(body: EmailDocumentRequest): Promise<EmailDocumentResponse> {
        try {
            const res: AxiosResponse<EmailDocumentResponse> = await this.post<EmailDocumentResponse, EmailDocumentRequest>(`/externalMessages/email`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Email to an external email
     * email
     **/

    async externalMessages(body: EmailDocumentRequest): Promise<EmailDocumentResponse> {
        try {
            const res: AxiosResponse<EmailDocumentResponse> = await this.post<EmailDocumentResponse, EmailDocumentRequest>(`/externalMessages/email`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

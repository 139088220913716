import React from 'react'
import { GenericAttributes } from '../../types'
import Button, { ButtonSizes } from '../Button'

type ButtonDangerProps = Omit<React.ComponentProps<'button'>, 'size'> & {
    size?: ButtonSizes
} & GenericAttributes

export const ButtonDanger = ({ size = 'large', children, ...props }: ButtonDangerProps) => {
    return (
        <Button {...props} size={size} mode={'danger'}>
            {children}
        </Button>
    )
}
export default ButtonDanger

/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { CreateAxiosDefaults } from 'axios'

export const apiConfig: Partial<CreateAxiosDefaults> = {
    timeout: __CONFIG__.API_TIMEOUT || 60000,
    baseURL: __CONFIG__.API || 'http://localhost:3001/api/',
    headers: {
        'Content-Type': 'application/vnd.api+json',
        common: {
            'cache-control': 'no-cache, no-store, must-revalidate',
            Pragma: 'no-cache',
            Accept: 'application/vnd.api+json',
        },
    },
    paramsSerializer: {
        indexes: null,
    },
}

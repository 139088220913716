import React, { PureComponent } from 'react'
import style from './style.module.scss'
import Checkbox, { CheckboxValues } from '../../atoms/Checkbox'
import { GenericAttributes } from '../../types'

type HeaderCheckBoxProps = {
    onSelect?: (ev: React.MouseEvent<HTMLElement>) => void
    onClick?: (ev: React.MouseEvent<HTMLElement>) => void
    selected?: CheckboxValues
} & GenericAttributes
export class HeaderCheckBox extends PureComponent<HeaderCheckBoxProps> {
    static selectionValues = Checkbox.selectionValues
    static defaultProps = {}
    render(): React.ReactNode {
        const { children, onSelect, onClick, selected, ...props } = this.props
        return (
            <div {...props} data-test={`${this.props['data-test']}`} className={[style.headerCheckBox].join(' ')}>
                {onSelect ? (
                    <div data-test={`${this.props['data-test']}.checkbox.click`} onClick={onClick} className={style.checkbox}>
                        <Checkbox data-test={`${this.props['data-test']}.checkbox.select`} value={selected} onClick={onSelect} />
                    </div>
                ) : null}
                {children}
            </div>
        )
    }
}
export default HeaderCheckBox

import React, { Fragment, PropsWithChildren } from 'react'
import style from './style.module.scss'
import ToolBarItem from '../../molecules/ToolBarItem'
import { GenericAttributes } from '../../types'

type ToolBarProps = {
    children: React.ReactElement<ToolBarItem>[]
} & GenericAttributes

export const ToolBar = ({ children, ...props }: PropsWithChildren<ToolBarProps>) => {
    return (
        <div {...props} data-test={props['data-test']} className={[style.toolBar].join(' ')}>
            {Object.values(children).map((child, index, arr) => {
                const shouldRenderDivider = index < arr.length - 1
                return (
                    <Fragment key={index}>
                        {child}
                        {shouldRenderDivider ? <div className={style.divider} /> : null}
                    </Fragment>
                )
            })}
        </div>
    )
}

export default React.memo(ToolBar)

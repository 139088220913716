import { createHashHistory } from 'history'

const historyHash = createHashHistory({ queryKey: false })
const push = historyHash.push
historyHash.push = (url, preserveSearch = true, preserveRedirect = false) => {
    if (historyHash.location && historyHash.location.search) {
        if (preserveRedirect) {
            url += `?url=${encodeURIComponent(`${historyHash.location.pathname}${preserveSearch ? historyHash.location.search : ''}`)}`
        } else {
            url += preserveSearch ? historyHash.location.search : ''
        }
    }
    push(url)
}
export default historyHash

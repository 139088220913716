import React, { useCallback, useEffect, useRef, useState } from 'react'
// eslint-disable-next-line css-modules/no-unused-class
import style from './style.module.scss'
import SectionText from '../../atoms/SectionText'
import TextField from '../../atoms/TextField'
import Link from '../../atoms/Link'
import { GenericAttributes } from '../../types'
import Popover, { positionDefault } from '@reach/popover'
import useOnScreen from '../../../../utils/hooks/useOnScreen'
import { Icon, IconTypes } from '../../particles/Icon'
import { Tooltip, TooltipProps } from '../../../../DesignSystem/Tooltip'

export type SelectAutocompleteItem = {
    id: string
    label: string
}

export type SelectTooltipProps = TooltipProps & {
    icon?: IconTypes
}

type SelectAutocompleteProps = {
    value?: string
    options: SelectAutocompleteItem[]
    link?: {
        label: string
        onClick: (ev: React.MouseEvent<HTMLElement>) => void
    }
    showArrowIcon?: boolean
    canType?: boolean
    opened?: boolean
    name?: string
    bottomMessage?: string
    className?: string
    popoverClassName?: string
    placeholder?: string
    title?: string
    disabled?: boolean
    hasError?: boolean
    hasWarning?: boolean
    required?: boolean
    autoFocus?: boolean
    onFocus?: (ev?: React.FocusEvent<HTMLElement>) => void
    onBlur?: (ev?: React.FocusEvent<HTMLElement>) => void
    onClick?: (ev: React.MouseEvent<HTMLElement>) => void
    onSelect?: (index: number, item: string) => void
    onChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void
    onClose?: () => void
    mode?: 'clean' // Style mode to suppress space not necessary like error spam
    tooltip?: SelectTooltipProps
} & GenericAttributes

export const SelectAutocomplete = React.forwardRef<HTMLInputElement, SelectAutocompleteProps>(
    (
        {
            hasError,
            hasWarning,
            showArrowIcon = true,
            canType = true,
            onClose,
            onFocus,
            disabled,
            onSelect,
            options,
            link,
            onChange,
            onBlur,
            value,
            name,
            placeholder,
            autoFocus,
            required,
            bottomMessage,
            title,
            mode,
            tooltip,
            className,
            popoverClassName,
            ...props
        }: SelectAutocompleteProps,
        ref
    ) => {
        /** states **/
        const [opened, setOpened] = useState(false)
        const [selected, setSelected] = useState(-1)
        const [isListHidden, setIsListHidden] = useState(false)

        /** refs **/
        const myRef = useRef<HTMLDivElement>(null)
        const listRef = useRef<HTMLDivElement>(null)
        const itemsRef = useRef<Array<HTMLDivElement | null>>([])

        /** hooks **/
        const isTextFieldVisible = useOnScreen(myRef)
        const arrowIconToShow = showArrowIcon ? (opened ? TextField.IconTypes.ArrowClose : TextField.IconTypes.ArrowOpen) : undefined
        /** callbacks **/
        const handlePageScroll = useCallback((): void => {
            if (!isTextFieldVisible && opened) {
                setOpened(false)
                setIsListHidden(true)
            }
        }, [opened, isTextFieldVisible])

        /** useEffects **/
        useEffect(() => {
            if (selected >= 0 && selected < options.length) itemsRef.current[selected]?.scrollIntoView({ block: 'nearest' })
        }, [selected])

        useEffect(() => {
            setSelected(-1)
            if (opened) {
                document.addEventListener('pointerdown', handleClick)
                document.addEventListener('scroll', handlePageScroll, true)

                if (onFocus) {
                    onFocus()
                }
            } else {
                document.removeEventListener('pointerdown', handleClick)
                if (onClose) {
                    onClose()
                }
            }
            return () => {
                document.removeEventListener('pointerdown', handleClick)
                document.removeEventListener('scroll', handlePageScroll, true)
            }
        }, [opened, handlePageScroll])

        useEffect(() => {
            if (isListHidden && isTextFieldVisible) {
                setOpened(true)
                setIsListHidden(false)
            }
        }, [isListHidden, isTextFieldVisible])

        const handleClick = (event: MouseEvent): void => {
            // Close when click outside div.
            if (listRef.current && !listRef.current.contains(event.target as Node) && myRef.current && !myRef.current.contains(event.target as Node)) {
                setOpened(false)
                onClose && onClose()
            }
        }

        const getIsOpenStyle = (): string => {
            return opened ? style.open : style.close
        }

        const toggleList = () => {
            if (disabled) return
            setOpened(!opened)
        }

        const onKeydown = (event: React.KeyboardEvent) => {
            if (opened) {
                switch (event.key) {
                    case 'ArrowDown':
                        event.preventDefault()
                        if (selected == -1 || selected >= options.length - 1) {
                            setSelected(0)
                        } else {
                            setSelected(selected + 1)
                        }
                        break
                    case 'ArrowUp':
                        event.preventDefault()
                        if (selected == -1 || selected == 0) {
                            setSelected(options.length - 1)
                        } else {
                            setSelected(selected - 1)
                        }
                        break
                    case 'Enter':
                        event.preventDefault()
                        if (selected != -1) onSelect && onSelect(selected, options[selected].id)
                        setOpened(false)
                        break
                }
            } else {
                switch (event.key) {
                    case 'ArrowDown':
                        event.preventDefault()
                        setOpened(true)
                        break
                }
            }
        }

        const selectItem = (item: SelectAutocompleteItem) => {
            if (onSelect) {
                onSelect(options.indexOf(item), item.id)
                setOpened(false)
            }
        }

        const renderList = () => {
            return (
                <Popover hidden={!opened} targetRef={myRef} position={positionDefault} className={popoverClassName}>
                    <div style={{ width: myRef.current?.clientWidth }} role='options' className={[style.options].join(' ')} ref={listRef}>
                        {link ? (
                            <div onClick={link.onClick} className={[style.listItem, style.link].join(' ')} key={'link'}>
                                <Link data-test={`${props['data-test']}.dropdown.link`} onClick={() => undefined}>
                                    {link.label}
                                </Link>
                            </div>
                        ) : null}
                        {options?.map((item, idx) => (
                            <div className={[style.listItem, selected == idx ? style.selected : ''].join(' ')} key={`${item.label}.${idx}`} ref={(el) => (itemsRef.current[idx] = el)}>
                                <SectionText
                                    data-test={`${props['data-test']}.dropdown.options.${idx}`}
                                    onMouseDown={() => {
                                        selectItem(item)
                                    }}>
                                    {item.label}
                                </SectionText>
                            </div>
                        ))}
                    </div>
                </Popover>
            )
        }
        const handleOnBlur = (ev?: React.FocusEvent<HTMLElement>) => {
            setIsListHidden(false)
            setOpened(false)

            onBlur && onBlur(ev)
        }
        const renderField = () => {
            return (
                <div ref={myRef} className={[style.toggle, getIsOpenStyle()].join(' ')}>
                    <TextField
                        id={name}
                        inputRef={ref}
                        placeholder={placeholder}
                        readOnly={!canType}
                        name={name}
                        disabled={disabled}
                        hasError={hasError}
                        hasWarning={hasWarning}
                        onBlur={handleOnBlur}
                        onKeydown={onKeydown}
                        onFocus={() => {
                            setOpened(true)
                            if (onFocus) {
                                onFocus()
                            }
                        }}
                        onClick={(e) => {
                            e.stopPropagation()
                            setOpened(true)
                        }}
                        onClickIcon={() => {
                            toggleList()
                        }}
                        type={TextField.Types.Text}
                        value={value}
                        icon={arrowIconToShow}
                        onChange={onChange}
                        autoComplete={'off'}
                        autoFocus={autoFocus}
                        data-test={`${props['data-test']}.dropdown.input`}
                    />
                </div>
            )
        }

        return (
            <div
                className={[style.selectAutocomplete, disabled ? style.disabled : '', hasWarning ? style.withWarning : '', hasError ? style.withError : '', className].join(' ')}
                data-test={`${props['data-test']}.dropdown`}>
                <label htmlFor={name} data-test={`${props['data-test']}.dropdown.label`}>
                    {required ? <span className={style.required}>*</span> : undefined}
                    {title}
                    {tooltip ? (
                        <Tooltip
                            content={tooltip.content}
                            title={tooltip.title}
                            action={tooltip.action}
                            defaultOpen={tooltip.defaultOpen}
                            side={'right'}
                            data-test={`${props['data-test']}`}
                            dark={tooltip.dark === undefined ? true : tooltip.dark}
                            asChild>
                            <span data-test={`${props['data-test']}.icon`} className={style.tooltip}>
                                <Icon size={24} type={tooltip.icon} />
                            </span>
                        </Tooltip>
                    ) : undefined}
                </label>
                {renderField()}
                {renderList()}
                {mode !== 'clean' ? (
                    <span data-test={`${props['data-test']}.bottomMessage`} className={[style.bottomMessage, 'errorBottomMessage'].join(' ')}>
                        {bottomMessage}&nbsp;
                    </span>
                ) : null}
            </div>
        )
    }
)

export default SelectAutocomplete

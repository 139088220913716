import React, { PureComponent } from 'react'
import style from './style.module.scss'
import Icon from '../../particles/Icon'
import Illustration from '../../particles/Illustration'
import ActionBar, { ActionBarButtonTypes } from '../../molecules/ActionBar'
import { GenericAttributes } from '../../types'

type ActionCardProps = {
    buttons: React.ReactElement<ActionBarButtonTypes>[]
    title?: string
    subtitle?: string | JSX.Element
    details?: Array<string>
    illustrationType?: string
    onClose?: (ev: React.MouseEvent) => void
} & GenericAttributes
export class ActionCard extends PureComponent<ActionCardProps> {
    static buttonsType = ActionBar.buttonsType

    static defaultProps = {
        illustrationType: Illustration.types.Error,
    }

    render(): React.ReactNode {
        const { children, buttons, title, subtitle, details, onClose, illustrationType, ...props } = this.props
        return (
            <div {...props} data-test={`${this.props['data-test']}`} className={style.modalBackground}>
                <div className={style.actionCard}>
                    {onClose ? (
                        <div className={style.close} onClick={onClose} data-test={`${this.props['data-test']}.close.icon`}>
                            <Icon type={Icon.types.Close} size={30} />
                        </div>
                    ) : (
                        <div className={style.close} data-test={`${this.props['data-test']}.padding`} />
                    )}
                    <div className={style.illustration} data-test={`${this.props['data-test']}.illustration`}>
                        <Illustration type={illustrationType} size={60} />
                    </div>
                    {title ? (
                        <div className={style.title} data-test={`${this.props['data-test']}.title`}>
                            {title}
                        </div>
                    ) : null}
                    <div className={style.subtitle} data-test={`${this.props['data-test']}.subtitle`}>
                        <p>{subtitle}</p>
                    </div>
                    <ul tabIndex={0} className={style.details}>
                        {details?.map((item, index) => {
                            return (
                                <li className={style.listItem} key={`${item}.${index}.key`}>
                                    - {item} <br />
                                </li>
                            )
                        })}
                    </ul>
                    {children}
                    <ActionBar data-test={`${this.props['data-test']}.actionBar`}>{buttons}</ActionBar>
                </div>
            </div>
        )
    }
}
export default ActionCard

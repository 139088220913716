import React from 'react'
import style from './style.module.scss'
import { GenericAttributes } from '../../types'

type CounterProps = {
    count: number
} & GenericAttributes

export const Counter = ({ count, ...props }: CounterProps) => {
    return (
        <div {...props} data-test={`${props['data-test']}`} className={[style.counter].join(' ')} title={`${count}`}>
            <span>{`${count ? (count < 100 ? count : '+99') : ''}`}</span>
        </div>
    )
}
export default React.memo(Counter)

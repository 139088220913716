import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import DateTime from 'react-datetime'
import moment from 'moment'
import 'react-datetime/css/react-datetime.css'
import style from './style.module.scss'
import { GenericAttributes } from '../../types'
const months = moment.months
/**
 * Generic styled PopupCalendar
 */

type PopupCalendarProps = {
    opened: boolean
    onClose: (target?: Node) => void
    onChange: (value: string | moment.Moment | Date) => void
    onViewModeChange?: (viewMode: string) => void
    value: string | Date | moment.Moment
    top?: boolean
    left?: boolean
    linkRef?: HTMLElement
    locale?: string
    input?: boolean
} & GenericAttributes

export class PopupCalendar extends Component<PopupCalendarProps> {
    private suggestionsRef: React.RefObject<HTMLDivElement> = React.createRef()
    private anchorRef: React.RefObject<HTMLDivElement> = React.createRef()
    private top?: boolean
    private left?: boolean
    private position = {
        x: 0,
        y: 0,
    }

    static defaultProps = {
        defaultOpened: false,
    }

    handleClick = (event: MouseEvent): void => {
        //Close when click outside div.
        const { opened, onClose } = this.props
        if (opened && !this.portalElement?.contains(event.target as Node)) {
            onClose(event.target as Node)
        }
    }
    handleWheel = (): void => {
        //Close when click scroll div.
        const { opened, onClose } = this.props
        if (opened) {
            onClose()
        }
    }

    shouldComponentUpdate(nextProps: PopupCalendarProps): boolean {
        if (nextProps.opened != this.props.opened) {
            this.setPopupCalendarWindowPosition(nextProps)
            return true
        }
        if (nextProps.value !== this.props.value) {
            return true
        }
        return false
    }

    private portalElement?: HTMLElement
    private id = 'PopupCalendar'

    componentDidUpdate(): void {
        if (this.portalElement) ReactDOM.render(this.getComponent(), this.portalElement)
    }

    setPopupCalendarWindowPosition = (props: PopupCalendarProps): void => {
        if (props.opened && this.suggestionsRef) {
            let p = this.id && document.getElementById(this.id)
            document.addEventListener('mouseup', this.handleClick, false)
            document.addEventListener('wheel', this.handleWheel)
            p = this.id && document.getElementById(this.id)
            if (!p) {
                p = document.createElement('div')
                p.id = this.id
                document.body.appendChild(p)
            }
            this.portalElement = p
            const ref: Element | undefined = props.linkRef
            if (ref) {
                this.top = props.top
                const calendarWidth = 265
                if (this.top === undefined) {
                    //Automatically calculate
                    const windowHeight = window.innerHeight
                    const bounds = ref?.getBoundingClientRect()
                    const topRenderSpaceLeft = bounds?.top || 0 //Distance from div top to the window top
                    const bottomRenderSpace = windowHeight - (bounds?.top || 0) //Distance from div top to the window bottom
                    const calendarHeight = 300 //There is a fixed height
                    this.top = bottomRenderSpace < calendarHeight && bottomRenderSpace < topRenderSpaceLeft
                }

                this.left = props.left
                let correctedX
                const windowWidth = window.innerWidth
                if (this.left === undefined) {
                    //Automatically calculate
                    const bounds = ref?.getBoundingClientRect()
                    const leftRenderSpaceLeft = bounds?.left || 0 //Distance from div left to the window right
                    const rightRenderSpace = windowWidth - (bounds?.right || 0) //Distance from div right to the window right +
                    this.left = rightRenderSpace < (this.suggestionsRef.current?.offsetWidth || 0) && rightRenderSpace < leftRenderSpaceLeft

                    //Check if calendar is out of the screen and fix the position
                    if (bounds && calendarWidth + bounds.right > windowWidth) {
                        correctedX = ref && bounds.right - calendarWidth - 10
                    }
                }
                this.position.x = correctedX ? correctedX : ref?.getBoundingClientRect().left || 0
                this.position.y = ref?.getBoundingClientRect().top || 0
                if (this.top) {
                    this.position.y -= 16
                } else {
                    this.position.y += 40
                }
                if (!this.left) {
                    this.position.x -= 10
                }
            }
        } else {
            document.removeEventListener('mousedown', this.handleClick, false)
            document.removeEventListener('wheel', this.handleWheel)
            document.body.removeChild(this.portalElement as Node)
            this.portalElement = undefined
        }
    }

    getPositionStyle = (): string => {
        return [this.top ? style.top : style.bottom, this.left ? style.left : style.right].join(' ')
    }

    getIsOpenStyle = (): string => {
        const { opened } = this.props
        return opened ? style.open : ''
    }

    render(): React.ReactNode {
        return null
    }

    getComponent(): React.ReactElement {
        const { opened, value, onChange, onViewModeChange, input = false, locale, ...props } = this.props
        return (
            <span {...props} className={[style.tooltip, this.getIsOpenStyle()].join(' ')} ref={this.anchorRef}>
                <div
                    ref={this.suggestionsRef}
                    style={{
                        position: 'fixed',
                        top: this.position.y,
                        left: this.position.x,
                    }}
                    className={[style.tooltipWindow, this.getIsOpenStyle(), this.getPositionStyle()].join(' ')}>
                    <div
                        className={style.infoContainer}
                        role={'dialog'}
                        data-test={`calendar-${this.props['data-test']}`}
                        id={`calendar-${this.props['data-test']}`}
                        aria-modal={true}
                        aria-label={'Calendar'}>
                        <CSSTransition in={opened} classNames='PopupCalendarAnimation' timeout={250}>
                            {opened ? (
                                <div style={{ marginTop: '10px' }}>
                                    <DateTime
                                        locale={locale || 'en'}
                                        timeFormat={false}
                                        initialValue={moment(value)}
                                        input={input}
                                        inputProps={{
                                            placeholder: 'MM/DD/YYYY',
                                            style: { width: '250px', textAlign: 'center', marginBottom: '10px' },
                                            id: `input-calendar-${this.props['data-test']}`,
                                            'aria-live': 'polite',
                                        }}
                                        renderDay={(props, currentDate) => {
                                            return (
                                                <td {...props} tabIndex={0}>
                                                    {currentDate.date()}
                                                </td>
                                            )
                                        }}
                                        renderMonth={(props, month) => {
                                            return (
                                                <td {...props} tabIndex={0}>
                                                    {months(month).slice(0, 3)}
                                                </td>
                                            )
                                        }}
                                        renderYear={(props, year) => {
                                            return (
                                                <td {...props} tabIndex={0}>
                                                    {year}
                                                </td>
                                            )
                                        }}
                                        onChange={onChange}
                                        onNavigate={onViewModeChange}
                                        data-test={this.props['data-test']}
                                        closeOnSelect={true}
                                        closeOnClickOutside={true}
                                        open={true}
                                    />
                                    <div>{this.props.children}</div>
                                </div>
                            ) : (
                                ''
                            )}
                        </CSSTransition>
                    </div>
                </div>
            </span>
        )
    }
}

export default PopupCalendar

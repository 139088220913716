import React, { PureComponent } from 'react'
import style from './style.module.scss'
import Link from '../../atoms/Link'
import Icon, { IconTypes } from '../../particles/Icon'
import { GenericAttributes } from '../../types'

type TitleSectionProps = {
    header: string
    footer?: string
    iconLeft?: IconTypes
    iconRight?: IconTypes
    onClick?: (ev: React.MouseEvent<HTMLElement>) => void
    link?: string | React.ReactNode
} & GenericAttributes

export class TitleSection extends PureComponent<TitleSectionProps> {
    static iconTypes = Icon.types

    render(): React.ReactNode {
        const { header, footer, iconLeft, iconRight, onClick = () => undefined, link, ...props } = this.props
        return (
            <div {...props} className={[style.titleSection].join(' ')} data-test={this.props['data-test']}>
                <h2 className={style.header} data-test={`${this.props['data-test']}.header`}>
                    {header}
                </h2>
                <div className={style.icon}>
                    {iconLeft ? <Icon size={30} type={iconLeft} /> : null}
                    {iconRight ? <Icon size={30} type={iconRight} /> : null}
                </div>
                <div className={style.separator} />
                <div className={style.footer} data-test={`${this.props['data-test']}.footer`}>
                    {footer}
                </div>
                <div className={style.link}>
                    {link ? (
                        <Link data-test={`${this.props['data-test']}.link`} onClick={onClick}>
                            {link}
                        </Link>
                    ) : undefined}
                </div>
            </div>
        )
    }
}

export default TitleSection

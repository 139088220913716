const DEFAULT_STATE = {
    workflow: undefined,
    error: undefined,
    loading: false,
}
import { ACTION_EXECUTE_WORKFLOW, ACTION_FETCH_WORKFLOW } from './actions'

export default (state = DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case `ACTION_LOGOFF`:
            return DEFAULT_STATE
        case `${ACTION_FETCH_WORKFLOW}_PENDING`:
            return { ...state, error: undefined, workflow: null, loading: true }
        case `${ACTION_FETCH_WORKFLOW}_FULFILLED`:
            return { ...state, workflow: payload.data.attributes, loading: false }
        case `${ACTION_FETCH_WORKFLOW}_REJECTED`:
            return { ...state, workflow: null, error: payload.errors?._error || 'Unable to load case', loading: false }
        case `${ACTION_EXECUTE_WORKFLOW}_PENDING`:
            return { ...state }
        case `${ACTION_EXECUTE_WORKFLOW}_FULFILLED`:
            return { ...state, workflow: payload.data.attributes }
        case `${ACTION_EXECUTE_WORKFLOW}_REJECTED`:
            return { ...state }
        default:
            return state
    }
}

import { ExecutorRequest, ExecutorResponseList } from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Estate-executors
 */
export class EstateExecutorsApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * Delete an executor
     * ${estateId}/executors/${executorId}
     **/

    async deleteExecutor(estateId: string, executorId: string): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.delete<void>(`/estates/${estateId}/executors/${executorId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Update an executor
     * ${estateId}/executors/${executorId}
     **/

    async updateExecutor(estateId: string, executorId: string, body: ExecutorRequest): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.patch<void, ExecutorRequest>(`/estates/${estateId}/executors/${executorId}`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * List the estate executors
     * ${estateId}/executors
     **/

    async listEstateExecutors(estateId: string): Promise<ExecutorResponseList> {
        try {
            const res: AxiosResponse<ExecutorResponseList> = await this.get<ExecutorResponseList>(`/estates/${estateId}/executors`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Create an executor to the case
     * ${estateId}/executors
     **/

    async createExecutor(estateId: string, body: ExecutorRequest): Promise<ExecutorResponseList> {
        try {
            const res: AxiosResponse<ExecutorResponseList> = await this.post<ExecutorResponseList, ExecutorRequest>(`/estates/${estateId}/executors`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

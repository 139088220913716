import axios from 'axios'
import { SubmissionError } from 'redux-form'
import DeepMergeObjects from './deepmergeObjects'
import restAPI from './restAPI'

axios['Accept'] = 'application/vnd.api+json'
axios.defaults.headers.common['Accept'] = 'application/vnd.api+json'
axios.defaults.headers['Content-Type'] = 'application/vnd.api+json'

const config = {
    lang: navigator.language || navigator.userLanguage || 'en',
    baseUrl: __CONFIG__.API,
    configureHeaders(headers) {
        return {
            ...headers,
            'Accept-Language': config.lang,
        }
    },
    afterResolve(response) {
        const { body } = response
        return Promise.resolve(body)
    },
    afterReject(response) {
        const { status, body } = response
        if (status === 401 && !document.location.hash.startsWith('#/login')) {
            if (network.store && network.store.dispatch) {
                return network.store.dispatch({
                    type: 'AUTHENTICATION_ERROR',
                    payload: network.getError(body),
                })
            }
        } else {
            if (status === 402) {
                document.location = '/#dashboard'
            }
            if (network.store && network.store.dispatch) {
                network.store.dispatch({
                    type: 'NETWORK_ERROR',
                    payload: network.getError(body),
                })
            }
            const ret = { status: status || 500, error: network.getError(body) }
            if (body && body.errors) ret.errors = body.errors
            if (ret.errors) {
                // Validation error
                ret.form = {}
                let haveForm = false
                ret.errors = ret.errors.map((error) => {
                    haveForm = true
                    if (error.source && error.source.pointer && error.source.pointer.startsWith('/data/attributes/')) {
                        const atts = error.source.pointer.substr(17).split('/')
                        const obj = {}
                        let curr = obj
                        for (let i = 0; i < atts.length; i++) {
                            curr[atts[i]] = i == atts.length - 1 ? error.title || error.detail : {}
                            curr = curr[atts[i]]
                        }
                        ret.form = DeepMergeObjects.mergeAll([ret.form, obj])
                    } else {
                        const obj = {
                            _error: error.title || error.detail,
                        }
                        ret.form = DeepMergeObjects.mergeAll([ret.form, obj])
                    }
                })
                if (haveForm) throw new SubmissionError(ret.form)
            }
            throw new SubmissionError({ _error: ret.error })
        }
    },
}

const network = {
    setLang: (newlang) => {
        axios.defaults.headers.common['Accept-Language'] = newlang
        config.lang = newlang
    },
    getError: (body) => {
        if (!body) return 'Connection Error'
        if (body.errors) return body.errors[0].title
        return 'Connection Error'
    },
    token: '',
    store: {},
    api: {},
    config,
    handleError: (error) => {
        if (!error.response) {
            error.response = { data: 'Connection Error', status: 500 }
        }
        if (error.response && error.response.status >= 500) {
            if (error.response.data.err === 'TokenExpiredError') {
                throw error.response.data.err
            }
            if (error.response && error.response.data && error.response.data.err === 'TokenExpiredError') {
                throw error.response.data.err
            }
            if (network.store && network.store.dispatch) {
                network.store.dispatch({
                    type: 'NETWORK_ERROR',
                    payload: error.response.data.message || error.response.data || `Error on access ${error.response.request.responseURL} (${error.response.request.status})`,
                })
            }
        } else {
            if (error.response.status == 413) {
                throw {
                    errors: [{ title: 'Request too large' }],
                }
            }
        }
        throw error.response.data && error.response.data.err ? error.response.data.err : error.response.data
    },
    get: (url, query) =>
        axios
            .get(url, query)
            .then((res) => res.data)
            .catch(network.handleError),
    options: (url, query) =>
        axios
            .options(url, query)
            .then((res) => res.data)
            .catch(network.handleError),
    getFile: (url, query) => axios.get(url, query).catch(network.handleError),
    post: (url, body) =>
        axios
            .post(url, body)
            .then((res) => res.data)
            .catch(network.handleError),
    delete: (url, body) =>
        axios
            .delete(url, body)
            .then((res) => res.data)
            .catch(network.handleError),
    put: (url, body, config) =>
        axios
            .put(url, body, config)
            .then((res) => res.data)
            .catch(network.handleError),
    patch: (url, body) =>
        axios
            .patch(url, body)
            .then((res) => res.data)
            .catch(network.handleError),
}

network.api = restAPI.build(config)

network.api.estates.executeWorkflow = ({ id }, data) => network.patch(`${__CONFIG__.API}/estates/${id}/workflow`, data) // UPLOAD not use jsonapi
network.api.estates.addComment = ({ id }, data) => network.post(`${__CONFIG__.API}/estates/${id}/comments`, data) // UPLOAD not use jsonapi
network.api.documents.parseDeathCertificate = (data) => network.post(`${__CONFIG__.API}/parsers/deathCertificate`, data) // UPLOAD not use jsonapi
network.api.documents.updateFile = ({ id }, data, config) => network.put(`${__CONFIG__.API}/documents/${id}/file`, data, config) // UPLOAD not use jsonapi
network.api.documents.downloadDocumentContent = ({ id }) =>
    network.getFile(`${__CONFIG__.API}/documents/${id}/file`, {
        responseType: 'arraybuffer',
    }) // DOWNLOAD not use jsonapi
network.api.documents.downloadDocumentContentBulk = (list) => network.getFile(`${__CONFIG__.API}/documents/download?list=${list}`, { responseType: 'arraybuffer' })

network.api.estates.getCommentFile = ({ estateId, id }) => network.getFile(`${__CONFIG__.API}/estates/${estateId}/comments/${id}/file`, { responseType: 'arraybuffer' }) // DOWNLOAD not use jsonapi
network.api.estates.getWorkflowFile = ({ estateId, taskId }) => network.getFile(`${__CONFIG__.API}/estates/${estateId}/workflow/${taskId}/file`, { responseType: 'arraybuffer' }) // DOWNLOAD not use jsonapi

network.api.orgs.getMemberPic = ({ orgId, userId }) => network.getFile(`${__CONFIG__.API}/orgs/${orgId}/members/${userId}/picture`, { responseType: 'arraybuffer' }) // DOWNLOAD not use jsonapi

network.api.reports.printHoldingsInventory = (estateId, inventoryId, current, lang) =>
    network.getFile(`${__CONFIG__.API}/reports/pdfHoldingsInventory?estateId=${estateId}&inventoryId=${inventoryId}&current=${current}&lang=${lang}`, { responseType: 'arraybuffer' }) // DOWNLOAD not use jsonapi
network.api.reports.printInventory = (estateId, lang) => network.getFile(`${__CONFIG__.API}/reports/pdfInventory?estateId=${estateId}&lang=${lang}`, { responseType: 'arraybuffer' }) // DOWNLOAD not use jsonapi
network.api.reports.printEstateActivityLog = (estateId, lang, filterQueryString) =>
    network.getFile(`${__CONFIG__.API}/reports/pdfEstateActivityLog?estateId=${estateId}&lang=${lang}&${filterQueryString}`, { responseType: 'arraybuffer' }) // DOWNLOAD not use jsonapi
network.api.reports.printEstateActivityLogV2 = (estateId, lang, filterQueryString) =>
    network.getFile(`${__CONFIG__.API}/reports/pdfEstateActivityLogV2?estateId=${estateId}&lang=${lang}&${filterQueryString}`, { responseType: 'arraybuffer' }) // DOWNLOAD not use jsonapi
network.api.reports.pdfTrustActivityLog = (estateId, lang, filterQueryString) =>
    network.getFile(`${__CONFIG__.API}/reports/pdfTrustActivityLog?estateId=${estateId}&lang=${lang}&${filterQueryString}`, { responseType: 'arraybuffer' }) // DOWNLOAD not use jsonapi
network.api.reports.pdfPOAActivityLog = (estateId, lang, filterQueryString) =>
    network.getFile(`${__CONFIG__.API}/reports/pdfPOAActivityLog?estateId=${estateId}&lang=${lang}&${filterQueryString}`, { responseType: 'arraybuffer' }) // DOWNLOAD not use jsonapi
network.api.reports.printCurrentInventory = (estateId, lang) => network.getFile(`${__CONFIG__.API}/reports/pdfCurrentInventory?estateId=${estateId}&lang=${lang}`, { responseType: 'arraybuffer' }) // DOWNLOAD not use jsonapi
network.api.reports.printProposedDistribution = (estateId, lang) =>
    network.getFile(`${__CONFIG__.API}/reports/pdfProposedDistribution?estateId=${estateId}&lang=${lang}`, { responseType: 'arraybuffer' }) // DOWNLOAD not use jsonapi
network.api.reports.printReport = (report, estateId, lang) => network.getFile(`${__CONFIG__.API}/reports/${report}?estateId=${estateId}&lang=${lang}`, { responseType: 'arraybuffer' }) // DOWNLOAD not use jsonapi

network.api.reports.printPassingOfAccounts = (
    estateId,
    lang,
    accountingStartDate,
    accountingEndDate,
    showListOfOriginalAssets,
    showReconciliation,
    showCapitalReceipts,
    showCapitalDisbursements,
    showIncomeReceipts,
    showIncomeDisbursements,
    showListOfUnrealizedOriginalAssets,
    showListOfEstateTrusteesInvestmentsOnHand,
    showListOfLiabilities,
    showHoldingAccounts,
    showCompensationFees,
    timeSpentInYears,
    discount,
    discountDescription,
    isTrust,
    reportId = null
) =>
    network.getFile(
        `${__CONFIG__.API}/reports/${
            reportId ? reportId : isTrust ? 'trustPassingOfAccounts' : 'passingOfAccounts'
        }?estateId=${estateId}&lang=${lang}&accountingStartDate=${accountingStartDate}&accountingEndDate=${accountingEndDate}&showListOfOriginalAssets=${
            showListOfOriginalAssets || false
        }&showReconciliation=${showReconciliation || false}&showCapitalReceipts=${showCapitalReceipts || false}&showCapitalDisbursements=${showCapitalDisbursements || false}&showIncomeReceipts=${
            showIncomeReceipts || false
        }&showIncomeDisbursements=${showIncomeDisbursements || false}&showListOfUnrealizedOriginalAssets=${showListOfUnrealizedOriginalAssets || false}&showListOfEstateTrusteesInvestmentsOnHand=${
            showListOfEstateTrusteesInvestmentsOnHand || false
        }&showListOfLiabilities=${showListOfLiabilities || false}&showHoldingAccounts=${showHoldingAccounts || false}&showCompensationFees=${showCompensationFees || false}${
            showCompensationFees ? '&timeSpentInYears=' + timeSpentInYears : ''
        }${showCompensationFees && discount ? '&discount=' + discount : ''}${showCompensationFees && discountDescription ? '&discountDescription=' + discountDescription : ''}`,
        { responseType: 'arraybuffer' }
    ) // DOWNLOAD not use jsonapi
network.api.reports.printEstateAccount = (estateId, lang, filters) =>
    network.getFile(`${__CONFIG__.API}/reports/pdfEstateAccount?estateId=${estateId}&lang=${lang}&filters=${filters}`, { responseType: 'arraybuffer' }) // DOWNLOAD not use jsonapi
network.api.reports.printTrustAccount = (estateId, lang, filters) =>
    network.getFile(`${__CONFIG__.API}/reports/pdfTrustAccount?estateId=${estateId}&lang=${lang}&filters=${filters}`, { responseType: 'arraybuffer' }) // DOWNLOAD not use jsonapi
network.api.reports.printPOAAccount = (estateId, lang, filters) =>
    network.getFile(`${__CONFIG__.API}/reports/pdfPOAAccount?estateId=${estateId}&lang=${lang}&filters=${filters}`, { responseType: 'arraybuffer' }) // DOWNLOAD not use jsonapi
network.api.reports.printHoldingTransactions = (estateId, lang, filters, format) =>
    network.getFile(`${__CONFIG__.API}/reports/pdfHoldingsTransactions?estateId=${estateId}&lang=${lang}&filters=${filters}`, { responseType: 'arraybuffer', headers: { Accept: format } }) // DOWNLOAD not use jsonapi
network.api.reports.pdfTrustHoldingsTransactions = (estateId, lang, filters, format) =>
    network.getFile(`${__CONFIG__.API}/reports/pdfTrustHoldingsTransactions?estateId=${estateId}&lang=${lang}&filters=${filters}`, { responseType: 'arraybuffer', headers: { Accept: format } }) // DOWNLOAD not use jsonapi
network.api.reports.pdfPOAHoldingsTransactions = (estateId, lang, filters, format) =>
    network.getFile(`${__CONFIG__.API}/reports/pdfPOAHoldingsTransactions?estateId=${estateId}&lang=${lang}&filters=${filters}`, { responseType: 'arraybuffer', headers: { Accept: format } }) // DOWNLOAD not use jsonapi
network.api.reports.printCompensationFees = (estateId, startDate, endDate, FIValue, currentInvValue, timeSpentInYears, lang) =>
    network.getFile(
        `${__CONFIG__.API}/reports/pdfStatementCompensationFees?estateId=${estateId}&startDate=${startDate}&endDate=${endDate}&FIValue=${FIValue}&currentInvValue=${currentInvValue}&timeSpentInYears=${timeSpentInYears}&lang=${lang}`,
        { responseType: 'arraybuffer' }
    ) // DOWNLOAD not use jsonapi
network.api.reports.printPOACompensationFees = (estateId, startDate, endDate, FIValue, currentInvValue, timeSpentInYears, lang) =>
    network.getFile(
        `${__CONFIG__.API}/reports/pdfPOAStatementCompensationFees?estateId=${estateId}&startDate=${startDate}&endDate=${endDate}&FIValue=${FIValue}&currentInvValue=${currentInvValue}&timeSpentInYears=${timeSpentInYears}&lang=${lang}`,
        { responseType: 'arraybuffer' }
    ) // DOWNLOAD not use jsonapi

network.api.reports.exportEstateAccount = (estateId, accountId, lang, format) =>
    network.getFile(`${__CONFIG__.API}/estates/${estateId}/accounts/${accountId}/export?lang=${lang}`, { responseType: 'arraybuffer', headers: { Accept: format } }) // DOWNLOAD not use jsonapi
network.api.reports.importEstateAccount = (estateId, accountId, lang, data) => network.patch(`${__CONFIG__.API}/estates/${estateId}/accounts/${accountId}/import?lang=${lang}`, data) // UPLOAD not use jsonapi

network.api.reports.importInventory = (estateId, lang, data) => network.put(`${__CONFIG__.API}/estates/${estateId}/inventory/import?lang=${lang}`, data) // UPLOAD not use jsonapi

network.api.reports.importHoldings = (estateId, lang, data) => network.patch(`${__CONFIG__.API}/estates/${estateId}/inventory/import?lang=${lang}`, data) // UPLOAD not use jsonapi

network.api.reports.exportInventory = (estateId, lang, format) =>
    network.getFile(`${__CONFIG__.API}/estates/${estateId}/inventory/export?lang=${lang}`, { responseType: 'arraybuffer', headers: { Accept: format } }) // DOWNLOAD not use jsonapi
network.api.reports.printBeneficiaries = (estateId, lang) => network.getFile(`${__CONFIG__.API}/reports/pdfBeneficiaries?estateId=${estateId}&lang=${lang}`, { responseType: 'arraybuffer' }) // DOWNLOAD not use jsonapi

network.api.reports.printDistributions = (estateId, lang) => network.getFile(`${__CONFIG__.API}/reports/pdfdistributions?estateId=${estateId}&lang=${lang}`, { responseType: 'arraybuffer' }) // DOWNLOAD not use jsonapi
network.api.reports.printTrustDistributions = (estateId, lang) => network.getFile(`${__CONFIG__.API}/reports/pdfTrustDistributions?estateId=${estateId}&lang=${lang}`, { responseType: 'arraybuffer' }) // DOWNLOAD not use jsonapi
network.api.reports.downloadAll = (estateId, lang, taskId) => network.getFile(`${__CONFIG__.API}/reports/all?estateId=${estateId}&lang=${lang}&taskId=${taskId}`, { responseType: 'arraybuffer' }) // DOWNLOAD not use jsonapi
network.api.orgs.export = (orgId) => network.getFile(`${__CONFIG__.API}/orgs/${orgId}/download`, { responseType: 'arraybuffer' }) // DOWNLOAD not use jsonapi
network.api.documents.downloadList = (estateId, ids) => network.getFile(`${__CONFIG__.API}/documents/files?ids=${ids}&estateId=${estateId}`, { responseType: 'arraybuffer' }) // DOWNLOAD not use jsonapi

export default network

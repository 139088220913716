import React, { PureComponent } from 'react'
import style from './style.module.scss'
import Checkbox, { CheckboxValues } from '../../atoms/Checkbox'
import { GenericAttributes } from '../../types'

type LineCheckboxProps = {
    onSelect?: (ev: React.MouseEvent<HTMLElement>) => void
    onClick?: (ev: React.MouseEvent<HTMLElement>) => void
    selected?: CheckboxValues
    active?: boolean
    detailPanelChildren?: React.ReactNode
} & GenericAttributes

export class LineCheckbox extends PureComponent<LineCheckboxProps> {
    static selectionValues = Checkbox.selectionValues
    static defaultProps = {}

    render(): React.ReactNode {
        const { children, onSelect, onClick, selected, active, detailPanelChildren, ...props } = this.props
        return (
            <>
                <div {...props} data-test={`${this.props['data-test']}`} onClick={onClick} className={[style.lineCheckbox, onClick ? style.clickArea : '', active ? style.active : ''].join(' ')}>
                    {onSelect ? (
                        <div className={style.checkbox}>
                            <Checkbox data-test={`${this.props['data-test']}.checkbox`} value={selected} onClick={onSelect} />
                        </div>
                    ) : null}
                    {children}
                </div>
                {active && detailPanelChildren ? <div className={style.detailPanelChildren}>{detailPanelChildren}</div> : null}
            </>
        )
    }
}

export default LineCheckbox

import React, { PureComponent } from 'react'
import style from './style.module.scss'
import CheckboxLabeledItem from '../../molecules/CheckboxLabeledItem'
import { GenericAttributes } from '../../types'

interface LinewGridCheckboxItems {
    selected: boolean
    label: string
    onClick: (ev: React.MouseEvent<HTMLElement>) => void
}
type LineGridCheckboxLabelProps = {
    readonly?: boolean
    values: LinewGridCheckboxItems[]
} & GenericAttributes
export class LineGridCheckboxLabel extends PureComponent<LineGridCheckboxLabelProps> {
    static SELECTED = CheckboxLabeledItem.selectionValues.SELECTED
    static NOT_SELECTED = CheckboxLabeledItem.selectionValues.NOT_SELECTED
    static defaultProps = {
        readonly: false,
    }
    render(): React.ReactNode {
        const { readonly, values, ...props } = this.props
        return (
            <div {...props} data-test={this.props['data-test']} className={style.lineGridCheckboxLabel}>
                {values.map((value, index) => {
                    return (
                        <div key={index} className={style.checkboxItem}>
                            <CheckboxLabeledItem
                                background={index % 2 ? true : false}
                                readonly={readonly}
                                onClick={value.onClick}
                                label={value.label}
                                value={value.selected ? LineGridCheckboxLabel.SELECTED : LineGridCheckboxLabel.NOT_SELECTED}
                                data-test={`${this.props['data-test']}.checkboxes.${index}`}
                            />
                        </div>
                    )
                })}
            </div>
        )
    }
}
export default LineGridCheckboxLabel

import { ACTION_SAVE_PROFILE } from '../Profile/actions'
import { ACTION_ACCEPT_INVITE, ACTION_GET_AUTH_CONFIG, ACTION_LOGIN, ACTION_LOGOFF, ACTION_REGISTER, ACTION_VALIDATE_EMAIL } from './actions'

const DEFAULT_STATE = {
    logged: false,
    errorMsg: '',
    registerError: '',
    registered: false,
    userInfo: undefined,
    registerMsg: '',
    validatedEmail: false,
    theme: {},
    features: {},
    prices: [],
    sso: { enabled: false },
    orgLocation: 'CA',
    address: {},
    logout: false,
}

export default (state = DEFAULT_STATE, { type, payload }) => {
    switch (type) {
        case 'AUTHENTICATION_ERROR':
            return {
                ...state,
                logout: true,
            }
        case `${ACTION_LOGOFF}`:
            return DEFAULT_STATE
        case `${ACTION_VALIDATE_EMAIL}_PENDING`:
            return {
                ...state,
                errorMsg: '',
                validatedEmail: true,
                registerMsg: '',
            }
        case `${ACTION_VALIDATE_EMAIL}_FULFILLED`:
            return {
                ...state,
                logged: false,
                errorMsg: '',
                validatedEmail: true,
                registerMsg: 'emailValidated',
            }
        case `${ACTION_VALIDATE_EMAIL}_REJECTED`:
            return {
                ...state,
                logged: false,
                errorMsg: payload.error || (payload.errors && payload.errors._error),
                validatedEmail: true,
                registerMsg: '',
            }
        case `${ACTION_LOGIN}_PENDING`:
            return { ...state, errorMsg: '' }
        case `${ACTION_LOGIN}_FULFILLED`:
            return {
                ...state,
                logged: true,
                errorMsg: '',
                userInfo: payload.userInfo,
                lastLogin: payload.lastLogin,
                theme: payload.theme || {},
                features: payload.features || {},
                prices: payload.prices || [],
                config: payload.config,
            }
        case 'ACTION_SAVE_PREFERENCE_FULFILLED':
        case `${ACTION_SAVE_PROFILE}_FULFILLED`:
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    profilePic: payload.data.attributes.profilePic && payload.data.attributes.profilePic.image,
                    phoneNumber: payload.data.attributes.phoneNumber,
                    name: payload.data.attributes.name,
                },
                lastLogin: new Date(),
            }

        case `${ACTION_ACCEPT_INVITE}`:
            return { ...state, invitehash: payload }
        case `${ACTION_ACCEPT_INVITE}_FULFILLED`:
            return { ...state }
        case `${ACTION_ACCEPT_INVITE}_REJECTED`:
            return { ...state }
        case `${ACTION_LOGIN}_REJECTED`:
            return {
                ...state,
                logged: false,
                errorMsg: payload.error || (payload.errors && payload.errors._error),
                userInfo: undefined,
                features: {},
                prices: [],
            }
        case `${ACTION_REGISTER}_PENDING`:
            return { ...state, errorMsg: '' }
        case 'ERROR_CLICK':
            return { ...state }
        case `${ACTION_GET_AUTH_CONFIG}_FULFILLED`:
            return { ...state, sso: payload.data.attributes.sso, orgLocation: payload.data.attributes.location, address: payload.data.attributes.address }
        case `${ACTION_GET_AUTH_CONFIG}_REJECTED`:
            return { ...state }
        default:
            return state
    }
}

import React, { PureComponent } from 'react'
// eslint-disable-next-line css-modules/no-unused-class
import style from './style.module.scss'
import Icon, { IconTypes } from '../../particles/Icon'
import { GenericAttributes } from '../../types'

type ToolBarItemProps = {
    //@deprecate - icon property is now deprecated, only used for old implementations
    icon?: IconTypes
    leftIcon?: IconTypes
    rightIcon?: IconTypes
    onLeftIconClick?: (event: React.MouseEvent<HTMLDivElement>) => void
    onRightIconClick?: (event: React.MouseEvent<HTMLDivElement>) => void
    title: string
    message?: string
    messageStyle?: ToolBarItemStyles
    onClick(event: React.MouseEvent<HTMLButtonElement>): void
} & GenericAttributes

enum ToolBarItemStyles {
    none = 'none',
    primary = 'primary',
    warning = 'warning',
}

export class ToolBarItem extends PureComponent<ToolBarItemProps> {
    static iconTypes = IconTypes
    static styleTypes = ToolBarItemStyles

    constructor(props: ToolBarItemProps) {
        if (props.icon) {
            // console.warn('icon property is deprecated. Please use leftIcon or rightIcon instead')
        }

        super(props)
    }

    render(): React.ReactNode {
        const { message, onClick, icon, leftIcon, onLeftIconClick, rightIcon, onRightIconClick, title, messageStyle = ToolBarItemStyles.none, ...props } = this.props

        return (
            <div {...props} data-test={this.props['data-test']} className={[style.toolBarItem].join(' ')}>
                <button type={'button'} className={style.content} onClick={onClick} data-test={`${this.props['data-test']}.content`} aria-haspopup='dialog' aria-label={`${title} ${message}`}>
                    {leftIcon ? (
                        <div onClick={onLeftIconClick}>
                            <Icon type={leftIcon} size={28} />
                        </div>
                    ) : null}
                    {title} <span className={style[messageStyle]}>{message}</span>
                    {!rightIcon && !leftIcon && icon ? <Icon type={icon} size={28} /> : null}
                    {rightIcon ? (
                        <div onClick={onRightIconClick}>
                            <Icon type={rightIcon} size={28} />
                        </div>
                    ) : null}
                </button>
            </div>
        )
    }
}

export default ToolBarItem

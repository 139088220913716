import { InventoryItemRequest, InventoryItemResponse, InventoryItemResponseList, Page, InventoryItemEditOpeningBalanceRequest } from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Inventory
 */
export class InventoryApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }

    //Methods

    /**
     * Get a Holding Lots
     * ${estateId}/inventory/${inventoryId}/holdingLots
     **/

    async getHoldingLots(estateId: string, inventoryId: string): Promise<InventoryItemResponse> {
        try {
            const res: AxiosResponse<InventoryItemResponse> = await this.get<InventoryItemResponse>(`/estates/${estateId}/inventory/${inventoryId}/holdingLots`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Edit inventory account opening balance and current value
     * ${estateId}/inventory/${inventoryId}/editOriginalValue
     **/

    async editOriginalValue(estateId: string, inventoryId: string, body: InventoryItemEditOpeningBalanceRequest): Promise<InventoryItemResponse> {
        try {
            const res: AxiosResponse<InventoryItemResponse> = await this.patch<InventoryItemResponse, InventoryItemEditOpeningBalanceRequest>(
                `/estates/${estateId}/inventory/${inventoryId}/editOriginalValue`,
                body
            )
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Get an inventory item (asset or liability)
     * ${estateId}/inventory/${inventoryId}
     **/

    async getInventoryItem(estateId: string, inventoryId: string): Promise<InventoryItemResponse> {
        try {
            const res: AxiosResponse<InventoryItemResponse> = await this.get<InventoryItemResponse>(`/estates/${estateId}/inventory/${inventoryId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Delete an inventory item
     * ${estateId}/inventory/${inventoryId}
     **/

    async deleteInventoryItem(estateId: string, inventoryId: string): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.delete<void>(`/estates/${estateId}/inventory/${inventoryId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Update an inventory item (asset or liability)
     * ${estateId}/inventory/${inventoryId}
     **/

    async updateInventoryItem(estateId: string, inventoryId: string, body: InventoryItemRequest): Promise<InventoryItemResponse> {
        try {
            const res: AxiosResponse<InventoryItemResponse> = await this.patch<InventoryItemResponse, InventoryItemRequest>(`/estates/${estateId}/inventory/${inventoryId}`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * List inventory items (assets and liability)
     * ${estateId}/inventory
     **/

    async listInventoryItems(estateId: string, page?: Page, filter?: object, sort?: string): Promise<InventoryItemResponseList> {
        try {
            const res: AxiosResponse<InventoryItemResponseList> = await this.get<InventoryItemResponseList>(`/estates/${estateId}/inventory`, {
                params: {
                    page,
                    filter,
                    sort,
                },
            })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Create an inventory item (asset or liability)
     * ${estateId}/inventory
     **/

    async createInventoryItem(estateId: string, body: InventoryItemRequest): Promise<InventoryItemResponse> {
        try {
            const res: AxiosResponse<InventoryItemResponse> = await this.post<InventoryItemResponse, InventoryItemRequest>(`/estates/${estateId}/inventory`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

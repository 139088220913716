import React, { PureComponent } from 'react'
import style from './style.module.scss'
import Checkbox, { CheckboxValues } from '../../atoms/Checkbox'
import Icon from '../../particles/Icon'
import { GenericAttributes } from '../../types'

type CheckboxItemProps = {
    value: CheckboxValues | undefined
    readonly?: boolean
    background?: boolean
    onClick?: (ev: React.MouseEvent<HTMLElement>) => void
    alt?: string
} & GenericAttributes

export class CheckboxItem extends PureComponent<CheckboxItemProps> {
    static selectionValues = CheckboxValues
    static defaultProps = {
        value: Checkbox.selectionValues.NOT_SELECTED,
        readonly: false,
        background: true,
    }
    render(): React.ReactNode {
        const { value, readonly, background, alt, onClick, 'aria-labelledby': ariaLabelledBy, ...props } = this.props
        const icon = value === CheckboxValues.SELECTED
        return (
            <div {...props} data-test={`${this.props['data-test']}`} className={[style.checkboxItem, background ? style.background : null].join(' ')}>
                {readonly ? (
                    icon ? (
                        <>
                            <span className={'textVisuallyHidden'}>{alt || 'Check'}</span>
                            <Icon type={Icon.types.Check} size={30} />
                        </>
                    ) : null
                ) : (
                    <Checkbox aria-labelledby={ariaLabelledBy} data-test={`${this.props['data-test']}.checkbox`} onClick={onClick} value={value} disabled={false} size={Checkbox.sizes.small} />
                )}
            </div>
        )
    }
}
export default CheckboxItem

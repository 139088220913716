import {
    SubstituteDecisionMakerResponseList, // Type import SubstituteDecisionMakerResponseList
    SubstituteDecisionMakerRequest, // Type import SubstituteDecisionMakerRequest
    SubstituteDecisionMakerResponse, // Type import SubstituteDecisionMakerResponse
    Page, // Type Pagination of list
} from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api, ResponseWithHeader } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/PoAs-substituteDecisionMakers
 */
export class PoAsSubstituteDecisionMakersApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * Delete a substitute decision maker
     * ${caseId}/contacts/substituteDecisionMakers/${substituteDecisionMakerId}
     **/

    async deleteSubstituteDecisionMaker(caseId: string, substituteDecisionMakerId: string): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.delete<void>(`/poas/${caseId}/contacts/substituteDecisionMakers/${substituteDecisionMakerId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Update a substitute decision maker
     * ${caseId}/contacts/substituteDecisionMakers/${substituteDecisionMakerId}
     **/

    async updateSubstituteDecisionMaker(caseId: string, substituteDecisionMakerId: string, body: SubstituteDecisionMakerRequest): Promise<SubstituteDecisionMakerResponse> {
        try {
            const res: AxiosResponse<SubstituteDecisionMakerResponse> = await this.patch<SubstituteDecisionMakerResponse, SubstituteDecisionMakerRequest>(
                `/poas/${caseId}/contacts/substituteDecisionMakers/${substituteDecisionMakerId}`,
                body
            )
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Get substitute decision maker
     * ${caseId}/contacts/substituteDecisionMakers/{substituteDecisionMakerId}
     **/

    async getSubstituteDecisionMaker(caseId: string): Promise<SubstituteDecisionMakerResponse> {
        try {
            const res: AxiosResponse<SubstituteDecisionMakerResponse> = await this.get<SubstituteDecisionMakerResponse>(`/poas/${caseId}/contacts/substituteDecisionMakers/{substituteDecisionMakerId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * List substitute decision makers
     * ${caseId}/contacts/substituteDecisionMakers
     **/

    async listSubstituteDecisionMakers(caseId: string, page?: Page, filter?: object, sort?: string, search?: string): Promise<SubstituteDecisionMakerResponseList> {
        try {
            const res: AxiosResponse<SubstituteDecisionMakerResponseList> = await this.get<SubstituteDecisionMakerResponseList>(`/poas/${caseId}/contacts/substituteDecisionMakers`, {
                params: { page, filter, sort, search },
            })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Add a substitute decision makers to the case
     * ${caseId}/contacts/substituteDecisionMakers
     **/

    async addSubstituteDecisionMaker(caseId: string, body: SubstituteDecisionMakerRequest): Promise<SubstituteDecisionMakerResponse> {
        try {
            const res: AxiosResponse<SubstituteDecisionMakerResponse> = await this.post<SubstituteDecisionMakerResponse, SubstituteDecisionMakerRequest>(
                `/poas/${caseId}/contacts/substituteDecisionMakers`,
                body
            )
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

import React, { PureComponent } from 'react'
import style from './style.module.scss'
import Icon, { IconTypes } from '../../particles/Icon'
import { GenericAttributes } from '../../types'

type AvatarCircleProps = {
    size?: AvatarCircleSizes
    src?: string
    icon?: IconTypes
} & GenericAttributes
type AvatarCircleState = {
    loaded: boolean
    iconReferenceSize: number
}
export enum AvatarCircleSizes {
    small = 'small',
    medium = 'medium',
    large = 'large',
}

/**
 * AvatarCircle is used to represent a circle icon of on user image
 */
export class AvatarCircle extends PureComponent<AvatarCircleProps, AvatarCircleState> {
    constructor(props: AvatarCircleProps) {
        super(props)
        this.state = {
            loaded: false,
            iconReferenceSize: 50,
        }
    }
    static iconTypes = IconTypes
    static sizeTypes = AvatarCircleSizes
    static defaultProps = {
        size: AvatarCircle.sizeTypes.small,
        icon: AvatarCircle.iconTypes.UserDefault,
    }
    renderSize = (): string => {
        switch (this.props.size) {
            case AvatarCircle.sizeTypes.small:
                this.setState({ iconReferenceSize: 30 })
                return style.small
            case AvatarCircle.sizeTypes.medium:
                this.setState({ iconReferenceSize: 50 })
                return style.medium
            case AvatarCircle.sizeTypes.large:
                this.setState({ iconReferenceSize: 90 })
                return style.large
            default:
                this.setState({ iconReferenceSize: 50 })
                return style.medium
        }
    }
    render(): React.ReactNode {
        const { src, icon } = this.props
        const { loaded, iconReferenceSize, ...props } = this.state
        const iconSize = Math.round(iconReferenceSize * 0.66)
        return (
            <div {...props} data-test={`${this.props['data-test']}`} className={[style.avatarCircle, this.renderSize()].join(' ')}>
                {loaded ? null : <Icon size={iconSize} type={icon} />}
                <img
                    alt={this.props['aria-label']}
                    src={src}
                    style={loaded ? {} : { display: 'none' }}
                    onLoad={() => this.setState({ loaded: true })}
                    onError={() => this.setState({ loaded: false })}
                />
            </div>
        )
    }
}
export default AvatarCircle

/**
 * Estateably
 * @Author :
 * @Date   : 2019-05-08, 4:41:50 p.m.
 */
export const ACTION_FETCH_WORKFLOW = 'ACTION_FETCH_WORKFLOW'
export const ACTION_EXECUTE_WORKFLOW = 'ACTION_EXECUTE_WORKFLOW'
export const ACTION_DOWNLOAD_FILES_CONTENT = 'ACTION_DOWNLOAD_FILES_CONTENT'
export const ACTION_SAVE_PREFERENCE = 'ACTION_SAVE_PREFERENCE'

import network from 'utils/network'
import { Buffer } from 'buffer'

const api = network.api
const { estates, format, orgs } = api
export const fetchEstateWorkflow = (estateId) => {
    return {
        type: ACTION_FETCH_WORKFLOW,
        payload: estates.getWorkflow({ id: estateId }),
    }
}

export const updateUserPreference = (orgId, userId) => {
    return {
        type: ACTION_SAVE_PREFERENCE,
        payload: orgs.updateMember({ id: orgId, userId }),
    }
}

export const executeEstateWorkflow = (estateId, data = {}, files = undefined, taskToExecute) => {
    let body
    if (files && (Array.isArray(files) || files instanceof File)) {
        body = new FormData()
        body.append('files', files)
        const formated = format({ body: data, taskToExecute })
        const json = JSON.stringify(formated.data)
        const blob = new Blob([json], {
            type: 'application/json',
        })
        body.append('data', blob)
    } else {
        body = format({ body: data, taskToExecute }, 'workflows')
    }
    return {
        type: ACTION_EXECUTE_WORKFLOW,
        payload: estates.executeWorkflow({ id: estateId }, body),
    }
}

export const downloadFileContent = (taskId, estateId, save = false) => {
    return {
        type: ACTION_DOWNLOAD_FILES_CONTENT,
        payload: estates.getWorkflowFile({ taskId, estateId }).then((response) => {
            let tmp = new Buffer.from(response.data, 'binary')
            let fileExtension = response.headers['file-extension'] || 'undefined'
            let fileName = response.headers['content-disposition'] && response.headers['content-disposition'].split('attachment; filename="')
            fileName = fileName.length > 1 ? fileName[1] : '"'
            fileName = fileName.split('"')[0]
            if (save) {
                fileSave(tmp, fileName, fileExtension)
                return { save: true, message: 'Download OK' }
            } else {
                let filetype = fileExtension === 'pdf' ? 'application/pdf' : fileExtension === 'jpg' || fileExtension === 'png' ? `image/${fileExtension}` : 'application/octet-stream'
                const blob = new Blob([tmp], { type: filetype })
                let blobURL = window.URL.createObjectURL(blob)
                return {
                    blobURL,
                    fileExtension,
                    fileName: fileName,
                    filetype,
                    save: false,
                }
            }
        }),
    }
}

const fileSave = function (data, filename, mime) {
    const blob = new Blob([data], { type: mime || 'application/octet-stream' })
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
        // IE workaround for "HTML7007: One or more blob URLs were
        // revoked by closing the blob for which they were created.
        // These URLs will no longer resolve as the data backing
        // the URL has been freed."
        window.navigator.msSaveBlob(blob, filename)
    } else {
        const blobURL = window.URL.createObjectURL(blob)
        const tempLink = document.createElement('a')
        tempLink.style.display = 'none'
        tempLink.href = blobURL
        tempLink.setAttribute('download', filename)

        // Safari thinks _blank anchor are pop ups. We only want to set _blank
        // target if the browser does not support the HTML5 download attribute.
        // This allows you to download files in desktop safari if pop up blocking
        // is enabled.
        if (typeof tempLink.download === 'undefined') {
            tempLink.setAttribute('target', '_blank')
        }

        document.body.appendChild(tempLink)
        tempLink.click()
        document.body.removeChild(tempLink)
        window.URL.revokeObjectURL(blobURL)
    }
}

import React, { Component, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { CSSTransition } from 'react-transition-group'
import moment from 'moment'
import style from './style.module.scss'
import { GenericAttributes } from '../../types'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

type DatePickerComponentProps = {
    onChangeDate: (startDate: string, endDate?: string) => void
    locale?: string
    initialStartDate: Date
    initialEndDate?: Date
}
export const DatePickerComponent = ({ onChangeDate, locale, initialStartDate, initialEndDate }: DatePickerComponentProps) => {
    const [startDate, setStartDate] = useState<Date | null>(initialStartDate || new Date())
    const [endDate, setEndDate] = useState<Date | null>(initialEndDate || null)

    const onChange = (dates: [Date | null, Date | null]) => {
        const [start, end] = dates
        setStartDate(start)
        setEndDate(end)
        if (start && end) {
            onChangeDate(moment(start).format('YYYY-MM-DD'), moment(end).format('YYYY-MM-DD'))
        } else if (start) {
            onChangeDate(moment(start).format('YYYY-MM-DD'))
        }
    }

    useEffect(() => {
        setStartDate(initialStartDate || new Date())
    }, [initialStartDate])

    useEffect(() => {
        setEndDate(initialEndDate || null)
    }, [initialEndDate])

    return <DatePicker id={'dataPickerRange'} locale={locale} className={'rangeDatePicker'} selected={startDate} onChange={onChange} startDate={startDate} endDate={endDate} selectsRange inline />
}

/**
 * Generic styled PopupCalendar
 */

type PopupRangeCalendarProps = {
    opened: boolean
    onClose: (target?: Node) => void
    onChangeDate: (startDate: string, endDate?: string) => void
    value: string | Date | moment.Moment
    valueEndDate?: string | Date | moment.Moment
    top?: boolean
    left?: boolean
    linkRef: HTMLElement | null
    locale?: string
} & GenericAttributes

export class PopupRangeCalendar extends Component<PopupRangeCalendarProps> {
    private suggestionsRef: React.RefObject<HTMLDivElement> = React.createRef()
    private anchorRef: React.RefObject<HTMLDivElement> = React.createRef()
    private top?: boolean
    private left?: boolean
    private position = {
        x: 0,
        y: 0,
    }

    static defaultProps = {
        defaultOpened: false,
    }

    constructor(props: PopupRangeCalendarProps) {
        super(props)

        this.state = {
            // selectedDate: new Date(),
            selectedStartDate: null,
            selectedEndDate: null,
        }
    }

    handleClick = (event: MouseEvent): void => {
        //Close when click outside div.
        const { opened, onClose } = this.props
        if (opened && !this.portalElement?.contains(event.target as Node)) {
            onClose(event.target as Node)
        }
    }
    handleWheel = (): void => {
        //Close when click scroll div.
        const { opened, onClose } = this.props
        if (opened) {
            onClose()
        }
    }
    shouldComponentUpdate(nextProps: PopupRangeCalendarProps): boolean {
        if (nextProps.opened != this.props.opened) {
            this.setPopupCalendarWindowPosition(nextProps)
            return true
        }
        if (nextProps.value !== this.props.value) {
            return true
        }
        return false
    }

    private portalElement?: HTMLElement
    private id = 'PopupCalendar'

    componentDidUpdate(): void {
        if (this.portalElement) ReactDOM.render(this.getComponent(), this.portalElement)
    }

    setPopupCalendarWindowPosition = (props: PopupRangeCalendarProps): void => {
        if (props.opened && this.suggestionsRef) {
            let p = this.id && document.getElementById(this.id)
            document.addEventListener('mousedown', this.handleClick, false)
            document.addEventListener('wheel', this.handleWheel)
            p = this.id && document.getElementById(this.id)
            if (!p) {
                p = document.createElement('div')
                p.id = this.id
                document.body.appendChild(p)
            }
            this.portalElement = p
            const ref: Element | null = props.linkRef
            if (ref !== null) {
                this.top = props.top
                const calendarWidth = 265
                if (this.top === undefined) {
                    //Automatically calculate
                    const windowHeight = window.innerHeight
                    const bounds = ref?.getBoundingClientRect()
                    const topRenderSpaceLeft = bounds?.top || 0 //Distance from div top to the window top
                    const bottomRenderSpace = windowHeight - (bounds?.top || 0) //Distance from div top to the window bottom
                    const calendarHeight = 300 //There is a fixed height
                    this.top = bottomRenderSpace < calendarHeight && bottomRenderSpace < topRenderSpaceLeft
                }

                this.left = props.left
                let correctedX
                const windowWidth = window.innerWidth
                if (this.left === undefined) {
                    //Automatically calculate
                    const bounds = ref?.getBoundingClientRect()
                    const leftRenderSpaceLeft = bounds?.left || 0 //Distance from div left to the window right
                    const rightRenderSpace = windowWidth - (bounds?.right || 0) //Distance from div right to the window right +
                    this.left = rightRenderSpace < (this.suggestionsRef.current?.offsetWidth || 0) && rightRenderSpace < leftRenderSpaceLeft

                    //Check if calendar is out of the screen and fix the position
                    if (bounds && calendarWidth + bounds.right > windowWidth) {
                        correctedX = ref && bounds.right - calendarWidth - 10
                    }
                }
                this.position.x = correctedX ? correctedX : ref?.getBoundingClientRect().left || 0
                this.position.y = ref?.getBoundingClientRect().top || 0
                if (this.top) {
                    this.position.y -= 16
                } else {
                    this.position.y += 40
                }
                if (!this.left) {
                    this.position.x -= 10
                }
            }
        } else {
            document.removeEventListener('mousedown', this.handleClick, false)
            document.removeEventListener('wheel', this.handleWheel)
            document.body.removeChild(this.portalElement as Node)
            this.portalElement = undefined
        }
    }

    getPositionStyle = (): string => {
        return [this.top ? style.top : style.bottom, this.left ? style.left : style.right].join(' ')
    }

    getIsOpenStyle = (): string => {
        const { opened } = this.props
        return opened ? style.open : ''
    }

    render(): React.ReactNode {
        return null
    }

    handleOnChange = (values: [Date | null, Date | null]) => {
        const [startDate, endDate] = values
        this.setState({
            selectedStartDate: startDate,
            selectedEndDate: endDate,
        })
    }

    getComponent(): React.ReactElement {
        const { opened, value, valueEndDate, locale, onChangeDate, ...props } = this.props
        return (
            <span {...props} className={[style.tooltip, this.getIsOpenStyle()].join(' ')} ref={this.anchorRef}>
                <div
                    ref={this.suggestionsRef}
                    style={{
                        position: 'fixed',
                        top: this.position.y,
                        left: this.position.x,
                    }}
                    className={[style.tooltipWindow, this.getIsOpenStyle(), this.getPositionStyle()].join(' ')}>
                    <div className={style.infoContainer}>
                        <CSSTransition in={opened} classNames='PopupCalendarAnimation' timeout={250}>
                            {opened ? (
                                <div>
                                    <DatePickerComponent
                                        initialStartDate={moment(value).toDate()}
                                        initialEndDate={valueEndDate ? moment(valueEndDate).toDate() : undefined}
                                        locale={locale}
                                        onChangeDate={onChangeDate}
                                    />
                                    {this.props.children}
                                </div>
                            ) : (
                                ''
                            )}
                        </CSSTransition>
                    </div>
                </div>
            </span>
        )
    }
}

export default PopupRangeCalendar

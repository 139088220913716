import network from 'utils/network'

const { users, format } = network.api
export const ACTION_RESET_PASSWORD = 'ACTION_RESET_PASSWORD'
export const ACTION_RENDER_RESET_PASSWORD_FORM = 'ACTION_RENDER_RESET_PASSWORD_FORM'
export const resetPassword = (values) => {
    return {
        type: ACTION_RESET_PASSWORD,
        payload: users.resetPassword(
            { email: values.email },
            format(
                {
                    token: values.token,
                    email: values.email,
                    password: values.password,
                },
                'users'
            )
        ),
    }
}

import { PasswordResetRequest, RequestToPasswordResetRequest, UserResponse, UserValidationRequest } from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Users
 */
export class UsersApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * Get an individual user
     * ${userId}
     **/

    async getUser(userId: string): Promise<UserResponse> {
        try {
            const res: AxiosResponse<UserResponse> = await this.get<UserResponse>(`/users/${userId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Reset user password
     * ${email}/passwordReset
     **/

    async resetPassword(email: string, body: PasswordResetRequest): Promise<UserResponse> {
        try {
            const res: AxiosResponse<UserResponse> = await this.patch<UserResponse, PasswordResetRequest>(`/users/${email}/passwordReset`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Request to reset password
     * ${email}/passwordReset
     **/

    async requestToResetUserPassword(email: string, body: RequestToPasswordResetRequest): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.post<void, RequestToPasswordResetRequest>(`/users/${email}/passwordReset`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Validate user registration
     * validation
     **/

    async validateUser(body: UserValidationRequest): Promise<UserResponse> {
        try {
            const res: AxiosResponse<UserResponse> = await this.post<UserResponse, UserValidationRequest>(`/users/validation`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Create user invited
     *
     **/

    async createUser(body: unknown): Promise<UserResponse> {
        try {
            const res: AxiosResponse<UserResponse> = await this.post<UserResponse, unknown>(`/users/`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

import network from 'utils/network'
import { createAuthSession } from 'utils/auth'

const { users } = network.api
export const ACTION_REGISTER = 'ACTION_REGISTER'
export const ACTION_RENDER_REGISTER_FORM = 'ACTION_RENDER_REGISTER_FORM'
export const ACTION_LOGIN = 'ACTION_LOGIN'

export const registerNewUser = (values) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_REGISTER,
            payload: users
                .create({
                    data: {
                        type: 'users',
                        attributes: {
                            firstName: values.firstName,
                            lastName: values.lastName,
                            email: values.email,
                            password: values.password,
                            invitationToken: values.invitationToken,
                        },
                    },
                })
                .then(() => {
                    return dispatch({
                        type: ACTION_LOGIN,
                        payload: createAuthSession({
                            data: {
                                type: 'auth',
                                attributes: {
                                    username: values.email,
                                    password: values.password,
                                },
                            },
                        }),
                    })
                }),
        })
    }
}

/**
 * @see https://stackoverflow.com/a/65008608/7724011
 */

import { RefObject, useEffect, useMemo, useState } from 'react'

const useOnScreen = (ref: RefObject<Element>) => {
    const [isIntersecting, setIsIntersecting] = useState(false)

    const observer = useMemo(() => new IntersectionObserver(([entry]) => setIsIntersecting(entry.isIntersecting)), [])

    useEffect(() => {
        observer.observe(ref.current!)
        return () => {
            observer.disconnect()
        }
    }, [])

    return isIntersecting
}

export default useOnScreen

import i18n, { Module } from 'i18next'
import { I18nextProvider, initReactI18next, Translation as _Translation, withTranslation as _withTranslation } from 'react-i18next'
import i18nBackend from './Backend'

import utils from '../../utils/utils'

let initiated = false
const supportedLanguages = ['fr', 'en']
/**
 *
 Translation.i18n.changeLanguage('fr')
 Translation.i18n.reloadResources(['fr', 'en'], 'test1')
 */
export const init = () => {
    i18n.use(i18nBackend as Module)
        .use(initReactI18next)
        .init({
            lng: utils.getCookie('lang') || getBrowserLanguage(),
            fallbackLng: false,
            debug: false,
            saveMissing: true,
            keySeparator: false,
            nsSeparator: '::',
            defaultNS: 'default',
            ns: 'default',
            backend: {
                // custom options
            },
            // other options
        })
    i18n.reloadResources(utils.getCookie('lang') || getBrowserLanguage(), 'default')
    initiated = true
}

export const cloneInstance = async (context: string) => {
    return new Promise((resolve, reject) => {
        const instance = i18n.cloneInstance(
            {
                lng: i18n.language,
                overloadTranslationOptionHandler: (args: string[]) => {
                    return {
                        defaultValue: args[0],
                        context,
                    }
                },
            },
            (error) => {
                if (error) return reject(error)
                i18n.on('languageChanged', function (lng) {
                    instance.changeLanguage(lng)
                })
                return resolve(instance)
            }
        )
    })
}

export const loadNameSpace = (lang: string, ns: string) => {
    return i18n.reloadResources(lang || supportedLanguages, ns)
}

export const changeLanguage = (lang: string) => {
    return i18n.changeLanguage(lang)
}

export const getBrowserLanguage = () => {
    const browserLang = navigator.language
    if (browserLang?.indexOf('en', 0) === 0) {
        return 'en'
    }
    if (browserLang?.indexOf('fr', 0) === 0) {
        return 'fr'
    }
    return 'en'
}

export const I18nProvider = I18nextProvider

export const setLanguage = changeLanguage
export const withTranslation = _withTranslation
export const Translation = _Translation

if (!initiated) {
    init()
}
export default i18n

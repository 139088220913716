import { InvitationCreateRequest, InvitationResponse, InvitationResponseList, InvitationUpdateRequest, Page } from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Organization-invitations
 */
export class OrganizationInvitationsApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * Cancel an invitation
     * ${orgId}/invitations/${invitationId}
     **/

    async cancelOrgInvitation(orgId: string, invitationId: string): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.delete<void>(`/orgs/${orgId}/invitations/${invitationId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Update an invitation
     * ${orgId}/invitations/${invitationId}
     **/

    async updateOrgInvitation(orgId: string, invitationId: string, body: InvitationUpdateRequest): Promise<InvitationResponseList> {
        try {
            const res: AxiosResponse<InvitationResponseList> = await this.patch<InvitationResponseList, InvitationUpdateRequest>(`/orgs/${orgId}/invitations/${invitationId}`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Send an invitation reminder
     * ${orgId}/invitations/${invitationId}/reminders
     **/

    async remindInvitation(orgId: string, invitationId: string, body: unknown): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.post<void, unknown>(`/orgs/${orgId}/invitations/${invitationId}/reminders`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * List pending invitations
     * ${orgId}/invitations
     **/

    async listPendingOrgsInvitations(orgId: string, page?: Page, filter?: object, sort?: string): Promise<InvitationResponseList> {
        try {
            const res: AxiosResponse<InvitationResponseList> = await this.get<InvitationResponseList>(`/orgs/${orgId}/invitations`, { params: { page, filter, sort } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Invite a user to join the organization
     * ${orgId}/invitations
     **/

    async inviteMember(orgId: string, body: InvitationCreateRequest): Promise<InvitationResponse> {
        try {
            const res: AxiosResponse<InvitationResponse> = await this.post<InvitationResponse, InvitationCreateRequest>(`/orgs/${orgId}/invitations`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

import letteringGreen from '../../images/estateably_logo_lettering_green.svg'
import letteringBlack from '../../images/estateably_logo_lettering_k.svg'
import letteringWhite from '../../images/estateably_logo_lettering_ko.svg'
import logotypeGreen from '../../images/estateably_logo_logotype_green.svg'
import logotypeBlack from '../../images/estateably_logo_logotype_k.svg'
import logotypeWhite from '../../images/estateably_logo_logotype_ko.svg'
import symbolGreen from '../../images/estateably_logo_symbol_green.svg'
import symbolBlack from '../../images/estateably_logo_symbol_k.svg'
import symbolWhite from '../../images/estateably_logo_symbol_ko.svg'

const getLogo = (variant: 'lettering' | 'logotype' | 'symbol', color: 'green' | 'black' | 'white') => {
    switch (variant) {
        case 'lettering':
            switch (color) {
                case 'green':
                    return letteringGreen
                case 'black':
                    return letteringBlack
                case 'white':
                    return letteringWhite
            }
            break
        case 'logotype':
            switch (color) {
                case 'green':
                    return logotypeGreen
                case 'black':
                    return logotypeBlack
                case 'white':
                    return logotypeWhite
            }
            break
        case 'symbol':
            switch (color) {
                case 'green':
                    return symbolGreen
                case 'black':
                    return symbolBlack
                case 'white':
                    return symbolWhite
            }
            break
    }
    return symbolGreen
}

export const getCompanyLogo = (variant: 'lettering' | 'logotype' | 'symbol' = 'symbol', color: 'green' | 'black' | 'white' = 'green') => {
    return getLogo(variant, color)
}

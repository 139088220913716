import {
    ResourceResponse, // Type import ResourceResponse
    ResourcesResponse, // Type import ResourcesResponse
    Page,
    CaseTypes, // Type Pagination of list
} from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api, ResponseWithHeader } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Workflow-resources
 */
export class WorkflowResourcesApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * Delete a resource
     * ${resourceId}
     **/

    async deleteResource(resourceId: string): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.delete<void>(`/resources/${resourceId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Update a specific resource
     * ${resourceId}
     **/

    async updateResource(resourceId: string, body: unknown): Promise<ResourceResponse> {
        try {
            const res: AxiosResponse<ResourceResponse> = await this.patch<ResourceResponse, unknown>(`/resources/${resourceId}`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Get a specific resource
     * ${resourceId}
     **/

    async getResource(resourceId: string, province?: string): Promise<ResourceResponse> {
        try {
            const res: AxiosResponse<ResourceResponse> = await this.get<ResourceResponse>(`/resources/${resourceId}`, { params: { province } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Return a resource by ref globally or in the org
     * ${ref}/ref
     **/

    async getResourceByRef(ref: string, province?: string, caseType?: CaseTypes): Promise<ResourceResponse> {
        try {
            const res: AxiosResponse<ResourceResponse> = await this.get<ResourceResponse>(`/resources/${ref}/ref`, { params: { province, caseType } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Create a resource
     *
     **/

    async createResource(body: unknown): Promise<ResourceResponse> {
        try {
            const res: AxiosResponse<ResourceResponse> = await this.post<ResourceResponse, unknown>(`/resources/`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * List resources
     *
     **/

    async listResources(page?: Page, filter?: object, search?: string, sort?: string): Promise<ResourcesResponse> {
        try {
            const res: AxiosResponse<ResourcesResponse> = await this.get<ResourcesResponse>(`/resources/`, { params: { page, filter, search, sort } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

import React, { useMemo } from 'react'
import style from './style.module.scss'
import Icon from '../../particles/Icon'
import { GenericAttributes } from '../../types'
import * as VisuallyHidden from '@radix-ui/react-visually-hidden'

export type SearchProps = {
    hasBackground?: boolean
    value?: string
    placeholder?: string
    onChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void
    onFocus?: (ev: React.FocusEvent<HTMLElement>) => void
    onClick?: (ev: React.MouseEvent<HTMLElement>) => void
    onClickIcon?: (ev: React.MouseEvent<HTMLElement>) => void
    disabled?: boolean
    label?: string
} & GenericAttributes

export const Search = ({ hasBackground = true, value, placeholder, label, onClick, onChange, onClickIcon, onFocus, className, disabled, ...props }: SearchProps) => {
    const events = useMemo(
        () => ({
            onFocus,
            onClick,
            onChange,
        }),
        [onFocus, onClick, onChange]
    )
    const backgroundStyle = useMemo(() => (hasBackground ? style.background : null), [hasBackground])

    return (
        <form role={'search'} {...props} data-test={`${props['data-test']}`} className={[style.search, backgroundStyle, className].join(' ')}>
            <VisuallyHidden.Root asChild>
                <label htmlFor={'inputSearch'}>{label}</label>
            </VisuallyHidden.Root>
            <input id={'inputSearch'} disabled={disabled} {...events} value={value} placeholder={placeholder} className={[style.input].join(' ')} data-test={`${props['data-test']}.input`} />
            <div className={[style.rightIcon].join(' ')} onClick={onClickIcon} data-test={`${props['data-test']}.icon`}>
                {value ? <Icon type={Icon.types.Close} size={25} /> : <Icon type={Icon.types.Search} size={25} />}
            </div>
        </form>
    )
}
export default React.memo(Search)

import React, { PureComponent } from 'react'
// eslint-disable-next-line css-modules/no-unused-class
import style from './style.module.scss'
import AvatarText from '../../atoms/AvatarText'
import Link from '../../atoms/Link'
import Icon, { IconTypes } from '../../particles/Icon'
import { GenericAttributes } from '../../types'

type ItemInfoProps = {
    title: string
    subtitle?: string
    img?: string
    actionLabel?: string
    isSelected?: boolean
    onClick?: (ev: React.MouseEvent<HTMLElement>) => void
    onClickAction?: (ev: React.MouseEvent<HTMLElement>) => void
    leftIcon?: IconTypes
    actionIcon?: IconTypes
} & GenericAttributes

type ItemInfoState = {
    actionHover: boolean
}
export class ItemInfo extends PureComponent<ItemInfoProps, ItemInfoState> {
    static defaultProps = {}
    static iconTypes = IconTypes
    constructor(props: ItemInfoProps) {
        super(props)
        this.state = {
            actionHover: false,
        }
    }
    onClickActionHandler = (e: React.MouseEvent<HTMLElement>): void => {
        const { onClickAction } = this.props
        e.stopPropagation()
        onClickAction && onClickAction(e)
    }
    render(): React.ReactNode {
        const { title, subtitle, img, actionLabel, onClick, onClickAction, leftIcon, actionIcon, isSelected, ...props } = this.props
        return (
            <div {...props} data-test={`${this.props['data-test']}`} className={[style.itemInfo, isSelected ? style.selected : '', onClick && !this.state.actionHover ? style.action : ''].join(' ')}>
                <div data-test={`${this.props['data-test']}.content`} onClick={onClick} className={style.content}>
                    {img ? (
                        <div className={style.col1}>
                            <AvatarText data-test={`${this.props['data-test']}.avatar`} text={title} size={AvatarText.sizeTypes.small} img={img} />
                        </div>
                    ) : leftIcon ? (
                        <div className={style.col1}>
                            <Icon size={32} type={leftIcon} />
                        </div>
                    ) : null}

                    <div className={style.col2}>
                        <div data-test={`${this.props['data-test']}.title`} className={style.title}>
                            {title}
                        </div>
                        <div data-test={`${this.props['data-test']}.subtitle`} className={style.subtitle}>
                            {subtitle}
                        </div>
                    </div>
                </div>
                {onClickAction && actionLabel ? (
                    <div
                        data-test={`${this.props['data-test']}.link.wrapper`}
                        className={style.col3}
                        onMouseEnter={() => this.setState({ actionHover: true })}
                        onMouseLeave={() => this.setState({ actionHover: false })}>
                        <Link data-test={`${this.props['data-test']}.link`} onClick={this.onClickActionHandler}>
                            <p>{actionLabel}</p>
                        </Link>
                    </div>
                ) : actionIcon ? (
                    <div
                        data-test={`${this.props['data-test']}.icon`}
                        className={[style.col3, onClickAction ? style.action : '', isSelected ? style.selected : ''].join(' ')}
                        onClick={this.onClickActionHandler}
                        onMouseEnter={() => onClickAction && this.setState({ actionHover: true })}
                        onMouseLeave={() => this.setState({ actionHover: false })}>
                        <Icon size={32} type={actionIcon} />
                    </div>
                ) : null}
            </div>
        )
    }
}
export default ItemInfo

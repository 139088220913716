import React, { useMemo } from 'react'
import style from './style.module.scss'
import Icon from '../../particles/Icon'
import PageNumber from '../../atoms/PageNumber'
import { GenericAttributes } from '../../types'

const LAST_PAGE_ELLIPSIS = 'LAST_PAGE_ELLIPSIS'
const FIRST_PAGE_ELLIPSIS = 'RIGHT_ELLIPSIS'

type PageBarProps = {
    selectedPage?: number
    numberOfPages: number
    maxNumberOfItems?: number
    offset?: number
    disabled?: boolean
    onPageSelected?: (index: number) => void
    onPreviousPage?: (ev: React.MouseEvent<HTMLElement>) => void
    onNextPage?: (ev: React.MouseEvent<HTMLElement>) => void
} & GenericAttributes

export const PageBar = ({ selectedPage = 1, numberOfPages, onNextPage, onPageSelected, onPreviousPage, disabled = false, ...props }: PageBarProps) => {
    const range = (from: number, to: number, step = 1): number[] => {
        let i = from
        const range = []
        while (i <= to) {
            range.push(i)
            i += step
        }
        return range
    }

    const pager = useMemo(() => {
        const totalPages = numberOfPages
        const currentPage = selectedPage
        const pageNeighbours = 3 /* < 1... 2, 3, 4 => 5 <== 6, 7, 8 <= ...14> Three pages between the selected and the first/last ones*/
        const totalNumbers = pageNeighbours * 2 + 2
        if (totalPages > totalNumbers) {
            let pages = []
            const leftBound = currentPage - pageNeighbours
            const rightBound = currentPage + pageNeighbours
            const beforeLastPage = totalPages - 1
            const startPage = leftBound > 2 ? leftBound : 2
            const endPage = rightBound < beforeLastPage ? rightBound : beforeLastPage
            pages = range(startPage, endPage)
            const pagesCount = pages.length
            const singleSpillOffset = totalNumbers - pagesCount - 2
            const leftSpill = startPage > 2
            const rightSpill = endPage < beforeLastPage
            const leftSpillPage = LAST_PAGE_ELLIPSIS
            const rightSpillPage = FIRST_PAGE_ELLIPSIS
            if (leftSpill && !rightSpill) {
                const extraPages = range(startPage - singleSpillOffset, startPage - 1)
                pages = [leftSpillPage, ...extraPages, ...pages]
            } else if (!leftSpill && rightSpill) {
                const extraPages = range(endPage + 1, endPage + singleSpillOffset)
                pages = [...pages, ...extraPages, rightSpillPage]
            } else if (leftSpill && rightSpill) {
                pages = [leftSpillPage, ...pages, rightSpillPage]
            }
            return [1, ...pages, totalPages]
        }
        return range(1, totalPages)
    }, [numberOfPages, selectedPage])

    if (numberOfPages < 2) return null
    return (
        <div {...props} className={[style.pageBar, disabled ? style.disabled : ''].join(' ')}>
            {selectedPage > 1 ? (
                <div className={style.arrow} onClick={onPreviousPage} data-test={`${props['data-test']}.previous`}>
                    <Icon type={Icon.types.ArrowLeft} size={30} />
                </div>
            ) : null}
            {pager.map((page, i) => {
                let label = page
                if (pager[i] === LAST_PAGE_ELLIPSIS || pager[i] === FIRST_PAGE_ELLIPSIS) {
                    return null
                }
                if (i < pager.length - 1 && pager[i + 1] === LAST_PAGE_ELLIPSIS) {
                    label = `${page}...`
                }
                if (i > 0 && pager[i - 1] === FIRST_PAGE_ELLIPSIS) {
                    label = `...${page}`
                }
                return (
                    <PageNumber data-test={`${props['data-test']}.pages.${i}`} onClick={() => onPageSelected && onPageSelected(page as number)} selected={selectedPage === page} key={page}>
                        {label}
                    </PageNumber>
                )
            })}
            {selectedPage < numberOfPages ? (
                <div className={style.arrow} onClick={onNextPage} data-test={`${props['data-test']}.next`}>
                    <Icon type={Icon.types.ArrowRight} size={30} />
                </div>
            ) : null}
        </div>
    )
}

export default React.memo(PageBar)

import { NoticeRequestCreate, NoticeResponse } from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Notice
 */
export class NoticeApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }

    //Methods

    /**
     * Create an notice to an estate case
     * ${estateId}/notice
     **/

    async createNotice(estateId: string, body: NoticeRequestCreate): Promise<NoticeResponse> {
        try {
            const res: AxiosResponse<NoticeResponse> = await this.post<NoticeResponse, NoticeRequestCreate>(`/estates/${estateId}/notice`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

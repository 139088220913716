import { JobsResponse, Page } from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Jobs
 */
export class JobsApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * Cancel a job in a session
     * sessions/${sessionId}/${jobId}
     **/

    async deleteJobInSession(jobId: string, sessionId: string): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.delete<void>(`/jobs/sessions/${sessionId}/${jobId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * List all jobs
     * sessions/${sessionId}
     **/

    async listJobsBySessionId(sessionId: string, page?: Page, filter?: object, search?: string, sort?: string): Promise<JobsResponse> {
        try {
            const res: AxiosResponse<JobsResponse> = await this.get<JobsResponse>(`/jobs/sessions/${sessionId}`, { params: { page, filter, search, sort } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * List all jobs
     * ${estateId}/jobs/allUser
     **/

    async listJobsByEstate(estateId: string, page?: Page, filter?: object, search?: string, sort?: string): Promise<JobsResponse> {
        try {
            const res: AxiosResponse<JobsResponse> = await this.get<JobsResponse>(`/estates/${estateId}/jobs/allUser`, { params: { page, filter, search, sort } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Cancel a job
     * ${estateId}/jobs/${jobId}
     **/

    async deleteJob(jobId: string, estateId: string): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.delete<void>(`/estates/${estateId}/jobs/${jobId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Get a specific job
     * ${estateId}/jobs/${jobId}
     **/

    async getJob(jobId: string, estateId: string): Promise<unknown> {
        try {
            const res: AxiosResponse<unknown> = await this.get<unknown>(`/estates/${estateId}/jobs/${jobId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * List jobs
     * ${estateId}/jobs
     **/

    async listJobs(estateId: string, page?: Page, filter?: object, search?: string, sort?: string): Promise<unknown> {
        try {
            const res: AxiosResponse<unknown> = await this.get<unknown>(`/estates/${estateId}/jobs`, { params: { page, filter, search, sort } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

import React, { PropsWithChildren } from 'react'
import style from './style.module.scss'
import { GenericAttributes } from '../../types'

type LabeledToggleProps = {
    disabled?: boolean
    selected?: boolean
    onClick?: (ev: React.MouseEvent<HTMLElement>) => void
} & GenericAttributes

export const LabeledToggle = ({ selected = false, disabled = false, onClick, children, ...props }: PropsWithChildren<LabeledToggleProps>) => {
    return (
        <div
            {...props}
            data-test={`${props['data-test']}`}
            className={[style.labeledToggle, selected ? style.selected : style.default, disabled ? style.disabled : ''].join(' ')}
            aria-label={`${children}`}
            tabIndex={0}
            role={'radio'}
            aria-checked={selected}>
            <input type='radio' id={`option-${props['data-test']}`} onClick={!disabled ? onClick : undefined} className={style.visuallyHidden} />
            <label className={[style.labeledToggle, selected ? style.selected : style.default ? style.labeledTag : ''].join(' ')}>{children}</label>
        </div>
    )
}
export default React.memo(LabeledToggle)

import {
    ACTION_DELETE_TRUST,
    ACTION_EDIT_TRUST,
    ACTION_FETCH_BILLING_PLAN,
    ACTION_FETCH_MEMBERS,
    ACTION_FETCH_PROFILE,
    ACTION_FETCH_TEAMS,
    ACTION_FETCH_TRUST_COLLABORATORS,
    ACTION_FETCH_TRUSTS,
    ACTION_FETCH_WORKFLOW,
    ACTION_NEW_TRUST,
    ACTION_SAVE_PREFERENCE,
    ACTION_SAVE_PROFILE,
    ACTION_SET_SEARCH,
} from './actions'

const EMPTY_TRUST = {
    name: '',
    case: -1,
    userInfo: null,
    userTeams: null,
}
const DEFAULT_STATE = {
    list: [],
    userTeams: undefined,
    estateCollaborators: [],
    visibleList: [],
    editing: EMPTY_TRUST,
    search: '',
    sort: '-meta.createdAt',
    loading: false,
    currentPage: 0,
    meta: {},
    error: '',
    selectedEstate: null,
    selectedStatusFilter: null,
    reportsData: {},
    usersList: null,
    showMissingPaymentPrompt: null,
    billingPlan: undefined,
}
export default (state = DEFAULT_STATE, { type, payload }) => {
    if (type.includes('REJECTED')) {
        if (
            (payload &&
                payload.errors &&
                payload.errors._error && //coming from forms
                (payload.errors._error === 'Missing valid payment method' || payload.errors._error === 'Organization has a missing payment')) ||
            (payload &&
                payload.errors &&
                payload.errors.length && //coming from requests
                parseInt(payload.errors[0].status) === 402)
        ) {
            state.showMissingPaymentAlert = true
        } else {
            state.showMissingPaymentAlert = null
        }
    }
    switch (type) {
        case `ACTION_LOGOFF`:
            return DEFAULT_STATE
        case `${ACTION_FETCH_MEMBERS}_PENDING`:
            return { ...state, usersList: null }
        case `${ACTION_FETCH_MEMBERS}_FULFILLED`:
            return { ...state, usersList: payload.data }
        case `${ACTION_FETCH_WORKFLOW}_PENDING`:
            return { ...state }
        case `${ACTION_FETCH_WORKFLOW}_FULFILLED`:
            return { ...state, workflow: payload.data.attributes }
        case `${ACTION_FETCH_WORKFLOW}_REJECTED`:
            return { ...state, workflow: null }
        case `${ACTION_NEW_TRUST}_FULFILLED`:
            return {
                ...state,
                editing: EMPTY_TRUST,
                list: [payload.data, ...state.list],
            }
        case `${ACTION_NEW_TRUST}`:
        case `${ACTION_NEW_TRUST}_REJECTED`:
            return { ...state, editing: EMPTY_TRUST }
        case ACTION_EDIT_TRUST:
            return { ...state, editing: payload || EMPTY_TRUST }
        case `${ACTION_EDIT_TRUST}_FULFILLED`:
            return {
                ...state,
                editing: EMPTY_TRUST,
                list: [...state.list.filter((estate) => estate.id != payload.data.id), payload.data],
            }
        case `${ACTION_EDIT_TRUST}_REJECTED`:
            return { ...state }
        case `${ACTION_DELETE_TRUST}_FULFILLED`:
            if (payload)
                return {
                    ...state,
                    editing: EMPTY_TRUST,
                    list: [...state.list.filter((estate) => estate.id != payload)],
                }
            else return { ...state, editing: EMPTY_TRUST }
        case `${ACTION_DELETE_TRUST}_REJECTED`:
            return { ...state }
        case ACTION_SET_SEARCH:
            return {
                ...state,
                search: payload.name,
                sort: payload.sort,
                currentPage: payload.currentPage,
                selectedStatusFilter: payload.selectedStatusFilter,
            }
        case `${ACTION_FETCH_TEAMS}_FULFILLED`: {
            return {
                ...state,
                userTeams: payload.data,
            }
        }
        case `${ACTION_FETCH_TEAMS}_REJECTED`: {
            return { ...state, list: [], loading: false, error: payload }
        }
        case `${ACTION_FETCH_TRUSTS}_FULFILLED`:
            return {
                ...state,
                editing: EMPTY_TRUST,
                list: payload.data,
                meta: payload.meta,
                loading: false,
            }
        case `${ACTION_FETCH_TRUSTS}_REJECTED`:
            return { ...state, list: [], loading: false, error: payload }
        case `${ACTION_FETCH_TRUSTS}_PENDING`:
            return { ...state, list: [], loading: true }
        case `${ACTION_FETCH_PROFILE}_FULFILLED`:
        case `${ACTION_SAVE_PROFILE}_FULFILLED`:
            return { ...state, userProfile: payload }
        case `${ACTION_SAVE_PREFERENCE}_FULFILLED`:
            return { ...state, userInfo: payload }
        case `${ACTION_FETCH_TRUST_COLLABORATORS}_FULFILLED`:
            return { ...state, estateCollaborators: payload.data }
        case `${ACTION_FETCH_BILLING_PLAN}_FULFILLED`:
            return {
                ...state,
                billingPlan: payload.data.attributes,
            }
        case `${ACTION_FETCH_BILLING_PLAN}_REJECTED`:
            return {
                ...state,
                billingPlan: undefined,
            }
        default:
            return state
    }
}

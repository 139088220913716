import { CommentRequestUpdate, CommentResponse, CommentResponseList, Page, CommentRequest } from '../types'
/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import { Api } from '../api'
import { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios'

/*
 * https://app.estateably.com/api-docs/#tag/Estate-comments
 */
export class EstateCommentsApi extends Api {
    constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        super(config, cancelTokenSource)
    }
    //Methods

    /**
     * Create a comment
     * ${estateId}/comments
     **/

    async createEstateComment(estateId: string, body: CommentRequest): Promise<CommentResponse> {
        try {
            const res: AxiosResponse<CommentResponse> = await this.post<CommentResponse, CommentRequest>(`/estates/${estateId}/comments`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Delete a comment
     * ${estateId}/comments/${commentId}
     **/

    async deleteEstateComment(estateId: string, commentId: string): Promise<void> {
        try {
            const res: AxiosResponse<void> = await this.delete<void>(`/estates/${estateId}/comments/${commentId}`)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * Update a comment
     * ${estateId}/comments/${commentId}
     **/

    async updateEstateComment(estateId: string, commentId: string, body: CommentRequestUpdate): Promise<CommentResponse> {
        try {
            const res: AxiosResponse<CommentResponse> = await this.patch<CommentResponse, CommentRequestUpdate>(`/estates/${estateId}/comments/${commentId}`, body)
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }

    /**
     * List comments
     * ${estateId}/comments
     **/

    async listEstateComments(estateId: string, page?: Page, filter?: object, sort?: string): Promise<CommentResponseList> {
        try {
            const res: AxiosResponse<CommentResponseList> = await this.get<CommentResponseList>(`/estates/${estateId}/comments`, { params: { page, filter, sort } })
            return this.success(res)
        } catch (error) {
            throw this.error(error)
        }
    }
}

/**
 * This file was automatically generated by estateably.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run npm run api:client to regenerate this file.
 */
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosResponseHeaders, Canceler, CancelTokenSource, InternalAxiosRequestConfig, RawAxiosResponseHeaders } from 'axios'
import { apiConfig } from './api.config'
import DeepMergeObjects from '../../utils/deepmergeObjects'
import { SubmissionError } from 'redux-form'

export interface ResponseWithHeader<T> {
    data: T
    headers: RawAxiosResponseHeaders | AxiosResponseHeaders
}

/**
 * ES6 Axios Class.
 *
 * @class Api
 * @extends {Axios}
 * @example
 * class UserApi extends Api {
 *   public constructor (config) {
 *     super(config);
 *
 *     this.login=this.login.bind(this);
 *   }
 *
 *   public login (user: User) {
 *     return this.api.post<string, User, AxiosResponse<User>>("https://www.domain/login", {name: user.name, pass: user.pass})
 *        .then((res: AxiosResponse<string>) => res.data);
 *   }
 * }
 */
export class Api {
    source: CancelTokenSource
    api: AxiosInstance
    cancel: Canceler

    /**
     * Creates an instance of Api.
     *
     * @param {import("axios").AxiosRequestConfig} [config] - axios configuration.
     * @memberof Api
     */
    public constructor(config?: AxiosRequestConfig, cancelTokenSource?: CancelTokenSource) {
        this.source = cancelTokenSource || axios.CancelToken.source()
        this.api = axios.create({ ...apiConfig, ...config })
        this.api.interceptors.request.use((param: InternalAxiosRequestConfig) => {
            return {
                baseUrl: config?.baseURL,
                cancelToken: this.source.token,
                ...param,
            }
        })
        this.cancel = this.source.cancel
        this.getUri = this.getUri.bind(this)
        this.request = this.request.bind(this)
        this.get = this.get.bind(this)
        this.delete = this.delete.bind(this)
        this.head = this.head.bind(this)
        this.post = this.post.bind(this)
        this.put = this.put.bind(this)
        this.patch = this.patch.bind(this)
    }

    /**
     * Get Uri
     *
     * @param {import("axios").AxiosRequestConfig} [config]
     * @returns {string}
     * @memberof Api
     */
    public getUri(config?: AxiosRequestConfig): string {
        return this.api.getUri(config)
    }

    /**
     * Generic request.
     *
     * @access public
     * @template T - `TYPE`: expected object.
     * @template R - `RESPONSE`: expected object inside a axios response format.
     * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
     * @returns {Promise<R>} - HTTP axios response payload.
     * @memberof Api
     *
     * @example
     * api.request({
     *   method: "GET|POST|DELETE|PUT|PATCH"
     *   baseUrl: "http://www.domain.com",
     *   url: "/api/v1/users",
     *   headers: {
     *     "Content-Type": "application/json"
     *  }
     * }).then((response: AxiosResponse<User>) => response.data)
     *
     */
    public request<T, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
        return this.api.request(config)
    }

    /**
     * HTTP GET method, used to fetch data `statusCode`: 200.
     *
     * @access public
     * @template T - `TYPE`: expected object.
     * @template R - `RESPONSE`: expected object inside a axios response format.
     * @param {string} url - endpoint you want to reach.
     * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
     * @returns {Promise<R>} HTTP `axios` response payload.
     * @memberof Api
     */
    public get<T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return this.api.get(url, { ...config })
    }

    /**
     * HTTP DELETE method, `statusCode`: 204 No Content.
     *
     * @access public
     * @template T - `TYPE`: expected object.
     * @template R - `RESPONSE`: expected object inside a axios response format.
     * @param {string} url - endpoint you want to reach.
     * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
     * @returns {Promise<R>} - HTTP [axios] response payload.
     * @memberof Api
     */
    public delete<T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return this.api.delete(url, config)
    }

    /**
     * HTTP HEAD method.
     *
     * @access public
     * @template T - `TYPE`: expected object.
     * @template R - `RESPONSE`: expected object inside a axios response format.
     * @param {string} url - endpoint you want to reach.
     * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
     * @returns {Promise<R>} - HTTP [axios] response payload.
     * @memberof Api
     */
    public head<T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> {
        return this.api.head(url, config)
    }

    /**
     * HTTP POST method `statusCode`: 201 Created.
     *
     * @access public
     * @template T - `TYPE`: expected object.
     * @template B - `BODY`: body request object.
     * @template R - `RESPONSE`: expected object inside a axios response format.
     * @param {string} url - endpoint you want to reach.
     * @param {B} data - payload to be send as the `request body`,
     * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
     * @returns {Promise<R>} - HTTP [axios] response payload.
     * @memberof Api
     */
    public post<T, B, R = AxiosResponse<T>>(url: string, data?: B, config?: AxiosRequestConfig): Promise<R> {
        return this.api.post(url, data, config)
    }

    /**
     * HTTP PUT method.
     *
     * @access public
     * @template T - `TYPE`: expected object.
     * @template B - `BODY`: body request object.
     * @template R - `RESPONSE`: expected object inside a axios response format.
     * @param {string} url - endpoint you want to reach.
     * @param {B} data - payload to be send as the `request body`,
     * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
     * @returns {Promise<R>} - HTTP [axios] response payload.
     * @memberof Api
     */
    public put<T, B, R = AxiosResponse<T>>(url: string, data?: B, config?: AxiosRequestConfig): Promise<R> {
        return this.api.put(url, data, config)
    }

    /**
     * HTTP PATCH method.
     *
     * @access public
     * @template T - `TYPE`: expected object.
     * @template B - `BODY`: body request object.
     * @template R - `RESPONSE`: expected object inside a axios response format.
     * @param {string} url - endpoint you want to reach.
     * @param {B} data - payload to be send as the `request body`,
     * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
     * @returns {Promise<R>} - HTTP [axios] response payload.
     * @memberof Api
     */
    public patch<T, B, R = AxiosResponse<T>>(url: string, data?: B, config?: AxiosRequestConfig): Promise<R> {
        return this.api.patch(url, data, config)
    }

    /**
     *
     * @template T - type.
     * @param {import("axios").AxiosResponse<T>} response - axios response.
     * @returns {T} - expected object.
     * @memberof Api
     */
    public success<T>(response: AxiosResponse<T>): T {
        return response.data
    }

    /**
     *
     * @template T - type.
     * @param {import("axios").AxiosResponse<T>} response - axios response.
     * @returns {T} - expected object.
     * @memberof Api
     */
    public successWithHeader<T>(response: AxiosResponse<T>): ResponseWithHeader<T> {
        return {
            data: response.data,
            headers: response.headers,
        }
    }

    public error(error: AxiosError<Error | JSONAPIResponseError>) {
        if ((error.response?.headers['content-type'] || '').indexOf('application/vnd.api+json') >= 0) {
            const data = error.response?.data as JSONAPIResponseError
            const errors = data.errors
            let haveForm = false
            let form: any = {}
            errors?.map((error) => {
                haveForm = true
                if (error.source && error.source.pointer && error.source.pointer.startsWith('/data/attributes/')) {
                    const atts = error.source.pointer.substr(17).split('/')
                    const obj = {}
                    let curr: any = obj
                    for (let i = 0; i < atts.length; i++) {
                        curr[atts[i]] = i == atts.length - 1 ? error.title || error.detail : {}
                        curr = curr[atts[i]]
                    }
                    form = DeepMergeObjects.mergeAll([form, obj])
                } else {
                    const obj = {
                        _error: error.title || error.detail,
                        status: parseInt(error.status || '0'),
                    }
                    form = DeepMergeObjects.mergeAll([form, obj])
                }
            })
            if (haveForm) {
                throw new SubmissionError(form)
            }
        }
        if (error.response?.status == 413) {
            throw {
                errors: [{ title: 'Request too large' }],
            }
        }

        const errorMessage = (error.response?.data as any)?.error
        const errorStatus = error.response?.status
        if (axios.isCancel(error)) {
            throw {
                message: 'Cancelled',
                cancelled: true,
            }
        } else {
            throw new SubmissionError({ _error: errorMessage, status: errorStatus })
        }
    }
}

interface JSONAPIError {
    meta?: {
        stack?: string
    }
    title: string
    detail: string
    status: string
    source: {
        pointer: string
    }
}

interface JSONAPIResponseError {
    errors: Array<JSONAPIError>
}

import React, { PureComponent } from 'react'
import style from './style.module.scss'
import Icon from '../../particles/Icon'
import { CSSTransition } from 'react-transition-group'
import { GenericAttributes } from '../../types'

type CollapsableListProps = {
    onClick?: (ev: React.MouseEvent<HTMLElement>) => void
    title: string
    collapsed?: boolean
    children?: React.ReactNode
} & GenericAttributes

export class CollapsableList extends PureComponent<CollapsableListProps> {
    static defaultProps = {
        collapsed: false,
    }

    render(): React.ReactNode {
        const { title, children, onClick, collapsed } = this.props
        return (
            <div className={style.collapsableHeader} data-test={`${this.props['data-test']}`}>
                <div data-test={`${this.props['data-test']}.header`} onClick={onClick} className={style.header}>
                    <div data-test={`${this.props['data-test']}.header.title`} className={style.title}>
                        {title}
                    </div>
                    <div className={style.grow} />
                    <Icon size={30} type={collapsed ? Icon.types.ChevronClose : Icon.types.ChevronOpen} />
                </div>
                {
                    <div className={[style.content, collapsed ? style.collapsed : ''].join(' ')}>
                        <CSSTransition in={collapsed} classNames={'collapseListAnimation'} timeout={250}>
                            {collapsed ? children ? <div className={'collapse-body'}>{children}</div> : <div /> : <div />}
                        </CSSTransition>
                    </div>
                }
            </div>
        )
    }
}

export default CollapsableList

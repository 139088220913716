import React, { PureComponent } from 'react'
import style from './style.module.scss'
import { IconTypes } from '../../particles/Icon'
import AvatarCircle from '../../atoms/AvatarCircle'
import { GenericAttributes } from '../../types'

type AvatarLinkProps = {
    defaultIcon?: IconTypes
    src?: string
    onClick?: (ev: React.MouseEvent<HTMLElement>) => void
} & GenericAttributes
export class AvatarLink extends PureComponent<AvatarLinkProps> {
    static iconTypes = IconTypes
    static defaultProps = {
        defaultIcon: IconTypes.Dynamic,
    }
    render(): React.ReactNode {
        const { children, defaultIcon, onClick, src, ...props } = this.props
        return (
            <div {...props} data-test={`${this.props['data-test']}`} className={[style.avatarLink].join(' ')} onClick={onClick}>
                <AvatarCircle data-test={`${this.props['data-test']}.avatar`} icon={defaultIcon} src={src} size={AvatarCircle.sizeTypes.large} />
                <p>{children}</p>
            </div>
        )
    }
}
export default AvatarLink
